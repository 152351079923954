import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs/internal/Subscription";
import { IEvent, IGroup, ILocation, IModule, ITrack } from "src/app/shared/interfaces";
import {
	IFilterOptions,
	IFilteredItemFormat,
	IFullCustomField
} from "src/app/shared/interfaces/custom-fields.interfaces";
import { IScheduleByTrack } from "src/app/shared/interfaces/schedules.interfaces";
import { environment } from "src/environments/environment";
import { TypeModule } from "../../../../../../../../shared/enums/type-module";
import { IFilter, IFilters } from "src/app/shared/interfaces/filters.interfaces";

@Component({
	selector: "app-filter",
	templateUrl: "./filter.component.html",
	styleUrls: ["./filter.component.scss"],
	standalone: false
})
export class FilterComponent implements OnInit {
	@Input() event: IEvent;
	@Input() module: IModule;

	// Generals filters
	@Input() selectedFilters: IFilteredItemFormat[];
	@Input() notSelectedFilters: IFilteredItemFormat[];
	@Input() notSelectedCustomFieldFilters: IFilteredItemFormat[];

	// Generals datas
	@Input() filters: IFilters;
	@Input() allDatas: any[];
	@Input() filteredDatas: any[];
	@Input() selectedDatas: IFilterOptions[];
	@Input() customFields: IFullCustomField[];
	@Input() groups: IGroup[];
	totalItemFiltered: number = 0;

	// Schedule datas
	@Input() sessionsByTrackFiltered: IScheduleByTrack[];
	@Input() notSelectedLocationDatas: IFilter[];
	@Input() notSelectedTrackDatas: IFilter[];
	@Input() selectedDate: { startDate: string; endDate: string };
	@Input() tracks: ITrack[];
	@Input() locations: ILocation[];

	languageSub: Subscription;
	typeModule = TypeModule;
	language: string = environment.platform.defaultLanguage;

	filtersCollapseState: { uid: string; collapsed: boolean }[] = [];

	constructor(
		private modalCtrl: ModalController,
		private STranslate: TranslateService
	) {}

	ngOnInit(): void {
		// Schedule filters collaspe state
		if (this.filters) {
			this.filtersCollapseState = this.filtersCollapseState.concat([
				{ uid: "locations", collapsed: this.filterIsActivated("locations") ? false : true },
				{ uid: "tracks", collapsed: this.filterIsActivated("tracks") ? false : true },
				{ uid: "groups", collapsed: this.filterIsActivated("groups") ? false : true }
			]);
			this.filters.customFields?.forEach((filter) => {
				this.filtersCollapseState.push({
					uid: filter.uid,
					collapsed: this.filterIsActivated("customs-fields", filter.uid) ? false : true
				});
			});

			this.totalItemFiltered = this.filteredDatas.length;
		}
	}

	ionViewWillEnter() {
		this.language = this.STranslate.currentLang;
		this.languageSub = this.STranslate.onLangChange.subscribe((lang) => {
			this.language = lang.lang;
		});
	}

	/**
	 * closeFilter
	 */
	closeFilter() {
		// this.clearAllFilter(); // ?
		this.modalCtrl.dismiss({
			notSelectedFilters: this.module.type === TypeModule.SCHEDULE ? [] : this.notSelectedFilters,
			notSelectedTrackDatas: this.module.type !== TypeModule.SCHEDULE ? [] : this.notSelectedTrackDatas,
			notSelectedLocationDatas: this.module.type !== TypeModule.SCHEDULE ? [] : this.notSelectedLocationDatas,
			notSelectedCustomFieldFilters:
				this.module.type !== TypeModule.SCHEDULE ? [] : this.notSelectedCustomFieldFilters,
			allDatas: this.allDatas,
			filteredDatas: this.filteredDatas,
			selectedDatas: this.module.type === TypeModule.SCHEDULE ? [] : this.selectedDatas,
			customFields: this.customFields,
			selectedDate: this.module.type === TypeModule.SCHEDULE ? this.selectedDate : null,
			tracks: this.module.type === TypeModule.SCHEDULE ? this.tracks : [],
			locations: this.module.type === TypeModule.SCHEDULE ? this.locations : [],
			sessionsByTrackFiltered: this.module.type === TypeModule.SCHEDULE ? this.sessionsByTrackFiltered : [],
			selectedFilters: this.module.type === TypeModule.SCHEDULE ? this.selectedFilters : [],
			filters: this.module.type === TypeModule.SCHEDULE ? this.filters : []
		});
	}

	/**
	 * onFilter
	 */
	onFilter() {
		this.modalCtrl.dismiss({
			notSelectedFilters: this.module.type === TypeModule.SCHEDULE ? [] : this.notSelectedFilters,
			notSelectedTrackDatas: this.module.type !== TypeModule.SCHEDULE ? [] : this.notSelectedTrackDatas,
			notSelectedLocationDatas: this.module.type !== TypeModule.SCHEDULE ? [] : this.notSelectedLocationDatas,
			notSelectedCustomFieldFilters:
				this.module.type !== TypeModule.SCHEDULE ? [] : this.notSelectedCustomFieldFilters,
			filteredDatas: this.filteredDatas,
			allDatas: this.allDatas,
			selectedDatas: this.module.type === TypeModule.SCHEDULE ? [] : this.selectedDatas,
			customFields: this.customFields,
			selectedDate: this.module.type === TypeModule.SCHEDULE ? this.selectedDate : null,
			tracks: this.module.type === TypeModule.SCHEDULE ? this.tracks : [],
			locations: this.module.type === TypeModule.SCHEDULE ? this.locations : [],
			sessionsByTrackFiltered: this.module.type === TypeModule.SCHEDULE ? this.sessionsByTrackFiltered : [],
			selectedFilters:
				this.module.type === TypeModule.SCHEDULE ||
				this.module.type === TypeModule.ATTENDEE ||
				this.module.type === TypeModule.SPEAKER
					? this.selectedFilters
					: [],
			filters: this.filters
		});
	}

	/**
	 * filterIsActivated
	 * @description return boolean on whether the given filter has a selected data in it or not
	 * @param filter
	 * @returns filter
	 */
	filterIsActivated(filterName: string, customFieldId?: string) {
		if (filterName === "locations") {
			return this.filters.locations.some((location) => location.checked);
		} else if (filterName === "tracks") {
			return this.filters.tracks.some((track) => track.checked);
		} else if (filterName === "groups") {
			return this.filters.groups.some((group) => group.checked);
		} else if (filterName === "customs-fields") {
			return customFieldId
				? this.filters.customFields.some((cus) =>
						cus.values.some((value) => value.filterId === customFieldId && value.isSelected)
					)
				: this.filters.customFields.some((cus) => cus.values.some((value) => value.isSelected));
		}
	}

	/**
	 * clearSessionItemFilterrFilter
	 * @description remove checked option from the given filter
	 * @param filter
	 */
	clearFilter(filterName: string, customFieldId?: string) {
		switch (filterName) {
			case "locations":
				this.filters.locations.forEach((location) => (location.checked = false));
				break;

			case "tracks":
				this.filters.tracks.forEach((track) => (track.checked = false));
				break;

			case "groups":
				this.filters.groups.forEach((group) => (group.checked = false));
				break;

			case "customs-fields":
				customFieldId
					? this.filters.customFields.forEach((cus) =>
							cus.values.forEach(
								(value) => value.filterId === customFieldId && (value.isSelected = false)
							)
						)
					: this.filters.customFields.forEach((cus) =>
							cus.values.forEach((value) => (value.isSelected = false))
						);
				break;
		}
	}

	/**
	 * clearAllFilter
	 * @description remove all checked options from all filters
	 */
	clearAllFilter() {
		this.clearFilter("locations");
		this.clearFilter("tracks");
		this.clearFilter("groups");
		this.clearFilter("customs-fields");
	}

	/**
	 * collapse
	 * @param index
	 */
	collapse(filterId: string) {
		this.filtersCollapseState.find((filterItem) => filterItem.uid === filterId).collapsed =
			!this.filtersCollapseState.find((filterItem) => filterItem.uid === filterId).collapsed;
	}

	getFilterCollapseState(filterId: string): boolean {
		return this.filtersCollapseState.find((filterItem) => filterItem.uid === filterId)
			? this.filtersCollapseState.find((filterItem) => filterItem.uid === filterId).collapsed
			: false;
	}

	resetFilter() {
		this.clearAllFilter();
	}
}
