import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { IEvent } from "src/app/shared/interfaces";

@Component({
	selector: "app-privacy-terms",
	templateUrl: "./privacy-terms.component.html",
	styleUrls: ["./privacy-terms.component.scss"],
	standalone: false
})
export class PrivacyTermsComponent implements OnInit {
	privacy: string = null;
	event: IEvent;

	constructor(
		private modalCtrl: ModalController,
		private navParams: NavParams,
		private STranslate: TranslateService
	) {}

	ngOnInit() {
		if (!this.navParams.get("privacy")) {
			if (!this.privacy) {
				this.privacy = this.event.privacy.content[this.STranslate.currentLang]
					? this.event.privacy.content[this.STranslate.currentLang]
					: this.event.privacy.content;
			}
		} else {
			(!this.privacy || this.privacy === "") && (this.privacy = this.navParams.get("privacy"));
		}
	}

	close() {
		this.modalCtrl.dismiss();
	}
}
