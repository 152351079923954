import { Component, OnDestroy } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, ILocation, IModule } from "src/app/shared/interfaces";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getLocations } from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService } from "src/app/shared/services";

@Component({
	selector: "app-locations",
	templateUrl: "./locations.component.html",
	styleUrls: ["./locations.component.scss"],
	standalone: false
})
export class LocationsComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	loader: boolean = true;

	event: IEvent;
	eventId: string;
	moduleId: string;
	module: IModule;
	locations: ILocation[] = [];

	isMobile: boolean = false;

	constructor(
		private SAnalytics: AnalyticsService,
		private platform: Platform,
		private store: Store,
		private navCtrl: NavController
	) {}

	ionViewWillEnter() {
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;

						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_LOCATIONS_MODULE
						);

						this.getEvent();
						this.getModule();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));

					this.getLocations();
				}
			})
		);
	}

	/**
	 * Getting locations list
	 */
	getLocations() {
		this.store
			.select(getLocations(this.module.options.locationsOrder))
			.pipe(take(1))
			.subscribe((locations) => {
				if (!_.isEqual(this.locations, locations)) {
					this.locations = locations;
				}
				this.loader = false;
			});
	}

	/**
	 * Open location
	 * @param location
	 */
	openLocation(location: ILocation) {
		this.navCtrl.navigateForward(`event/${this.event.uid}/locations/${this.moduleId}/${location.uid}`);
	}
}
