import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, take } from "rxjs";
import { IEvent, IEventUser, IModule } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AppointmentsService, FirestoreService, UtilityService } from "src/app/shared/services";
import {
	IAppointmentRule,
	IAppointmentTimeSlotSchedule,
	IAvailableTimeSlotSchedule,
	IAvailableTimeSlots
} from "src/app/shared/interfaces/appointments.interfaces";
import { DateTime } from "luxon";
import { GetHeaderState, Loading } from "src/app/shared/actions/utility.actions";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TimezoneSwitchComponent } from "../../../modals/timezone-switch/timezone-switch.component";

@Component({
	selector: "app-appointments-settings",
	templateUrl: "./appointments-settings.component.html",
	styleUrls: ["./appointments-settings.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class AppointmentsSettingsComponent implements OnDestroy {
	languageSub: Subscription;
	subscriptions: Subscription[] = [];

	loader: boolean = true;
	limit: number = 10;

	event: IEvent;
	eventId: string;
	module: IModule;
	moduleId: string;
	eventUser: IEventUser = null;
	availableTimeSlots: IAvailableTimeSlots[] = [];

	isMobile: boolean = false;
	currentLanguage: string;

	selectedDayByRule: Record<string, IAvailableTimeSlots> = {};

	constructor(
		private modalCtrl: ModalController,
		private store: Store,
		private platform: Platform,
		private SAppointments: AppointmentsService,
		private SFirestore: FirestoreService,
		public SUtility: UtilityService,
		private STranslate: TranslateService,
		private snackbar: MatSnackBar,
		private cd: ChangeDetectorRef
	) {
		this.isMobile = this.platform.is("ios") || this.platform.is("android") ? true : false;
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSub = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
	}

	ionViewWillEnter() {
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.subscriptions.forEach((sub) => sub.unsubscribe());

						this.initDatas();
					});
			});
	}

	initDatas() {
		this.getEvent();
		this.getModule(), this.getEventUser();
	}

	ionViewWillLeave() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		if (this.languageSub && !this.languageSub.closed) {
			this.languageSub.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	getModule() {
		this.store
			.select(getSpecificModule(this.moduleId))
			.pipe(take(1))
			.subscribe((module) => {
				this.module = module;
				this.store.dispatch(
					GetHeaderState({
						payload: {
							item: null,
							module: this.module,
							type: null,
							title: this.module.name
						}
					})
				);
				this.cd.markForCheck();
			});
	}

	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				this.eventUser = eventUser;

				this.buildAvailableTimeSlots();
			})
		);
	}

	buildAvailableTimeSlots() {
		this.availableTimeSlots = [];
		this.module.options.rules.forEach((rule) => {
			this.availableTimeSlots = this.availableTimeSlots.concat(this.getDaysOfRule(rule));
		});
		this.cd.markForCheck();
	}

	filterRules(rules: IAppointmentRule[]) {
		return rules
			.filter(
				(rule) =>
					this.eventUser &&
					(this.eventUser.groups.includes(rule.groupsRules.recipientsGroupId) ||
						rule.groupsRules.recipientsGroupId === "allUsers")
			)
			.sort((a, b) =>
				DateTime.fromISO(a.startDate) > DateTime.fromISO(b.startDate)
					? 1
					: DateTime.fromISO(a.startDate) < DateTime.fromISO(b.startDate)
						? -1
						: 0
			);
	}

	getDaysOfRule(rule: IAppointmentRule) {
		const days: IAvailableTimeSlots[] = [];
		const startTime = rule.startTime ? rule.startTime.split(":") : [];
		const dateTimeStartDate = DateTime.fromISO(rule.startDate);
		const constructedStartDate = DateTime.fromObject({
			year: dateTimeStartDate.year,
			month: dateTimeStartDate.month,
			day: dateTimeStartDate.day,
			hour: startTime.length > 0 ? parseInt(startTime[0]) : 0,
			minute: startTime.length > 0 ? parseInt(startTime[1]) : 0,
			second: 0,
			millisecond: 0
		});
		let startDate = this.SUtility.getDateWithTimezoneType(
			this.event,
			this.eventUser.updatedSettings.timezoneType,
			constructedStartDate.toISO()
		);

		const endTime = rule.endTime ? rule.endTime.split(":") : [];
		const dateTimeEndDate = DateTime.fromISO(rule.endDate);
		const constructedEndDate = DateTime.fromObject({
			year: dateTimeEndDate.year,
			month: dateTimeEndDate.month,
			day: dateTimeEndDate.day,
			hour: endTime.length > 0 ? parseInt(endTime[0]) : 0,
			minute: endTime.length > 0 ? parseInt(endTime[1]) : 0,
			second: 0,
			millisecond: 0
		});
		const endDate = this.SUtility.getDateWithTimezoneType(
			this.event,
			this.eventUser.updatedSettings.timezoneType,
			constructedEndDate.toISO()
		);

		const eventUserRules =
			this.eventUser.updatedSettings && this.eventUser.updatedSettings.appointmentsSchedules
				? this.eventUser.updatedSettings && this.eventUser.updatedSettings.appointmentsSchedules
				: [];

		let check: boolean = true;
		while (check) {
			if (startDate <= endDate) {
				if (
					!days.find(
						(daySlots) =>
							this.SUtility.getDateWithTimezoneType(
								this.event,
								this.eventUser.updatedSettings.timezoneType,
								daySlots.day
							).hasSame(startDate, "year") &&
							this.SUtility.getDateWithTimezoneType(
								this.event,
								this.eventUser.updatedSettings.timezoneType,
								daySlots.day
							).hasSame(startDate, "month") &&
							this.SUtility.getDateWithTimezoneType(
								this.event,
								this.eventUser.updatedSettings.timezoneType,
								daySlots.day
							).hasSame(startDate, "day")
					)
				) {
					const availableTimeSlotEventUser = eventUserRules.find(
						(availableTimeSlotEventUser) =>
							availableTimeSlotEventUser.ruleLinked.uid === rule.uid &&
							this.SUtility.getDateWithTimezoneType(
								this.event,
								this.eventUser.updatedSettings.timezoneType,
								availableTimeSlotEventUser.day
							).year === startDate.year &&
							this.SUtility.getDateWithTimezoneType(
								this.event,
								this.eventUser.updatedSettings.timezoneType,
								availableTimeSlotEventUser.day
							).month === startDate.month &&
							this.SUtility.getDateWithTimezoneType(
								this.event,
								this.eventUser.updatedSettings.timezoneType,
								availableTimeSlotEventUser.day
							).day === startDate.day
					);
					const schedules: IAvailableTimeSlotSchedule[] = rule.schedules.map((schedule) => {
						let startDateSchedule = this.SUtility.getDateWithTimezoneType(
							this.event,
							this.eventUser.updatedSettings.timezoneType,
							schedule.startSchedule
						);
						startDateSchedule = startDateSchedule.set({
							year: startDate.year,
							month: startDate.month,
							day: startDate.day
						});
						let correspondingSchedule = null;
						if (availableTimeSlotEventUser) {
							correspondingSchedule = availableTimeSlotEventUser.schedules.find((eventUserSchedule) => {
								const eventUserScheduleDate = this.SUtility.getDateWithTimezoneType(
									this.event,
									this.eventUser.updatedSettings.timezoneType,
									eventUserSchedule.startSchedule
								);

								return (
									startDateSchedule.year === eventUserScheduleDate.year &&
									startDateSchedule.month === eventUserScheduleDate.month &&
									startDateSchedule.day === eventUserScheduleDate.day &&
									startDateSchedule.hour === eventUserScheduleDate.hour &&
									startDateSchedule.minute === eventUserScheduleDate.minute
								);
							});
						}

						return {
							available: !schedule.disabled,
							startSchedule: startDateSchedule.toISO(),
							disabled: schedule.disabled,
							disabledEventUser: correspondingSchedule ? correspondingSchedule.disabledEventUser : false,
							duration: schedule.duration
						};
					});
					if (schedules.length > 0) {
						days.push({
							day: startDate.toISO(),
							ruleLinked: rule,
							schedules: schedules.sort((a, b) => {
								const aDate = DateTime.fromISO(a.startSchedule);
								const bDate = DateTime.fromISO(b.startSchedule);
								return aDate > bDate ? 1 : aDate < bDate ? -1 : 0;
							})
						});
					}
				}
			} else {
				check = false;
			}
			startDate = startDate.plus({ day: 1 });
		}
		return this.SAppointments.buildAvailableTimeSlotsBasedOnTimezone(this.event, this.eventUser, days);
	}

	showSchedule(schedule: IAppointmentTimeSlotSchedule) {
		return !schedule.disabled && DateTime.fromISO(schedule.startSchedule) >= DateTime.local();
	}

	/**
	 * Get  date
	 * @param date
	 * @returns
	 */
	getPartOfDate(date: string, type: string) {
		return date ? this.SUtility.getPartOfDate(this.event, this.eventUser, date, type) : "";
	}

	handleChange(evt) {
		this.selectedDayByRule[evt.target.value.ruleLinked.uid] = evt.target.value;
		// this.selectedDay = evt.target.value;
	}

	handleChangeSchedule(schedule: IAvailableTimeSlotSchedule, daySelected: string) {
		const day = this.availableTimeSlots.find(
			(timeSlot) =>
				this.SUtility.getDateWithTimezoneType(
					this.event,
					this.eventUser.updatedSettings.timezoneType,
					timeSlot.day
				).hasSame(
					this.SUtility.getDateWithTimezoneType(
						this.event,
						this.eventUser.updatedSettings.timezoneType,
						daySelected
					),
					"year"
				) &&
				this.SUtility.getDateWithTimezoneType(
					this.event,
					this.eventUser.updatedSettings.timezoneType,
					timeSlot.day
				).hasSame(
					this.SUtility.getDateWithTimezoneType(
						this.event,
						this.eventUser.updatedSettings.timezoneType,
						daySelected
					),
					"month"
				) &&
				this.SUtility.getDateWithTimezoneType(
					this.event,
					this.eventUser.updatedSettings.timezoneType,
					timeSlot.day
				).hasSame(
					this.SUtility.getDateWithTimezoneType(
						this.event,
						this.eventUser.updatedSettings.timezoneType,
						daySelected
					),
					"day"
				)
		);
		const scheduleFinded = day.schedules.find(
			(sched) =>
				this.SUtility.getDateWithTimezoneType(
					this.event,
					this.eventUser.updatedSettings.timezoneType,
					sched.startSchedule
				).hasSame(
					this.SUtility.getDateWithTimezoneType(
						this.event,
						this.eventUser.updatedSettings.timezoneType,
						schedule.startSchedule
					),
					"hour"
				) &&
				this.SUtility.getDateWithTimezoneType(
					this.event,
					this.eventUser.updatedSettings.timezoneType,
					sched.startSchedule
				).hasSame(
					this.SUtility.getDateWithTimezoneType(
						this.event,
						this.eventUser.updatedSettings.timezoneType,
						schedule.startSchedule
					),
					"minute"
				)
		);
		scheduleFinded.disabledEventUser = schedule.disabledEventUser;
	}

	compareWith(o1, o2) {
		return o1 && o2 ? o1.day === o2.day : o1 === o2;
	}

	async modifySchedule() {
		try {
			this.store.dispatch(Loading({ payload: true }));
			await this.SFirestore.updateDocument(
				// eslint-disable-next-line max-len
				`events/${this.eventUser.eventId}/modules/${this.eventUser.moduleId}/event-users-updated-settings/${this.eventUser.uid}`,
				{
					appointmentsSchedules: this.availableTimeSlots
				}
			);
			this.store.dispatch(Loading({ payload: false }));
			this.snackbar.open(this.STranslate.instant("snackbar.update_successfull"), "", {
				duration: 3000,
				panelClass: "success-snackbar"
			});
		} catch (error) {
			this.store.dispatch(Loading({ payload: false }));
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open timezone switch modal
	 */
	async openMobileTimezoneSwitchModal() {
		// this.loader = true;
		const timezoneSwitchModal = await this.modalCtrl.create({
			component: TimezoneSwitchComponent,
			componentProps: {
				event: this.event
			},
			cssClass: "filter-modal-css",
			showBackdrop: true,
			presentingElement: await this.modalCtrl.getTop() // Get the top-most ion-modal
		});

		await timezoneSwitchModal.present();
	}
}
