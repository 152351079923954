import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NavController, Platform } from "@ionic/angular";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription, skipWhile } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, IEventUser, IModule, ISchedule, ISurvey } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getAccessiblesSurveysByType } from "src/app/shared/selectors/interactivity.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { SchedulesService } from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-surveys",
	templateUrl: "./surveys.component.html",
	styleUrls: ["./surveys.component.scss"],
	standalone: false
})
export class SurveysComponent implements OnDestroy, OnChanges {
	subscriptions: Subscription[] = [];

	@Input() componentMode: boolean;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input() sessionId: string;
	session: ISchedule;
	eventUser: IEventUser;

	surveys: ISurvey[] = [];

	unansweredSurveys: ISurvey[] = [];
	answeredSurveys: ISurvey[] = [];
	surveysSaved: any;

	surveySelectedId: string;
	surveySelectedModuleId: string;

	needRebuild: boolean = true;
	currentLanguage: string = environment.platform.defaultLanguage;

	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		private SAnalytics: AnalyticsService,
		private SSchedules: SchedulesService,
		private store: Store,
		private navCtrl: NavController,
		private STranslate: TranslateService,
		private router: Router
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;

		if (!this.componentMode) {
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.subscriptions.forEach((sub) => sub.unsubscribe());

							// Analytics
							this.SAnalytics.moduleAccess(
								this.eventId,
								this.moduleId,
								TypeTracking.ACCESS_TO_SURVEYS_MODULE
							);

							this.subscriptions.push(
								this.STranslate.onLangChange.subscribe((lang) => {
									this.currentLanguage = lang.lang;
								})
							);

							this.initDatas();
						});
				});
		}
	}

	ngOnChanges(): void {
		if (this.componentMode) {
			this.subscriptions.forEach((sub) => sub.unsubscribe());
			this.initDatas();
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		if (this.sessionId) {
			this.getSession();
		}
		this.getAccessiblesSurveys();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			if (!this.router.routerState.snapshot.url.includes("survey")) {
				this.needRebuild = true;
			}
			this.store.dispatch(ResetHeaderState(null));
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}

				if (this.module && !this.componentMode) {
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
				}
			})
		);
	}

	/**
	 * Get session
	 */
	getSession() {
		this.subscriptions.push(
			this.SSchedules.getSpecificSession(this.eventId, this.moduleId, this.sessionId).subscribe((session) => {
				// this.store.select(getSpecificSession(this.sessionId)).subscribe((session) => {
				if (!_.isEqual(this.session, session)) {
					this.session = session;
				}
			})
		);
	}

	/**
	 * Get accessibles surveys
	 */
	getAccessiblesSurveys() {
		this.subscriptions.push(
			this.store
				.pipe(
					skipWhile(() => this.componentMode && !this.session),
					select(
						getAccessiblesSurveysByType(
							this.componentMode ? 1 : 0,
							this.componentMode && this.session ? this.session : null
						)
					)
				)
				.subscribe((surveys) => {
					const surveysSorted = surveys.sort((a, b) =>
						a.order > b.order
							? 1
							: a.order < b.order
								? -1
								: DateTime.fromISO(b.creationDate).valueOf() -
									DateTime.fromISO(a.creationDate).valueOf()
					);
					if (!_.isEqual(this.surveys, surveysSorted) || this.needRebuild) {
						this.needRebuild = false;
						this.surveys = surveysSorted;

						if (this.surveys.length === 1 && !this.componentMode) {
							if (
								(this.surveys[0].eventUserResults &&
									this.surveys[0].eventUserResults.length !== this.surveys[0].questions.length) ||
								!this.surveys[0].eventUserResults
							) {
								this.openSurvey(this.surveys[0]);
								// this.surveySelectedId = this.surveys[0].uid;
								// this.surveySelectedModuleId = this.surveys[0].moduleId;
								// this.store.dispatch(GetHeaderTitle({ payload: this.surveys[0].name }));
							}
						}
					}
				})
		);
	}

	/**
	 * Get answered or not surveys
	 * @param type
	 * @returns
	 */
	getAnsweredOrNotSurveys(type: string) {
		return type === "answered"
			? this.surveys.filter(
					(survey) => survey.eventUserResults && survey.eventUserResults.length > 0
					// &&
					// 	survey.eventUserResults.length ===
					// 		survey.questions.filter((question) => question.type !== "plainText").length
				)
			: this.surveys.filter(
					(survey) => !survey.eventUserResults || survey.eventUserResults.length === 0
					// ||
					// (survey.eventUserResults &&
					// 	survey.eventUserResults.length !==
					// 		survey.questions.filter((question) => question.type !== "plainText").length)
				);
	}

	/**
	 * Navigate to survey or open it in component mode
	 * @param survey
	 */
	openSurvey(survey: ISurvey) {
		if (survey.activateMinPoints && survey.minPoints > this.eventUser.points) {
			return;
		}
		if (!this.componentMode) {
			this.navCtrl.navigateForward(`/event/${this.eventId}/surveys/${survey.moduleId}/${survey.uid}`);
		} else {
			// Switch to survey view on component mode
			this.changeView.emit({ segmentType: "surveys", type: "survey", uid: survey.uid });
		}
	}

	/**
	 * Present Popover
	 * @param ev
	 * @param minPoints
	 * @returns
	 */
	async presentMinPointsPopover(ev: any, minPoints: number) {
		const el = document.createElement("div");
		const msg = this.STranslate.instant("surveys.min_points_alert_msg", { points: minPoints });
		el.innerHTML = `<p>${msg}</p>`;
		el.style.padding = "0 10px";
		el.style.fontFamily = "var(--font)";
		const popoverElement = Object.assign(document.createElement("ion-popover"), {
			component: el,
			mode: "ios",
			event: ev
		});
		document.body.appendChild(popoverElement);
		return await popoverElement.present();
	}

	/**
	 * Count questions without plainText
	 */
	countQuestions(questions) {
		return questions.filter((question) => question.type !== "plainText").length;
	}
}
