import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { AppointmentsService, EventUsersService, FirestoreService, UtilityService } from "src/app/shared/services";
import { Subscription, combineLatest, firstValueFrom, of } from "rxjs";
import { Location, registerLocaleData } from "@angular/common";
import localeAr from "@angular/common/locales/ar";
import localeFr from "@angular/common/locales/fr";
import localePt from "@angular/common/locales/pt";
import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import localeDe from "@angular/common/locales/de";
import {
	IEvent,
	IEventUser,
	IFullCustomField,
	ILocation,
	IModule,
	IModuleCustomField
} from "src/app/shared/interfaces";
import { Store } from "@ngrx/store";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { switchMap, take } from "rxjs/operators";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { GetHeaderTitle, Loading } from "src/app/shared/actions/utility.actions";
import { TypeUser } from "src/app/shared/enums/type-user";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { NavController } from "@ionic/angular";
import { DateTime } from "luxon";
import {
	IAppointment,
	IAvailableTimeSlotSchedule,
	IAvailableTimeSlots
} from "src/app/shared/interfaces/appointments.interfaces";
import { AppointmentTimeSlotStatus } from "src/app/shared/enums/type-appointments";
import {
	getBaseCustomFields,
	getLocations,
	getModulesCustomsFieldsOfModule
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { TranslateService } from "@ngx-translate/core";
import { where } from "@angular/fire/firestore";
import { IEventUserUpdatedSettings } from "src/app/shared/interfaces/event-users.interfaces";
import * as _ from "lodash-es";

@Component({
	selector: "app-user-profile-timeslots",
	templateUrl: "./user-profile-timeslots.page.html",
	styleUrls: ["./user-profile-timeslots.page.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class UserProfileTimeslotsPage implements OnDestroy {
	timeSlotsSub: Subscription;
	languageSub: Subscription;
	subscriptions: Subscription[] = [];
	loader: boolean = true;

	limit: number = 15;

	event: IEvent;
	eventId: string;
	module: IModule;
	moduleId: string;
	locations: ILocation[] = [];
	customFields: IFullCustomField[] = [];
	modulesCustomsFields: IModuleCustomField[] = [];
	eventUser: IEventUser;
	eventUserProfile: IEventUser;
	eventUserProfileId: string;

	timeSlotsByDay: IAvailableTimeSlots[] = [];
	dateLocale: string;
	selectedSchedule: IAvailableTimeSlotSchedule;
	appointmentSubject: string;
	appointmentInformations: string;

	selectedTimeSlotDay: IAvailableTimeSlots;
	isMobile: boolean = window.innerWidth < 768;

	step: number = 0;

	currentLanguage: string;

	showAlertConfirm: boolean = false;
	showAlertError: boolean = false;
	showAlertLimitApplicant: boolean = false;
	showAlertLimitRecipient: boolean = false;
	showAlertSlotDisabledRecipient: boolean = false;
	showAlertSlotsTaken: boolean = false;

	get canSubmit() {
		return this.appointmentSubject && this.appointmentSubject.trim() && this.selectedSchedule ? true : false;
	}

	constructor(
		private store: Store,
		private SEventUsers: EventUsersService,
		private SFirestore: FirestoreService,
		private SAppointments: AppointmentsService,
		private STranslate: TranslateService,
		private location: Location,
		private navCtrl: NavController,
		private SUtility: UtilityService,
		private cd: ChangeDetectorRef
	) {
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSub = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
	}

	ionViewWillEnter() {
		this.step = 0;
		this.appointmentSubject = "";
		this.appointmentInformations = "";
		this.selectedSchedule = null;

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.eventUserProfileId = params.userId;
						this.subscriptions.forEach((sub) => sub.unsubscribe());

						this.initDatas();
					});
			});
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getLocations();
		this.getEventUsersDatas();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		if (this.timeSlotsSub && !this.timeSlotsSub.closed) {
			this.timeSlotsSub.unsubscribe();
		}
		if (this.languageSub && !this.languageSub.closed) {
			this.languageSub.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
				this.cd.markForCheck();
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;
				if (this.module) {
					this.store.dispatch(
						GetHeaderTitle({
							payload: {
								ArAR: this.STranslate.instant("appointments.take-appointment"),
								DeDE: this.STranslate.instant("appointments.take-appointment"),
								EnUS: this.STranslate.instant("appointments.take-appointment"),
								EsES: this.STranslate.instant("appointments.take-appointment"),
								FrFR: this.STranslate.instant("appointments.take-appointment"),
								PtBR: this.STranslate.instant("appointments.take-appointment")
							}
						})
					);
				}

				this.cd.markForCheck();
			})
		);
	}

	getLocations() {
		this.subscriptions.push(
			this.store.select(getLocations("asc")).subscribe((locations) => {
				this.locations = locations;
				this.cd.markForCheck();
			})
		);
	}

	/**
	 * getCustomFields
	 */
	getCustomFields() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(getModulesCustomsFieldsOfModule(this.eventUserProfile.moduleId))
			]).subscribe((results) => {
				// Custom fields datas
				this.customFields = [];
				const baseCustomFields = results[0];
				this.modulesCustomsFields = results[1];

				if (this.modulesCustomsFields.filter((cus) => cus.canBeTag).length > 0) {
					this.modulesCustomsFields
						.filter((cus) => cus.canBeTag)
						.forEach((customField) => {
							const baseCustomFieldCorresponding = baseCustomFields.find(
								(custField) => custField.uid === customField.uid
							);

							if (baseCustomFieldCorresponding) {
								const data = this.eventUserProfile.customFields.find(
									(data) => data.uid === customField.uid
								);
								this.customFields.push({
									baseSettings: baseCustomFieldCorresponding,
									moduleSettings: customField,
									fieldDatas: data
										? data
										: {
												uid: "",
												field: {}
											}
								});
							}
						});
				}
				this.cd.markForCheck();
			})
		);
	}

	getEventUsersDatas() {
		combineLatest([
			this.store.select(getCurrentEventUser),
			this.SEventUsers.getSpecificEventUser(this.eventId, this.eventUserProfileId).pipe(
				switchMap((eventUser) => {
					return this.SEventUsers.getSpecificEventUserUpdatedSettings(
						this.eventId,
						eventUser.moduleId,
						this.eventUserProfileId
					).pipe(
						take(1),
						switchMap((updatedSettings) => {
							eventUser.updatedSettings = updatedSettings;
							return of(eventUser);
						})
					);
				})
			)
		])
			.pipe(take(1))
			.subscribe((results) => {
				this.eventUser = results[0];
				if (this.eventUser) {
					this.initLangFormat(
						this.event &&
							this.eventUser.updatedSettings &&
							this.event.languagesSettings[this.eventUser.updatedSettings.language]
							? this.eventUser.updatedSettings.language
							: this.event.language
					);
					this.dateLocale =
						this.event &&
						this.eventUser.updatedSettings &&
						this.event.languagesSettings[this.eventUser.updatedSettings.language]
							? this.eventUser.updatedSettings.language.substring(0, 2).toLowerCase() +
								"-" +
								this.eventUser.updatedSettings.language.substring(2).toUpperCase()
							: this.event.language.substring(0, 2).toLowerCase() +
								"-" +
								this.event.language.substring(2).toUpperCase();
				}

				this.eventUserProfile = results[1];
				if (this.eventUserProfile && this.eventUserProfile.type !== TypeUser.ATTENDEE) {
					this.location.back();
				}

				if (this.eventUserProfile) {
					this.getCustomFields();
				}
				this.getAvailableTimeSlotsByDayForUser();
			});
	}

	/**
	 * Get  date
	 * @param date
	 * @returns
	 */
	getPartOfDate(date: string, type: string) {
		return date ? this.SUtility.formatDate(this.event, this.eventUser, date, type) : "";
	}

	getEndScheduleDate(date: string, duration: number) {
		return DateTime.fromISO(date).plus({ minutes: duration }).toISO();
	}

	/**
	 * Get available time slot by day for user
	 */
	getAvailableTimeSlotsByDayForUser() {
		if (this.timeSlotsSub && !this.timeSlotsSub.closed) {
			this.timeSlotsSub.unsubscribe();
		}
		this.timeSlotsSub = this.SAppointments.getAvailableTimeSlotsForEventUser(
			this.moduleId,
			this.eventUser,
			this.eventUserProfile,
			this.limit
		)
			.pipe(
				switchMap((timeSlotsByDay) => {
					const concatedTimeSlots: IAvailableTimeSlots[] = [];
					timeSlotsByDay.forEach((timeSlot) => {
						const dateTimeStartDate = DateTime.fromISO(timeSlot.day);

						const constructedStartDate = DateTime.fromObject({
							year: dateTimeStartDate.year,
							month: dateTimeStartDate.month,
							day: dateTimeStartDate.day,
							hour: 0,
							minute: 0,
							second: 0,
							millisecond: 0
						});
						const startDate = this.SUtility.getDateWithTimezoneType(
							this.event,
							this.eventUser.updatedSettings.timezoneType,
							constructedStartDate.toISO()
						);
						const findedTimeSlot: IAvailableTimeSlots = concatedTimeSlots.find((daySlots) => {
							return (
								this.SUtility.getDateWithTimezoneType(
									this.event,
									this.eventUser.updatedSettings.timezoneType,
									daySlots.day
								).hasSame(startDate, "day") &&
								this.SUtility.getDateWithTimezoneType(
									this.event,
									this.eventUser.updatedSettings.timezoneType,
									daySlots.day
								).hasSame(startDate, "month") &&
								this.SUtility.getDateWithTimezoneType(
									this.event,
									this.eventUser.updatedSettings.timezoneType,
									daySlots.day
								).hasSame(startDate, "year")
							);
						});
						if (!findedTimeSlot) {
							concatedTimeSlots.push(timeSlot);
						} else {
							findedTimeSlot.schedules = _.uniqBy(
								findedTimeSlot.schedules
									.concat(timeSlot.schedules)
									.filter((schedule) => schedule.available),
								"startSchedule"
							).sort((a, b) => {
								const aDate = DateTime.fromISO(a.startSchedule);
								const bDate = DateTime.fromISO(b.startSchedule);
								return aDate > bDate ? 1 : aDate < bDate ? -1 : 0;
							});
						}
					});
					return of(concatedTimeSlots);
				})
			)
			.subscribe((timeSlotsByDay) => {
				this.timeSlotsByDay = timeSlotsByDay.sort((a, b) => {
					return DateTime.fromISO(a.day) > DateTime.fromISO(b.day)
						? 1
						: DateTime.fromISO(a.day) < DateTime.fromISO(b.day)
							? -1
							: 0;
				});
				this.loader = false;
				if (this.timeSlotsByDay.length > 0) {
					this.selectedTimeSlotDay = this.timeSlotsByDay[0];
				}
				this.cd.markForCheck();
			});
	}

	showXTimeSlots() {
		return this.timeSlotsByDay.slice(0, this.limit);
	}

	initLangFormat(lang) {
		let langtoUse;
		switch (lang) {
			case "ArAR": {
				langtoUse = localeAr;
				break;
			}
			case "PtBR": {
				langtoUse = localePt;
				break;
			}
			case "EnUS": {
				langtoUse = localeEn;
				break;
			}
			case "EsES": {
				langtoUse = localeEs;
				break;
			}
			case "FrFR": {
				langtoUse = localeFr;
				break;
			}
			case "DeDE": {
				langtoUse = localeDe;
				break;
			}
		}
		registerLocaleData(langtoUse);
	}

	/**
	 *
	 * New functions
	 *
	 */

	selectSchedule(timeSlotsDay: IAvailableTimeSlots, schedule: IAvailableTimeSlotSchedule) {
		this.selectedTimeSlotDay = timeSlotsDay;
		this.selectedSchedule = schedule;
	}

	goPreviousStep() {
		this.step = this.step > 0 ? this.step - 1 : 0;
	}

	goNextStep() {
		this.step = this.step < 1 ? this.step + 1 : 1;
	}

	creating: boolean = false;
	/**
	 * Create appointment
	 */
	async createAppointment() {
		if (!this.creating) {
			this.creating = true;
			this.store.dispatch(Loading({ payload: true }));
			try {
				const ruleOfAppointment = this.module.options.rules.find(
					(rule) => rule.uid === this.selectedTimeSlotDay.ruleLinked.uid
				);

				const newAppointment: IAppointment = {
					applicant: {
						uid: this.eventUser.uid,
						eventId: this.eventUser.eventId,
						moduleId: this.eventUser.moduleId,
						email: this.eventUser.email,
						identifier: this.eventUser.identifier,
						name: this.eventUser.name,
						feedback: {
							feedback: "",
							commentary: "",
							notation: -1,
							recipientId: ""
						}
					},
					appointmentInformations: {
						informations: this.appointmentInformations.replace(/<[^>]*>/g, ""),
						subject: this.appointmentSubject.replace(/<[^>]*>/g, "")
					},
					creationDate: DateTime.local().toISO(),
					eventId: this.eventId,
					moduleId: this.moduleId,
					oneSignalNotifsLinked: [],
					duration: this.selectedSchedule.duration,
					location:
						this.module.options.autoAcceptAppointment || ruleOfAppointment.localisations.length === 0
							? "none"
							: "",
					recipient: {
						uid: this.eventUserProfile.uid,
						eventId: this.eventUserProfile.eventId,
						moduleId: this.eventUserProfile.moduleId,
						email: this.eventUserProfile.email,
						identifier: this.eventUserProfile.identifier,
						name: this.eventUserProfile.name,
						feedback: {
							feedback: "",
							commentary: "",
							notation: -1,
							recipientId: ""
						}
					},
					sourceRuleId: this.selectedTimeSlotDay.ruleLinked.uid,
					startDate: this.selectedSchedule.startSchedule,
					startDateTimestamp: DateTime.fromISO(this.selectedSchedule.startSchedule).toMillis(),
					status: this.module.options.autoAcceptAppointment
						? AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED
						: AppointmentTimeSlotStatus.APPOINTMENT_PENDING,
					updateInformations: {
						acceptedBy: "",
						cancelledBy: "",
						madeBy: this.eventUser.uid,
						rejectedBy: ""
					},
					updatedDate: DateTime.local().toMillis(),
					urlVisio: "",
					uid: this.SFirestore.createId(`events/${this.eventId}/modules/${this.moduleId}/appointments`)
				};

				const types: number[] = [AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED];
				if (this.module.options.autoHidePendingAppointmentsAvailability) {
					types.push(AppointmentTimeSlotStatus.APPOINTMENT_PENDING);
				}
				const result = await firstValueFrom(
					combineLatest([
						this.SFirestore.getCountOfQueryObs(
							`events/${this.event.uid}/modules/${this.module.uid}/appointments`,
							"default",
							[
								where("sourceRuleId", "==", newAppointment.sourceRuleId),
								where("recipient.uid", "==", newAppointment.recipient.uid),
								where("status", "==", AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED)
							]
						),
						this.SFirestore.getCountOfQueryObs(
							`events/${this.event.uid}/modules/${this.module.uid}/appointments`,
							"default",
							[
								where("sourceRuleId", "==", newAppointment.sourceRuleId),
								where("applicant.uid", "==", newAppointment.applicant.uid),
								where("status", "==", AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED)
							]
						),
						this.SFirestore.getDocumentsObs(
							`events/${this.event.uid}/modules/${this.module.uid}/appointments`,
							[
								where("recipient.uid", "==", newAppointment.recipient.uid),
								where("startDateTimestamp", "==", newAppointment.startDateTimestamp),
								where("status", "in", types)
							]
						),
						this.SEventUsers.getSpecificEventUserUpdatedSettings(
							this.eventId,
							newAppointment.recipient.moduleId,
							newAppointment.recipient.uid
						)
					]).pipe(
						take(1),
						switchMap((results) => {
							const acceptedAppointmentsForRecipient = results[0];
							const acceptedAppointmentsForApplicant = results[1];
							const appointmentsSnapshot = results[2];
							const recipientUpdatedSettings: IEventUserUpdatedSettings = results[3];

							const recipientRuleSettings =
								recipientUpdatedSettings &&
								recipientUpdatedSettings.appointmentsSchedules &&
								recipientUpdatedSettings.appointmentsSchedules.find(
									(setting) => setting.ruleLinked.uid === ruleOfAppointment.uid
								)
									? recipientUpdatedSettings.appointmentsSchedules.find(
											(setting) => setting.ruleLinked.uid === ruleOfAppointment.uid
										)
									: null;

							if (recipientRuleSettings) {
								// Check if schedule disabled
								const startDateSchedule = DateTime.fromISO(newAppointment.startDate);
								const recipientScheduleDisabled = this.SAppointments.getScheduleOfAvailableTimeSlots(
									recipientRuleSettings,
									startDateSchedule.setZone(this.event.timezone).toISO()
								);

								if (recipientScheduleDisabled && recipientScheduleDisabled.disabledEventUser) {
									return of("slot-disabled-by-recipient");
								}
							}

							const appointments = appointmentsSnapshot.docs.map((doc) => doc.data() as IAppointment);

							const checkRecipientAppointments = appointments.filter((appointment) => {
								if (!this.module.options.autoHidePendingAppointmentsAvailability) {
									return appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED;
								} else {
									return (
										appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED ||
										appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING
									);
								}
							});

							if (checkRecipientAppointments.length > 0) {
								return of("slot-already-taken");
							} else if (
								!ruleOfAppointment ||
								(ruleOfAppointment &&
									ruleOfAppointment.maximumAppointmentsByUserForRecipients <=
										acceptedAppointmentsForRecipient)
							) {
								return of("limit-recipient-accepted");
							} else if (
								!ruleOfAppointment ||
								(ruleOfAppointment &&
									ruleOfAppointment.maximumAppointmentsByUserForApplicants <=
										acceptedAppointmentsForApplicant)
							) {
								return of("limit-applicant-accepted");
							} else {
								return Promise.all([
									this.SFirestore.setDocument(
										// eslint-disable-next-line max-len
										`events/${this.eventId}/modules/${this.moduleId}/appointments/${newAppointment.uid}`,
										newAppointment
									),
									// Create notification in app
									firstValueFrom(
										this.SAppointments.sendAppointmentNotifications(
											this.event,
											this.module,
											this.eventUser,
											this.eventUser,
											this.eventUserProfile,
											newAppointment
										)
									).catch((err) => {
										console.error("Err: ", err);
									}),
									// Create mailing
									firstValueFrom(
										this.SAppointments.sendEmailForAppointmentTaken(
											this.event,
											this.module,
											newAppointment,
											this.event.language,
											newAppointment.appointmentInformations.subject,
											newAppointment.status
										)
									)
								]);
							}
						})
					)
				);

				this.creating = false;
				if (!result) {
					// Error appointment accepted in schedule send toast for inform user
					this.showAlertError = true;
				} else if (result && result === "slot-already-taken") {
					this.showAlertSlotsTaken = true;
				} else if (result && result === "limit-recipient-accepted") {
					this.showAlertLimitRecipient = true;
				} else if (result && result === "limit-applicant-accepted") {
					this.showAlertLimitApplicant = true;
				} else if (result && result === "slot-disabled-by-recipient") {
					this.showAlertSlotDisabledRecipient = true;
					this.getEventUsersDatas();
					this.selectedSchedule = null;
					this.step = 0;
				} else {
					this.showAlertConfirm = true;
				}
				this.store.dispatch(Loading({ payload: false }));
				this.cd.markForCheck();
			} catch (error) {
				this.creating = false;
				this.store.dispatch(Loading({ payload: false }));
				this.cd.markForCheck();
			}
		}
	}

	redirectToList() {
		this.navCtrl.navigateForward(this.module.urlApp, { replaceUrl: true });
	}
}
