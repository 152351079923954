import { Component, Input, OnDestroy } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, IEventUser, IModule, IWordCloud } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getAccessiblesWordsClouds } from "src/app/shared/selectors/interactivity.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService } from "src/app/shared/services";
import * as _ from "lodash-es";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-words-clouds",
	templateUrl: "./words-clouds.component.html",
	styleUrls: ["./words-clouds.component.scss"],
	standalone: false
})
export class WordsCloudsComponent implements OnDestroy {
	subscriptions: Subscription[] = [];

	@Input() componentMode: boolean = false;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	wordsClouds: IWordCloud[] = [];
	eventUser: IEventUser;
	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(
		private SAnalytics: AnalyticsService,
		private STranslate: TranslateService,
		private store: Store,
		private navCtrl: NavController
	) {}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		if (!this.componentMode) {
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.subscriptions.forEach((sub) => sub.unsubscribe());

							// Analytics
							this.SAnalytics.moduleAccess(
								this.eventId,
								this.moduleId,
								TypeTracking.ACCESS_TO_WORDS_CLOUDS_MODULE
							);

							this.initDatas();
						});
				});
		} else {
			this.initDatas();
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getWordsClouds();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.store.dispatch(ResetHeaderState(null));
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
				if (this.module) {
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
				}
			})
		);
	}

	/**
	 * Get accessibles words clouds
	 */
	getWordsClouds() {
		this.subscriptions.push(
			this.store.select(getAccessiblesWordsClouds).subscribe((wordsClouds) => {
				if (!_.isEqual(this.wordsClouds, wordsClouds)) {
					this.wordsClouds = wordsClouds;
				}
			})
		);
	}

	/**
	 * Open words cloud
	 * @param wordsCloud
	 */
	openWordsCloud(wordsCloud: IWordCloud) {
		this.navCtrl.navigateForward(`/event/${this.eventId}/words-clouds/${this.moduleId}/${wordsCloud.uid}`);
	}
}
