<div class="tw-w-full tw-h-10 tw-flex tw-flew-row tw-items-center tw-justify-between tw-mb-[1px]">
	@for (tab of tabs(); track tab) {
		<div
			class="tw-w-fit tw-h-fit tw-p-1.5 tw-flex tw-flew-row tw-items-center tw-justify-center tw-grow tw-font-['Inter'] tw-text-lg tw-font-medium tw-text-center tw-whitespace-pre-wrap tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid"
			[class.tw-border-b-2]="tabActive() === tab.key"
			[class.tw-border-b]="tabActive() !== tab.key"
			[style.border-color]="tabActive() === tab.key ? 'var(--btn-bg-color)' : '#e5e5e5'"
			[style.color]="tabActive() === tab.key ? 'var(--title-color)' : '#E5E7EB'"
			[class.tw-cursor-pointer]="tabActive() !== tab.key"
			(click)="tabChanged(tab.key)"
		>
			{{ tab.translation ? (tab.text | translate) : tab.text }}
		</div>
	}
</div>
