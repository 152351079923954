/* eslint-disable max-len */
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnDestroy,
	OnInit,
	signal,
	ViewChild,
	WritableSignal
} from "@angular/core";
import { where } from "@angular/fire/firestore";
import { IonicModule, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import * as _ from "lodash-es";
import { Observable, Subject, Subscription, combineLatest } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule } from "src/app/shared/enums/type-module";
import { TypeUser } from "src/app/shared/enums/type-user";
import { TabsComponent } from "src/app/shared/generic-components/tabs/tabs.component";
import { MaterialModule } from "src/app/shared/global-modules/material-modules";
import {
	ICheckin,
	ICheckinChecked,
	IEvent,
	IEventUser,
	IFullCustomField,
	IGroup,
	IModule,
	IModuleCustomField
} from "src/app/shared/interfaces";
import { getMyEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getGroupsByOrder,
	getModulesCustomsFieldsOfModule
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByType, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { CheckinsService, FirestoreService, MongodbService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { EventUserItemComponent } from "../../event-users/event-user-item/event-user-item.component";
import { SignaturePadComponent } from "src/app/shared/generic-components/signature-pad/signature-pad.component";
import { ISearchFilter, SearchFilter } from "src/app/shared/interfaces/search.interfaces";

@Component({
	selector: "app-checkin",
	templateUrl: "./checkin.component.html",
	styleUrls: ["./checkin.component.scss"],
	imports: [
		EventUserItemComponent,
		IonicModule,
		MaterialModule,
		SignaturePadComponent,
		TabsComponent,
		ZXingScannerModule,
		TranslateModule
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CheckinsService]
})
export class CheckinComponent implements OnInit, OnDestroy {
	private SMongo = inject(MongodbService);
	private platform = inject(Platform);
	private store = inject(Store);

	SCheckins = inject(CheckinsService);
	private SFirestore = inject(FirestoreService);
	private STranslate = inject(TranslateService);

	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
	subscriptions: Subscription[] = [];
	searchSub: Subscription;
	destroySuscription = new Subject();

	tabShowed: WritableSignal<string> = signal("scan");

	loading: WritableSignal<boolean> = signal(false);

	isMobile: WritableSignal<boolean> = signal(false);

	itemSize: WritableSignal<number> = signal(97);
	height: WritableSignal<number> = signal(873);

	eventId: WritableSignal<string> = signal("");
	event: WritableSignal<IEvent> = signal(null);
	moduleId: WritableSignal<string> = signal("");
	module: WritableSignal<IModule> = signal(null);
	checkinId: WritableSignal<string> = signal("");
	checkin: WritableSignal<ICheckin> = signal(null);
	checkeds: WritableSignal<ICheckinChecked[]> = signal([]);
	eventUsersModules: WritableSignal<IModule[]> = signal([]);
	datas: WritableSignal<IEventUser[]> = signal([]);
	eventUser: WritableSignal<IEventUser> = signal(null);
	groups: WritableSignal<IGroup[]> = signal([]);
	customFields: WritableSignal<IFullCustomField[]> = signal([]);

	totalAway: WritableSignal<number> = signal(0);
	totalPresent: WritableSignal<number> = signal(0);
	total: WritableSignal<number> = signal(0);

	currentLanguage: WritableSignal<string> = signal(environment.platform.defaultLanguage);

	ngOnInit(): void {
		this.isMobile.set(this.platform.is("mobile"));

		this.SCheckins.stateScanQrSubject.pipe(takeUntil(this.destroySuscription)).subscribe((stateScan) => {
			if (stateScan.state === "ENDED") {
				this.getTotals();
				this.getSpecificEventUsers("", true);
			}
		});
	}

	ionViewWillEnter() {
		this.currentLanguage.set(this.STranslate.currentLang);
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage.set(lang.lang);
			})
		);

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId.set(params.eventId);
						this.moduleId.set(params.moduleId);
						this.checkinId.set(params.checkinId);

						this.initDatas();
					});
			});
	}

	ionViewWillLeave() {
		this.SCheckins.enableScanning.set(false);
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe subscriptions on destroy
	 */
	ngOnDestroy() {
		this.destroySuscription.complete();
		this.subscriptions.concat([this.searchSub]).forEach((sub) => sub?.unsubscribe());
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getAllEventUsersModules();
		this.getCustomFields();
		this.getMyEventUser();
		this.getCheckin();
		this.getGroups();
	}

	getEvent() {
		this.store
			.select(getCurrentEvent)
			.pipe(take(1))
			.subscribe((event) => {
				this.event.set(event);
			});
	}

	getModule() {
		this.store
			.select(getSpecificModule(this.moduleId()))
			.pipe(take(1))
			.subscribe((module) => {
				this.module.set(module);
			});
	}

	/**
	 * getMyEventUser
	 * @desc get actual (authenticated) eventuser
	 */
	getMyEventUser() {
		this.subscriptions.push(
			this.store.select(getMyEventUser).subscribe((eventUser) => {
				this.eventUser.set(eventUser);
			})
		);
	}

	/**
	 * Get custom fields
	 */
	getCustomFields() {
		this.store
			.select(getModulesByType(TypeModule.ATTENDEE))
			.pipe(take(1))
			.subscribe((modules) => {
				const obs: Observable<IModuleCustomField[]>[] = [];
				modules.forEach((module) => {
					obs.push(this.store.select(getModulesCustomsFieldsOfModule(module.uid)));
				});
				combineLatest([this.store.select(getBaseCustomFields), combineLatest(obs)])
					.pipe(take(1))
					.subscribe((results) => {
						const baseCustomFields = results[0];
						const modulesCustomFields = results[1];
						const customFields: IFullCustomField[] = [];
						modulesCustomFields.forEach((moduleCustomFields) => {
							moduleCustomFields.forEach((customField) => {
								const baseCustomFieldCorresponding = baseCustomFields.find(
									(custField) => custField.uid === customField.uid
								);

								customFields.push({
									baseSettings: baseCustomFieldCorresponding ? baseCustomFieldCorresponding : null,
									moduleSettings: customField,
									fieldDatas: {
										uid: "",
										field: {
											date: {
												day: 0,
												month: 0,
												year: 0,
												value: "",
												fullDateISO: "",
												zone: ""
											},
											file: {
												lastModifiedDate: "",
												name: "",
												type: "",
												url: "",
												size: 0
											},
											image: {
												format: "",
												lastModifiedDate: "",
												name: "",
												url: "",
												size: 0
											},
											module: {
												items: [],
												moduleType: -1
											},
											multiLanguageText: {
												ArAR: "",
												DeDE: "",
												EnUS: "",
												EsES: "",
												FrFR: "",
												PtBR: ""
											},
											multiLanguageSelectArray: [],
											multiLanguageTextArray: {
												ArAR: [],
												DeDE: [],
												EnUS: [],
												EsES: [],
												FrFR: [],
												PtBR: []
											},
											numeric: 0,
											phoneNumber: {
												countryCode: "",
												dialCode: "",
												e164Number: "",
												internationalNumber: "",
												nationalNumber: "",
												number: ""
											},
											text: ""
										}
									}
								});
							});
						});
						this.customFields.set(customFields);
					});
			});
	}

	getCustomFieldsOfModule(moduleId: string) {
		return this.customFields().filter((customField) => customField.moduleSettings.moduleId === moduleId);
	}

	getAllEventUsersModules() {
		this.subscriptions.push(
			this.store.select(getModulesByType(TypeModule.ATTENDEE)).subscribe((modules) => {
				this.eventUsersModules.set(modules);
			})
		);
	}

	getSpecificEventUsersModule(moduleId: string) {
		return this.eventUsersModules().find((module) => module.uid === moduleId);
	}

	getCheckin() {
		this.SCheckins.getCheckin(this.eventId(), this.moduleId(), this.checkinId())
			.pipe(take(1))
			.subscribe((checkin) => {
				this.checkin.set(checkin);

				if (this.checkin()) {
					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: this.checkin(),
								module: this.module(),
								title: {
									ArAR: this.checkin().name,
									DeDE: this.checkin().name,
									EnUS: this.checkin().name,
									EsES: this.checkin().name,
									FrFR: this.checkin().name,
									PtBR: this.checkin().name
								},
								type: TypeHeader.CHECKIN
							}
						})
					);
					this.getTotals();
				}

				this.SCheckins.enableScanning.set(true);
			});
	}

	/**
	 * Get groups
	 */
	getGroups() {
		this.store
			.select(getGroupsByOrder("asc"))
			.pipe(take(1))
			.subscribe((groups) => {
				if (!_.isEqual(this.groups, groups)) {
					this.groups.set(groups);
				}
			});
	}

	getTotals() {
		const countTotalQuery =
			this.checkin().groupsInType !== 0
				? this.SFirestore.getCountOfQueryObs("event-users", "group", [
						where("eventId", "==", this.eventId()),
						where("type", "==", TypeUser.ATTENDEE),
						where("groups", "array-contains-any", this.checkin().groupsInCheckin)
					])
				: this.SFirestore.getCountOfQueryObs("event-users", "group", [
						where("eventId", "==", this.eventId()),
						where("type", "==", TypeUser.ATTENDEE)
					]);
		return combineLatest([
			countTotalQuery,
			this.SFirestore.getCountOfQueryObs("checkin-checked", "group", [
				where("eventId", "==", this.eventId()),
				where("moduleId", "==", this.moduleId()),
				where("checkinId", "==", this.checkinId()),
				where("checkinStatus", "==", true)
			])
		])
			.pipe(take(1))
			.subscribe((results) => {
				this.total.set(results[0]);
				this.totalPresent.set(results[1]);
				this.totalAway.set(this.total() - this.totalPresent());
			});
	}

	tmpFiltersSearch: ISearchFilter = null;
	getSpecificEventUsers(searchValue: string, overWriteFilters: boolean) {
		const filtersSearch = overWriteFilters ? this.tmpFiltersSearch : new SearchFilter();

		if (!overWriteFilters) {
			filtersSearch.equalityFields.push({ fieldKey: "eventId", compareData: this.eventId() });

			if (this.checkin().groupsInType !== 0) {
				filtersSearch.arrayContainsAnyFields.push({
					fieldKey: "groups",
					compareData: this.checkin().groupsInCheckin
				});
			}

			// Search on name
			filtersSearch.includeOrTextFields.push({
				fieldKey: "name",
				compareData: searchValue
			});
			// Search on identifier
			filtersSearch.includeOrTextFields.push({
				fieldKey: "identifier",
				compareData: searchValue
			});
			// Search on email
			filtersSearch.includeOrTextFields.push({
				fieldKey: "email",
				compareData: searchValue
			});

			// Lookup check of event user
			filtersSearch.lookup = [
				{
					where: "beforeMatch",
					stage: {
						$lookup: {
							from: "checkin-checked",
							let: {
								uid: "$uid"
							},
							pipeline: [
								{
									$match: {
										$expr: {
											$and: [
												{
													$eq: ["$eventId", this.eventId()]
												},
												{
													$eq: ["$checkinId", this.checkinId()]
												},
												{
													$eq: ["$userId", "$$uid"]
												}
											]
										}
									}
								}
							],
							as: "checkedsDatas"
						}
					}
				}
			];
			filtersSearch.sortBy.push({
				fieldKey: "name",
				type: "asc"
			});

			this.tmpFiltersSearch = filtersSearch;
		}

		this.SMongo.queryDatasMongodb(
			this.SMongo.convertSearchFilterToQueryOrAggregationMongo(filtersSearch, "aggregation", "eventUsersSearch"),
			"event-users",
			{},
			"aggregation"
		).subscribe({
			next: (res) => {
				this.datas.set(res.docs);
			}
		});
	}

	/**
	 * Reset filter
	 */
	resetFilter() {
		this.datas.set([]);
	}
}
