import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { ISearchFilter } from "../interfaces/search.interfaces";
import { Functions, httpsCallableData } from "@angular/fire/functions";
import { transformFiltersToQuery } from "../helpers-functions/filter-search";

export interface IApiManageDatas {
	type:
		| "insert-one"
		| "insert-many"
		| "update-one"
		| "update-many"
		| "delete-one"
		| "delete-many"
		| "replace-one"
		| "bulk-write";
	query?: any; // For all update, delete and replace
	operations?: any[]; // For bulk write
	collection: string; // Collection name
	datas: any[]; // Datas to manage
	options?: any; // Potential options for update, replace, delete, bulk write
}

@Injectable({
	providedIn: "root"
})
export class MongodbService {
	headers: HttpHeaders;

	constructor(
		private http: HttpClient,
		private functions: Functions
	) {
		this.headers = new HttpHeaders();
		this.headers.append("Accept", "application/json");
		this.headers.append("Content-Type", "application/json");
	}

	queryDatasMongodb(query: any, collection: string, options: any, type: "aggregation" | "normal") {
		return this.http
			.post(
				`https://api-queryDatas${environment.platform.apiV2BaseUrl}`,
				{
					type: type,
					query: query,
					collection: collection,
					options: options
				},
				{
					headers: this.headers,
					observe: "body"
				}
			)
			.pipe(
				switchMap((results: any) => {
					const result = results.result;
					return of({
						docs: result.length > 0 ? result[0].docs : result["datas"] ? result["datas"] : [],
						totalDocuments:
							result.length > 0 &&
							result[0].meta &&
							result[0].meta.length > 0 &&
							result[0].meta[0] &&
							result[0].meta[0]["total"]
								? result[0].meta[0].total
								: result["totalDocuments"]
									? result["totalDocuments"]
									: 0
					});
				})
			);
	}

	manageDatasMongodb(body: IApiManageDatas) {
		return httpsCallableData(this.functions, "api-manageDatas", {})(body);
	}

	transformObjectToUpdateItem(data: any) {
		const item: any = {
			$set: {}
		};

		for (const key of Object.keys(data)) {
			item["$set"][key] = data[key];
		}
		return item;
	}

	convertSearchFilterToQueryOrAggregationMongo(
		filters: ISearchFilter,
		queryType: "aggregation" | "normal",
		index: string
	) {
		const query: any = {};
		if (queryType === "aggregation") {
			return transformFiltersToQuery(filters, "aggregation", index);
		} else {
			return query;
		}
	}
}
