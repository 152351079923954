import { Component } from "@angular/core";
import { MenuController } from "@ionic/angular";

@Component({
	selector: "app-splash",
	templateUrl: "./splash.page.html",
	styleUrls: ["./splash.page.scss"],
	standalone: false
})
export class SplashPage {
	constructor(private menuCtrl: MenuController) {
		this.menuCtrl.enable(false);
	}
}
