import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { skipWhile, Subscription, take } from "rxjs";
import { FirestoreService, PointsService } from ".";
import { GetAllSurveys, GetAllSurveysResults } from "../actions/interactivity.actions";
import { TypeModule } from "../enums/type-module";
import { IEventUser, IPointRecord } from "../interfaces";
import { ISurvey, ISurveyQuestionResult } from "../interfaces/surveys.interfaces";
import { getCurrentEventUser } from "../selectors/auth.selectors";
import { checkSameEvent } from "../selectors/interactivity.selectors";
import { where } from "@angular/fire/firestore";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";

@Injectable({
	providedIn: "root"
})
export class SurveysService {
	surveysSub: Subscription;
	surveysResultsSub: Subscription;
	currentEventUser: IEventUser;

	constructor(
		private SFirestore: FirestoreService,
		private store: Store,
		private SPoints: PointsService
	) {}

	unsubscribeAll() {
		if (this.surveysSub && !this.surveysSub.closed) {
			this.surveysSub.unsubscribe();
		}
		if (this.surveysResultsSub && !this.surveysResultsSub.closed) {
			this.surveysResultsSub.unsubscribe();
		}
	}

	/**
	 * Get all surveys of event
	 * @param eventId
	 */
	getSurveysOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "surveys", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.surveysSub && !this.surveysSub.closed) {
					return;
				} else if (!sameEvent && this.surveysSub && !this.surveysSub.closed) {
					this.surveysSub.unsubscribe();
				}

				this.surveysSub = this.SFirestore.collectionGroupValueChangesDocuments("surveys", [
					where("eventId", "==", eventId)
				]).subscribe((surveys: ISurvey[]) => {
					this.store.dispatch(GetAllSurveys({ payload: surveys, eventId: eventId }));
					this.store
						.select(getInitSpecificEventDatasPart("initSurveys"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(InitSpecificEventDatasPart({ part: "initSurveys", payload: true }));
							}
						});
				});

				this.store
					.select(getCurrentEventUser)
					.pipe(
						skipWhile((eventUser) => !eventUser),
						take(1)
					)
					.subscribe((eventUser) => {
						if (
							(sameEvent &&
								this.surveysResultsSub &&
								!this.surveysResultsSub.closed &&
								eventUser &&
								this.currentEventUser &&
								eventUser.uid === this.currentEventUser.uid) ||
							!eventUser
						) {
							return;
						} else if (!sameEvent && this.surveysResultsSub && !this.surveysResultsSub.closed) {
							this.surveysResultsSub.unsubscribe();
						}

						this.currentEventUser = eventUser;

						this.surveysResultsSub = this.SFirestore.collectionGroupValueChangesDocuments(
							"survey-results",
							[where("eventId", "==", eventId), where("userId", "==", eventUser.uid)]
						).subscribe((surveysResults: ISurveyQuestionResult[]) => {
							this.store.dispatch(GetAllSurveysResults({ payload: surveysResults }));
						});
					});
			});
	}

	/**
	 * Create survey results
	 * @param eventId
	 * @param moduleId
	 * @param surveyId
	 * @param results
	 */
	createSurveyResults(
		eventId: string,
		moduleId: string,
		surveyId: string,
		results: { result: ISurveyQuestionResult; new: boolean }[],
		eventUser: IEventUser,
		pointsRecording: boolean,
		points: number
	) {
		const batch = this.SFirestore.getBatch();

		results.forEach((result) => {
			batch.set(
				this.SFirestore.docRef(
					`events/${eventId}/modules/${moduleId}/surveys/${surveyId}/survey-results/${result.result.uid}`
				),
				result.result
			);

			if (pointsRecording && eventUser) {
				const pointRecord: IPointRecord = {
					uid: "",
					creationDate: DateTime.local().toISO(),
					eventId: eventId,
					fixedPoints: points,
					moduleId: eventUser ? eventUser.moduleId : "",
					options: {
						moduleId: moduleId,
						answerId: result.result.type === "oneSelect" ? result.result.oneSelect.answerId : null,
						answerIds:
							result.result.type === "multipleSelect" ? result.result.multipleSelect.answersIds : null,
						surveyId: surveyId,
						questionId: result.result.questionId,
						questionType: result.result.type
					},
					type: TypeModule.SURVEY,
					userId: eventUser ? eventUser.uid : ""
				};
				if (!result.new) {
					batch.delete(this.SPoints.getRefOfEventUserPointRecord(eventId, pointRecord));
				}
				batch.set(this.SPoints.getRefOfEventUserPointRecord(eventId, pointRecord), pointRecord);
			}
		});

		return batch.commit();
	}
}
