<ion-content id="main">
	@if (
		languages.length > 1 &&
		actualStep === 0 &&
		!isMobile &&
		(mode === "register-modal" || mode === "fill-form-modal")
	) {
		<div
			(click)="changeLanguage()"
			class="btn-menu-lang"
			[style.left]="isMobile ? '10px' : '100px'"
			[style.bottom]="isMobile ? '' : '20px'"
			style="
				position: fixed;
				cursor: pointer;
				min-width: 150px;
				width: fit-content;
				padding: 5px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			"
			[style.border-color]="event.styling.menuTextColorGradient"
		>
			<div style="margin-right: 10px; font-size: 12px">{{ "languages.choose-language" | translate }}</div>
			<div style="display: flex; flex-direction: row; align-items: center; justify-content: center">
				<span style="font-size: 12px; margin: 0 6px 0 3px">{{ language | slice: 0 : 2 }}</span>
				<ion-icon style="width: 12px; height: 12px" src="assets/icon/ArrowDown.svg"></ion-icon>
			</div>
		</div>
	}
	<!-- Language change (mobile only)-->
	@if (languages.length > 1 && actualStep === 0 && isMobile) {
		<ion-fab
			(click)="changeLanguage()"
			slot="fixed"
			horizontal="right"
			vertical="bottom"
			style="right: 5px; bottom: 70px"
		>
			<ion-fab-button class="basic-fab-button" style="width: 50px; height: 50px">
				<ion-icon name="flag" style="font-size: 20px"></ion-icon>
			</ion-fab-button>
		</ion-fab>
	}
	<!-- Logout button (private mode & mobile only) -->
	@if (canNotCloseModal() && !event.settings.visibility && isMobile) {
		<ion-fab
			(click)="logout()"
			slot="fixed"
			horizontal="right"
			vertical="bottom"
			style="right: 5px; cursor: pointer"
		>
			<ion-fab-button class="basic-fab-button" style="width: 50px; height: 50px">
				<ion-icon src="assets/icon/logout_white.svg" style="font-size: 20px"></ion-icon>
			</ion-fab-button>
		</ion-fab>
	}
	@if (registerForm) {
		<div
			[ngClass]="isMobile ? 'background background-no-scrollbar' : 'background'"
			[style.backgroundColor]="registerForm.formContent.backgroundColor"
			[style.color]="registerForm.formContent.textColor"
			[ngStyle]="{
				'background-image': isMobile
					? 'url(' + registerForm.formContent.mobileBgImageUrl + ')'
					: 'url(' + registerForm.formContent.desktopBgImageUrl + ')'
			}"
		>
			<!-- Close Button -->
			@if (isModal && (!canNotCloseModal() || manualOpen)) {
				<div [ngClass]="device === 'ios' ? 'ios-close-btn' : 'close-btn'" class="p-l-10 p-t-20">
					<ion-button fill="clear" color="dark" (click)="close(null, mode)">
						<ion-icon color="danger" src="assets/icon/Close.svg"></ion-icon>
					</ion-button>
				</div>
			}
			@if (loader) {
				<div class="align-loader">
					<div class="loader-default"></div>
				</div>
			}

			<!-- Logout button (private mode only) -->
			@if (canNotCloseModal() && !event.settings.visibility && !isMobile) {
				<div class="tw-fixed !tw-z-[999] tw-bottom-[2%] tw-right-[2%] tw-w-fit !tw-cursor-pointer">
					<ion-button
						(click)="logout()"
						[style.border-color]="event.styling.menuTextColorGradient"
						class="tw-rounded-[50px] tw-border-solid tw-border-[1px] tw-cursor-pointer"
						fill="clear"
						color="danger"
						style="text-transform: none !important"
					>
						<ion-icon color="danger" src="assets/icon/logout_black.svg"></ion-icon>
						&nbsp;
						<span>{{ "buttons.log_out" | translate | capitalizeFirstLetter }}</span>
					</ion-button>
				</div>
			}

			<!-- `registration limit message  -->
			@if (registrationLimitReached()) {
				<div class="registration-limit-msg p-t-40">
					{{ "register.registrations_are_closed_please_contact_your_event_manager" | translate }}
				</div>
			}
			@if (!registrationLimitReached() && !loader) {
				<div class="f-col-c-c main-container">
					<!-- Public logo -->
					@if (registerForm.formContent.logoUrl) {
						<div class="p-t-40">
							<img [src]="registerForm.formContent.logoUrl" class="basic-logo" />
						</div>
					}
					<!-- Public custom field top  -->
					<div
						class="p-t-40 margins"
						[ngClass]="mode === 'accompanying-form-modal' ? 'head-accompanying-style' : ''"
						[innerHTML]="
							mode === 'accompanying-form-modal'
								? (registerForm.formContent.accompanyingHeadTitle[currentLanguage()] | safeHtml)
								: (registerForm.formContent.headTitle[currentLanguage()] | safeHtml)
						"
					></div>
					@if (!formLoader) {
						<form [formGroup]="form">
							<div>
								<ion-grid class="ion-padding">
									<app-form-fields
										[mode]="mode"
										[event]="event"
										[module]="module"
										[registerForm]="registerForm"
										[computedCustomFields]="computedCustomFields"
										[currentLanguage]="currentLanguage()"
										(emailFilled)="firstStepVerifEmail()"
										(validateFields)="onSubmitForm($event)"
										[loader]="activeLoader"
										[messages]="formMessage"
										[lockNextButton]="lockStep"
										[recaptchaStatus]="recaptchaStatus"
										[loginMode]="addToEventMode"
										[eventUser]="mode === 'fill-form-modal' ? adminEventUser : null"
									></app-form-fields>
									<!-- Code access -->
									@if (
										!formLoader &&
										formFieldsComponent &&
										formFieldsComponent.actualStep ===
											registerForm.formContent.sections.length - 1 &&
										codeView
									) {
										<ion-row>
											<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
												<ion-item
													lines="none"
													mode="md"
													class="input-item"
													[ngStyle]="{
														border: passwordView
															? '1px solid var(--label-login-color)'
															: '1px solid var(--text-color-2nd-login-desktop)'
													}"
												>
													<ion-label
														position="floating"
														mode="md"
														class="input-label-text label-color"
													>
														{{ "login.verify_code" | translate }}</ion-label
													>
													<ion-input
														mode="md"
														class="custom-input input-color"
														type="number"
														pattern="[0-9]*"
														min="0"
														max="10"
														formControlName="codeNumber"
													></ion-input>
												</ion-item>
												<br />
												@if (count !== 0) {
													<span class="timer"
														>{{ "login.remaining_time" | translate }}
														{{ timeRemaining }}</span
													>
												}
											</ion-col>
										</ion-row>
									}
									<!-- Code access (resend) -->
									@if (
										!formLoader &&
										formFieldsComponent &&
										formFieldsComponent.actualStep ===
											registerForm.formContent.sections.length - 1 &&
										codeView &&
										count === 0
									) {
										<ion-row>
											<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
												<ion-button
													class="btn-default btn-outline-default"
													shape="round"
													fill="outline"
													[style.--background]="event.styling.btnBgColor"
													[disabled]="activeLoader"
													(click)="createCodeNumber(); activeLoader = true"
												>
													@if (!activeLoader) {
														<span>{{ "login.resend_code" | translate }}</span>
													}
													@if (activeLoader) {
														<ion-spinner name="crescent" color="secondary"></ion-spinner>
													}
												</ion-button>
											</ion-col>
										</ion-row>
									}
									<!-- Recaptcha -->
									<!-- V2 -->
									@if (
										!formLoader &&
										registerForm.formSettings.enableCaptcha &&
										formFieldsComponent &&
										(formFieldsComponent.actualStep ===
											registerForm.formContent.sections.length - 1 ||
											formFieldsComponent.nextSectionId === null)
									) {
										<ion-row>
											<ion-col
												class="recaptcha"
												size-sm="12"
												size-md="6"
												size-lg="6"
												push-md="3"
												push-lg="3"
											>
												<re-captcha
													#recaptcha
													type="image"
													[siteKey]="recaptchaKey"
													(resolved)="captchaResolved($event, recaptchaKey)"
													errorMode="handled"
													(error)="onCaptchaError($event)"
												>
												</re-captcha>
											</ion-col>
										</ion-row>
									}
								</ion-grid>
							</div>
						</form>
					}
					@if (formLoader) {
						<div class="align-loader">
							<div class="loader-default"></div>
						</div>
					}
					<!-- Public custom field bottom  -->
					<div
						class="p-t-40 margins"
						[innerHTML]="registerForm.formContent.bottomTitle[currentLanguage()] | safeHtml"
					></div>
				</div>
			}
		</div>
	}
</ion-content>
