import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { QueryConstraint, orderBy, where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { Observable, Subject, Subscription, firstValueFrom, forkJoin, from, of } from "rxjs";
import { map, skipWhile, switchMap, take } from "rxjs/operators";
import { GetAllCheckins } from "../actions/checkins.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { ICheckin, ICheckinChecked, IMultiCheckRecord } from "../interfaces/checkin.interfaces";
import { checkSameEvent, getAllCheckins } from "../selectors/checkins.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";
import { MongodbService } from "./mongodb.service";
import { IEventUser } from "../interfaces";
import { UtilityService } from "./utility.service";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { checkinActionTypes, TypeNameTracking, TypeTracking, TypeTrackingNumber } from "../enums/type-analytics";
import { IAnalyticsUserRoadTracking, IOptionsAnalytics } from "../interfaces/analytics.interfaces";
import { getCurrentEvent } from "../selectors/events.selectors";
import { TypeModule } from "../enums/type-module";
import { StorageService } from "./storage.service";
import { getSpecificsTracks } from "../selectors/generics-modules-data.selectors";
import { MatSnackBar } from "@angular/material/snack-bar";
import { getCurrentEventUser } from "../selectors/auth.selectors";

@Injectable({
	providedIn: "root"
})
export class CheckinsService {
	snackbar = inject(MatSnackBar);
	checkinsSub: Subscription;
	eventUsersSub: Subscription;

	scanQrSubject: Subject<boolean> = new Subject();
	stateScanQrSubject: Subject<{
		state: "STARTSCAN" | "SCANNING" | "ERROR" | "ENDED" | "RESCAN" | "NONE";
		dataToUpdate?: any;
	}> = new Subject();

	eventUserChecked: WritableSignal<IEventUser> = signal(null);
	eventUserCheck: WritableSignal<ICheckinChecked> = signal(null);

	errorCheck: WritableSignal<string> = signal("");
	validMsgCheck: WritableSignal<string> = signal("");
	showSignatureStage: WritableSignal<boolean> = signal(false);
	showCheckModal: WritableSignal<boolean> = signal(false);

	enableScanning: WritableSignal<boolean> = signal(false);
	cameraFound: WritableSignal<boolean> = signal(false);
	checkType: WritableSignal<"checked" | "unchecked" | "error" | ""> = signal("");
	modalCurrentStep: WritableSignal<"profil" | "signature" | "end"> = signal("profil");

	constructor(
		private SFirestore: FirestoreService,
		private SMongo: MongodbService,
		private SStorage: StorageService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private store: Store
	) {}

	/**
	 * Get all checkins for event
	 * @param eventId
	 * @returns
	 */
	getCheckinsOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.checkinsSub && !this.checkinsSub.closed) {
					return;
				} else if (!sameEvent && this.checkinsSub && !this.checkinsSub.closed) {
					this.checkinsSub.unsubscribe();
				}

				this.checkinsSub = this.SFirestore.collectionGroupValueChangesDocuments("checkins", [
					where("eventId", "==", eventId),
					where("visibility", "==", true)
				]).subscribe((checkins: ICheckin[]) => {
					this.store.dispatch(GetAllCheckins({ payload: checkins, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initCheckins"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initCheckins", payload: true })
								);
							}
						});
				});
			});
	}

	unsubscribeAll() {
		if (this.checkinsSub) {
			this.checkinsSub.unsubscribe();
		}
	}

	/**
	 * Get checkin checked
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @returns
	 */
	getCheckinCheckeds(eventId: string, moduleId: string, checkinId: string) {
		return this.SFirestore.valueChangesDocuments(
			`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked`,
			[]
		);
	}

	/**
	 * Get checkin checked for users
	 * @param eventId
	 * @param checkinId
	 * @param usersIds
	 * @returns
	 */
	getCheckinCheckedForUsers(eventId: string, checkinId: string, usersIds: string[]) {
		if (usersIds.length === 0) {
			return of([]);
		}
		const allIds = _.chunk(usersIds, 10);
		const obsArray: Observable<ICheckinChecked[]>[] = [];
		const queryConstraints: QueryConstraint[] = [
			where("eventId", "==", eventId),
			where("checkinId", "==", checkinId)
		];

		allIds.forEach((ids) => {
			obsArray.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup(
						`checkin-checked`,
						queryConstraints.concat([where("userId", "in", ids)])
					)
				).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as ICheckinChecked)))
			);
		});
		return forkJoin(obsArray).pipe(
			switchMap((results) => {
				const checkinCheckeds: ICheckinChecked[] = _.uniqBy(_.flatten(results), "userId");
				return of(checkinCheckeds);
			})
		);
	}

	/**
	 * Get checkin checked for users
	 * @param eventId
	 * @param checkinId
	 * @param usersIds
	 * @returns
	 */
	getCheckinCheckedForUser(eventId: string, checkinId: string, userId: string) {
		const queryConstraints: QueryConstraint[] = [
			where("eventId", "==", eventId),
			where("checkinId", "==", checkinId),
			where("userId", "==", userId)
		];

		return from(this.SFirestore.getDocumentsCollectionGroup(`checkin-checked`, queryConstraints)).pipe(
			map((snapshot) => snapshot.docs.map((doc) => doc.data() as ICheckinChecked))
		);
	}

	/**
	 * Get checkin checkeds for event user
	 * @param eventId
	 * @param eventUserId
	 * @returns
	 */
	getCheckedsForEventUser(eventId: string, eventUserId: string) {
		return this.SFirestore.collectionGroupValueChangesDocuments("checkin-checked", [
			where("eventId", "==", eventId),
			where("userId", "==", eventUserId),
			// where("checkinStatus", "==", true),
			orderBy("creationDate", "desc")
		]);
	}

	/**
	 * Get checkin
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @returns
	 */
	getCheckin(eventId: string, moduleId: string, checkinId: string) {
		return this.SFirestore.valueChangesDocument(`events/${eventId}/modules/${moduleId}/checkins/${checkinId}`);
	}

	/**
	 * Create checkin checked
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @param checked
	 * @returns
	 */
	createCheckinChecked(eventId: string, moduleId: string, checkinId: string, checked: ICheckinChecked) {
		checked.uid = checked.uid
			? checked.uid
			: this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked`);
		return Promise.all([
			this.SMongo.manageDatasMongodb({
				type: "insert-one",
				collection: "checkin-checked",
				datas: [checked]
			}),
			this.SFirestore.setDocument(
				`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked/${checked.uid}`,
				checked
			)
		]);
	}

	/**
	 * Update checkin checked
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @param checked
	 * @returns
	 */
	updateCheckinChecked(eventId: string, moduleId: string, checkinId: string, checked: ICheckinChecked) {
		return Promise.all([
			this.SMongo.manageDatasMongodb({
				type: "update-one",
				collection: "checkin-checked",
				query: {
					eventId: { $eq: eventId },
					moduleId: { $eq: moduleId },
					checkinId: { $eq: checkinId },
					uid: { $eq: checked.uid }
				},
				datas: [
					this.SMongo.transformObjectToUpdateItem(
						_.omit(checked, ["_id", "uid", "moduleId", "checkinId", "eventId", "userId"])
					)
				]
			}),
			this.SFirestore.updateDocument(
				`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked/${checked.uid}`,
				_.omit(checked, ["eventId", "moduleId", "checkinId", "uid", "userId", "validationType", "_id"])
			)
		]);
	}

	/**
	 *
	 * Scan part
	 *
	 */

	resetCheck() {
		this.eventUserChecked.set(null);
		this.eventUserCheck.set(null);
		this.errorCheck.set("");
		this.checkType.set("");
		this.showCheckModal.set(false);
		this.showSignatureStage.set(false);
		this.modalCurrentStep.set("profil");
		this.enableScanning.set(true);
		this.stateScanQrSubject.next({ state: "ENDED" });
	}

	async testSuccessHandler(checkin: ICheckin) {
		this.enableScanning.set(false);
		// Get event user data linked to check
		try {
			const qrEventId = "QkZDb8NWKLr7dmNAjIBn";
			const qrEventUserId = "Aq6yQ88dkI4HR3ob98si";

			if (qrEventId && qrEventUserId) {
				const eventUser = await firstValueFrom(
					this.SFirestore.collectionGroupValueChangesDocuments(`event-users`, [
						where("eventId", "==", qrEventId),
						where("uid", "==", qrEventUserId)
					]).pipe(
						take(1),
						switchMap((results: IEventUser[]) => {
							return of(results.length > 0 ? results[0] : null);
						})
					)
				);
				eventUser["tagsDatas"] = await firstValueFrom(
					this.store.select(getSpecificsTracks(eventUser.tags ? eventUser.tags : []))
				);

				if (eventUser) {
					this.eventUserChecked.set(eventUser);

					this.changeEventUserStatus(checkin, eventUser);
				} else {
					this.checkType.set("error");
					this.errorCheck.set(this.STranslate.instant("alerts.invalid_qrcode"));
					this.showCheckModal.set(true);
				}
			} else {
				this.checkType.set("error");
				this.errorCheck.set(this.STranslate.instant("alerts.invalid_qrcode"));
				this.showCheckModal.set(true);
			}
		} catch (error) {
			this.checkType.set("error");
			this.errorCheck.set(this.STranslate.instant("alerts.invalid_qrcode"));
			this.showCheckModal.set(true);
		}
	}

	/**
	 * Scan success event for web
	 * @param evt
	 */
	async scanSuccessHandler(barcodeData: string, checkin: ICheckin) {
		this.enableScanning.set(false);
		// Get event user data linked to check
		try {
			const qrEventId =
				barcodeData && barcodeData.split("+") && barcodeData.split("+").length > 0
					? barcodeData.split("+")[0]
					: "";
			const qrEventUserId =
				barcodeData && barcodeData.split("+") && barcodeData.split("+").length > 0
					? barcodeData.split("+")[1]
					: "";

			if (qrEventId && qrEventUserId) {
				const eventUser = await firstValueFrom(
					this.SFirestore.collectionGroupValueChangesDocuments(`event-users`, [
						where("eventId", "==", qrEventId),
						where("uid", "==", qrEventUserId)
					]).pipe(
						take(1),
						switchMap((results: IEventUser[]) => {
							return of(results.length > 0 ? results[0] : null);
						})
					)
				);

				eventUser["tagsDatas"] = await firstValueFrom(
					this.store.select(getSpecificsTracks(eventUser.tags ? eventUser.tags : []))
				);

				if (
					eventUser &&
					(checkin.groupsInType === 0 ||
						(checkin.groupsInType === 1 &&
							eventUser.groups.some((grpId) => checkin.groupsInCheckin.includes(grpId))))
				) {
					this.eventUserChecked.set(eventUser);

					this.changeEventUserStatus(checkin, eventUser);
				} else {
					this.checkType.set("error");
					this.errorCheck.set(this.STranslate.instant("alerts.invalid_qrcode"));
					this.showCheckModal.set(true);
				}
			} else {
				this.checkType.set("error");
				this.errorCheck.set(this.STranslate.instant("alerts.invalid_qrcode"));
				this.showCheckModal.set(true);
			}
		} catch (error) {
			this.checkType.set("error");
			this.errorCheck.set(this.STranslate.instant("alerts.invalid_qrcode"));
			this.showCheckModal.set(true);
		}
	}

	/**
	 * Emits an array of video-devices after view was initialized.
	 */
	camerasFoundHandler() {
		this.cameraFound.set(true);
	}

	/**
	 * Not camera found for scanning
	 */
	async camerasNotFoundHandler() {
		this.cameraFound.set(false);
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.error_scanning"),
			this.STranslate.instant("alerts.no_camera"),
			[
				{
					text: this.STranslate.instant("buttons.no"),
					role: "cancel"
				}
			]
		);
		alert.present();
	}

	/**
	 * Scan error handler
	 * @param evt
	 */
	async scanErrorHandler(error: any) {
		this.enableScanning.set(false);
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.error_scanning"),
			this.STranslate.instant("alerts.invalid_qrcode"),
			[
				{
					text: this.STranslate.instant("buttons.yes"),
					handler: () => {
						this.enableScanning.set(true);
					}
				},
				{
					text: this.STranslate.instant("buttons.no"),
					role: "cancel"
				}
			]
		);
		alert.present();
	}

	/**
	 *
	 * Check part
	 *
	 */

	/**
	 * Manual change of status
	 * @param attendee
	 */
	async manualChangeStatus(checkin: ICheckin, eventUser: IEventUser) {
		eventUser["tagsDatas"] = await firstValueFrom(
			this.store.select(getSpecificsTracks(eventUser.tags ? eventUser.tags : []))
		);

		this.eventUserChecked.set(eventUser);

		this.changeEventUserStatus(checkin, eventUser);
	}

	/**
	 * Change status
	 * @param eventUser
	 */
	async changeEventUserStatus(checkin: ICheckin, eventUser: IEventUser) {
		try {
			let checkChecked = await firstValueFrom(
				this.SFirestore.getDocumentsObs(
					`events/${checkin.eventId}/modules/${checkin.moduleId}/checkins/${checkin.uid}/checkin-checked`,
					[where("userId", "==", eventUser.uid)]
				).pipe(
					map((snapshot) => snapshot.docs.map((doc) => doc.data() as ICheckinChecked)),
					switchMap((results) => {
						return of(results.length > 0 ? results[0] : null);
					})
				)
			);
			if (checkin.onlyOneCheck && checkChecked && checkChecked.checkinStatus) {
				this.checkType.set("error");
				this.errorCheck.set(this.STranslate.instant("checkin.only-one-check-authorized"));
				this.showCheckModal.set(true);
				return;
			} else {
				if (checkChecked) {
					this.eventUserCheck.set(checkChecked);
					checkChecked.checkinStatus = !checkChecked.checkinStatus;
					if (checkChecked.checkinStatus) {
						// If checkin status true
						if (!eventUser.checkins) {
							const checkins = await firstValueFrom(this.store.select(getAllCheckins));

							eventUser.checkins = {
								checkinsChecked: [checkChecked.checkinId],
								checkinsNotChecked: checkins
									.filter((checkin) => checkin.uid !== checkChecked.checkinId)
									.map((checkin) => checkin.uid)
							};
						} else {
							if (!eventUser.checkins.checkinsChecked.includes(checkChecked.checkinId)) {
								eventUser.checkins.checkinsChecked.push(checkChecked.checkinId);
							}
							eventUser.checkins.checkinsNotChecked = eventUser.checkins.checkinsNotChecked.filter(
								(uid) => uid !== checkChecked.checkinId
							);
						}
					} else {
						// If checkin status false
						if (!eventUser.checkins) {
							const checkins = await firstValueFrom(this.store.select(getAllCheckins));
							eventUser.checkins = {
								checkinsChecked: [],
								checkinsNotChecked: checkins.map((checkin) => checkin.uid)
							};
						} else {
							eventUser.checkins.checkinsChecked = eventUser.checkins.checkinsChecked.filter(
								(uid) => uid !== checkChecked.checkinId
							);
							if (!eventUser.checkins.checkinsNotChecked.includes(checkChecked.checkinId)) {
								eventUser.checkins.checkinsNotChecked.push(checkChecked.checkinId);
							}
						}
					}

					if (checkin.multiCheck && checkChecked.checkinStatus) {
						checkChecked = this.constructMultiCheckRecord(
							checkChecked,
							eventUser,
							checkChecked.checkinStatus ? "add" : "remove"
						);
					}

					checkChecked.userQueryName = eventUser.queryName;
					checkChecked.groupsWhenChecked = eventUser.groups;
					checkChecked.creationDate = DateTime.local().toISO();
					await this.updateCheckinChecked(checkin.eventId, checkin.moduleId, checkin.uid, checkChecked);

					await this.SFirestore.updateDocument(
						`events/${eventUser.eventId}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}`,
						{ checkins: eventUser.checkins }
					);

					await this.checkinProcessed(checkin.eventId, eventUser, {
						checkinId: checkin.uid,
						checkinStatus: checkChecked.checkinStatus,
						moduleId: checkin.moduleId,
						type: TypeTracking.CHECKIN_ACTION_PROCESSED
					});
					this.checkType.set(checkChecked.checkinStatus ? "checked" : "unchecked");
					this.validMsgCheck.set(
						checkChecked.checkinStatus
							? this.STranslate.instant("checkin.user_registered")
							: this.STranslate.instant("checkin.user_unregistered")
					);
					this.showCheckModal.set(true);
				} else if (!checkChecked) {
					const newChecked: ICheckinChecked = {
						uid: "",
						checkinId: checkin.uid,
						checkinStatus: true,
						creationDate: DateTime.local().toISO(),
						eventId: checkin.eventId,
						groupsWhenChecked: eventUser.groups,
						moduleId: checkin.moduleId,
						multiCheckRecords: [],
						multiCheckRecordsCount: 0,
						signature: "",
						userQueryName: eventUser.queryName,
						userId: eventUser.uid,
						validationType: 2
					};

					// If checkin status true
					if (!eventUser.checkins) {
						const checkins = await firstValueFrom(this.store.select(getAllCheckins));
						eventUser.checkins = {
							checkinsChecked: [newChecked.checkinId],
							checkinsNotChecked: checkins
								.filter((checkin) => checkin.uid !== newChecked.checkinId)
								.map((checkin) => checkin.uid)
						};
					} else if (!eventUser.checkins.checkinsChecked.includes(newChecked.checkinId)) {
						eventUser.checkins.checkinsChecked.push(newChecked.checkinId);
						eventUser.checkins.checkinsNotChecked = eventUser.checkins.checkinsNotChecked.filter(
							(uid) => uid !== newChecked.checkinId
						);
					}
					await this.createCheckinChecked(checkin.eventId, checkin.moduleId, checkin.uid, newChecked);
					await this.SFirestore.updateDocument(
						`events/${eventUser.eventId}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}`,
						{ checkins: eventUser.checkins }
					);

					await this.checkinProcessed(checkin.eventId, eventUser, {
						checkinId: checkin.uid,
						checkinStatus: newChecked.checkinStatus,
						moduleId: checkin.moduleId,
						type: TypeTracking.CHECKIN_ACTION_PROCESSED
					});
					this.eventUserCheck.set(newChecked);
					this.checkType.set("checked");
					this.validMsgCheck.set(this.STranslate.instant("checkin.user_registered"));
					this.showCheckModal.set(true);
				} else {
					this.checkType.set("checked");
					this.validMsgCheck.set(this.STranslate.instant("checkin.already_checked"));
					this.showCheckModal.set(true);
				}
			}
		} catch (error) {
			this.checkType.set("error");
			this.errorCheck.set(this.STranslate.instant("alerts.not_possible_change_status"));
			this.showCheckModal.set(true);
		}
	}

	async modifySignatureOfCheck(check: ICheckinChecked, signature: string) {
		try {
			let signatureFileUrl = "";
			if (signature && signature !== "empty") {
				signatureFileUrl = await this.SStorage.uploadBase64(
					signature.split("data:image/png;base64,")[1],
					"image/png",
					// eslint-disable-next-line max-len
					`events/${check.eventId}/modules/${check.moduleId}/checkins-signature/${check.checkinId}/${check.userId}/signature.png`
				);
			}
			check.signature = signatureFileUrl;
			await this.updateCheckinChecked(check.eventId, check.moduleId, check.checkinId, check);
			if (signatureFileUrl || signature === "empty") {
				this.snackbar.open(this.STranslate.instant("snackbar.signature-saved"), "", {
					duration: 3000,
					panelClass: "success-snackbar"
				});
				this.resetCheck();
			}
		} catch (error) {
			console.log("Error: ", error);
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	checkProgress: boolean = false;
	updating: boolean = false;
	async modifyMultiCheck(checkin: ICheckin, eventUserId: string, type: "add" | "remove") {
		if (!this.updating) {
			this.updating = true;
			const eventUser = await firstValueFrom(
				this.SFirestore.getDocumentsObs(`event-users`, [
					where("eventId", "==", checkin.eventId),
					where("uid", "==", eventUserId)
				]).pipe(
					map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)),
					switchMap((results) => {
						return of(results.length > 0 ? results[0] : null);
					})
				)
			);
			let checked = await firstValueFrom(
				this.SFirestore.getDocumentsObs(
					`events/${checkin.eventId}/modules/${checkin.moduleId}/checkins/${checkin.uid}/checkin-checked`,
					[where("userId", "==", eventUserId)]
				).pipe(
					map((snapshot) => snapshot.docs.map((doc) => doc.data() as ICheckinChecked)),
					switchMap((results) => {
						return of(results.length > 0 ? results[0] : null);
					})
				)
			);

			if (
				type === "remove" &&
				checked &&
				(!checked.multiCheckRecordsCount || checked.multiCheckRecordsCount <= 0) &&
				!this.checkProgress
			) {
				return;
			}
			this.checkProgress = true;
			const checker = await firstValueFrom(this.store.select(getCurrentEventUser));
			checked = this.constructMultiCheckRecord(checked, checker, type);
			try {
				await this.updateCheckinChecked(checked.eventId, checked.moduleId, checked.checkinId, checked);

				if (eventUser) {
					await this.checkinProcessed(checkin.eventId, eventUser, {
						checkinId: checkin.uid,
						checkinStatus: checked.checkinStatus,
						moduleId: checkin.moduleId,
						type:
							type === "remove"
								? TypeTracking.CHECKIN_MULTI_CHECK_REMOVE_ACTION_PROCESSED
								: TypeTracking.CHECKIN_MULTI_CHECK_ADD_ACTION_PROCESSED
					});
				}

				this.checkProgress = false;
				this.updating = false;
				this.resetCheck();
			} catch (error) {
				this.checkProgress = false;
				this.updating = false;
				this.resetCheck();
			}
		}
	}

	constructMultiCheckRecord(checked: ICheckinChecked, eventUser: IEventUser, type: "add" | "remove") {
		const newCheck: IMultiCheckRecord = {
			checkDate: DateTime.local().toISO(),
			checkedUserEmail: "",
			checkedUserId: checked.userId,
			checkerUserEmail: eventUser.email,
			checkerUserId: eventUser.uid,
			type: type
		};

		if (type === "add") {
			checked.multiCheckRecordsCount =
				checked.multiCheckRecordsCount === undefined || checked.multiCheckRecordsCount === null
					? 1
					: checked.multiCheckRecordsCount + 1;
		} else {
			checked.multiCheckRecordsCount =
				checked.multiCheckRecordsCount === undefined || checked.multiCheckRecordsCount === null
					? 0
					: checked.multiCheckRecordsCount - 1;
		}

		checked.multiCheckRecords.push(newCheck);
		return checked;
	}

	// /*********************************************************/
	// /******************* Checkin analytics *****************/
	// /*********************************************************/
	async checkinProcessed(eventId: string, eventUser: IEventUser, checkinData: any) {
		if (eventId && eventUser && checkinData) {
			try {
				const event = await firstValueFrom(
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				);
				if (!event) return;

				const tracking: IAnalyticsUserRoadTracking = {
					creationDate: DateTime.now().toISO(),

					eventId: eventUser.eventId,
					moduleId: eventUser.moduleId,
					options: {
						data: {
							...checkinData
						},
						targetModuleId: checkinData.moduleId,
						targetItemId: checkinData.checkinId,
						pathUids: [checkinData.moduleId, checkinData.checkinId],
						pathTypes: [
							checkinActionTypes.includes(checkinData.type) ? TypeModule.CHECKIN : TypeModule.ATTENDEE,
							checkinData.type
						]
					} as IOptionsAnalytics,
					type: TypeTrackingNumber[checkinData.type],
					typeName: TypeNameTracking[checkinData.type],
					uid: this.SFirestore.createId(
						// eslint-disable-next-line max-len
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
					),
					userId: eventUser.uid
				};

				return this.SFirestore.setDocument(
					// eslint-disable-next-line max-len
					`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
					tracking
				);
			} catch (error) {
				console.error("Error on 'checkinProcessed()' : ", error);
			}
		} else {
			return;
		}
	}
}
