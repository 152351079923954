import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import { GetHeaderTitle, GetHeaderType, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { ICheckin, IEvent, IEventUser, IModule } from "src/app/shared/interfaces";
import { getMyEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCheckins } from "src/app/shared/selectors/checkins.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { FirestoreService } from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";

@Component({
	selector: "app-checkins",
	templateUrl: "./checkins.component.html",
	styleUrls: ["./checkins.component.scss"],
	standalone: false
})
export class CheckinsComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	checkinsSub: Subscription;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;

	checkins: ICheckin[] = [];

	isMobile: boolean = false;
	eventUser: IEventUser;
	eventUsersSub: any;
	eventUsers: IEventUser[] = [];

	constructor(
		private SAnalytics: AnalyticsService,
		private store: Store,
		private navCtrl: NavController,
		private platform: Platform,
		private SFirestore: FirestoreService
	) {}

	ngOnInit() {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("android") ||
			this.platform.is("ios")
				? true
				: false;
	}

	ionViewWillEnter() {
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.subscriptions.forEach((sub) => sub.unsubscribe());

						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_CHECKIN_MODULE
						);
						this.initDatas();
					});
			});
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getCheckins();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.unsubscribeCheckins();
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.unsubscribeCheckins();
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.eventUsersSub && this.eventUsersSub.unsubscribe();
	}

	unsubscribeCheckins() {
		if (this.checkinsSub && !this.checkinsSub.closed) {
			this.checkinsSub.unsubscribe();
		}
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Get eventUser
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getMyEventUser).subscribe((user) => {
				this.eventUser = user;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;
				if (this.module) {
					this.store.dispatch(
						GetHeaderType({
							payload: TypeHeader.CHECKINS
						})
					);
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				}
			})
		);
	}

	/**
	 * Get all checkins
	 */
	getCheckins() {
		this.unsubscribeCheckins();
		this.checkinsSub = this.store
			.select(getCheckins)
			.pipe(skipWhile(() => !this.module))
			.subscribe((checkins) => {
				this.checkins = checkins;
			});
	}

	/**
	 * Open checkin
	 * @param checkin
	 */
	openCheckin(checkin: ICheckin) {
		this.navCtrl.navigateForward(`/event/${this.eventId}/checkins/${this.moduleId}/checkin/${checkin.uid}`);
	}
}
