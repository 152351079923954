<!-- Blank (Required) Error field -->
@if (
	computedCustomField.moduleSettings.required &&
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	) &&
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	).errors?.required &&
	(form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	).touched ||
		form.get(
			mode === "singleLanguage"
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + "_" + language
		).dirty)
) {
	<div class="form-bloc-error">
		<p class="error-label-text">
			{{ "edit-profil.errors.blank.simple_text" | translate }}
		</p>
	</div>
}

<!-- Invalids format -->
<!-- (--minimum length error --) -->
@if (
	computedCustomField.baseSettings.type !== 1 &&
	computedCustomField.baseSettings.type !== 2 &&
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	) &&
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	).errors?.minlength
) {
	<div class="form-bloc-error">
		<p class="error-label-text">
			{{ "edit-profil.errors.invalid.min_length" | translate }}&nbsp;
			{{ 1 }}
			{{ "edit-profil.errors.characters" | translate }}&nbsp;
		</p>
	</div>
}

<!-- (--maximum length error x--) -->
@if (
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	) &&
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	) &&
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	).errors?.maxlength
) {
	<div class="form-bloc-error">
		<p class="error-label-text">{{ "edit-profil.errors.invalid.multiple_text-too-long" | translate }} (100)</p>
	</div>
}

<!-- Numeric error -->
@if (
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	) &&
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	) &&
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	).errors?.max
) {
	<div class="form-bloc-error">
		<p class="error-label-text">
			{{ "edit-profil.errors.invalid.big_number" | translate }}
		</p>
	</div>
}

<!-- Pattern error -->
@if (
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	) &&
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	) &&
	form.get(
		mode === "singleLanguage"
			? computedCustomField.baseSettings.uid
			: computedCustomField.baseSettings.uid + "_" + language
	).errors?.pattern
) {
	<div class="form-bloc-error">
		<!-- URL -->
		@if (computedCustomField.baseSettings.type === typeCustomFields.URL) {
			<p class="error-label-text">
				{{ "edit-profil.errors.invalid.url" | translate }}
			</p>
		}
		<!-- EMAIL -->
		@if (computedCustomField.baseSettings.type === typeCustomFields.EMAIL) {
			<p class="error-label-text">
				{{ "edit-profil.errors.invalid.email" | translate }}
			</p>
		}
		<!-- Date -->
		@if (computedCustomField.baseSettings.type === typeCustomFields.DATE) {
			<p class="error-label-text">
				{{ "edit-profil.errors.invalid.date" | translate }}
			</p>
		}
	</div>
}

<!-- Phone number error -->
@if (computedCustomField.baseSettings.type === typeCustomFields.PHONE) {
	<div class="form-bloc-error">
		@if (
			form.get(
				mode === "singleLanguage"
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + "_" + language
			) &&
			form.get(
				mode === "singleLanguage"
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + "_" + language
			).errors?.required &&
			(form.get(
				mode === "singleLanguage"
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + "_" + language
			).touched ||
				form.get(
					mode === "singleLanguage"
						? computedCustomField.baseSettings.uid
						: computedCustomField.baseSettings.uid + "_" + language
				).dirty)
		) {
			<p class="error-label-text">
				{{ "edit-profil.errors.blank.required" | translate }}
			</p>
		}
		@if (
			form.get(
				mode === "singleLanguage"
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + "_" + language
			) &&
			!form.get(
				mode === "singleLanguage"
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + "_" + language
			).errors?.required &&
			form.get(
				mode === "singleLanguage"
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + "_" + language
			).errors?.pattern &&
			(form.get(
				mode === "singleLanguage"
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + "_" + language
			).touched ||
				form.get(
					mode === "singleLanguage"
						? computedCustomField.baseSettings.uid
						: computedCustomField.baseSettings.uid + "_" + language
				).dirty)
		) {
			<p class="error-label-text">
				{{ "edit-profil.errors.invalid.phone_number" | translate }}
			</p>
		}
	</div>
}

<!-- Custom Validators -->

<!-- Multi-text -->
@if (
	computedCustomField.baseSettings.type === typeCustomFields.DATE &&
		SForm.getInputErrorType($any(form.get(computedCustomField.baseSettings.uid)));
	as errorType
) {
	<div class="form-bloc-error">
		@if (
			errorType === "multiTextIsRequired" ||
			errorType === "controlShouldNotContainValue" ||
			errorType === "multiTextShouldContainValue"
		) {
			<p class="error-label-text">
				{{ form.get(computedCustomField.baseSettings.uid)?.errors?.[errorType]?.["msg"] | translate }}
			</p>
		}
	</div>
}

<!-- Min/max dateValidators -->
@if (
	computedCustomField.baseSettings.type === typeCustomFields.DATE &&
		SForm.getInputErrorType($any(form.get(computedCustomField.baseSettings.uid)));
	as errorType
) {
	<div class="form-bloc-error">
		@if (errorType === "dateMaximum") {
			<p class="error-label-text" style="font-size: 0.8rem; width: 100%">
				{{
					form.get(computedCustomField.baseSettings.uid)?.errors?.[errorType]?.["msg"]
						| translate
							: {
									maxDate: SLuxon.convertIsoDateToFormat(
										computedCustomField.baseSettings?.dateRule?.maxDayDateIsEnabled
											? dateTime.now().toISO()
											: computedCustomField.baseSettings?.dateRule?.maxDate,
										language
									)
							  }
				}}
			</p>
		}
		@if (errorType === "dateMinimum") {
			<p class="error-label-text" style="font-size: 0.8rem; width: 100%">
				{{
					form.get(computedCustomField.baseSettings.uid)?.errors?.[errorType]?.["msg"]
						| translate
							: {
									minDate: SLuxon.convertIsoDateToFormat(
										computedCustomField.baseSettings?.dateRule?.minDayDateIsEnabled
											? dateTime.now().toISO()
											: computedCustomField.baseSettings?.dateRule?.minDate,
										language
									)
							  }
				}}
			</p>
		}
	</div>
}
