import { Component, OnDestroy, AfterViewInit } from "@angular/core";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, combineLatest } from "rxjs";
import { debounceTime, skipWhile, take } from "rxjs/operators";
import { GetHeaderTitle, GetPaginationRecords, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, IEventUser, IFullCustomField, ILocation, IModule, ISchedule, ITrack } from "src/app/shared/interfaces";
import { IScheduleByTrack } from "src/app/shared/interfaces/schedules.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getLocations,
	getModulesCustomsFieldsOfModule,
	getTracks
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { getPaginationRecords, getSpecificPaginationRecord } from "src/app/shared/selectors/utility.selectors";
import { AnalyticsService, CustomFieldsService, SchedulesService, UtilityService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { ConfirmIcsDownloadComponent } from "../../modals/confirm-ics-download/confirm-ics-download.component";
import { register } from "swiper/element/bundle";
import * as _ from "lodash-es";
import { IFilters } from "src/app/shared/interfaces/filters.interfaces";

@Component({
	selector: "app-personal-schedule",
	templateUrl: "./personal-schedule.component.html",
	styleUrls: ["./personal-schedule.component.scss"],
	standalone: false
})
export class PersonalScheduleComponent implements OnDestroy, AfterViewInit {
	// @ViewChild("datesSwiper", { static: false }) datesSwiper: ElementRef;
	// swiperModules = [IonicSlides];
	// swiperOptions: SwiperOptions = {
	// 	effect: "flip",
	// 	slidesPerView: 6,
	// 	slidesPerGroup: 1,
	// 	updateOnWindowResize: true,
	// 	centerInsufficientSlides: true
	// };
	subscriptions: Subscription[] = [];
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	computedCustomFields: IFullCustomField[] = [];
	sessions: ISchedule[] = [];
	sessionsFiltered: ISchedule[] = [];
	sessionsByTrackFiltered: IScheduleByTrack[] = [];
	tracks: ITrack[] = [];
	locations: ILocation[] = [];
	eventUser: IEventUser;
	dates: string[] = [];
	selectedDate: string = "all";
	filters: IFilters = {
		locations: [],
		tracks: [],
		customFields: [],
		principalKey: ""
	};
	sessionsPerPage: number = 10; // Min 10
	numberOfSessionsShowed: number = 10; // Min 10
	paginateByTrack: number = 1;

	isMobile: boolean = false;
	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(
		private SAnalytics: AnalyticsService,
		private store: Store,
		private modalCtrl: ModalController,
		private navCtrl: NavController,
		private STranslate: TranslateService,
		private SCustomFields: CustomFieldsService,
		private SSchedules: SchedulesService,
		public SUtility: UtilityService,
		private platform: Platform
	) {
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.subscriptions.forEach((sub) => sub.unsubscribe());
						this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());

						this.subscriptions.push(
							this.STranslate.onLangChange.subscribe((lang) => {
								this.currentLanguage = lang.lang;
							})
						);

						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_PERSONNAL_SCHEDULE_MODULE
						);

						this.initDatas();
					});
			});
	}

	ngAfterViewInit() {
		register();
	}

	initDatas() {
		// Get pagination record
		this.store
			.select(getSpecificPaginationRecord(this.moduleId))
			.pipe(take(1))
			.subscribe((paginationRecord) => {
				if (paginationRecord) {
					this.numberOfSessionsShowed =
						paginationRecord.totalItemsLoaded > 10 ? paginationRecord.totalItemsLoaded : 10;
				}
			});
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getTracks();
		this.getLocations();
		this.getCustomFields();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.eventUser = null;
		this.store
			.select(getPaginationRecords)
			.pipe(take(1))
			.subscribe((paginationRecords) => {
				let paginationRecord = paginationRecords.find((pagRecord) => pagRecord.listModuleId === this.moduleId);
				if (paginationRecord) {
					paginationRecord.itemId = "";
					paginationRecord.listModuleId = this.moduleId;
					paginationRecord.totalItemsLoaded = this.numberOfSessionsShowed;
				} else {
					paginationRecord = {
						itemId: "",
						listModuleId: this.moduleId,
						totalItemsLoaded: this.numberOfSessionsShowed
					};
					paginationRecords.push(paginationRecord);
				}
				this.store.dispatch(GetPaginationRecords({ payload: paginationRecords }));
			});
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;
				if (this.module) {
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
					if (this.eventUser) {
						this.getPersonalScheduleSessions();
					}
				}
			})
		);
	}

	/**
	 * Get custom fields
	 */
	getCustomFields() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(getModulesCustomsFieldsOfModule(this.moduleId))
			]).subscribe((results) => {
				this.computedCustomFields =
					results[1].length > 0
						? results[1].map((customField) => {
								const baseCustomFieldCorresponding = results[0].find(
									(custField) => custField.uid === customField.uid
								);
								return {
									baseSettings: baseCustomFieldCorresponding ? baseCustomFieldCorresponding : null,
									moduleSettings: customField,
									fieldDatas: null
								};
							})
						: [];
			})
		);
	}

	/**
	 * Get all tracks
	 */
	getTracks() {
		this.subscriptions.push(
			this.store
				.select(getTracks)
				.pipe(skipWhile(() => !this.eventUser))
				.subscribe((tracks) => {
					this.tracks = tracks;

					this.filters.tracks = this.tracks
						.sort((a, b) =>
							a.name[
								this.eventUser.updatedSettings &&
								this.eventUser.updatedSettings.language &&
								a.name[this.eventUser.updatedSettings.language]
									? this.eventUser.updatedSettings.language
									: this.event.language
							] >
							b.name[
								this.eventUser.updatedSettings &&
								this.eventUser.updatedSettings.language &&
								b.name[this.eventUser.updatedSettings.language]
									? this.eventUser.updatedSettings.language
									: this.event.language
							]
								? 1
								: a.name[
											this.eventUser.updatedSettings &&
											this.eventUser.updatedSettings.language &&
											a.name[this.eventUser.updatedSettings.language]
												? this.eventUser.updatedSettings.language
												: this.event.language
									  ] <
									  b.name[
											this.eventUser.updatedSettings &&
											this.eventUser.updatedSettings.language &&
											b.name[this.eventUser.updatedSettings.language]
												? this.eventUser.updatedSettings.language
												: this.event.language
									  ]
									? -1
									: 0
						)
						.map((track) => {
							return {
								uid: track.uid,
								name: track.name,
								checked:
									this.filters && this.filters.tracks.find((tra) => tra.uid === track.uid)
										? this.filters.tracks.find((tra) => tra.uid === track.uid).checked
										: false,
								totalDatas: 0
							};
						});
				})
		);
	}

	/**
	 * Get all locations
	 */
	getLocations() {
		this.subscriptions.push(
			this.store
				.select(getLocations("asc"))
				.pipe(skipWhile(() => !this.eventUser))
				.subscribe((locations) => {
					this.locations = locations;

					this.filters.locations = this.locations
						.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
						.map((location) => {
							return {
								uid: location.uid,
								name: location.name,
								checked:
									this.filters && this.filters.locations.find((loc) => loc.uid === location.uid)
										? this.filters.locations.find((loc) => loc.uid === location.uid).checked
										: false,
								totalDatas: 0
							};
						});
				})
		);
	}

	compareDates(dateOne: string, dateTwo: string) {
		return (
			this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateTwo),
				"day"
			) &&
			this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateTwo),
				"year"
			) &&
			this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateTwo),
				"month"
			)
		);
	}

	/**
	 * Get personal schedule sessions
	 */
	getPersonalScheduleSessions() {
		this.subscriptions.push(
			this.SSchedules.getPersonalScheduleSessions(this.eventId, this.eventUser.uid)
				// this.store
				// 	.select(getPersonalScheduleSessions)
				.pipe(
					skipWhile(() => !this.eventUser),
					debounceTime(200)
				)
				.subscribe((sessions) => {
					this.sessions = sessions.sort((a, b) =>
						a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0
					);

					// Build dates
					this.dates = this.sessions
						.filter((session, index) => {
							const sessionsBefore = index > 0 ? this.sessions.slice(0, index) : [];
							return !sessionsBefore.some((sessionBefore) =>
								this.compareDates(sessionBefore.startDate, session.startDate)
							);
						})
						.map((session) => session.sessionDate)
						.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
					this.filterByDate();
				})
		);
	}

	/**
	 * Paginate dates
	 * @returns
	 */
	getPaginatedDates() {
		if (this.selectedDate === "all") {
			return this.sessions
				.slice(0, this.numberOfSessionsShowed)
				.filter((session, index) => {
					const sessionsBefore = index > 0 ? this.sessions.slice(0, index) : [];
					return sessionsBefore.some((sessionBefore) =>
						this.compareDates(sessionBefore.startDate, session.startDate)
					)
						? false
						: true;
				})
				.map((session) => session.sessionDate)
				.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
		} else {
			return [this.selectedDate];
		}
	}

	/**
	 * Get paginated sessions
	 * @returns
	 */
	getPaginatedSessions() {
		return this.sessionsFiltered.slice(0, this.numberOfSessionsShowed);
	}

	/**
	 * Get paginated sessions
	 * @returns
	 */
	getPaginatedSessionsByTracks() {
		if (this.paginateByTrack === 1) {
			let checkIndexSlice = 0;
			let numberOfSessionsInSlice = 0;
			this.sessionsByTrackFiltered.forEach((groupTrack) => {
				if (checkIndexSlice < this.paginateByTrack || numberOfSessionsInSlice < 10) {
					numberOfSessionsInSlice = numberOfSessionsInSlice + groupTrack.sessions.length;
					checkIndexSlice++;
				}
			});

			this.paginateByTrack = checkIndexSlice;
			return this.sessionsByTrackFiltered.slice(0, this.paginateByTrack);
		} else {
			return this.sessionsByTrackFiltered.slice(0, this.paginateByTrack);
		}
	}

	/**
	 * Load more sessions
	 */
	moreSessions(evt: any, type: string) {
		if (evt) {
			if (type === "tracks") {
				if (this.paginateByTrack < this.sessionsByTrackFiltered.length) {
					this.paginateByTrack++;
					evt.target.complete();
				} else {
					evt.target.disabled = true;
				}
			} else {
				if (this.numberOfSessionsShowed < this.sessionsFiltered.length) {
					this.numberOfSessionsShowed = this.numberOfSessionsShowed + this.sessionsPerPage;
					evt.target.complete();
				} else {
					evt.target.disabled = true;
				}
			}
		}
	}

	/**
	 * Filter sessions by date
	 */
	filterByDate() {
		this.sessionsFiltered =
			this.selectedDate === "all"
				? this.applyFiltersOnSessions().sort((a, b) =>
						a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0
					)
				: this.applyFiltersOnSessions()
						.filter((session) => this.compareDates(session.startDate, this.selectedDate))
						.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
		// By tracks
		this.sessionsByTrackFiltered = [];
		this.applyFiltersOnSessions().forEach((session) => {
			if (session.tracks.length > 0) {
				session.tracks.forEach((trackId) => {
					const track = this.tracks.find((track) => track.uid === trackId);
					if (track) {
						const groupTrack = this.sessionsByTrackFiltered.find(
							(group) => group.track && group.track.uid === track.uid
						);
						if (!groupTrack) {
							this.sessionsByTrackFiltered.push({
								beginDate: "",
								endDate: "",
								mainLocation: null,
								sessions: [session],
								track: track ? track : null
							});
						} else {
							groupTrack.sessions.push(session);
						}
					}
				});
			} else {
				this.sessionsByTrackFiltered.push({
					beginDate: session.startDate,
					endDate: session.endDate ? session.endDate : null,
					mainLocation: null,
					sessions: [session],
					track: null
				});
			}
		});
		this.sessionsByTrackFiltered
			.map((groupTrack) => {
				groupTrack.sessions.sort((a, b) =>
					a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0
				);
				groupTrack.beginDate = groupTrack.sessions.length > 0 ? groupTrack.sessions[0].startDate : "";
				groupTrack.endDate =
					groupTrack.sessions.length > 0 && groupTrack.sessions[groupTrack.sessions.length - 1].endDate
						? groupTrack.sessions[groupTrack.sessions.length - 1].endDate
						: "";
				const locations = [];
				groupTrack.sessions.forEach((session) => {
					if (session.locations.length > 0) {
						session.locations.forEach((locationId) => {
							const location = this.locations.find((loc) => loc.uid === locationId);
							if (!locations.find((loc) => loc.uid === location.uid)) {
								locations.push(location);
							}
						});
					}
				});

				if (locations.length === 1) {
					groupTrack.mainLocation = locations[0];
				}

				return groupTrack;
			})
			.sort((a, b) => (a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0));
	}

	/**
	 * Apply filters on session
	 * @returns
	 */
	applyFiltersOnSessions() {
		return this.sessions
			.filter((session) => {
				// Filter by locations
				if (
					this.filters.locations.length === 0 ||
					!this.filters.locations.some((location) => location.checked)
				) {
					return true;
				} else {
					return this.filters.locations
						.filter((location) => location.checked)
						.some((location) => session.locations.includes(location.uid))
						? true
						: false;
				}
			})
			.filter((session) => {
				// Filter by tracks
				if (this.filters.tracks.length === 0 || !this.filters.tracks.some((track) => track.checked)) {
					return true;
				} else {
					return this.filters.tracks
						.filter((track) => track.checked)
						.some((track) => session.tracks.includes(track.uid))
						? true
						: false;
				}
			});
	}

	/**
	 * Get and update filters
	 */
	async getFilters() {
		const modal = await this.modalCtrl.create({
			component: PathComponents.schedulesFilters,
			componentProps: {
				event: this.event,
				eventUser: this.eventUser,
				filters: this.filters
			},
			cssClass: "schedules-filters-modal"
		});
		await modal.present();

		const { data } = await modal.onWillDismiss();
		if (data && data.filters) {
			this.filters = data.filters;
			this.filterByDate();
		}
	}

	/**
	 * Get part of date
	 * @param date
	 * @param unit
	 * @returns
	 */
	getPartOfDate(date: string, unit: string) {
		return this.SUtility.getPartOfDate(this.event, this.eventUser, date, unit);
	}

	/**
	 * Check session date and given date
	 * @param session
	 * @param date
	 * @returns
	 */
	checkSessionDate(session: ISchedule, date: string) {
		return this.compareDates(session.startDate, date) ? true : false;
		// return DateTime.fromISO(session.sessionDate).hasSame(DateTime.fromISO(date), "day") &&
		// 	DateTime.fromISO(session.sessionDate).hasSame(DateTime.fromISO(date), "year") &&
		// 	DateTime.fromISO(session.sessionDate).hasSame(DateTime.fromISO(date), "month")
		// 	? true
		// 	: false;
	}

	/**
	 * Get first track for session
	 * @param session
	 * @returns
	 */
	getFirstTrackForSession(session: ISchedule) {
		return session.tracks.length > 0 ? this.tracks.find((track) => track.uid === session.tracks[0]) : null;
	}

	/**
	 * Get specific track
	 * @param uid
	 */
	getSpecificTrack(uid: string) {
		return this.tracks.find((track) => track.uid === uid);
	}

	/**
	 * Get specific location
	 * @param uid
	 */
	getSpecificLocation(uid: string) {
		return this.locations.find((location) => location.uid === uid);
	}

	/**
	 * Slide previous
	 */
	// previousDatesSlide() {
	// 	this.datesSwiper.nativeElement.swiper.slidePrev();
	// }

	/**
	 * Slide next
	 */
	// nextDatesSlide() {
	// 	this.datesSwiper.nativeElement.swiper.slideNext();
	// }

	/**
	 * Get all custom fields tags for session
	 * @param session
	 */
	getTagsForSession(session: ISchedule) {
		return this.computedCustomFields.filter((custom) => {
			const fieldData = session.customFields.find((fieldData) => fieldData.uid === custom.baseSettings.uid);
			return custom.moduleSettings.canBeTag &&
				fieldData &&
				this.event &&
				this.SCustomFields.checkValueCustomField(custom.baseSettings.type, fieldData, this.event.language)
				? true
				: false;
		});
	}

	/**
	 * Get tag value
	 * @param session
	 * @param tag
	 */
	getTagValue(session: ISchedule, tag: IFullCustomField) {
		const fieldData = session.customFields.find((fieldData) => fieldData.uid === tag.baseSettings.uid);
		return this.SCustomFields.getValueForCustomField(
			tag.baseSettings.type,
			fieldData,
			this.event.language,
			this.eventUser.updatedSettings.language
		);
	}

	/**
	 * Go to session
	 * @param session
	 */
	openSession(session: ISchedule) {
		this.navCtrl.navigateForward(`/event/${this.eventId}/schedule/${session.moduleId}/session/${session.uid}`);
	}

	/**
	 * openDownloadModal
	 * @param session
	 */
	async openDownloadModal(session: ISchedule, event, _module?: IModule) {
		try {
			event.stopPropagation();
			const modal = await this.modalCtrl.create({
				component: ConfirmIcsDownloadComponent,
				componentProps: {
					event: this.event,
					module: this.module,
					eventUser: this.eventUser,
					session: session,
					sessions: this.sessions,
					language:
						this.eventUser && this.eventUser.updatedSettings.language
							? this.eventUser.updatedSettings.language
							: this.event.language,
					mode: "session"
				},
				mode: "ios",
				canDismiss: true,
				cssClass: this.isMobile ? "confirm-ics-download-modal-mobile" : "confirm-ics-download-modal"
			});

			modal.present();
		} catch (error) {
			// snackbar error
		}
	}

	async openDownloadScheduleModal() {
		try {
			event.stopPropagation();
			const modal = await this.modalCtrl.create({
				component: ConfirmIcsDownloadComponent,
				componentProps: {
					event: this.event,
					module: this.module,
					sessions: this.sessions,
					language:
						this.eventUser && this.eventUser.updatedSettings.language
							? this.eventUser.updatedSettings.language
							: this.event.language,
					mode: "schedule"
				},
				mode: "ios",
				canDismiss: true,
				cssClass: this.isMobile
					? "confirm-ics-download-modal-mobile-schedule"
					: "confirm-ics-download-modal-schedule"
			});

			modal.present();
		} catch (error) {
			// snackbar error
		}
	}
}
