import * as _ from "lodash-es";
import { ISearchField, ISearchFieldBloc, ISearchFilter } from "../interfaces/search.interfaces";
import {
	ICustomFieldData,
	IDocument,
	IEventUser,
	IFullCustomField,
	IGroup,
	ILocation,
	IModule,
	ISchedule,
	ISheet,
	ITrack
} from "../interfaces";
import { TypeCustomFields } from "../enums/type-custom-fields";
import { TypeModule } from "../enums/type-module";
// eslint-disable-next-line @typescript-eslint/no-var-requires
import he from "he";

export class SearchFilter implements ISearchFilter {
	equalityFields: ISearchField[] = [];
	inequalityFields: ISearchField[] = [];
	includeTextFields: ISearchField[] = [];
	includeOrTextFields: ISearchField[] = [];
	superiorFields: ISearchField[] = [];
	superiorOrEqualFields: ISearchField[] = [];
	inferiorFields: ISearchField[] = [];
	inferiorOrEqualFields: ISearchField[] = [];
	anyTextFields: ISearchField[] = [];
	arrayContainsAnyFields: ISearchField[] = [];
	arrayContainsAllFields: ISearchField[] = [];
	arrayContainsBlocAndOrFields: ISearchFieldBloc[] = [];
	arrayNotContainsFields: ISearchField[] = [];
	elemMatchArrayFields: ISearchField[] = [];
	specificMongodbQuery: any[] = [];
	lookup: { where: "beforeMatch" | "afterMatch"; stage: any }[] = [];
	addFields: any[] = [];
	page: number = 0;
	itemsPerPage: number = 20;
	sortBy: {
		fieldKey: string;
		type: string | number;
	}[] = [];

	getJsonDatas() {
		const obj: any = {};
		Reflect.ownKeys(this).forEach((name) => {
			obj[name] = this[name];
		});
		return obj as ISearchFilter;
	}
}

/**
 * * Fonction pour filtrer des données (datas param) basé sur une sélection de filtres (filters param)
 * @param filters
 * @param datas
 * @returns
 */
export const filterSearch = (filters: ISearchFilter, datas: any[]) => {
	let datasFiltered: any[] = datas;

	if (!filters) {
		return {
			datas: datas,
			totalItems: datas.length,
			focusedItemId: ""
		};
	}
	datasFiltered = datas.filter((data) => {
		if (filters.equalityFields && filters.equalityFields.length > 0) {
			let check: boolean = true;
			filters.equalityFields.forEach((fieldFilter) => {
				if (Array.isArray(fieldFilter.compareData)) {
					if (
						fieldFilter.compareData.length > 0 &&
						fieldFilter.compareData.includes(_.get(data, fieldFilter.fieldKey))
					) {
						check = false;
					}
				} else {
					if (_.get(data, fieldFilter.fieldKey) != fieldFilter.compareData) {
						check = false;
					}
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.inequalityFields && filters.inequalityFields.length > 0) {
			let check: boolean = true;
			filters.inequalityFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) == fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.includeTextFields && filters.includeTextFields.length > 0) {
			let check: boolean = true;
			filters.includeTextFields.forEach((fieldFilter) => {
				if (
					!_.get(data, fieldFilter.fieldKey)
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.toLocaleUpperCase()
						.includes(
							fieldFilter.compareData
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.toLocaleUpperCase()
						)
				) {
					check = false;
				}
			});

			if (!check) {
				return false;
			}
		}

		if (filters.includeOrTextFields && filters.includeOrTextFields.length > 0) {
			let check: boolean = true;
			check = filters.includeOrTextFields.some((fieldFilter) => {
				if (fieldFilter.fieldKey === "groups") {
					return _.get(data, fieldFilter.fieldKey).some((data) => fieldFilter.compareData.includes(data));
				} else if (
					fieldFilter.fieldKey.includes("customFields") &&
					fieldFilter.fieldKey.includes("module.items")
				) {
					const separatedFields = fieldFilter.fieldKey.split("|");
					if (data["customFields"] && separatedFields[1] && separatedFields[2]) {
						return data["customFields"].some((cus: ICustomFieldData) => {
							return (
								cus &&
								cus.uid === separatedFields[2] &&
								_.get(cus.field as any, separatedFields[1]).some((data) =>
									fieldFilter.compareData.includes(data)
								)
							);
						});
					} else {
						return false;
					}
				} else if (fieldFilter.fieldKey.includes("customFields")) {
					const separatedFields = fieldFilter.fieldKey.split("|");
					if (data["customFields"] && separatedFields[1] && separatedFields[2]) {
						return data["customFields"].some((cus: ICustomFieldData) => {
							if (separatedFields[3]) {
								if (separatedFields[1] === "multiLanguageSelectArray") {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										cus.field.multiLanguageSelectArray
											.map((opt) =>
												opt[separatedFields[3]]
													.toString()
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.toLocaleUpperCase()
											)
											.some((dataCheck) =>
												dataCheck
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.toLocaleUpperCase()
													.includes(
														fieldFilter.compareData
															.normalize("NFD")
															.replace(/[\u0300-\u036f]/g, "")
															.toLocaleUpperCase()
													)
											)
									);
								} else if (separatedFields[1] === "multiLanguageTextArray") {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										cus.field.multiLanguageTextArray[separatedFields[3]] &&
										cus.field.multiLanguageTextArray[separatedFields[3]].length > 0 &&
										cus.field.multiLanguageTextArray[separatedFields[3]].some((dataCheck) => {
											return dataCheck
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase()
												.includes(
													fieldFilter.compareData
														.normalize("NFD")
														.replace(/[\u0300-\u036f]/g, "")
														.toLocaleUpperCase()
												);
										})
									);
								} else {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										(cus.field as any)[separatedFields[1]] &&
										(cus.field as any)[separatedFields[1]][separatedFields[3]] &&
										(cus.field as any)[separatedFields[1]][separatedFields[3]]
											.normalize("NFD")
											.replace(/[\u0300-\u036f]/g, "")
											.toLocaleUpperCase()
											.includes(
												fieldFilter.compareData
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.toLocaleUpperCase()
											)
									);
								}
							} else {
								return (
									cus &&
									cus.uid === separatedFields[2] &&
									(cus.field as any)[separatedFields[1]] &&
									(cus.field as any)[separatedFields[1]]
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
										.toLocaleUpperCase()
										.includes(
											fieldFilter.compareData
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase()
										)
								);
							}
						});
					} else {
						return false;
					}
				} else {
					if (fieldFilter.fieldKey.includes("cfs")) {
						const separatedFields = fieldFilter.fieldKey.split(".");
						const afterFirstPoint = fieldFilter.fieldKey
							.split(".")
							.slice(1, fieldFilter.fieldKey.split(".").length)
							.join(".");
						if (separatedFields[0] && data[separatedFields[0]]) {
							return data[separatedFields[0]].some((item: any) => {
								return (
									item &&
									_.get(item, afterFirstPoint) &&
									he
										.decode(_.get(item, afterFirstPoint))
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
										.toLocaleUpperCase()
										.includes(
											he
												.decode(fieldFilter.compareData)
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase()
										)
								);
							});
						} else {
							return false;
						}
					} else {
						if (!_.get(data, fieldFilter.fieldKey)) {
							return false;
						} else {
							return he
								.decode(_.get(data, fieldFilter.fieldKey))
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.toLocaleUpperCase()
								.includes(
									he
										.decode(fieldFilter.compareData)
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
										.toLocaleUpperCase()
								);
						}
					}
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.isIncludedInFields && filters.isIncludedInFields.length > 0) {
			let check: boolean = true;
			filters.isIncludedInFields.forEach((fieldFilter) => {
				if (!fieldFilter.compareData.includes(_.get(data, fieldFilter.fieldKey))) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.anyTextFields && filters.anyTextFields.length > 0) {
			let check: boolean = true;
			if (!filters.anyTextFields.some((filter) => _.get(data, filter.fieldKey) == filter.compareData)) {
				check = false;
			}
			if (!check) {
				return false;
			}
		}

		if (filters.inferiorFields && filters.inferiorFields.length > 0) {
			let check: boolean = true;
			filters.inferiorFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) && _.get(data, fieldFilter.fieldKey) >= fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.inferiorOrEqualFields && filters.inferiorOrEqualFields.length > 0) {
			let check: boolean = true;

			filters.inferiorOrEqualFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) && _.get(data, fieldFilter.fieldKey) > fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.superiorFields && filters.superiorFields.length > 0) {
			let check: boolean = true;

			filters.superiorFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) && _.get(data, fieldFilter.fieldKey) <= fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.superiorOrEqualFields && filters.superiorOrEqualFields.length > 0) {
			let check: boolean = true;

			filters.superiorOrEqualFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) && _.get(data, fieldFilter.fieldKey) < fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.arrayContainsBlocAndOrFields && filters.arrayContainsBlocAndOrFields.length > 0) {
			let check: boolean = true;
			filters.arrayContainsBlocAndOrFields.forEach((fieldFilter) => {
				if (!fieldFilter.fieldKey.includes("customFields")) {
					if (
						!fieldFilter.compareData.some((comparedData) =>
							_.get(data, fieldFilter.fieldKey).includes(comparedData)
						)
					) {
						check = false;
					}
				} else {
					const separatedFields = fieldFilter.fieldKey.split("|");
					if (data["customFields"] && separatedFields[1] && separatedFields[2]) {
						check = data["customFields"].some((cus: ICustomFieldData) => {
							if (separatedFields[3]) {
								if (separatedFields[1] === "multiLanguageSelectArray") {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										fieldFilter.compareData.some(
											(comparedData) =>
												cus &&
												cus.uid &&
												cus.field.multiLanguageSelectArray
													.map((opt) =>
														opt[separatedFields[3]]
															.toString()
															.normalize("NFD")
															.replace(/[\u0300-\u036f]/g, "")
															.toLocaleUpperCase()
													)
													.includes(
														comparedData
															.normalize("NFD")
															.replace(/[\u0300-\u036f]/g, "")
															.toLocaleUpperCase()
													)
										)
									);
								} else {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										fieldFilter.compareData.some(
											(comparedData) =>
												cus &&
												cus.uid &&
												(cus.field as any)[separatedFields[1]] &&
												(cus.field as any)[separatedFields[1]][separatedFields[3]] &&
												(cus.field as any)[separatedFields[1]][separatedFields[3]]
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.toLocaleUpperCase() ===
													comparedData
														.normalize("NFD")
														.replace(/[\u0300-\u036f]/g, "")
														.toLocaleUpperCase()
										)
									);
								}
							} else {
								return (
									cus &&
									cus.uid === separatedFields[2] &&
									fieldFilter.compareData.some(
										(comparedData) =>
											(cus.field as any)[separatedFields[1]]
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase() ===
											comparedData
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase()
									)
								);
							}
						});
					} else {
						check = false;
					}
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.arrayContainsAllFields && filters.arrayContainsAllFields.length > 0) {
			let check: boolean = true;
			if (
				!filters.arrayContainsAllFields.every((fieldFilter) => {
					if (!fieldFilter.fieldKey.includes("customFields")) {
						return _.get(data, fieldFilter.fieldKey).includes(fieldFilter.compareData);
					} else {
						const separatedFields = fieldFilter.fieldKey.split("|");
						if (data["customFields"] && separatedFields[1] && separatedFields[2] && data["customFields"]) {
							return data["customFields"].some((cus: ICustomFieldData) => {
								if (separatedFields[3]) {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										(cus.field as any)[separatedFields[1]][separatedFields[3]] ===
											fieldFilter.compareData
									);
								} else {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										(cus.field as any)[separatedFields[1]] === fieldFilter.compareData
									);
								}
							});
						} else {
							return false;
						}
					}
				})
			) {
				check = false;
			}
			if (!check) {
				return false;
			}
		}

		if (filters.arrayContainsAnyFields && filters.arrayContainsAnyFields.length > 0) {
			let check: boolean = true;
			check = filters.arrayContainsAnyFields.some((fieldFilter) => {
				return _.isArray(fieldFilter.compareData)
					? fieldFilter.compareData.some((comparedData) => {
							return (
								_.get(data, fieldFilter.fieldKey) &&
								_.get(data, fieldFilter.fieldKey).length > 0 &&
								_.get(data, fieldFilter.fieldKey).includes(comparedData)
							);
						})
					: _.get(data, fieldFilter.fieldKey)
							.map((data) =>
								data
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.toLocaleUpperCase()
							)
							.includes(
								fieldFilter.compareData
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.toLocaleUpperCase()
							);
			});

			if (!check) {
				return false;
			}
		}
		if (filters.arrayIsEmpty && filters.arrayIsEmpty.length > 0) {
			let check: boolean = true;
			check = filters.arrayIsEmpty.some((fieldFilter) => {
				return _.get(data, fieldFilter.fieldKey) && _.get(data, fieldFilter.fieldKey).length > 0;
			});

			if (!check) {
				return false;
			}
		}

		return true;
	});

	// Sorting
	if (filters.sortBy && filters.sortBy.length > 0) {
		datasFiltered = datasFiltered.sort((a, b) => {
			const aKeyValue = _.get(a, filters.sortBy[0].fieldKey);
			const bKeyValue = _.get(b, filters.sortBy[0].fieldKey);
			if (filters.sortBy[0].type === "asc") {
				return aKeyValue > bKeyValue ? 1 : aKeyValue < bKeyValue ? -1 : 0;
			} else {
				return aKeyValue < bKeyValue ? 1 : aKeyValue > bKeyValue ? -1 : 0;
			}
		});
	} else {
		datasFiltered = datasFiltered.sort((a, b) => {
			const aKeyValue = _.get(a, "creationDate");
			const bKeyValue = _.get(b, "creationDate");
			return aKeyValue > bKeyValue ? 1 : aKeyValue < bKeyValue ? -1 : 0;
		});
	}
	const totalItems: number = datasFiltered.length;

	const returnedObject: { datas: any[]; totalItems: number; focusedItemId: "" } = {
		datas: [],
		totalItems: 0,
		focusedItemId: ""
	};

	if (filters.page >= 0 && filters.itemsPerPage > 0 && datasFiltered.length > 0) {
		const chunkedDatas = _.chunk(datasFiltered, filters.itemsPerPage);
		returnedObject.datas = chunkedDatas[filters.page];
	}

	returnedObject.totalItems = totalItems;

	return returnedObject;
};

/**
 * *
 * @param modules Fonction pour construire le filtre basé sur différents paramètres
 * @param filtersQuery
 * @param searchValue
 * @param currentLanguage
 * @param computedCustomFields
 * @param attendees
 * @param speakers
 * @param sessions
 * @param documents
 * @param groups
 * @param locations
 * @param tracks
 * @returns
 */
export const buildFiltersQuery = (
	modules: IModule[],
	datasGetType: "firestore" | "mongo",
	filtersQuery: ISearchFilter,
	searchValue: string,
	currentLanguage: string,
	computedCustomFields: IFullCustomField[],
	attendees: IEventUser[],
	speakers: IEventUser[],
	sheets: ISheet[],
	sessions: ISchedule[],
	documents: IDocument[],
	groups: IGroup[],
	locations: ILocation[],
	tracks: ITrack[]
) => {
	if (searchValue) {
		searchValue = searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		for (let i = 0; i < modules.length; i++) {
			const module = modules[i];
			if (module && module.options && module.options.searchFields) {
				for (let iS = 0; iS < module.options.searchFields.length; iS++) {
					const searchField = module.options.searchFields[iS];
					// If search activated on field
					if (searchField.searchActivated) {
						// If search field is a custom field
						if (
							searchField.isCustomField &&
							getCustomFieldType(computedCustomFields, searchField.customFieldUid) !==
								TypeCustomFields.MODULE
						) {
							const fieldKey =
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.TEXT ||
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.SELECT
									? "multiLanguageText"
									: getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
												TypeCustomFields.URL ||
										  getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
												TypeCustomFields.EMAIL
										? "text"
										: getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
											  TypeCustomFields.NUMERIC
											? "numeric"
											: getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
												  TypeCustomFields.MULTI_SELECT
												? "multiLanguageSelectArray"
												: getCustomFieldType(
															computedCustomFields,
															searchField.customFieldUid
													  ) === TypeCustomFields.MULTI_TEXT
													? "multiLanguageTextArray"
													: false;

							const isMultilanguage: boolean =
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.TEXT ||
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.SELECT ||
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.MULTI_SELECT ||
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.MULTI_TEXT
									? true
									: false;

							if (
								!filtersQuery.includeOrTextFields.find(
									(filter) =>
										filter.fieldKey ===
										"customFields|" +
											fieldKey +
											"|" +
											searchField.customFieldUid +
											(isMultilanguage ? "|" + currentLanguage : "")
								)
							) {
								filtersQuery.includeOrTextFields.push({
									fieldKey:
										"customFields|" +
										fieldKey +
										"|" +
										searchField.customFieldUid +
										(isMultilanguage ? "|" + currentLanguage : ""),
									compareData: searchValue
								});
							}
						} else if (
							searchField.isCustomField &&
							getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
								TypeCustomFields.MODULE
						) {
							const correspondingCustomField = computedCustomFields.find(
								(custom) =>
									custom &&
									custom.baseSettings &&
									custom.baseSettings.uid === searchField.customFieldUid
							);
							if (correspondingCustomField) {
								if (
									datasGetType === "firestore" ||
									(datasGetType === "mongo" &&
										[
											TypeModule.DOCUMENT,
											TypeModule.SCHEDULE,
											TypeModule.TRACKS,
											TypeModule.LOCATION
										].includes(correspondingCustomField.moduleSettings.customFieldModuleType))
								) {
									const basedDatas: any[] =
										correspondingCustomField.moduleSettings.customFieldModuleType ===
										TypeModule.ATTENDEE
											? attendees
											: correspondingCustomField.moduleSettings.customFieldModuleType ===
												  TypeModule.SPEAKER
												? speakers
												: correspondingCustomField.moduleSettings.customFieldModuleType ===
													  TypeModule.SHEETS
													? sheets
													: correspondingCustomField.moduleSettings.customFieldModuleType ===
														  TypeModule.DOCUMENT
														? documents
														: correspondingCustomField.moduleSettings
																	.customFieldModuleType === TypeModule.SCHEDULE
															? sessions
															: correspondingCustomField.moduleSettings
																		.customFieldModuleType === TypeModule.TRACKS
																? tracks
																: correspondingCustomField.moduleSettings
																			.customFieldModuleType ===
																	  TypeModule.LOCATION
																	? locations
																	: [];
									const fieldKey =
										"customFields|" + "module.items" + "|" + searchField.customFieldUid;
									if (
										correspondingCustomField.moduleSettings.customFieldModuleType ===
											TypeModule.ATTENDEE ||
										correspondingCustomField.moduleSettings.customFieldModuleType ===
											TypeModule.SPEAKER ||
										correspondingCustomField.moduleSettings.customFieldModuleType ===
											TypeModule.SHEETS
									) {
										const datasFound = basedDatas.filter((data) =>
											data.name
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase()
												.includes(searchValue.toLocaleUpperCase())
										);
										if (datasFound.length > 0) {
											const bloc = filtersQuery.includeOrTextFields.find(
												(bloc) => bloc.fieldKey === fieldKey
											);
											if (!bloc) {
												if (
													!filtersQuery.includeOrTextFields.find(
														(filter) => filter.fieldKey === fieldKey
													)
												) {
													filtersQuery.includeOrTextFields.push({
														fieldKey: fieldKey,
														compareData: datasFound.map((data) => data.uid)
													});
												}
											} else {
												bloc.compareData.concat(datasFound.map((data) => data.uid));
											}
										}
									} else {
										const datasFound = basedDatas.filter((data) =>
											data.name[currentLanguage]
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase()
												.includes(searchValue.toLocaleUpperCase())
										);
										if (datasFound.length > 0) {
											const bloc = filtersQuery.includeOrTextFields.find(
												(bloc) => bloc.fieldKey === fieldKey + "|" + currentLanguage
											);
											if (!bloc) {
												if (
													!filtersQuery.includeOrTextFields.find(
														(filter) => filter.fieldKey === fieldKey + "|" + currentLanguage
													)
												) {
													filtersQuery.includeOrTextFields.push({
														fieldKey: fieldKey + "|" + currentLanguage,
														compareData: datasFound.map((data) => data.uid)
													});
												}
											} else {
												bloc.compareData.concat(datasFound.map((data) => data.uid));
											}
										}
									}
								} else {
									const fieldKey: string =
										correspondingCustomField.moduleSettings.customFieldModuleType ===
										TypeModule.ATTENDEE
											? "cfsEventUsersDatas"
											: correspondingCustomField.moduleSettings.customFieldModuleType ===
												  TypeModule.SHEETS
												? "cfsSheetsDatas"
												: "";
									const bloc = filtersQuery.includeOrTextFields.find(
										(bloc) => bloc.fieldKey === `${fieldKey}.name`
									);
									if (!bloc) {
										if (
											!filtersQuery.includeOrTextFields.find(
												(filter) => filter.fieldKey === `${fieldKey}.name`
											)
										) {
											filtersQuery.includeOrTextFields.push({
												fieldKey: `${fieldKey}.name`,
												compareData: searchValue
											});
										}
									} else {
										bloc.compareData.concat(searchValue);
									}
								}
							}
						} else {
							// If search field is groups
							if (searchField.fieldType === "groups") {
								const groupsFound = groups.filter((group) =>
									group.name
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
										.toLocaleUpperCase()
										.includes(searchValue.toLocaleUpperCase())
								);
								if (groupsFound.length > 0) {
									const bloc = filtersQuery.includeOrTextFields.find(
										(bloc) => bloc.fieldKey === "groups"
									);
									if (!bloc) {
										if (
											!filtersQuery.includeOrTextFields.find(
												(filter) => filter.fieldKey === "groups"
											)
										) {
											filtersQuery.includeOrTextFields.push({
												fieldKey: "groups",
												compareData: groupsFound.map((group) => group.uid)
											});
										}
									} else {
										bloc.compareData.concat(groupsFound.map((group) => group.uid));
									}
								}
							} else {
								// If search field is other type
								if (
									!filtersQuery.includeOrTextFields.find(
										(filter) =>
											filter.fieldKey ===
											`${searchField.key}${
												searchField.multiLanguage ? "." + currentLanguage : ""
											}`
									)
								) {
									filtersQuery.includeOrTextFields.push({
										fieldKey: `${searchField.key}${
											searchField.multiLanguage ? "." + currentLanguage : ""
										}`,
										compareData: searchValue
									});
								}
							}
						}
					}
				}
			} else {
				if (
					!filtersQuery.includeTextFields.find(
						(filter) =>
							filter.fieldKey ===
							(module.options &&
							module.options.usersOrder &&
							["asc", "desc", "recent", "oldest"].includes(module.options.usersOrder)
								? "name"
								: "identifier")
					)
				) {
					filtersQuery.includeTextFields.push({
						fieldKey:
							module.options &&
							module.options.usersOrder &&
							["asc", "desc", "recent", "oldest"].includes(module.options.usersOrder)
								? "name"
								: "identifier",
						compareData: searchValue
					});
				}
			}
		}
	}
	return filtersQuery;
};

const getCustomFieldType = (computedCustomFields: IFullCustomField[], uid: string) => {
	return computedCustomFields.find(
		(computedCustomField) =>
			computedCustomField && computedCustomField.baseSettings && computedCustomField.baseSettings.uid === uid
	)?.baseSettings.type;
};

/**
 * * Fonction pour créer des regex permettant de
 *  faire de la recherche case insensitive et avec ou sans accents sur mongodb
 * @param string
 * @returns
 */
export const diacriticSensitiveRegex = (string: string) => {
	let newString: string = "";
	string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	for (let i = 0; i < string.length; i++) {
		const copyString = _.cloneDeep(string);
		if (copyString[i] === "a" || copyString[i] === "A") {
			newString += copyString[i].replace(/[aA]/g, "[A,À,Á,Â,Ä,a,à,á,â,ä]");
		} else if (copyString[i] === "e" || copyString[i] === "E") {
			newString += copyString[i].replace(/[eE]/g, "[E,È,É,Ê,Ë,e,è,é,ê,ë]");
		} else if (copyString[i] === "i" || copyString[i] === "I") {
			newString += copyString[i].replace(/[iI]/g, "[I,Ì,Í,Î,Ï,i,ì,í,î,ï]");
		} else if (copyString[i] === "o" || copyString[i] === "O") {
			newString += copyString[i].replace(/[oO]/g, "[O,Ò,Ó,Ô,Ö,o,ò,ó,ô,ö]");
		} else if (copyString[i] === "u" || copyString[i] === "U") {
			newString += copyString[i].replace(/[uU]/g, "[U,Ù,Ú,Û,Ü,u,ù,ú,û,ü]");
		} else if (copyString[i] === "c" || copyString[i] === "C") {
			newString += copyString[i].replace(/[cC]/g, "[C,Ç,c,ç]");
		} else {
			newString += copyString[i];
		}
	}
	return newString;
};

/**
 * * Fonction permettant de transformer les filtres (filters param) en aggregation mongodb
 * @param filters
 * @param type
 * @param index
 * @returns
 */
export const transformFiltersToQuery = (
	filters: ISearchFilter,
	type: "aggregation" | "normal",
	_index: string
): any => {
	const query: any = {
		$sort: {}
	};
	let aggregation: any[] = [];
	const andQuery: any = {
		$and: []
	};
	const orQuery: any = { $or: [] };

	const lookupBefore =
		filters && filters.lookup && filters.lookup.length > 0
			? filters.lookup.filter((stage) => stage.where === "beforeMatch")
			: [];

	if (lookupBefore && type === "aggregation") {
		aggregation = aggregation.concat(
			lookupBefore.map((stage) => {
				return stage.stage;
			})
		);
	}

	if (!filters) {
		return query;
	}
	if (filters.equalityFields && filters.equalityFields.length > 0) {
		filters.equalityFields.forEach((fieldFilter) => {
			if (Array.isArray(fieldFilter.compareData)) {
				andQuery["$and"].push({
					[fieldFilter.fieldKey]: {
						$in: fieldFilter.compareData
					}
				});
			} else {
				andQuery["$and"].push({
					[fieldFilter.fieldKey]: {
						$eq: fieldFilter.compareData
					}
				});
			}
		});
	}

	if (filters.inequalityFields && filters.inequalityFields.length > 0) {
		filters.inequalityFields.forEach((fieldFilter) => {
			andQuery["$and"].push({
				[fieldFilter.fieldKey]: {
					$ne: fieldFilter.compareData
				}
			});
		});
	}

	if (filters.includeTextFields && filters.includeTextFields.length > 0) {
		filters.includeTextFields.forEach((fieldFilter) => {
			andQuery["$and"].push({
				[fieldFilter.fieldKey]: {
					$regex: `.*${fieldFilter.compareData.toLocaleUpperCase()}.*`
				}
			});
		});
	}

	if (filters.includeOrTextFields && filters.includeOrTextFields.length > 0) {
		filters.includeOrTextFields.forEach((fieldFilter) => {
			if (fieldFilter.fieldKey.includes("customFields") && fieldFilter.fieldKey.includes("module.items")) {
				const separatedFields = fieldFilter.fieldKey.split("|");
				if (separatedFields[1] && separatedFields[2]) {
					orQuery["$or"].push({
						["customFields.field.module.items"]: {
							$elemMatch: {
								$eq: Array.isArray(fieldFilter.compareData)
									? fieldFilter.compareData[0]
									: fieldFilter.compareData
							}
						}
					});
				}
			} else if (!fieldFilter.fieldKey.includes("customFields")) {
				if (fieldFilter.fieldKey.includes("groups")) {
					orQuery["$or"].push({
						[fieldFilter.fieldKey]: {
							$elemMatch: {
								$eq: Array.isArray(fieldFilter.compareData)
									? fieldFilter.compareData[0]
									: fieldFilter.compareData
							}
						}
					});
				} else {
					orQuery["$or"].push({
						[fieldFilter.fieldKey]: {
							$regex: `.*${diacriticSensitiveRegex(
								Array.isArray(fieldFilter.compareData)
									? fieldFilter.compareData[0]
									: fieldFilter.compareData
							)}.*`,
							$options: "i"
						}
					});
				}
			} else {
				const separatedFields = fieldFilter.fieldKey.split("|");
				if (separatedFields[1] && separatedFields[2] && separatedFields[3]) {
					orQuery["$or"].push({
						customFields: {
							$elemMatch: {
								[`field.${separatedFields[1]}.${separatedFields[3]}`]: {
									$regex: `.*${diacriticSensitiveRegex(
										Array.isArray(fieldFilter.compareData)
											? fieldFilter.compareData[0]
											: fieldFilter.compareData
									)}.*`,
									$options: "i"
								},
								[`uid`]: {
									$eq: separatedFields[2]
								}
							}
						}
					});
				} else if (separatedFields[1] && separatedFields[2]) {
					orQuery["$or"].push({
						customFields: {
							$elemMatch: {
								[`field.${separatedFields[1]}`]: {
									$regex: `.*${diacriticSensitiveRegex(
										typeof fieldFilter.compareData === "string"
											? fieldFilter.compareData
											: fieldFilter.compareData[0]
									)}.*`,
									$options: "i"
								},
								[`uid`]: {
									$eq: separatedFields[2]
								}
							}
						}
					});
				}
			}
		});
	}

	// Any equality text field
	if (filters.anyTextFields && filters.anyTextFields.length > 0) {
		filters.anyTextFields.forEach((fieldFilter) => {
			andQuery["$and"].push({
				[fieldFilter.fieldKey]: {
					$in: [fieldFilter.compareData]
				}
			});
		});
	}

	if (filters.inferiorFields && filters.inferiorFields.length > 0) {
		filters.inferiorFields.forEach((fieldFilter) => {
			andQuery["$and"].push({
				[fieldFilter.fieldKey]: {
					$lt: fieldFilter.compareData
				}
			});
		});
	}

	if (filters.inferiorOrEqualFields && filters.inferiorOrEqualFields.length > 0) {
		filters.inferiorOrEqualFields.forEach((fieldFilter) => {
			andQuery["$and"].push({
				$or: [
					{
						[fieldFilter.fieldKey]: {
							$lte: fieldFilter.compareData
						}
					},
					{
						[fieldFilter.fieldKey]: {
							$eq: null
						}
					},
					{
						[fieldFilter.fieldKey]: {
							$eq: ""
						}
					}
				]
			});
		});
	}

	if (filters.superiorFields && filters.superiorFields.length > 0) {
		filters.superiorFields.forEach((fieldFilter) => {
			andQuery["$and"].push({
				[fieldFilter.fieldKey]: {
					$gt: fieldFilter.compareData
				}
			});
		});
	}

	if (filters.superiorOrEqualFields && filters.superiorOrEqualFields.length > 0) {
		filters.superiorOrEqualFields.forEach((fieldFilter) => {
			andQuery["$and"].push({
				$or: [
					{
						[fieldFilter.fieldKey]: {
							$gte: fieldFilter.compareData
						}
					},
					{
						[fieldFilter.fieldKey]: {
							$eq: null
						}
					},
					{
						[fieldFilter.fieldKey]: {
							$eq: ""
						}
					}
				]
			});
		});
	}

	if (filters.elemMatchArrayFields && filters.elemMatchArrayFields.length > 0) {
		filters.elemMatchArrayFields.forEach((fieldFilter) => {
			andQuery["$and"].push({
				[fieldFilter.fieldKey]: {
					$exists: true,
					$all: [fieldFilter.compareData]
				}
			});
		});
	}

	if (filters.specificMongodbQuery && filters.specificMongodbQuery.length > 0) {
		filters.specificMongodbQuery.forEach((fieldFilter) => {
			andQuery["$and"].push(fieldFilter);
		});
	}

	if (filters.arrayContainsBlocAndOrFields && filters.arrayContainsBlocAndOrFields.length > 0) {
		filters.arrayContainsBlocAndOrFields.forEach((fieldFilter) => {
			if (!fieldFilter.fieldKey.includes("customFields")) {
				andQuery["$and"].push({
					[fieldFilter.fieldKey]: {
						$elemMatch: { $in: fieldFilter.compareData }
					}
				});
			} else {
				const separatedFields = fieldFilter.fieldKey.split("|");
				if (separatedFields[1] && separatedFields[2] && separatedFields[3]) {
					andQuery["$and"].push({
						[separatedFields[0]]: {
							$elemMatch: {
								[`field.${separatedFields[1]}.${separatedFields[3]}`]: {
									$in: fieldFilter.compareData
								},
								uid: { $eq: separatedFields[2] }
							}
						}
					});
				} else if (separatedFields[1] && separatedFields[2]) {
					andQuery["$and"].push({
						[separatedFields[0]]: {
							$elemMatch: {
								[`field.${separatedFields[1]}`]: {
									$in: fieldFilter.compareData
								},
								uid: { $eq: separatedFields[2] }
							}
						}
					});
				}
			}
		});
	}

	if (filters.arrayContainsAllFields && filters.arrayContainsAllFields.length > 0) {
		filters.arrayContainsAllFields.forEach((fieldFilter) => {
			andQuery["$and"].push({
				[fieldFilter.fieldKey]: {
					$all: [fieldFilter.compareData]
				}
			});
		});
	}

	if (filters.arrayContainsAnyFields && filters.arrayContainsAnyFields.length > 0) {
		filters.arrayContainsAnyFields.forEach((fieldFilter) => {
			if (Array.isArray(fieldFilter.compareData)) {
				andQuery["$and"].push({
					[fieldFilter.fieldKey]: {
						$in: fieldFilter.compareData
					}
				});
			} else {
				andQuery["$and"].push({
					[fieldFilter.fieldKey]: {
						$eq: fieldFilter.compareData
					}
				});
			}
		});
	}

	if (filters.arrayNotContainsFields && filters.arrayNotContainsFields.length > 0) {
		filters.arrayNotContainsFields.forEach((fieldFilter) => {
			andQuery["$and"].push({
				[fieldFilter.fieldKey]: {
					$nin: [fieldFilter.compareData]
				}
			});
		});
	}

	if (filters.arrayIsEmpty && filters.arrayIsEmpty.length > 0) {
		filters.arrayIsEmpty.forEach((fieldFilter) => {
			andQuery["$and"].push({
				[fieldFilter.fieldKey]: {
					$size: 0
				}
			});
		});
	}

	if (orQuery["$or"] && orQuery["$or"].length > 1) {
		andQuery["$and"].push(orQuery);
	} else if (orQuery["$or"] && orQuery["$or"].length === 1) {
		andQuery["$and"] = andQuery["$and"].concat(orQuery["$or"][0]);
	}

	if (andQuery["$and"] && andQuery["$and"].length > 0) {
		if (type === "aggregation") {
			aggregation.push({ $match: andQuery });
		} else {
			query["$and"] = andQuery["$and"];
		}
	}

	const lookupAfter =
		filters && filters.lookup && filters.lookup.length > 0
			? filters.lookup.filter((stage) => stage.where === "afterMatch")
			: [];

	if (lookupAfter && type === "aggregation") {
		aggregation = aggregation.concat(
			lookupAfter.map((stage) => {
				return stage.stage;
			})
		);
	}

	// Add fields
	if (type === "aggregation" && filters.addFields && filters.addFields.length > 0) {
		aggregation = aggregation.concat(filters.addFields);
	}

	// Sorting
	if (filters && filters.sortBy && filters.sortBy.length > 0) {
		if (type === "aggregation") {
			const sortAggregation = {
				$sort: {}
			};
			filters.sortBy.forEach((fieldFilter) => {
				sortAggregation["$sort"][fieldFilter.fieldKey] = fieldFilter.type === "asc" ? 1 : -1;
			});
			aggregation.push(sortAggregation);
		} else {
			filters.sortBy.forEach((fieldFilter) => {
				query["$sort"][fieldFilter.fieldKey] = fieldFilter.type === "asc" ? 1 : -1;
			});
		}
	} else {
		if (type === "aggregation") {
			const sortAggregation = {
				$sort: {}
			};
			sortAggregation["$sort"]["creationDate"] = 1;
			aggregation.push(sortAggregation);
		} else {
			query["$sort"]["creationDate"] = 1;
		}
	}

	if (type === "aggregation") {
		aggregation.push({
			$facet: {
				docs: [{ $skip: filters.page * filters.itemsPerPage }, { $limit: filters.itemsPerPage }],
				meta: [{ $count: "total" }]
			}
		});
	} else {
		if (filters && filters.itemsPerPage) {
			query["$limit"] = filters.itemsPerPage;
		}

		if (filters && filters.page > 0 && filters.itemsPerPage > 0) {
			query["$skip"] = (filters.page - 1) * filters.itemsPerPage;
		}
	}

	return type === "aggregation" ? aggregation : query;
};
