import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { orderBy, QueryConstraint, where } from "@angular/fire/firestore";
import { AlertController, NavController, Platform, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import OneSignal from "onesignal-cordova-plugin";
import { forkJoin, from, Observable, of, Subscription } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { FirestoreService } from ".";
import { GetAllNotifications } from "../actions/generics-modules-data.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IEvent, IEventUser, IModule, INotification, INotificationLanguages } from "../interfaces";
import { IDevicePublicEventNotif } from "../interfaces/notifications.interfaces";
import { getMyEventUser } from "../selectors/auth.selectors";
import { getCurrentEvent } from "../selectors/events.selectors";
import { checkSameEvent, getNotifications } from "../selectors/generics-modules-data.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { Router } from "@angular/router";
@Injectable({
	providedIn: "root"
})
export class NotificationsService {
	navCtrl = inject(NavController);
	router = inject(Router);
	notificationsSub: Subscription;
	subscriptions: Subscription[] = [];

	event: IEvent;
	eventUser: IEventUser;
	notifications: INotification[];

	serviceWorker: Worker = null;

	allNotificationsSub: Subscription;
	eventSub: Subscription;
	eventUserSub: Subscription;
	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		private http: HttpClient,
		private store: Store,
		private SFirestore: FirestoreService,
		public alertCtrl: AlertController,
		public toastController: ToastController,
		private STranslate: TranslateService
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) || this.platform.is("mobileweb") ? true : false;
		if (this.allNotificationsSub && !this.allNotificationsSub.closed) {
			this.allNotificationsSub.unsubscribe();
		}
		if (this.eventSub && !this.eventSub.closed) {
			this.eventSub.unsubscribe();
		}
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}
		this.eventUserSub = this.store.select(getCurrentEvent).subscribe((event) => {
			this.event = event;
		});
		this.eventUserSub = this.store.select(getMyEventUser).subscribe((user) => {
			this.eventUser = user;
		});
		this.allNotificationsSub = this.store.select(getNotifications()).subscribe((res) => {
			this.notifications = res;
		});
	}

	unsubscribeAll() {
		if (this.notificationsSub) {
			this.notificationsSub.unsubscribe();
		}
	}

	/**
	 * Init onesignal configuration for mobile push
	 * @param appId
	 */
	initOneSignal(appId: string) {
		// Remove this method to stop OneSignal Debugging
		if (!environment.production) OneSignal.Debug.setLogLevel(6);

		// Replace YOUR_ONESIGNAL_APP_ID with your OneSignal App ID
		OneSignal.initialize(appId);

		OneSignal.Notifications.addEventListener("click", async (openedEvent) => {
			console.log("***** openedEvent *****", JSON.stringify(openedEvent));
			if (
				openedEvent &&
				openedEvent["notification"] &&
				openedEvent["notification"]["additionalData"] &&
				openedEvent["notification"]["additionalData"]["redirectUrl"] &&
				openedEvent["notification"]["additionalData"]["eventId"]
			) {
				if (openedEvent["notification"]["additionalData"]["redirectUrl"].includes("notifications")) {
					const eventDoc = await this.SFirestore.getDocument(
						`events/${openedEvent["notification"]["additionalData"]["eventId"]}`
					);
					if (eventDoc.exists) {
						const event = eventDoc.data() as IEvent;
						this.event = event;
						console.log(
							"***** openNotifications *****: ",
							openedEvent["notification"]["additionalData"]["redirectUrl"],
							event
						);
						this.openNotifications();
					}
				} else {
					console.log(
						"***** navigateForward *****: ",
						openedEvent["notification"]["additionalData"]["redirectUrl"]
					);
					this.navCtrl.navigateForward(openedEvent["notification"]["additionalData"]["redirectUrl"]);
				}
			}
		});

		OneSignal.Notifications.requestPermission(true).then((success: boolean) => {
			console.log("Notification permission granted " + success);
		});
	}

	logOutOneSignal() {
		OneSignal.logout();
	}

	/**
	 * Get all notifications of an event
	 * @param eventId
	 */
	getNotificationsForEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "notifications", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.notificationsSub && !this.notificationsSub.closed) {
					return;
				} else if (!sameEvent && this.notificationsSub && !this.notificationsSub.closed) {
					this.notificationsSub.unsubscribe();
				}

				this.notificationsSub = this.SFirestore.collectionGroupValueChangesDocuments("notifications", [
					where("eventId", "==", eventId),
					orderBy("deliveryDate", "desc")
				]).subscribe((notifications: INotification[]) => {
					this.store.dispatch(GetAllNotifications({ payload: notifications, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initNotifications"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initNotifications", payload: true })
								);
							}
						});
				});
			});
	}

	/**
	 * Display notification toast(pop-up)
	 * @param title
	 * @param msg
	 * @param id
	 * @param type
	 * @returns
	 */
	async presentNotifToast(title: string, msg: string, link: string, id?: string) {
		const text = msg.trim().length > 195 ? msg.substring(0, 192) + "..." : msg;

		const toast = await this.toastController.create({
			header: title,
			message: text,
			position: "top",
			buttons: [
				{
					side: "end",
					icon: "paper-plane",
					cssClass: "btn-notif-toast",
					handler: () => {
						if (link) {
							if (link === "notifications") {
								this.openNotifications();
							} else {
								this.navCtrl.navigateForward(link);
							}
						}
						toast.remove();
					}
				} as any,
				{
					side: "end",
					icon: "close",
					cssClass: "btn-notif-toast"
				}
			]
		});
		toast.id = id;
		toast.style.top = "60px";
		toast.style.marginRight = "0";
		toast.style.maxWidth = this.isMobile ? "80vw" : "660px";
		toast.style.width = this.isMobile ? "80vw" : "40%";
		toast.style.maxHeight = "200px";
		toast.style.left = this.isMobile ? "calc(100vw - 80vw)" : "unset";
		toast.style.right = this.isMobile ? "unset" : "0";
		toast.style.setProperty("--background", "#343434");
		toast.style.setProperty("--color", "#fff");
		toast.style.setProperty("--button-color", "#fff"); // pour changer la couleur des boutons

		toast.present();
		return toast;
	}

	/**
	 * Init service worker
	 */
	initServiceWorker() {
		this.serviceWorker = new Worker("service-worker.js");
		this.serviceWorker.onmessage = (msg) => {
			if (msg.data.displayedNotifId) {
				const notif = this.notifications.find((n) => n.uid === msg.data.displayedNotifId);
				const lang = this.STranslate.currentLang.toLowerCase().substring(0, 2);

				notif &&
					this.event.settings.allowNotificationPopup &&
					this.presentNotifToast(notif.titles[lang], notif.contents[lang], "notifications", notif.uid).then(
						() => {
							const els = document.querySelectorAll("ion-toast");
							els.forEach((item) => {
								if (item.id !== notif.uid) {
									// if(!item.id.includes('_globalMsg')){
									item.style.top = "180px";
									setTimeout(() => item.remove(), 400);
									// }
								}
							});
						}
					);
			}
		};
	}

	/**
	 * Send data to service worker
	 * @param data
	 */
	postMessage(data: { notifs: INotification[] }) {
		if (this.serviceWorker) {
			this.serviceWorker.postMessage(data);
		}
	}

	/**
	 * Destroy service worker
	 */
	destroyServiceWorker() {
		// this.serviceWorker.onmessage = () => {
		// };
		this.serviceWorker = null;
	}

	/**
	 * Get Notifications
	 * @returns
	 */
	getNotifications() {
		return this.notifications;
	}

	/**
	 * Add device id to send push notif for public event
	 * @param eventId
	 * @param moduleId
	 * @param id
	 * @returns
	 */
	async addDeviceIdPublicEvent(eventId: string, moduleId: string, userId: string) {
		const uid = this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/public-event-devices`);
		const device: IDevicePublicEventNotif = {
			creationDate: DateTime.now().valueOf(),
			eventId: eventId,
			moduleId: moduleId,
			uid: uid,
			userId: userId
		};
		const devices = (
			await this.SFirestore.getDocuments(`events/${eventId}/modules/${moduleId}/public-event-devices`, [
				where("userId", "==", userId)
			])
		).docs.map((doc) => doc.data() as IDevicePublicEventNotif);

		if (!devices.find((d) => d.userId === userId)) {
			return this.SFirestore.setDocument(
				`events/${eventId}/modules/${moduleId}/public-event-devices/${uid}`,
				device
			);
		}
	}

	/**
	 * Get one signal public device ids
	 * @param eventId
	 * @returns
	 */
	getOneSignalPublicDeviceIds(eventId: string) {
		return from(
			this.SFirestore.getDocumentsCollectionGroup("public-event-devices", [where("eventId", "==", eventId)])
		).pipe(map((docs) => docs.docs.map((doc) => doc.data() as IDevicePublicEventNotif)));
	}

	/**
	 * Get one signal ids for users
	 * @param eventId
	 * @param usersIds
	 * @returns
	 */
	getOneSignalForUsers(eventId: string, usersIds: string[], chatId: string): Observable<string[]> {
		const chunkedIds = _.chunk(usersIds, 10);
		const obsEventUsers: Observable<any[]>[] = [];
		chunkedIds.forEach((chunk) => {
			obsEventUsers.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup("event-users", [
						where("eventId", "==", eventId),
						where("uid", "in", chunk)
					])
				).pipe(map((docs) => docs.docs.map((doc) => doc.data() as IEventUser)))
			);
		});
		return forkJoin(obsEventUsers).pipe(
			switchMap((results) => {
				return of(
					_.flatten(results)
						.filter(
							(user) =>
								user &&
								user.options &&
								user.options.notifOneSignalConfig &&
								user.options.notifOneSignalConfig.userId &&
								(!user.updatedSettings ||
									!user.updatedSettings.chatsNotifs ||
									user.updatedSettings.chatsNotifs[chatId] === undefined ||
									user.updatedSettings.chatsNotifs[chatId])
						)
						.map((user) => user.options.notifOneSignalConfig.userId)
				);
			})
		);
	}

	/**
	 * Get one signal ids for users with specific groups
	 * @param eventId
	 * @param usersIds
	 * @returns
	 */
	getOneSignalForUsersFromGroups(
		eventId: string,
		groupsIds: string[],
		itemId: string,
		optionName: string,
		currentUserId: string
	): Observable<string[]> {
		const chunkedIds = _.chunk(groupsIds, 10);
		const obsEventUsers: Observable<any[]>[] = [];
		chunkedIds.forEach((chunk) => {
			const queryConstraints: QueryConstraint[] = [
				where("eventId", "==", eventId),
				where("options.notifOneSignalConfig.userId", "!=", null)
			];

			queryConstraints.push(where("groups", "array-contains-any", chunk));

			obsEventUsers.push(
				from(this.SFirestore.getDocumentsCollectionGroup("event-users", queryConstraints)).pipe(
					map((docs) => docs.docs.map((doc) => doc.data() as IEventUser))
				)
			);
		});
		return forkJoin(obsEventUsers).pipe(
			switchMap((results) => {
				return of(
					_.flatten(results)
						.filter(
							(eventUser) =>
								eventUser.eventId === eventId &&
								eventUser.uid !== currentUserId &&
								(!eventUser.options ||
									!eventUser.options[optionName] ||
									eventUser.options[optionName][itemId] === undefined ||
									eventUser.options[optionName][itemId])
						)
						.map((user) => user.options.notifOneSignalConfig.userId)
				);
			})
		);
	}

	/**
	 * Get one signal ids for all users
	 * @param eventId
	 * @param usersIds
	 * @returns
	 */
	getOneSignalForAllUsers(
		eventId: string,
		itemId: string,
		optionName: string,
		currentUserId: string,
		queryConstraints: QueryConstraint[]
	): Observable<string[]> {
		// GET ALL EVENT USERS HERE
		return from(this.SFirestore.getDocumentsCollectionGroup("event-users", queryConstraints)).pipe(
			map((docs) => docs.docs.map((doc) => doc.data() as IEventUser)),
			switchMap((results) => {
				return of(
					_.flatten(results)
						.filter(
							(eventUser) =>
								eventUser.eventId === eventId &&
								eventUser.uid !== currentUserId &&
								(!eventUser.options ||
									!eventUser.options[optionName] ||
									eventUser.options[optionName][itemId] === undefined ||
									eventUser.options[optionName][itemId])
						)
						.map((user) => user.options.notifOneSignalConfig.userId)
				);
			})
		);
	}

	/**
	 * Send a notification
	 * @param eventId
	 * @param moduleId
	 * @param headings
	 * @param contents
	 * @param usersIds
	 * @returns
	 */
	sendNotification(
		event: IEvent,
		module: IModule,
		item: any,
		titles: INotificationLanguages,
		contents: INotificationLanguages,
		usersIds: string[],
		datas: any,
		sendAfter: string
	) {
		const headers = new HttpHeaders();
		headers.append("content-type", "application/json");
		headers.append("Authorization", "Basic" + environment.platform.oneSignalApiId);
		return this.http.post(
			environment.platform.apiV2BaseUrl
				? "https://notifications-sendPushNotification" + environment.platform.apiV2BaseUrl
				: environment.platform.apiBaseUrl + "notifications-sendPushNotification",
			{
				event: event,
				module: module,
				item: item,
				titles: titles,
				contents: contents,
				usersIds: usersIds,
				datas: datas,
				sendAfter: sendAfter
			},
			{
				headers: headers,
				observe: "body"
			}
		);
		// const postNotification: INotificationOneSignal = {
		// 	app_id: environment.platform.oneSignalAppId,
		// 	data: datas,
		// 	headings: headings,
		// 	contents: contents,
		// 	include_player_ids: usersIds,
		// 	send_after: "",
		// 	priority: 10,
		// 	url: "",
		// 	api_id: environment.platform.oneSignalApiId,
		// 	uid: "",
		// 	moduleId: moduleId,
		// 	eventId: eventId
		// };
		// return this.http.post("https://onesignal.com/api/v1/notifications", postNotification, {
		// 	headers: headers,
		// 	observe: "body"
		// });
	}

	/**
	 * Open notification modal
	 * @returns
	 */
	openNotifications() {
		this.navCtrl.navigateForward(`/notifications/${this.event.uid}/none/${DateTime.now().valueOf()}`);
		// const notifications = this.getNotifications();
		// const modal = await this.modalCtrl.create({
		// 	component: PathComponents.notifications,
		// 	componentProps: {
		// 		eventId: this.event.uid,
		// 		// currentUser: this.eventUser,
		// 		openTime: DateTime.now().valueOf(),
		// 		notifications: notifications.filter((notif) => notif.deliveryDate <= DateTime.now().valueOf()),
		// 		displayNotifID: ""
		// 	},
		// 	cssClass: "full-sized-modal"
		// });
		// return await modal.present();
	}
}
