<!-- Fabs buttons -->
@if (module && (module?.options?.showAddContactButton || module?.options?.enableScansDownloading)) {
	<ion-fab
		*ngIf="filteredEventUsers.length > 0"
		class="fabs-buttons"
		vertical="bottom"
		horizontal="end"
		slot="fixed"
		[ngClass]="isMobile ? 'p-r-b-100' : ''"
	>
		@if (module?.options?.showAddContactButton) {
			<ion-fab-button
				class="basic-fab-button"
				[style]="isMobile ? 'right: 10px !important; bottom: 70px !important;' : ''"
				[ngClass]="{ 'fab-mobile': isMobile, 'fab-desktop': !isMobile }"
				(click)="goToList()"
			>
				<ion-icon name="add"></ion-icon>
			</ion-fab-button>
		}
		@if (module?.options?.enableScansDownloading) {
			<ion-fab-button
				class="basic-fab-button"
				style="margin-top: 5px"
				[style]="isMobile ? 'right: 10px !important; bottom: 70px !important;' : ''"
				[ngClass]="{ 'fab-mobile': isMobile, 'fab-desktop': !isMobile }"
				(click)="
					SCardExchange.downloadScans(
						event,
						module,
						myEventUser,
						filteredEventUsers,
						customFields,
						form?.fields,
						$event,
						allEventUsersModules
					)
				"
			>
				<ion-icon name="download"></ion-icon>
			</ion-fab-button>
		}
	</ion-fab>
}

<!-- Event users -->
@if (!SCardExchange.enableScanning()) {
	<div class="background">
		<div [ngClass]="'event-users-container'">
			<ion-searchbar
				*ngIf="eventUserModule?.options?.showSearchIcon"
				[(ngModel)]="searchValue"
				class="event-users-searchbar"
				searchIcon="assets/icon/Search.svg"
				mode="md"
				[style.--background]="'rgb(234, 239, 242)'"
				[style.margin-left]="'auto'"
				placeholder="{{ 'texts.search_plc' | translate }}"
				(ionClear)="resetFilter()"
				(keyup)="searchBar($event)"
			>
			</ion-searchbar>
			<!-- Buttons -->

			<ng-container *ngIf="!loader">
				<ng-container *ngIf="filteredEventUsers.length > 0; else emptyContent">
					<div #contentContainer class="content-container" [ngClass]="'pb-0'">
						<!-- <ion-content class="content-container" id="content-container" style="--background: transparent;"> -->
						<cdk-virtual-scroll-viewport
							#virtualScrollEventUsers
							(scrolledIndexChange)="scrolledIndexChanged($event, contentContainer)"
							style="height: 100%; width: 100%"
							[itemSize]="itemSize"
							[minBufferPx]="height"
							[maxBufferPx]="height"
						>
							<div
								id="main-list"
								style="height: 100%; width: 100%"
								[style.padding-top]="!module.options.showSearchIcon ? '25px' : '0'"
								[style.margin-left]="'0px'"
								[style.min-height]="'100%'"
							>
								<!-- Normal list -->
								<ng-container>
									<ng-container *cdkVirtualFor="let eventUser of filteredEventUsers">
										<div
											*ngIf="groupedLetters[eventUser.uid]"
											class="header-divider"
											[hidden]="!module.options.showLetters"
											style="height: 50px"
										>
											<div class="letter" [style.color]="event.styling.titleColor">
												{{ groupedLetters[eventUser.uid] }}
											</div>
											<div class="bar" [style.background-color]="event.styling.titleColor"></div>
										</div>
										<ion-item
											lines="none"
											(click)="
												navigateTo(
													'/event/' +
														eventId +
														'/card-exchange/' +
														module.uid +
														'/profile/' +
														eventUser.uid
												)
											"
											class="item-list"
										>
											<ion-avatar
												slot="start"
												[ngStyle]="{ padding: eventUser?.photoUrl === '' ? '20px 0' : '' }"
											>
												<div
													class="is-online"
													*ngIf="
														event.settings.enableOnlineStatus &&
														eventUser &&
														eventUser.updatedSettings &&
														eventUser.updatedSettings.connected
													"
												></div>
												<img
													class="picture"
													[src]="eventUser?.photoUrl"
													*ngIf="eventUser.photoUrl !== ''"
												/>
												<ion-img
													class="cus-picture"
													*ngIf="eventUser?.photoUrl === ''"
													src="assets/icon/User2.svg"
												>
												</ion-img>
											</ion-avatar>

											<ion-label style="height: auto">
												<h2 class="line-clamp-2" [style.color]="event.styling.titleColor">
													{{ eventUser?.name }}
												</h2>
												<div
													class="margin-padding-0"
													*ngFor="
														let customField of getEventUserTags(eventUser);
														let count = count
													"
												>
													<h5
														class="ellipsis-100 custom-fields-event-users-module-item-tags"
														[style.color]="event.styling.contentTextColor"
														[style.fontSize]="count === 3 ? 'O.8rem' : '0.9rem'"
													>
														{{
															customField.field.text?.trim() ||
																customField.field.multiLanguageText?.[
																	currentLanguage
																]?.trim() ||
																customField.field.numeric?.toString()
														}}
													</h5>
												</div>
											</ion-label>

											<!-- Download contact -->
											<ion-icon
												*ngIf="module.options.enableVcardDownloading"
												slot="end"
												name="bookmark_remove"
												fill="{{ event.styling.contentTextColor }}"
												style="position: relative; top: -1px"
												[style.color]="event.styling.contentTextColor"
												src="assets/icon/download_contact_2.svg"
												(click)="
													SCardExchange.downloadVcard(
														event,
														module,
														myEventUser,
														eventUser,
														customFields[eventUser.moduleId],
														form?.fields,
														$event
													)
												"
											>
											</ion-icon>
										</ion-item>
									</ng-container>
								</ng-container>
							</div>
						</cdk-virtual-scroll-viewport>
						<div class="container-no-result" *ngIf="showNoResultImage">
							<ion-icon
								src="assets/images/emptycontent/AUCUNRESULTAT.svg"
								[style.color]="
									event.styling.menuColor.includes('linear')
										? event.styling.menuColor.split(',')[1]
										: event.styling.menuColor
								"
							>
							</ion-icon>
							<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
								<span class="title"> {{ "texts.no_result_title" | translate }}</span>
								<span>{{ "texts.no_result_text" | translate }}</span>
							</div>
						</div>
					</div>
				</ng-container>
				<ng-template #emptyContent>
					<div class="container">
						<span class="wait-content" [style.color]="event.styling.titleColor">{{
							(dontBelongAnyGroups
								? "texts.not_belonging_to_any_group"
								: searchValue
									? "texts.no_result_title"
									: "texts.waiting_for_content"
							) | translate
						}}</span>
						<ion-icon
							[src]="
								!searchValue
									? 'assets/images/emptycontent/PARTICIPANTS.svg'
									: 'assets/images/emptycontent/AUCUNRESULTAT.svg'
							"
							[style.color]="
								event.styling.menuColor.includes('linear')
									? event.styling.menuColor.split(',')[1]
									: event.styling.menuColor
							"
						>
						</ion-icon>
						<div
							*ngIf="!searchValue"
							class="text-empty-content"
							[style.color]="event.styling.contentTextColor"
						>
							<span class="title"> {{ "event_users.empty_content_title" | translate }}</span>
							<span>{{ "event_users.empty_content_text_card_exchange" | translate }}</span>
						</div>
					</div>
				</ng-template>
			</ng-container>

			<div
				*ngIf="!loader && filteredEventUsers.length === 0"
				class="buttons cards-button"
				[class]="platform.is('ios') ? 'f-row-c-c' : 'f-col-c-c'"
			>
				<!-- Add through form -->
				@if (module?.options?.showAddContactButton) {
					<ion-button (click)="goToList()" shape="round" color="warning">
						{{ "buttons.add-contact" | translate }}
					</ion-button>
				}
				<!-- Add through scan -->
				<ion-button (click)="onPerfomScan()" shape="round" color="danger" fill="outline">
					{{ "buttons.scan-a-profil" | translate }}
				</ion-button>
			</div>

			<!-- await loading attendees list -->
			<div *ngIf="loader" class="loader-block">
				<ion-item-divider>
					<ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
				</ion-item-divider>
				<ion-item lines="none" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
					<ion-avatar slot="start">
						<img src="../../../assets/images/grey.jpg" />
					</ion-avatar>
					<ion-label>
						<h3>
							<ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
						</h3>
						<h3>
							<ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
						</h3>
						<h3>
							<ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
						</h3>
					</ion-label>
				</ion-item>
			</div>
		</div>
	</div>
}
@if (SCardExchange.enableScanning()) {
	<div
		[ngClass]="platform.is('ios') ? 'ios-close-btn' : 'close-btn'"
		class="p-l-5 p-t-15 tw-absolute tw-right-1 tw-bottom-2 tw-z-50"
	>
		<ion-button
			class="tw-absolute tw-right-0"
			fill="clear"
			color="dark"
			(click)="SCardExchange.enableScanning.set(false)"
		>
			<ion-icon color="danger" src="assets/icon/Close.svg"></ion-icon>
		</ion-button>
	</div>

	<zxing-scanner
		(tryHarder)="(true)"
		[enable]="SCardExchange.cameraFound()"
		(camerasFound)="SCardExchange.camerasFoundHandler()"
		(camerasNotFound)="SCardExchange.camerasNotFoundHandler()"
		(scanError)="SCardExchange.scanErrorHandler()"
		(scanSuccess)="
			SCardExchange.scanSuccessHandler(
				{
					form: form,
					event: event,
					module: module,
					eventUserAdmin: myEventUser,
					eventUserContactId: null,
					isMobile: isMobile
				},
				$event
			)
		"
	></zxing-scanner>
}
