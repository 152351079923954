<div
	class="video-wrapper floating video-wrapper-global"
	cdkDragBoundary="ion-app"
	(cdkDragStarted)="moving = true"
	(cdkDragEnded)="moving = false"
	cdkDrag
	[style.width]="getWidth() + 'px'"
	[style.height]="getHeight() + 'px'"
>
	<!-- For vimeo player -->
	<iframe
		#videoPlayer
		id="videoPlayer"
		class="video-player-global"
		*ngIf="type === 'vimeo' && src.includes('player')"
		[src]="src | video: start | safeHtml: 'resourceUrl'"
		[width]="getWidth()"
		[height]="getHeight()"
		frameborder="0"
		allow="autoplay; fullscreen"
		allowfullscreen
		[hidden]="moving"
		[style.position]="'none'"
	></iframe>
	<div
		*ngIf="type === 'vimeo' && !src.includes('player')"
		[hidden]="moving"
		class="video-player-global"
		[style.width]="getWidth()"
		[style.height]="getHeight()"
	>
		<div #videoPlayer id="videoPlayer"></div>
	</div>

	<!-- For youtube player -->
	<div
		*ngIf="type === 'youtube'"
		[hidden]="moving"
		class="video-player-global"
		[style.width]="getWidth()"
		[style.height]="getHeight()"
	>
		<youtube-player
			#videoPlayer
			[videoId]="src"
			[height]="getHeight()"
			[width]="getWidth()"
			(ready)="onReady($event)"
			(stateChange)="stateChange($event)"
			(error)="onError()"
		>
		</youtube-player>
	</div>

	<!-- Other video -->
	<video
		*ngIf="type !== 'youtube' && type !== 'vimeo'"
		[hidden]="moving"
		class="video-player-global"
		#videoPlayer
		[width]="getWidth()"
		[height]="getHeight()"
		controls
		autoplay
	>
		<source [src]="src" type="video/mp4" />
		Your browser does not support the video tag.
	</video>

	<!-- Empty iframe showing when moving the mini player -->
	<iframe [width]="getWidth()" [height]="getHeight()" [hidden]="!moving"></iframe>

	<div class="close-btn">
		<div (click)="onCloseClick()" [style.background]="event.styling.btnBgColor" class="f-row-c-c btn-video-player">
			<ion-icon slot="icon-only" [style.color]="event.styling.btnTextColor" name="close"></ion-icon>
		</div>
	</div>

	<div class="drag-handle" cdkDragHandle>
		<div [style.background]="event.styling.btnBgColor" class="f-row-c-c btn-video-player">
			<svg width="22px" [style.fill]="event.styling.btnTextColor" viewBox="0 0 24 24">
				<path
					d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
				></path>
				<path d="M0 0h24v24H0z" fill="none"></path>
			</svg>
		</div>
	</div>
</div>
