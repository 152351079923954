import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import {
	IAskQuestions,
	IAskQuestionsResult,
	IAskQuestionsResultVote,
	IEvent,
	IEventUser,
	IModule
} from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificAskQuestions } from "src/app/shared/selectors/interactivity.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AskQuestionsService, UtilityService } from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";

@Component({
	selector: "app-ask-questions",
	templateUrl: "./ask-questions.component.html",
	styleUrls: ["./ask-questions.component.scss"],
	standalone: false
})
export class AskQuestionsComponent implements OnInit, OnChanges, OnDestroy {
	subscriptions: Subscription[] = [];

	@Input() componentMode: boolean = false;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input() askQuestionsId: string;
	@Input() showBackBtn: boolean;
	askQuestions: IAskQuestions;
	questions: IAskQuestionsResult[] = [];
	eventUserVotes: IAskQuestionsResultVote[] = [];
	eventUser: IEventUser;

	textQuestion: string = null;
	markQuestionAnonymous: boolean = false;

	submitting: boolean = false;
	voting: boolean = false;

	isMobile: boolean = false;

	constructor(
		private SAnalytics: AnalyticsService,
		private store: Store,
		private SAsksQuestions: AskQuestionsService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private platform: Platform
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
	}

	ngOnInit() {
		if (this.componentMode) {
			this.initDatas();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.askQuestionsId && !changes.askQuestionsId.firstChange) {
			this.subscriptions.forEach((sub) => sub?.unsubscribe());
			this.event = null;
			this.module = null;
			this.askQuestions = null;
			this.questions = [];
			this.eventUser = null;
			this.eventUserVotes = [];
			this.initDatas();
		}
	}

	ionViewWillEnter() {
		if (!this.componentMode) {
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.askQuestionsId = params.askQuestionsId;

							// Analytics
							this.SAnalytics.askQuestionAccess(this.eventId, this.moduleId, this.askQuestionsId);

							this.initDatas();
						});
				});
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getAskQuestions();
		this.getAskQuestionsResults();
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.store.dispatch(ResetHeaderState(null));
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
					if (this.eventUser) {
						this.getAskQuestionsEventUserVotes();
					}
				}
			})
		);
	}

	/**
	 * Get ask questions
	 */
	getAskQuestions() {
		this.subscriptions.push(
			this.store.select(getSpecificAskQuestions(this.askQuestionsId)).subscribe((askQuestions) => {
				if (!_.isEqual(this.askQuestions, askQuestions)) {
					this.askQuestions = askQuestions;
					// Todo: Pourquoi ça ?
					// if (!this.askQuestions || (this.askQuestions && !this.askQuestions.visibility)) {
					// 	this.goBackToList();
					// }
				}

				if (this.askQuestions && !this.componentMode) {
					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: this.askQuestions,
								title: this.askQuestions.name,
								type: TypeHeader.QUIZ
							}
						})
					);
				}
			})
		);
	}

	/**
	 * Get ask questions results
	 */
	getAskQuestionsResults() {
		this.subscriptions.push(
			this.SAsksQuestions.getAskQuestionsResults(this.eventId, this.moduleId, this.askQuestionsId).subscribe(
				(questions) => {
					const questionsSorted = questions
						.filter(
							(question) =>
								question?.visibility ||
								(question.validateByModerator === false && question.userId === this.eventUser.uid)
						)
						.sort((a, b) =>
							a.totalVotes < b.totalVotes
								? 1
								: a.totalVotes > b.totalVotes
									? -1
									: DateTime.fromISO(b.creationDate).valueOf() -
										DateTime.fromISO(a.creationDate).valueOf()
						);
					if (!_.isEqual(this.questions, questionsSorted)) {
						this.questions = questionsSorted;
					}
				}
			)
		);
	}

	/**
	 * Get ask questions votes for event user
	 */
	getAskQuestionsEventUserVotes() {
		this.subscriptions.push(
			this.SAsksQuestions.getAskQuestionsEventUserVotes(
				this.eventId,
				this.moduleId,
				this.askQuestionsId,
				this.eventUser.uid
			).subscribe((votes) => {
				if (!_.isEqual(this.eventUserVotes, votes)) {
					this.eventUserVotes = votes;
				}
			})
		);
	}

	/**
	 * Create a question
	 */
	async createQuestion() {
		// Prevent sending message for non authenticate users (event public case)
		if (!this.eventUser) {
			this.SUtility.alertLoginRegister(
				this.eventId,
				this.STranslate.instant("interactivity.questions_empty_content_title")
			);
			return;
		}

		this.submitting = true;
		try {
			if (this.textQuestion) {
				let newQuestion: IAskQuestionsResult = {
					uid: "",
					askQuestionsId: this.askQuestionsId,
					answered: false,
					creationDate: DateTime.local().toISO(),
					edit: false,
					eventId: this.eventId,
					markedAnonymousByUser: this.markQuestionAnonymous || this.askQuestions.anonymous,
					moduleId: this.moduleId,
					question: this.textQuestion.replace(/<[^>]*>/g, ""),
					totalVotes: 0,
					userId: this.eventUser.uid,
					visibility: this.askQuestions.moderate ? false : true
				};

				if (this.askQuestions.moderate) {
					newQuestion = Object.assign(newQuestion, { validateByModerator: false });
				}
				await this.SAsksQuestions.createAskQuestionsResult(
					this.eventId,
					this.moduleId,
					this.askQuestionsId,
					newQuestion
				);

				// Analytics (send question tracking)
				this.SAnalytics.sendQuestion(
					this.eventId,
					this.moduleId,
					this.eventUser,
					this.askQuestionsId,
					newQuestion
				);
				this.textQuestion = "";
				this.markQuestionAnonymous = false;

				if (this.askQuestions.moderate) {
					this.SUtility.presentToast(
						this.STranslate.instant("alerts.asking_saved_successfully"),
						3000,
						"bottom",
						"success"
					);
				}
				this.submitting = false;
			}
		} catch (error) {
			this.submitting = false;
			this.SUtility.presentToast(
				this.STranslate.instant("asks-questions.error-send-question"),
				3000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * Add or remove vote to a question
	 * @param question
	 */
	async questionAddOrRemoveVote(question: IAskQuestionsResult) {
		if (!this.eventUser) {
			this.SUtility.alertLoginRegister(
				this.eventId,
				this.STranslate.instant("interactivity.questions_empty_content_title")
			);
			return;
		}

		if (!this.voting) {
			this.voting = true;
			try {
				// Check if vote exist
				const vote = this.eventUserVotes.find((vote) => vote.askQuestionsResultId === question.uid);
				if (vote) {
					// Remove vote and decrement total votes
					this.SAsksQuestions.incOrDecTotalVotesOfQuestion(
						this.eventId,
						this.moduleId,
						this.askQuestionsId,
						question.uid,
						false
					);
					await this.SAsksQuestions.deleteVote(
						this.eventId,
						this.moduleId,
						this.askQuestionsId,
						question.uid,
						vote.uid
					);
				} else {
					// Create vote and increment total votes
					const newVote: IAskQuestionsResultVote = {
						uid: "",
						askQuestionsId: this.askQuestionsId,
						askQuestionsResultId: question.uid,
						creationDate: DateTime.local().toISO(),
						eventId: this.eventId,
						moduleId: this.moduleId,
						userId: this.eventUser.uid
					};
					this.SAsksQuestions.incOrDecTotalVotesOfQuestion(
						this.eventId,
						this.moduleId,
						this.askQuestionsId,
						question.uid,
						true
					);
					await this.SAsksQuestions.createVote(
						this.eventId,
						this.moduleId,
						this.askQuestionsId,
						question.uid,
						newVote
					);
				}
				this.voting = false;
			} catch (error) {
				this.voting = false;
				this.SUtility.presentToast(
					this.STranslate.instant("asks-questions.error-voting"),
					3000,
					"bottom",
					"danger"
				);
			}
		}
	}

	/**
	 * showModeratorMsg
	 */
	showModeratorMsg() {
		this.SUtility.presentToast(
			this.STranslate.instant("asks-questions.your-question-awaiting-moderator-approbation"),
			3000,
			"bottom",
			"warning"
		);
	}

	/**
	 * Check liked question or not
	 * @param question
	 * @returns
	 */
	checkLikedQuestion(question: IAskQuestionsResult) {
		return this.eventUserVotes.find((vote) => vote.askQuestionsResultId === question.uid) ? true : false;
	}

	/**
	 * Check overflow
	 * @param el
	 * @returns
	 */
	checkOverflow(el) {
		return el.offsetWidth < el.scrollWidth ? true : false;
		// const elemWidth = el.getBoundingClientRect().width;
		// const parentWidth = el.parentElement.getBoundingClientRect().width;

		// return elemWidth > parentWidth;
	}

	/**
	 * Show more or less
	 * @param idBtn
	 * @param el
	 */
	showMoreLess(idBtn, el) {
		const btn = document.getElementById(idBtn);
		const id = idBtn.substr(4);
		el.style.wordBreak = "break-word";
		const showMoreTxt = this.STranslate.instant("buttons.show_more");
		const showLessTxt = this.STranslate.instant("buttons.show_less");
		if (btn.innerHTML === showMoreTxt) {
			el.style.whiteSpace = "break-spaces";
			btn.innerHTML = showLessTxt;
			const newBtn = document.getElementById(`newB${id}`);
			newBtn.style.display = "block";
			newBtn.innerHTML = showLessTxt;
			const label = document.getElementById(`label${id}`);
			document.getElementById(`avatar${id}`).style.height = label.offsetHeight - 15 + "px";
		} else {
			el.style.whiteSpace = "nowrap";
			btn.innerHTML = showMoreTxt;
			btn.style.display = "none";
			document.getElementById(`avatar${id}`).style.height = "50px";
		}
	}

	/**
	 * Open user profile
	 * @param question
	 */
	// openProfile(question: IAskQuestionsResult) {
	// 	this.navCtrl.navigateForward(`/event/${this.eventId}/profile/${question.userData.moduleId}/${question.userId}`);
	// }

	/**
	 * Go back to list
	 */
	goBackToList() {
		if (this.componentMode) {
			this.changeView.emit({ segmentType: "questions", type: "questions", uid: "" });
		} else {
			this.SUtility.callGoBackOnHeader();
		}
	}
}
