{
	"alerts": {
		"add_to_my_calendar": " أضف إلى الرزنامة الخاصة بي ",
		"all_right": " نعم ",
		"already_scanned": " رمز التحقق مسجل من قبل ",
		"already_checked_checkin": " انت مسجل من قبل في هذا الإيداع ",
		"answer_saved_successfully": " تم حفظ مساهمتكم بنجاح ",
		"app_open_doc_not_found_txt": "  لا يوجد في الهاتف تطبيق مثبت يدعم هذا النوع من التنسيق، هل تريد تنزيل واحد؟ ",
		"asking_saved_successfully": " تم حفظ مساهمتكم بنجاح ",
		"attendee_not_found": " لم يتم العثور على هذا المشارك. هل تريد نسخ رمز التحقق مرة أخرى؟",
		"cannot_scan_web": " لا يمكن مسح رمز التحقق من خلال تطبوق الويب يرجى إستعمال هاتف نقال ",
		"change_status": " تغيير الحالة ",
		"change_status_confirm_msg": " هل أنت متأكد من  تغيير الحالة ",
		"close": " إغلاق ",
		"confirm_exit_event": " هل أنت متأكد من المغادرة؟ ",
		"confirmed_presence": " تم تسجيل المشارك من قبل هل تريد نسخ رمز التحقق مرة أخرى؟",
		"date_already_selected": " لقد قمت بتحديد تاريخ من قبل، لن يتم حفظ التاريخ الجديد ",
		"delete_account_title": " حذف الحساب ",
		"delete_account_message": " هل أنت متأكد أنك تريد حذف حسابك؟ هذا سيؤدي إلى حذف جميع البيانات الخاصة بك وبالتالي التسجيلات الخاصة بك لجميع الأحداث.",
		"error": " خطأ تقني ",
		"error_scanning": " خطأ في النسخ ",
		"event_shortcode_blank": " رمز التحقق غير صالح ، يرجى المحاولة مرة أخرى ",
		"exit_event": " مغادرة التطبيق ",
		"failure_scanning": " فك رمز التحقق مستحيل ",
		"go_to_container_confirm": " هل  تريد فعلا العودة إلى قائمة الأحداث؟",
		"invalid_qrcode": "لم يتم تسجيل هذا المشارك في نقطة العبور هذه",
		"list_events": " قائمة الأحداث ",
		"login-page": "صفحة الاتصال",
		"go-to-login-confirm": "هل تريد حقًا العودة إلى صفحة الاتصال؟",
		"login_client_title_error": " خطأ في محاولة الدخول ",
		"login_client_error": " أنت تحاول الدخول بحساب مستعمل ، يجب عليك إنشاء حساب مشترك لتسجيل الدخول!",
		"no_camera": " لا يمكن الوصول إلى الكاميرا ",
		"not_change_answer": " لا يمكنك تعديل إجاباتك ",
		"not_found_title": " لم يتم العثور على الملف ",
		"not_open_market_msg": "عفوا، لا يمكن فتح المتجر(stores)، هل تريد فتح الملف على المتصفح (browser)؟ ",
		"not_possible_change_status": " لا يمكن إجراء التحقق ",
		"not_success": " لا يمكن إجراء العملية ",
		"please_tryagain": ". يرجى إعادة المحاولة لاحقا ",
		"post_moderated": " تم إرسال منشورك للتحقق ",
		"not_confirm_your_presence": " لا يمكن تسجيل المشترك. يرجى إعادة المحاولة لاحقا .",
		"clear-filter": " حذف الفلتر ",
		"status_changed_successfully": " تم التعديل بنجاح ",
		"success": " تمت العملية بنجاح ",
		"thankyour_asking": " شكرا!",
		"thankyou_answer": " شكرا !",
		"the_presence": " التحقق من ",
		"the_status_changed": " حالة ",
		"type_event_shortcode": " أدخل رمز الحدث ",
		"user_chat_not_available": " لم يتم تسجيل دخول هذا المشترك بعد ، لا تستطيع الدردشة معه ",
		"your_presence_confirmed": " تم تسجيل دخولك بنجاح ",
		"blocked-event-info": "تم حظر الحدث ، والميزات والوصول محدودة"
	},
	"appointments": {
		"show-more-timeslots": "إظهار المزيد من فتحات",
		"no-rdv-accepted": "ليس لديك مواعيد مخططة قريبًا...",
		"no-rdv-pending": "ليس لديك أي مواعيد في انتظار التأكيد...",
		"no-rdv-ended": "لا يوجد موعد قد تم الانتهاء منه في الوقت الحالي...",
		"no-rdv-cancelled": "لم يتم إلغاء أي موعد في الوقت الحالي...",
		"appointment-with": "موعد مع ",
		"save-on-personal-schedule": "احفظ موعدك في جدول أعمالك الشخصي",
		"appointment-infos": "معلومات الاجتماع",
		"aditionnal-infos": "مزيد من المعلومات",
		"select-day": "حدد يوم واحد ...",
		"select-disponibilities": "حدد توافرك:",
		"appointment": "ميعاد",
		"someone-want-to-make-appointment": "{{user}} ترغب في تحديد موعد..",
		"take-appointment": "تحديد موعد",
		"confirm-appointment": "تأكيد الموعد",
		"informations": "المعلومات",
		"fill-informations": "إبلاغ المعلومات",
		"notation_title": " ما رأيك في هذا الموعد",
		"commentary_title": " هل لديك أي تعليقات أخرى؟ ",
		"commentary": " تعليقات ",
		"input_subject": " يرجى منك تحديد الموضوع.",
		"input_message": " يرجى منك إدخال الرسالة",
		"appointment_accepted": " لقد تم قبول الموعد ",
		"appointment_pending": " طلبك معلق يرجى الانتظار",
		"appointment_cancelled": " لقد تم إلغاء الموعد ",
		"appointment_cancelled_automatically": " لقد تم إلغاء الموعد ",
		"appointment_rejected": " لقد تم رفض الموعد ",
		"appointment_rejected_automatically": " لقد تم رفض الموعد ",
		"cancel_appointment": " إلغاء الموعد ",
		"join_visio": " هل تريد الانضمام إلى الإجتماع ",
		"display_empty_time_slots": " عرض المواعيد الفارغة ",
		"make_all_disabled": " هل تريد جعل كل المواعيد غير متاحة ",
		"make_all_enabled": " هل تريد جعل كل المواعيد غير متاحة ",
		"available_time_slot": " موعد متاح ",
		"unavailable_time_slot": " موعد غير متاح ",
		"request_appointment": " طلب موعد ",
		"choose_time_slot": " اختر تاريخ الموعد ",
		"appointment_request": " طلب تحديد موعد ",
		"applicant": " مقدم الطلب ",
		"invited": " ضيف ",
		"on_date": " ليوم ",
		"from": " من ",
		"to": " إلى ",
		"subject": " موضوع ",
		"status": " حالة ",
		"see_details": " عرض التفاصيل ",
		"accept": " قبول الموعد ",
		"reject": " رفض الموعد ",
		"cancel": " إلغاء الموعد ",
		"choose_new_time_slot": " اختيار توقيت موعد جديد ",
		"make_appointment": " أخذ موعد ",
		"select-location": "حدد مكان موعدك...",
		"alerts": {
			"send-appointment-slot-disabled-recipient-title": "تعطيل مكانة",
			"send-appointment-slot-disabled-recipient-message": "تم تعطيل المتخصصة من قبل المستلم",
			"send-appointment-slots-taken-title": "المتخصصة التي اتخذت بالفعل",
			"send-appointment-slots-taken-message": "لم يعد المتخصصة التي طلبت من أجل الموعد متوفرة من أجلها",
			"send-appointment-limit-accepted-applicant-title": "بلغ الحد",
			"send-appointment-limit-accepted-applicant-message": "من المستحيل تحديد الموعد ، يتم التوصل إلى الحد الأقصى لعدد من الاجتماعات المقبولة",
			"send-appointment-limit-accepted-recipient-title": "بلغ الحد",
			"send-appointment-limit-accepted-recipient-message": "من المستحيل تحديد الموعد ، يتم الوصول إلى حد الاجتماع المقبول للمستلم",
			"appointment-accepted-limit": "من المستحيل قبول الموعد ، يتم الوصول إلى حد الموعد المقبول.",
			"send-appointment-title": "طلب الموعد المقدم",
			"send-appointment-message": "تم إرسال طلبك.سيتم إرسال إشعار إليك عندما يتم فحصه والموافقة عليه.",
			"send-appointment-exist-title": "Rendezvous غير متوفر",
			"send-appointment-exist-message": "لم يعد التخصيص الذي حددته من أجله متاحًا.",
			"cancel_appointment": {
				"title": " إلغاء هذا الموعد ",
				"message": " هل أنت متأكد أنك تريد إلغاء هذا الموعد? "
			}
		},
		"buttons": {
			"send-message": "إبعث رسالة",
			"see-details": "انظر التفاصيل",
			"accept": "يقبل",
			"refuse": "رفض",
			"cancel": "يلغي"
		},
		"notification": {
			"reminder-title": "تذكير بموعدك",
			"reminder-content": "موعدك مع {{user}} يبدأ في {{time}} دقائق",
			"accepted": " تم قبول الموعد\"{{subject}}\".",
			"pending": " طلب موعد {{date}}",
			"rejected": "تم رفض الموعد \"{{subject}}\" .",
			"cancelled": "تم إلغاء الموعد \"{{subject}}\".",
			"rejected_automatically": " تم رفض الموعد\"{{subject}}\" تلقائيا. لم يعد المستخدم متاحا في هذا الوقت.",
			"cancelled_automatically": "تم إلغاء الموعد \"{{subject}}\"  تلقائيا. لم يعد المستخدم متاحا في هذا الوقت."
		},
		"app_status": {
			"coming": "ليأتي",
			"waiting": "في الانتظار",
			"ended": "انتهى",
			"accepted": " مقبول ",
			"cancelled": " ملغى ",
			"cancelled-by": "ألغيت بواسطة {{ user }}",
			"cancelled_automatically": " ملغى تلقائيا ",
			"rejected": " مرفوض ",
			"rejected_automatically": " مرفوض تلقائيا ",
			"pending": " في انتظار إجابة "
		},
		"select_date": " حدد التاريخ ",
		"date_time_appointment": " تاريخ الموعد :",
		"make_appointment_available": " جعل الموعد متاحا ",
		"make_appointment_unavailable": " جعل الموعد غير متاح "
	},
	"app_settings": {
		"old_version_description": " يحتوي هذا التطبيق  على تحديث جديد ، قم بتنزيله ",
		"old_version_title": " النسخة القديمة "
	},
	"asks-questions": {
		"ask-questions-plc": "اكتب النص هنا...",
		"error-send-question": " لا يمكن إرسال سؤالك ",
		"error-voting": " حدث خطأ أثناء الإعجاب ",
		"ask-question-anonymously": " اطرح السؤال بشكل مجهول "
	},
	"auth": {
		"need_login_register_for_participating": " يجب تسجيل الدخول أو التسجيل من أجل المشاركة."
	},
	"buttons": {
		"access": " تسجيل الدخول ",
		"authenticate": " التسجيل ",
		"access_to": " دخول ",
		"access_by_email": " الدخول بإستعمال البريد الإلكتروني ",
		"access_by_shortcode": " الدخول بإستعمال رمز مشفر ",
		"add": " إضافة ",
		"answer": " تأكيد ",
		"apply": " تطبيق ",
		"back": " عودة ",
		"back_to_list_event": " العودة إلى القائمة ",
		"back-to-login": "العودة إلى الاتصال",
		"btn_verify": " دخول ",
		"cancel": " إلغاء ",
		"comment": " تعليق ",
		"confirm": " تأكيد ",
		"create": " إنشاء ",
		"delete": " إزالة ",
		"download": " تحميل ",
		"delete_account": " حذف الحساب ",
		"edit-profil": " تعديل الحساب ",
		"enter": " تحقق ",
		"exit": " تسجيل الخروج ",
		"from_photos": " مكتبة الصور ",
		"take_photo": " التقاط صورة ",
		"go": " دخول ",
		"log_out": " تسجيل الخروج ",
		"next": " التالي ",
		"no": " لا ",
		"nonees": "لا أحد",
		"open_gallery": " صوري ",
		"ok": " نعم ",
		"post": " نشر ",
		"prev": "سابق",
		"public_events_list": " قائمة الأحداث العامة ",
		"register": " تسجيل ",
		"select_picture": " حدد صورة ",
		"click-to-select-document": " انقر لإختيار وثيقة ",
		"click-on-document-to-replace": " انقر لإستبدال هذه الوثيقة ",
		"share": " نشر ",
		"show_more": " شاهد المزيد ",
		"show_less": " شاهد أقل ",
		"social_sharing": " المواقع الاجتماعية ",
		"take_picture": "التقاط صورة ",
		"update": "المعدل",
		"validate": " تأكيد ",
		"yes": " نعم "
	},
	"chat": {
		"chat": " محادثة ",
		"chats": " محادثات ",
		"chat_history": " محادثات ",
		"chat_empty_content_title": " دردش مع المشاركين ",
		"chat_empty_content_text": "اسمح لنفسك بمحادثة مع مشارك آخر. كن أول من يرسل رسالة",
		"create_chat": " إنشاء مجموعة دردشة ",
		"display_group_info": " عرض المعلومات ",
		"group_chat_empty_content_title": " دردش مع المشاركين ",
		"group_chat_empty_content_text": " اسمح لنفسك بالدردشة في مجموعة مشاركين. كن أول من يشارك ويشجع الضيوف الآخرين على الانضمام",
		"groups": " مجموعات الدردشة ",
		"group_info": " معلومات حول المجموعة ",
		"new_chat": " محادثة جديدة ",
		"no_messages": " ليس لديك أي رسائل ",
		"no_official": " مجموعات دردشة غير رسمية ",
		"official": " مجموعات دردشة رسمية ",
		"notifications": " إشعارات ",
		"received_message": " لقد تلقيت رسالة جديدة ",
		"silent_mode": " الوضع الصامت ",
		"toggle": " تشغيل / تعطيل ",
		"type_msg": " اكتب رسالتك...",
		"you": " أنت: "
	},
	"checkin": {
		"only-one-check-authorized": "يُسمح بفحص واحد فقط",
		"already_checked": " مشترك مسجل من قبل ",
		"away": " غائب ",
		"default_title": " تسجيل الحضور ",
		"empty_content_title": " يرجى التحقق من تسجيلات  الحضور ",
		"empty_content_text": " يرجى التحقق من تسجيلات  الحضور ",
		"last_checkins": " عمليات تسجيل الحضور الأخيرة ",
		"qrcode_focus": " نسخ رمز التحقق ",
		"showed": " حضور ",
		"total": " المجموع ",
		"user_registered": " مستخدم مسجل!",
		"user_unregistered": " مستخدم غير مسجل!"
	},
	"documents": {
		"default_title": " وثائق ",
		"empty_content_title": " مراجعة وثائق الحدث ",
		"empty_content_text": " عرض وتحميل وثائق الحدث!! "
	},
	"edit-profil": {
		"name": " الاسم ",
		"email": " البريد الإلكتروني ",
		"biography": " السيرة الذاتية ",
		"website": " الموقع الإلكتروني ",
		"facebook": " فايسبوك ",
		"linkedin": "LinkedIn",
		"twitter": " تويتر ",
		"instagram": " إنستغرام ",
		"submit": " تأكيد ",
		"placeholders": {
			"name_text-info": " الاسم ",
			"email_text-info": " البريد الإلكتروني ",
			"bio_text-info": " كيف تصف نفسك؟ ",
			"website_text-info": " الموقع الإلكتروني ",
			"facebook_text-info": " فايسبوك  الصفحة الشخصية ",
			"linkedin_text-info": "الصفحة الشخصيةLinkedIn",
			"twitter_text-info": "تويتر  الصفحة الشخصية ",
			"instagram_text-info": "إنستغرام الصفحة الشخصية",
			"company_text-info": " إسم شركتك ",
			"title_text-info": " منصب العمل ؟",
			"url_text-info": " يرجى إدخال رابط ",
			"simple_text-info": " يرجى إدخال قيمة نصية ",
			"numeric_text-info": " يرجى إدخال قيمة رقمية ",
			"html_text-info": " يرجى إدخال أدخل نص أو محتوى HTML ",
			"select_text-info": " اختر قيمة ",
			"multi-select_text-info": " اختر قيمة واحدة أو أكثر ",
			"multiple_text-info": " أدخل بعض النص واضغط على \"تأكيد\"  للتحقق من الصحة.",
			"date_text-info": " اختر التاريخ ",
			"phonetel_text-info": " أدخل رقم الهاتف ",
			"image_text-info": " تحميل صورة ",
			"file_text-info": " تحميل ملف ",
			"example": {
				"email1": "email@example.com",
				"email2": " مثال: jeandupont@mail.com"
			}
		},
		"errors": {
			"characters": "caractères",
			"blank": {
				"name": " يرجى إدخال الإسم",
				"simple_text": " فارغ! الرجاء إدخال قيمة.",
				"email": " الرجاء إدخال البريد الإلكتروني.",
				"select": " الرجاء تحديد خيار!",
				"multi_select": " الرجاء تحديد خيار واحد أو أكثر! ",
				"required": " فارغ ! يرجى إدخال قيمة"
			},
			"invalid": {
				"bio": " يجب أن يكون عدد الأحرف بين 15 و 5000  ",
				"url": " رابط غير صالح !",
				"facebook": " رابط فايسبوك غير صالح ",
				"linkedin": " رابط LinkedIn غير صالح ",
				"twitter": " رابط تويتر غير صالح ",
				"instagram": " رابط إنستغرام غير صالح ",
				"name": " يجب أن يحتوي الاسم على 3 أحرف على الأقل! ",
				"email": " بريد إلكتروني غير صالح!",
				"date": " تاريخ غير صالح!",
				"generic": " الشكل غير صالح!",
				"multiple_text-too-long": " تم تجاوز الحد الأقصى لعدد الأحرف! ",
				"multiple_text-content-exist": " هذا المحتوى موجود من قبل في القائمة ",
				"min_length": " الرجاء إدخال أقل ",
				"phone_number": " رقم الهاتف غير صالح ",
				"big_number": " عدد كبير جدا ",
				"file_size": " ملف كبير جدا ، والحد :"
			}
		}
	},
	"events": {
		"switch_timezone_user": " التبديل إلى المنطقة الزمنية المحلية ",
		"switch_timezone_event": " التبديل إلى المنطقة الزمنية للحدث ",
		"add_event_to_calendar": " أضف إلى الرزنامة الخاصة بي "
	},
	"events_list": {
		"events": " الأحداث ",
		"my_events": " الأحداث الخاصة بي ",
		"publics_events": " الأحداث العامة ",
		"no-events": " أنت غير مسجل في أي حدث في هذا الملف !"
	},
	"event_users": {
		"default_title": " المشاركين ",
		"empty_content_title": " مراجعة قائمة المشاركين ",
		"empty_content_text": " عرض ملفات تعريف المشاركين. الدردشة أو الدردشة المباشرة مع أحد المشاركين من ملفه الشخصي!",
		"company": " الشركة ",
		"post": " المنصب ",
		"delete_all": " حذف الكل ",
		"not_specified": " غير محدد "
	},
	"feedbacks": {
		"cannot_send_feedback": " يتعذر إرسال ردود الاستطلاع ",
		"default_title": " إستفتاء ",
		"default_title_feedback": " إستفتاء ",
		"answered": " تمت الإجابة من قبل ",
		"not_answered": "لم يتم تسجيل اي إجابة ",
		"answer_plc": "...",
		"force_complete_title_alert": " حذاري ",
		"force_complete_message_alert": " يرجى ملء الإستمارة بالكامل ",
		"feedback_send": "تم إرسال إجاباتك على الاستطلاع بنجاح"
	},
	"feed_news": {
		"default_title": " موجز الأخبار ",
		"description_plc": " عبر عن نفسك!",
		"comments_plc": " اكتب تعليقا",
		"likes": "Likes",
		"comment": " تعليق ",
		"comments": " تعليقات ",
		"thumb-up": " إعجاب ",
		"heart": " أحب ",
		"clap": " رائع ",
		"idea": " إرشادي ",
		"thinking": " مهم ",
		"time": " هناك {{time}} {{timeLabel}}",
		"empty_content_title": " نشر المحتوى على موجز أخبار الحدث ",
		"empty_content_text": " تبادل مع المشتركين بفضل المشاركات والإعجابات والتعليقات المنشورة على موجز الأخبار للحدث!"
	},
	"filter": {
		"not_specified": " غير محدد ",
		"delete_all": " حذف الكل ",
		"text-information": " استخدم الفلاتر لتحسين عملية البحث "
	},
	"gallery": {
		"default_title": " معرض الصور ",
		"empty_content_title": " عرض صور الحدث ",
		"empty_content_text": " مشاهدة وتحميل صور هذا الحدث!"
	},
	"gamification": {
		"treasure_hunt_description": " ابحث عن الرموز المنتشرة في جميع أنحاء الحدث"
	},
	"interactivity": {
		"default_title": " تفاعل ",
		"default_empty_content_title": " لا توجد نتائج !",
		"default_empty_content_text": " يرجى إعادة المحاولة لاحقا!",
		"externals_interactivity_description": " تفاعل مع المشتركين !",
		"note-taking-description": "لإنشاء ملاحظة ، انقر فوق الزر + في أسفل الشاشة",
		"survey_description": " أجب على الأسئلة !",
		"quiz_description": " أجب على الأسئلة !",
		"training_description": " توجيه التدريب ",
		"ask_question_description": " أسئلة للمتحدثين !",
		"feedback_empty_content_title": " أجب على الاستطلاعات ",
		"feedback_empty_content_text": " رأيكم يهمنا ! يرجى التوجه إلى الإستطلاعات والرد عليها مباشرة على المنصة.",
		"quiz_empty_content_title": " أجب على الأسئلة ",
		"quiz_empty_content_text": " اختبر معلوماتك مع المسابقة !",
		"questions_empty_content_title": " اطرح أسئلتك ",
		"questions_empty_content_text": " لا تتردد في طرح جميع أسئلتك على المتحدثين! سيتمكن المشاركون الآخرون من الإعجاب و الإستفادة بهذه الأسئلة ",
		"survey_empty_content_title": " أجب على الأسئلة ",
		"survey_empty_content_text": " رأيكم يهمنا ! يرجى التوجه إلى الإستطلاعات والرد عليها مباشرة على المنصة.",
		"min_points_msg_part_1": "يجب على الأقل تحقيق ",
		"min_points_msg_part_2": " نقطة لفتح هذا الإستطلاع"
	},
	"labels": {
		"accompanying": "المن��ة",
		"address": "عنوان",
		"and": "و",
		"code": "الرمز",
		"comment": "تعليق",
		"content": "محتوى",
		"choose-date": "اختر تاريخًا",
		"description": "الوصف",
		"email": "البريد الإلكتروني",
		"end": "زعنفة",
		"filters": "مرشحات",
		"groups": "مجموعات",
		"group": "مجموعة",
		"hidden": "مخفي",
		"visible": "مرئي",
		"hours": "ساعة/ساعات",
		"identifier": "معرف",
		"information": "معلومات",
		"internet_site": "موقع الإنترنت",
		"legal_mentions": "قانوني",
		"list": "قائمة",
		"loading": "جاري التحميل...",
		"locations": "المواقع",
		"members": "أعضاء",
		"minutes": "دقيقة/دقائق",
		"name": "الاسم",
		"no": "لا",
		"on-maintenance": "المنصة تحت الصيانة حاليًا، الرجاء العودة لاحقًا",
		"plan": "الخطة",
		"privacy": "سياسة الخصوصية",
		"reset": "إعادة ضبط",
		"scan": "مسح",
		"search": "بحث",
		"seconds": "ثانية/ثواني",
		"sessions": "جلسات",
		"signature": "إمضاء",
		"start": "بداية",
		"support": "البريد الإلكتروني للدعم",
		"tags": "وسوم",
		"terms_of_use": "شروط الاستخدام",
		"title": "العنوان",
		"tracks": "مسارات",
		"yes": "نعم"
	},
	"placeholders": {
		"enter-phone-number": " يرجى إدخال رقم هاتف صالح ",
		"link-or-import-image": " الرجاء إدخال رابط أو استيراد صورة ",
		"import-file": " استيراد ملف ",
		"address_plc": "99 Av. des Champs-Élysées, 75008 Paris France"
	},
	"languages": {
		"choose-language": "اختر اللغة",
		"arabic": "عرب",
		"english": " الإنجليزية ",
		"french": " الفرنسية ",
		"portuguese": " البرتغالية ",
		"spanish": " الإسبانية ",
		"german": " الألمانية "
	},
	"login": {
		"accept_term": " أوافق على ",
		"confirm_new_pass": " تأكيد كلمة المرور الجديدة ",
		"email": "البريد الإلكتروني  ",
		"password": " كلمة المرور ",
		"forgot_pass": " لقد نسيت كلمة المرور الخاصة بي ",
		"first_access_txt_01": " الدخول الأول!",
		"first_access_txt_02": " تم إرسال رمز مكون من ستة أرقام إلى بريدك الإلكتروني ، أدخله أدناه لتأكيد هويتك:",
		"new_pass": "كلمة مرور جديدة ",
		"not_registered": " هذا البريد الإلكتروني غير مسجل للحدث, الرجاء التسجيل.",
		"incorrect_pass": " كلمة مرور غير صالحة!",
		"invalid_pass": " كلمة مرور غير صالحة! يجب أن تتكون كلمة المرور الخاصة بك من 6 أحرف على الأقل ",
		"pass_error": " لم نتمكن من إنشاء كلمة المرور الخاصة بك ، يرجى المحاولة مرة أخرى لاحقا!",
		"recovery_pass_title": " إعادة تعيين كلمة المرور ",
		"recovery_pass_txt": " يرجى تأكيد البريد الإلكتروني الخاص بك من أجل إعادة تعيين كلمة المرور البريد الإلكتروني:",
		"your_email_plc": " أدخل بريدك الإلكتروني ",
		"recovery_btn": " إرسال ",
		"recovery_success": " لقد أرسلنا لك بريدا إلكترونيا يسمح لك بإعادة تعيين كلمة المرور الخاصة بك.",
		"recovery_text": " لقد أرسلنا بريدا إلكترونيا يسمح لك بإعادة تعيين كلمة المرور الخاصة بك. تذكر أن تتحقق من بريدك. سيكون عليك فقط النقر على الرابط لإنشاء رابط جديد",
		"recovery_fail": " لم نتمكن من إعادة تعيين كلمة المرور الخاصة بك. يرجى المحاولة مرة أخرى!",
		"access_your_account": " تسجيل الدخول ",
		"term_check_required": " يجب عليك قبول شروط الاستخدام وسياسة الخصوصية ",
		"terms_of_use": " شروط الاستخدام ",
		"privacy_txt": " سياسة الخصوصية ",
		"invalid_email": " البريد الإلكتروني غير صالح ",
		"invalid_name": " اسم غير صالح ",
		"blank_email": " يرجى إدخال بريد إلكتروني ",
		"email_not_found": " البريد الإلكتروني غير مسجل ",
		"btn_no_have_acc": " ليس لدي حساب ",
		"verify_code": " قد يكون البريد الإلكتروني الذي يحتوي على الرمز في البريد العشوائي(spams) الخاص بك ",
		"btn_make_pass": " إنشاء كلمة مرور ",
		"great_title": " أهلا بك!",
		"make_pass_txt": " الرجاء إنشاء كلمة مرور فردية ( 6 أحرف على الأقل)",
		"make_pass_strong_txt": "يرجى إنشاء كلمة مرور فردية (الحد الأدنى 12 حرفًا كبيرًا ، صغيرًا ، الشكل ، الرموز)",
		"error_send_email_code": " لم نتمكن من إرسال البريد الإلكتروني الذي يحتوي على الرمز المكون من 6 أرقام. يرجى المحاولة مرة أخرى لاحقا!",
		"invalid_code": " الرمز غير صالح. يرجى إعادة المحاولة مرة أخرى! ",
		"password_not_match": " كلمات المرور ليست هي نفسها يرجى إدخال نفس الرمز. ",
		"email_already_use": " لم نتمكن من إنشاء حساب جديد مع البريد الإلكتروني الخاص بك لأنه مسجل من قبل ",
		"resend_code_number": " إذا لم تكن قد تلقيت الرمز المكون من 6 أرقام: تحقق من البريد العشوائيالخاص بك ",
		"remaining_time": " الوقت المتبقي ",
		"many_pass_errors": " تم حظر حسابك مؤقتا بعد العديد من محاولات تسجيل الدخول. يرجى الانتظار قليلا قبل المحاولة مرة أخرى.",
		"input_name_plc": "Martine Rault",
		"input_email_plc": "martine@mail.com",
		"geral_error_register": " حدث خطأ أثناء التسجيل ، يرجى المحاولة مرة أخرى.",
		"resend_code": " إرسال الرمز المكون من ستة أرقام مرة أخرى ",
		"account_already_exist_with_email_private_event": "يوجد حساب بالفعل بهذا البريد الإلكتروني. الرجاء تسجيل الدخول باستخدام نموذج الدخول.",
		"account_already_exist_with_email_public_event": "يوجد حساب بالفعل بهذا البريد الإلكتروني. نظرًا لكونه حدثًا عامًا، ستقوم بإدخال كلمة المرور الخاصة بك في نهاية النموذج للتسجيل.",
		"event_user_already_exist_in_event": " لقد تم تسجيلك من قبل في هذا الحدث. ما عليك سوى تسجيل الدخول عن طريق إدخال كلمة المرور الخاصة بك ",
		"not_have_access_to_event": " لا يستطيع المستخدم الوصول إلى الحدث المطلوب ",
		"min_6_characters": " يرجى إدخال 6 أحرف على الأقل ",
		"account_disabled_many_request": " تم تعليق الدخول إلى هذا الحساب مؤقتا بسبب المحاولات الخاطئة. يرجى إعادة المحاولة لاحقا.",
		"attempt_to_login_with_short_code_private_event_msg": " هذا الحدث خاص ، لا يمكنك تسجيل الدخول باستخدام رمز الوصول. يرجى تسجيل الدخول مباشرة باستخدام عنوان البريد الإلكتروني الخاص بك ",
		"attempt_to_login_with_short_code_private_event_title": " الدخول إلى حدث "
	},
	"note-taking": {
		"success-create": "ملاحظة تخلق",
		"success-delete": "ملاحظة محذوفة",
		"success-edit": "ملاحظة معدلة",
		"share-title": "سوف",
		"title-too-long": "يجب ألا يكون العنوان أكثر من 70 حرفًا",
		"content-too-long": "يجب ألا يصنع المحتوى أكثر من 700 حرف"
	},
	"notifications": {
		"appointment_chat_title_received": " تم إستلام الموعد ",
		"appointment_chat_title_updated": " تم تعديل الموعد ",
		"appointment_chat_title": " موعد ",
		"appointment_chat_title_cancelled": " تم إلغاء الموعد "
	},
	"profile": {
		"start_chat": " بدء الدردشة ",
		"start_visio": " بدء تشغيل المحادثة (فيديو) "
	},
	"quizs": {
		"warning-validation-definitive": "حذاري !التحقق من الصحة نهائي.",
		"score-is": "درجاتك...",
		"correct-answered-out-of-1": "لقد ردت على ",
		"correct-answered-out-of-2": " أسئلة حول ",
		"retry": "إعادة تشغيل",
		"retry-later": "ابدأ مرة أخرى لاحقًا",
		"validate": "للتحقق من صحة",
		"cannot_send_quiz": " عفوا، يتعذر إرسال الإجابات ",
		"default_title": " اختبار ",
		"default_title_quiz": " اختبار ",
		"answered": " تمت الإجابة من قبل ",
		"not_answered": " لم تتم الإجابة من قبل ",
		"answer_plc": "...",
		"force_complete_title_alert": " حذار ",
		"force_complete_message_alert": " يجب ملء الإستمارة بالكامل ",
		"result_send": " تم حفظ إجابتك بنجاح "
	},
	"rankings": {
		"empty_content_title": " عرض التصنيف ",
		"empty_content_text": " عرض التصنيف ",
		"global_ranking": " التصنيف العام ",
		"my_ranking": " عرض التصنيف الخاص بي "
	},
	"register": {
		"register": " التسجيل ",
		"email_exist": " تم تسجيل مستخدم مرتبط بهذا البريد الإلكتروني من قبل",
		"register_to_event": " التسجيل في هذا الحدث ",
		"register_a_user_to_the_event": " تسجيل مشارك ",
		"user_already_has_an_account": " لديك حساب من قبل؟",
		"user_doesnt_has_an_account": " أنت غير مسجل بعد؟",
		"next_step": " المرحلة التالية ",
		"previous_step": " المرحلة السابقة ",
		"registrations_are_closed_please_contact_your_event_manager": " لا يمكنك تسجيل مرا��ق للحد�� ل��نك و��لت ��",
		"register_an_accompanying_user_title": "تسجيل مرافق للحدث",
		"register_an_accompanying_user_question": "هل ترغب في تسجيل مرافق للحدث؟",
		"accompanying_users_limit_reached": "لا يمكنك تسجيل مرافق للحدث لأنك وصلت إلى حد التسجيل المحدد من قبل المسؤول",
		"event_users_limit_reached": "لا يمكنك التسجيل في الحدث لأنك وصلت إلى الحد الأقصى لعدد المشاركين الذي حدده المسؤول"
	},
	"reports": {
		"alert_title": " الإبلاغ ",
		"alert_send": " تم إرسال الإبلاغ بنجاح "
	},
	"schedule": {
		"default_title": " جدول الأعمال ",
		"filter_hour": " يوميا ",
		"filter_track": " عن طريق المسار ",
		"empty_content_title": " ابحث عن برنامج الحدث الخاص بك ",
		"empty_content_text": " استشر البرنامج للحدث الخاص بك بسرعة!",
		"download_schedule": " تحميل جدول الأعمال ",
		"start-soon": "تبدأ في",
		"end-soon": "تنتهي",
		"prev-sessions": "الجلسات السابقة"
	},
	"schedule_detail": {
		"cannot_register_to_session": " عفوا، لا يمكن تسجيلك في الدورة ",
		"default_title": " التفاصيل ",
		"no_date": " لا ",
		"number_attendees": " المشاركون المسجلون من قبل ",
		"number_available_vacancies": " عدد الأماكن المتبقية ",
		"register_to_session": " التسجيل للدورة ",
		"unregister_to_session": " إلغاء الاشتراك من الدورة ",
		"registered_to_session": " أنت مسجل في الدورة ",
		"unregistered_to_session": " لم تعد مسجلا في الدورة ",
		"error_reg_unreg_to_session": " لا يمكن التسجيل أو الإنسحاب من الدورة",
		"unavailable": "لا أحد",
		"interactivity": {
			"details": " لا ",
			"ask-questions": " الأسئلة ",
			"externals_interactivity": " التفاعل الخارجي ",
			"survey": " الأراء ",
			"quiz": " الإختبار ",
			"training": " التدريب ",
			"group-discussion": " مجموعات الدردشة ",
			"feedback-session": " استطلاع حول الدورة "
		},
		"prev-session": " الدورة السابقة ",
		"next-session": " الدورة المقبلة ",
		"session_limit_attendees": " تم الوصول  إلى الحد الأقصى  من المشاركين لهذه الدورة !",
		"registered_users_list": " المشاركون المسجلون من قبل "
	},
	"snackbar": {
		"authentification_required": " يجب تسجيل الدخول من قبل للوصول إلى هذا الحدث ",
		"cannot_create_account": " لا يمكن إنشاء حسابك مؤقتا ",
		"cannot_login": " لا يمكن  تسجيل الدخول مؤقتا ",
		"error_occured": " حدث خطأ، يرجى الإنتظار ",
		"error_occured_on_verif_email": "حدث خطأ أثناء التحقق من عنوان بريدك الإلكتروني",
		"event_shortcode_blank": " الرمز غير صالح ، يرجى المحاولة مرة أخرى ",
		"no_form_attached": " لا يوجد ت��كيد الملف",
		"no_homepage": " لا يحتوي هذا الحدث على صفحة رئيسية ",
		"no_network": " لا يوجد اتصال بالإنترنت ",
		"no_shortcode": " لا يوجد رمز للتأكيد ",
		"no_user": " لا يوجد حساب مستخدم ",
		"not_authorized_event": " لا تملك صلاحية للدخول إلى هذا الحدث ",
		"update_successfull": " تم التحديث بنجاح ",
		"file_updated_successfully": " تم تنزيل الملف بنجاح ",
		"picture_updated_successfull": " تم تحديث الصورة بنجاح ",
		"failed_to_upload_media": " لا يمكن تحميل الملفات الخاصة بك مؤقتا ",
		"no_password": " يرجى إدخال كلمة المرور ",
		"wrong_password": " كلمة مرور خاطئة ",
		"success_delete_account": " سيتم حذف حسابك.",
		"signature-saved": "حفظ التوقيع المحفوظ"
	},
	"surveys": {
		"cannot_send_survey": " يتعذر إرسال الردود على الإستطلاع ",
		"default_title": " إستطلاع ",
		"default_title_survey": " إستطلاع ",
		"answered": " تمت الإجابة من قبل ",
		"not_answered": " لم تتم الإجابة ",
		"answer_plc": "...",
		"force_complete_title_alert": " حذار ",
		"force_complete_message_alert": " يرجى ملء الإستمارة بالكامل ",
		"survey_send": " تم إرسال إجاباتك على الاستطلاع ",
		"min_points_alert_msg": " يجب الحصول على الأقل على <strong> {{ points }}</strong>  نقطة لتفعيل هذا الاستطلاع!"
	},
	"texts": {
		"see-filter-results": "انظر النتائج",
		"current-date": "تاريخ اليوم",
		"personalschedule": " جدول الأعمال الشخصي ",
		"speakers": " المتحدثون ",
		"addschedule": " التسجيل للدورة ",
		"removeschedule": " الإنسحاب من الدورة ",
		"event": " الحدث ",
		"events": " الأحداث ",
		"survey": " استطلاع الآراء ",
		"quiz": " اختبار ",
		"training": " مسابقات التدريب ",
		"subscription": " استمارة التسجيل ",
		"profile": " الملف الشخصي ",
		"nation": " البلد ",
		"state": " الولاية / المنطقة ",
		"city": " المدينة ",
		"save": " تسجيل ",
		"site": " الموقع الإلكتروني ",
		"pt_BR": " البرتغالية ",
		"en_US": " الإنجليزية ",
		"es_ES": " الإسبانية ",
		"fr_FR": " الفرنسية ",
		"de_DE": " الألمانية ",
		"sunday": " الأحد ",
		"monday": " الاثنين ",
		"tuesday": " الثلاثاء ",
		"wednesday": " الأربعاء ",
		"thursday": " الخميس ",
		"friday": " الجمعة ",
		"saturday": " السبت ",
		"january": " كانون الثاني / يناير ",
		"february": " شباط / فبراير ",
		"march": " آذار / مارس ",
		"april": " نيسان / أبريل ",
		"may": " أيار / مايو ",
		"june": " حزيران / يونيه ",
		"july": " تموز / يوليه ",
		"august": " آب / أغسطس ",
		"september": " أيلول / سبتمبر ",
		"october": " تشرين الأول / أكتوبر ",
		"november": " تشرين الثاني / نوفمبر ",
		"december": " كانون الأول / ديسمبر ",
		"of": " من ",
		"maps": " خريطة ",
		"btn_send": " إرسال ",
		"thankyou": " شكرا!",
		"loading": " تحميل التطبيق...",
		"and": " و ",
		"terms_of_use": " شروط الاستخدام ",
		"privacy": " سياسة الخصوصية ",
		"cookie": " سياسة ملفات تعريف الارتباط cookies",
		"legal_mentions": " القانونية ",
		"terms_legal": " القانونية ",
		"license": "Invent App - All Rights Reserved © 2021.",
		"search_plc": " جاري البحث...",
		"description": " الوصف ",
		"select": " اختر ",
		"select_image": " إختر  صورة ",
		"select_option": " إختر أحد الخيارات ",
		"image": " صورة ",
		"filter": " تصفية ",
		"all": " الكل ",
		"about": " حول ",
		"start_chat": " دردشة ",
		"picture": " صورة ",
		"name": " اللقب الإسم ",
		"points": " نقاط ",
		"menu": " القائمة ",
		"need_login_to_use": " يجب تسجيل الدخول أو إنشاء حساب للوصول إلى هذا المحتوى!",
		"timezone": " المنطقة الزمنية ",
		"support": " المساعدة ",
		"a_z": " أبجدي من الألف إلى الياء ",
		"z_a": " أبجدي من الياء إلى الألف ",
		"locations": " الموقع ",
		"social_medias": " الشبكات الاجتماعية ",
		"position": "الموقع.",
		"network_offline": " لا يوجد اتصال بالإنترنت!",
		"network_online": " تمت استعادة اتصال الإنترنت!",
		"network_offline_detail": " لا يوجد اتصال بالإنترنت. قد لا تتوفر بعض الميزات ",
		"network_online_detail": " تمت استعادة الاتصال بالأنترنت. جميع الميزات متوفرة مرة أخرى ",
		"code": " رمز ",
		"read_qrcode": " قراءة رمز التحقق ",
		"join-visio": " انضم إلى الدردشة المرئية ",
		"visio-launched": " تم إطلاق المكالمة ",
		"visio-leaved": " تم الإنسحاب من المكالمة ",
		"start_visio": " مكالمة ",
		"waiting_for_content": " في انتظار المحتوى…",
		"not_belonging_to_any_group": " لا تنتمي إلى أي مجموعة ",
		"no_result_title": " لا توجد نتائج !",
		"no_result_text": " عد لاحقا!",
		"reactions": " ردود الأفعال ",
		"zoom-visio-reload": " إنتهت الدورة على تطبيق zoom ، سيتم غلق الصفحة في : {{timer}}s.",
		"unsopported_navigator_msg1": "أنت تستعمل إصدار متصفح غير متوافق مع منصتنا ، قم بالتبديل إلى google chrome للاستفادة من جميع الميزات. يمكنك تحميل البرنامج عن طريق النقر",
		"here": " هنا ",
		"unsupported_navigator_msg2": " أو على الشعار أدناه ",
		"previous-step": "خطوة سابقة"
	},
	"toasts": {
		"copied-to-clipboard": "تم نسخها!",
		"download_image": " تم التنزيل بنجاح.",
		"errors": {
			"create-basic-error": " حدث خطأ أثناء الإنشاء، يرجى المحاولة مرة أخرى ",
			"error-open-document": " لا يمكن فتح الملف ",
			"cannot_send_message": " لا يمكن فتح الرسالة ",
			"download_error": " غير قادر على تحميل ",
			"share_error": " يتعذر مشاركة الملف ",
			"cannot-copy-to-clipboard": "غير قادر على نسخ البيانات"
		}
	},
	"tooltips": {
		"click-for-open-document": " انقر لفتح الملف ",
		"click-for-view-profil": " انقر لعرض ملف المستخدم ",
		"click-for-access-to-session": " انقر للوصول إلى الدورة ",
		"click-to-view-image": " انقر لرؤية الصورة "
	},
	"treasure_hunts": {
		"already_scan": " تم نسخ رمز التحقق من قبل ",
		"btn_qrcode": " تم نسخ رمز التحقق ",
		"error_scan": " رمز التحقق غير صالح. هل تريد النسخ مرة أخرى؟ ",
		"gaming_default_txt": " ابحث عن رموز التحقق المنتشرة في جميع أنحاء الحدث لكسب النقاط ",
		"go_gaming": " لعب?",
		"scan_read_error": " خطأ في قراءة رمز التحقق ، يرجى إعادة المحاولة مرة أخرى.",
		"success_scan": "شكرا، تم نسخ رمز التحقق",
		"remaining_qrcodes": " رموز التحقق المتبقية : ",
		"title": " الألعاب ",
		"total_qrcodes": " العدد الإجمالي لرموز التحقق :"
	},
	"visios": {
		"creating-visio": " إنشاء مكلمة عن طريق الفيديو...",
		"opening-visio": " الدخول إلى المكالمة",
		"visio-created": " تم إنشاء المكالمة بنجاح ",
		"visio-created-error": " عفوا، يتعذر إنشاء المكالمة، يرجى إعادة المحاولة ",
		"visio-not-available": " عفوا، المكالمة عن طريق الفيديو غير متوفرة في هذا الحدث ",
		"visio-open-error": " عفوا، يتعذر الوصول إلى المكالمة "
	},
	"widgets": {
		"external_link_title": " رابط خارجي "
	},
	"word_cloud": {
		"add_word": " إضافة كلمة ",
		"error-send-word": " عفوا، لا يمكن إرسال الكلمة ",
		"success-send-word": " تم إرسال الكلمة بنجاح "
	}
}
