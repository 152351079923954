import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetAllCustomPages } from "../actions/custom-pages.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { ICustomPage } from "../interfaces/custom-pages.interfaces";
import { checkSameEvent } from "../selectors/custom-pages.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class CustomPagesService {
	customPagesSub: Subscription;

	constructor(
		private SFirestore: FirestoreService,
		private store: Store
	) {}

	/**
	 * Get all custom pages of event
	 * @param eventId
	 * @returns
	 */
	getCustomPagesOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.customPagesSub && !this.customPagesSub.closed) {
					return;
				} else if (!sameEvent && this.customPagesSub && !this.customPagesSub.closed) {
					this.customPagesSub.unsubscribe();
				}

				this.customPagesSub = this.SFirestore.collectionGroupValueChangesDocuments("custom-pages", [
					where("eventId", "==", eventId)
				]).subscribe((customPages: ICustomPage[]) => {
					this.store.dispatch(GetAllCustomPages({ payload: customPages, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initCustomPages"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initCustomPages", payload: true })
								);
							}
						});
				});
			});
	}

	unsubscribeAll() {
		if (this.customPagesSub) {
			this.customPagesSub.unsubscribe();
		}
	}
}
