import { Injectable } from "@angular/core";
import { of, skipWhile, Subscription, switchMap } from "rxjs";
import { DateTime } from "luxon";
import { ILastAccess, ILastAccessOptions } from "../interfaces/last-access.interfaces";
import { TypeLastAccess } from "../enums/type-last-access";
import { Store } from "@ngrx/store";
import { getCurrentUser } from "../selectors/auth.selectors";
import { FirestoreService } from "./firestore.service";
import { limit, orderBy, where } from "@angular/fire/firestore";

@Injectable({
	providedIn: "root"
})
export class LastAccessService {
	chatsSub: Subscription;
	messagesSub: Subscription;
	groupsChats: Subscription;

	constructor(
		private SFirestore: FirestoreService,
		private store: Store
	) {}

	/**
	 * Get chats last access
	 * @param eventId
	 * @returns
	 */
	getChatsLastAccess(eventId: string) {
		return this.store.select(getCurrentUser).pipe(
			skipWhile((user) => !user),
			switchMap((user) => {
				if (!user || !user.uid || !eventId) {
					return of([]);
				}
				return this.SFirestore.collectionGroupValueChangesDocuments("last-access", [
					where("eventId", "==", eventId),
					where("type", "==", TypeLastAccess.CHAT),
					where("userId", "==", user.uid),
					limit(1),
					orderBy("lastAccess", "desc")
				]);
			})
		);
	}

	/**
	 * Create a last access
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @returns
	 */
	createLastAccess(
		eventId: string,
		moduleId: string,
		userId: string,
		type: number,
		options: ILastAccessOptions,
		path: string
	) {
		const newLastAccess: ILastAccess = {
			uid: this.SFirestore.createId(path),
			eventId: eventId,
			moduleId: moduleId,
			lastAccess: DateTime.local().toISO(),
			options: options,
			type: type,
			userId: userId
		};
		return this.SFirestore.setDocument(path + `/${newLastAccess.uid}`, newLastAccess);
	}
}
