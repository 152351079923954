<div class="main-container">
	<div class="content">
		<mat-stepper
			#stepper
			[class.disable-progress-bar]="true"
			[animationDuration]="'600'"
			(selectionChange)="stepChanged($event)"
			[orientation]="registerForm.formSettings.orientation"
		>
			@if (!formLoader()) {
				<form [formGroup]="form">
					<!-- Step 1 (Info) -->
					@for (section of sections; track section) {
						<mat-step [label]="null" (completed)="(false)" [stepControl]="form.get(section.uid)">
							<ng-template matStepLabel>
								{{ section.title }}
							</ng-template>
							@for (item of section.items; track item) {
								<div [formGroupName]="section.uid" class="item-content">
									<!-- Text type fields -->
									@if (item.type === formItemType.TEXT) {
										<div>
											<div
												class="text-data"
												style="white-space: pre-line"
												[innerHTML]="item.value[currentLanguage] | safeHtml"
											></div>
										</div>
									}
									<!-- Input type fields -->
									@if (item.type === formItemType.INPUT) {
										<div>
											<div class="bloc-input">
												<!-- "BASE FIELDS" ('NAME' & 'EMAIL') -->
												@if (
													item.isBaseField &&
													item.inputFieldType !== typeCustomFields.CHECKBOX &&
													item.inputFieldType !== typeCustomFields.IMAGE
												) {
													<ion-item lines="none" mode="md" fill="outline" shape="round">
														<!-- Item name -->
														<ion-label
															mode="md"
															position="stacked"
															class="item-name-label label-color"
														>
															{{ item.label[currentLanguage] }}
															@if (item.required) {
																<span class="required"> * </span>
															}
														</ion-label>
														<!-- Text input -->
														@if (item.inputFieldType === typeCustomFields.TEXT) {
															<ion-input
																[readonly]="
																	isUniqueEditControlHasValue(
																		section,
																		item,
																		currentLanguage
																	)
																"
																autocapitalize="sentences"
																mode="md"
																class="custom-input"
																[ngClass]="
																	mode !== 'register-modal' &&
																	mode !== 'accompanying-form-modal' &&
																	mode !== 'checkin' &&
																	item.isBaseField
																		? 'readonly-input-style'
																		: 'input-color'
																"
																type="text"
																[ngStyle]="{
																	color: form.get(section.uid).get(item.uid)?.errors
																		? '#900C3F'
																		: '#4B4D4D'
																}"
																[placeholder]="
																	STranslate.instant(
																		'edit-profil.placeholders.name_text-info'
																	)
																"
																formControlName="{{ item.uid }}"
															>
															</ion-input>
														}
														<!-- Email input -->
														@if (item.inputFieldType === typeCustomFields.EMAIL) {
															<ion-input
																[readonly]="
																	isUniqueEditControlHasValue(
																		section,
																		item,
																		currentLanguage
																	)
																"
																mode="md"
																class="custom-input"
																[ngClass]="
																	mode !== 'register-modal' &&
																	mode !== 'accompanying-form-modal' &&
																	mode !== 'checkin' &&
																	item.isBaseField
																		? 'readonly-input-style'
																		: 'input-color'
																"
																type="email"
																[readonly]="
																	mode !== 'register-modal' &&
																	mode !== 'accompanying-form-modal' &&
																	mode !== 'checkin' &&
																	item.isBaseField
																"
																[ngStyle]="{
																	color: form.get(section.uid).get(item.uid)?.errors
																		? '#900C3F'
																		: '#4B4D4D'
																}"
																[placeholder]="
																	STranslate.instant(
																		'edit-profil.placeholders.example.email1'
																	)
																"
																formControlName="{{ item.uid }}"
															>
																<span
																	class="float-right"
																	[class.auto-generate-button-spinner]="
																		emailButtonLoader
																	"
																>
																</span>
															</ion-input>
														}
														<!-- Password input -->
														@if (item.inputFieldType === typeCustomFields.PASSWORD) {
															<ion-input
																autocapitalize="sentences"
																mode="md"
																class="custom-input input-color"
																type="password"
																[ngStyle]="{
																	color: form.get(section.uid).get(item.uid)?.errors
																		? '#900C3F'
																		: '#4B4D4D'
																}"
																[placeholder]="
																	item.uid === 'passwordConfirmation'
																		? STranslate.instant(
																				'edit-profil.placeholders.password_confirm_text-info'
																			)
																		: STranslate.instant(
																				'edit-profil.placeholders.password_create_text-info'
																			)
																"
																formControlName="{{ item.uid }}"
															>
															</ion-input>
														}
														<!-- Icons -->
														<form-field-icons
															style="
																position: absolute;
																top: 19px;
																right: 15px;
																z-index: 999;
															"
															[fieldId]="item.uid"
															[form]="form.get(section.uid)"
															[language]="currentLanguage"
															[fieldType]="
																!SForms.isMultiLanguageField(item)
																	? 'singleLanguage'
																	: 'multiLanguage'
															"
															[disabledIcon]="
																isUniqueEditControlHasValue(
																	section,
																	item,
																	currentLanguage
																)
															"
														>
														</form-field-icons>
													</ion-item>
												}
												<!-- Profil picture -->
												@if (
													item.isBaseField && item.inputFieldType === typeCustomFields.IMAGE
												) {
													<div class="profile-update-container">
														<label class="profile-image-label label-color"
															>{{ item.label[currentLanguage] }}
															@if (item.required) {
																<span class="required"> * </span>
															}
														</label>
														<div class="image-upload-preview">
															<img
																[src]="
																	(eventUser && eventUser?.photoUrl) ||
																	(actualImage()?.base64String &&
																		'data:image/' +
																			actualImage()?.format +
																			';base64,' +
																			actualImage()?.base64String) ||
																	'assets/icon/profile.png'
																"
																class="profile-image-preview"
																alt="Profile Image"
															/>
														</div>
														<label class="icon-bloc">
															@if (!actualImage() && !eventUser?.photoUrl) {
																<img
																	matTooltip="{{
																		'edit-profil.placeholders.image_text-info'
																			| translate
																	}}"
																	class="upload-icon-button"
																	src="assets/icon/camera.png"
																	(click)="promptCameraModal(section.uid, item.uid)"
																	alt="Télécharger une image"
																/>
															}
															@if (actualImage() || eventUser?.photoUrl) {
																<mat-icon
																	(click)="deleteProfilPic(item)"
																	style="cursor: pointer"
																	>delete</mat-icon
																>
															}
														</label>
													</div>
												}
												<!-- End of "BASE FIELDS" -->
												<!-- Custom fields -->
												@if (
													!item.isBaseField &&
													item.inputFieldType !== typeCustomFields.IMAGE &&
													item.inputFieldType !== typeCustomFields.FILE &&
													item.inputFieldType !== typeCustomFields.DATE &&
													item.inputFieldType !== typeCustomFields.PASSWORD &&
													item.inputFieldType !== typeCustomFields.PHONE &&
													item.inputFieldType !== typeCustomFields.CHECKBOX &&
													item.inputFieldType !== typeCustomFields.ADDRESS &&
													item.inputFieldType !== typeCustomFields.MULTI_SELECT
												) {
													<ion-item lines="none" mode="md" fill="outline" shape="round">
														<!-- Item name -->
														<ion-label
															mode="md"
															position="stacked"
															class="item-name-label label-color"
														>
															{{ item.label[currentLanguage] }}
															@if (item.required) {
																<span class="required"> * </span>
															}
														</ion-label>
														<!-- Multilanguage text -->
														@if (item.inputFieldType === typeCustomFields.TEXT) {
															<ion-input
																[readonly]="
																	isUniqueEditControlHasValue(
																		section,
																		item,
																		currentLanguage
																	)
																"
																autocapitalize="sentences"
																mode="md"
																class="custom-input input-color"
																type="text"
																[ngStyle]="{
																	color: form
																		.get(section.uid)
																		.get(item.uid + '_' + currentLanguage)?.errors
																		? '#900C3F'
																		: '#4B4D4D'
																}"
																[placeholder]="
																	item.uid === 'email'
																		? STranslate.instant(
																				'edit-profil.placeholders.example.email1'
																			)
																		: STranslate.instant(
																				'edit-profil.placeholders.simple_text-info'
																			)
																"
																formControlName="{{ item.uid + '_' + currentLanguage }}"
															>
															</ion-input>
														}
														<!-- Single TExt fields (numeric, email, etc..) fields -->
														@if (
															item.inputFieldType === typeCustomFields.URL ||
															item.inputFieldType === typeCustomFields.NUMERIC ||
															item.inputFieldType === typeCustomFields.EMAIL
														) {
															<ion-input
																[readonly]="
																	isUniqueEditControlHasValue(
																		section,
																		item,
																		currentLanguage
																	)
																"
																autocapitalize="sentences"
																mode="md"
																class="custom-input input-color"
																[type]="typeFieldDataString[item.inputFieldType]"
																[ngStyle]="{
																	color: form.get(section.uid).get(item.uid)?.errors
																		? '#900C3F'
																		: '#4B4D4D'
																}"
																style="
																	width: 90% !important;
																	margin-left: 0;
																	margin-right: auto;
																"
																[placeholder]="
																	item.inputFieldType === typeCustomFields.EMAIL
																		? STranslate.instant(
																				'edit-profil.placeholders.example.email1'
																			)
																		: item.inputFieldType ===
																			  typeCustomFields.NUMERIC
																			? STranslate.instant(
																					'edit-profil.placeholders.numeric_text-info'
																				)
																			: STranslate.instant(
																					'edit-profil.placeholders.url_text-info'
																				)
																"
																formControlName="{{ item.uid }}"
															>
															</ion-input>
														}
														<!-- Icons -->
														<form-field-icons
															style="
																position: absolute;
																top: 22px;
																right: 15px;
																z-index: 999;
															"
															[fieldId]="item.uid"
															[form]="form.get(section.uid)"
															[language]="currentLanguage"
															[fieldType]="
																!SForms.isMultiLanguageField(item)
																	? 'singleLanguage'
																	: 'multiLanguage'
															"
															[disabledIcon]="
																isUniqueEditControlHasValue(
																	section,
																	item,
																	currentLanguage
																)
															"
														>
														</form-field-icons>
														<!-- Select fields -->
														@if (
															getFullOrPartOfComputedCustomField(item.uid, "both");
															as computedCus
														) {
															@if (item.inputFieldType === typeCustomFields.SELECT) {
																<ion-select
																	[attr.disabled]="
																		isUniqueEditControlHasValue(
																			section,
																			item,
																			currentLanguage
																		)
																	"
																	[readonly]="
																		isUniqueEditControlHasValue(
																			section,
																			item,
																			currentLanguage
																		)
																	"
																	(ionChange)="
																		onCustomFieldOptionChange(
																			$any(computedCus),
																			$event
																		)
																	"
																	#myIonSelect
																	mode="md"
																	class="custom-input input-color select-field"
																	type="text"
																	[ngStyle]="{
																		color:
																			form
																				.get(section.uid)
																				?.get(
																					item.uid + '_' + currentLanguage
																				) &&
																			form
																				.get(section.uid)
																				?.get(item.uid + '_' + currentLanguage)
																				?.errors
																				? '#900C3F'
																				: '#4B4D4D'
																	}"
																	placeholder="{{
																		'edit-profil.placeholders.select_text-info'
																			| translate
																	}}"
																	formControlName="{{
																		item.uid + '_' + currentLanguage
																	}}"
																	[selectedText]="
																		form
																			.get(section.uid)
																			?.get(item.uid + '_' + currentLanguage)?.[
																			currentLanguage
																		]
																	"
																	[compareWith]="compareWithFn"
																>
																	@for (
																		option of $any(item.value).baseSettings.options;
																		track option;
																		let optIndex = $index
																	) {
																		<ion-select-option
																			[value]="option"
																			disabled="{{
																				!eventUser &&
																					$any(item.value)?.baseSettings
																						.enableSelectLimit &&
																					!canDisableOptionOptionLimit(
																						item.uid,
																						optIndex
																					)
																			}}"
																		>
																			<span>{{ option[currentLanguage] }}</span>
																			&nbsp;&nbsp;
																			@if (
																				myIonSelect.ionDismiss &&
																				realTimeCountValues &&
																				$any(item.value)?.baseSettings
																					.enableSelectLimit
																			) {
																				<small class="number-limit">{{
																					"(" +
																						getCountValue(item, optIndex) +
																						" / " +
																						$any(
																							getFullOrPartOfComputedCustomField(
																								item.uid,
																								"base"
																							)
																						)?.optionsLimit?.[optIndex]?.[
																							event.language +
																								"_Limit_" +
																								optIndex
																						] +
																						")"
																				}}</small>
																			}
																		</ion-select-option>
																	}
																</ion-select>
															}
														}
														<!-- "Multiple text" field type -->
														@if (item.inputFieldType === typeCustomFields.MULTI_TEXT) {
															<ion-input
																[readonly]="
																	isUniqueEditControlHasValue(
																		section,
																		item,
																		currentLanguage
																	)
																"
																mode="md"
																class="custom-input input-color"
																type="text"
																[ngStyle]="{
																	color: form
																		.get(section.uid)
																		.get(item.uid + '_' + currentLanguage)?.errors
																		? '#900C3F'
																		: '#4B4D4D'
																}"
																formControlName="{{ item.uid + '_' + currentLanguage }}"
																(keypress)="
																	onEnter(
																		$event,
																		getMultiTextInputIndex(item.uid),
																		item.label[currentLanguage],
																		item.uid
																	)
																"
																(focusout)="reinitializeInputStatus($event)"
																id="{{ item.label[currentLanguage] }}"
																placeholder="{{
																	'edit-profil.placeholders.multiple_text-info'
																		| translate
																}}"
															>
															</ion-input>
														}
														<!-- Country field type -->
														@if (item.inputFieldType === typeCustomFields.COUNTRY) {
															<ion-input
																[disabled]="
																	isUniqueEditControlHasValue(
																		section,
																		item,
																		currentLanguage
																	)
																"
																(click)="
																	isUniqueEditControlHasValue(
																		section,
																		item,
																		currentLanguage
																	)
																		? null
																		: showCoutryModal(
																				$any(item.value),
																				section.uid,
																				$event
																			)
																"
																autocapitalize="sentences"
																mode="md"
																class="custom-input input-color"
																type="text"
																[ngStyle]="{
																	color: form.get(section.uid).get(item.uid)?.errors
																		? '#900C3F'
																		: '#4B4D4D'
																}"
																placeholder="{{
																	'edit-profil.placeholders.simple_text-info'
																		| translate
																}}"
																[formControlName]="item.uid"
															>
															</ion-input>
														}
													</ion-item>
												}
												<!-- "ADDRESS" field type -->
												@if (item.inputFieldType === typeCustomFields.ADDRESS) {
													<mat-form-field
														class="mat-form-field-simple-text"
														floatLabel="always"
														[style.width]="isMobile ? '100%' : '50%'"
													>
														<mat-label class="label-color">
															{{ item.label[currentLanguage] }}
														</mat-label>
														<input
															#addressInput
															formControlName="{{ item.uid }}"
															matInput
															style="
																width: 90% !important;
																margin-left: 0;
																margin-right: auto;
															"
															[matAutocomplete]="auto"
															class="custom-input addressInput"
															type="text"
															(keyup)="onKeyupAddress(item.uid, addressInput?.value)"
															[ngStyle]="{
																color: form.get(section.uid).get(item.uid)?.errors
																	? '#900C3F'
																	: '#4B4D4D'
															}"
															placeholder="{{ 'placeholders.address_plc' | translate }}"
														/>
														<!-- Icons -->
														@if (!getCustomFieldAddressState(item.uid)?.loading) {
															<form-field-icons
																style="
																	position: absolute;
																	top: 22px;
																	right: 0px;
																	z-index: 999;
																"
																[fieldId]="item.uid"
																[form]="form.get(section.uid)"
																[language]="currentLanguage"
																[fieldType]="
																	!SForms.isMultiLanguageField(item)
																		? 'singleLanguage'
																		: 'multiLanguage'
																"
																[disabledIcon]="
																	isUniqueEditControlHasValue(
																		section,
																		item,
																		currentLanguage
																	)
																"
															>
															</form-field-icons>
														}
														<span
															class="address-loader-spinner"
															[class.form-button-spinner]="
																getCustomFieldAddressState(item.uid)?.loading
															"
														></span>
														<mat-autocomplete
															#auto="matAutocomplete"
															style="position: relative; top: -10px; left: 5px"
															[displayWith]="displayFn"
														>
															@for (
																prediction of getPlacePredictionsById(
																	$any(item.value).baseSettings.uid
																);
																track prediction
															) {
																<mat-option
																	(optionSelections)="
																		displayFn($event);
																		getCustomFieldAddressState(
																			$any(item.value).baseSettings.uid
																		).loading = false
																	"
																	(click)="
																		displayFn($event);
																		getCustomFieldAddressState(
																			$any(item.value).baseSettings.uid
																		).loading = false
																	"
																	[value]="prediction.description"
																>
																	{{ prediction.description }}
																</mat-option>
															}
														</mat-autocomplete>
													</mat-form-field>
												}
												<!-- multi-select fields -->
												@if (
													getFullOrPartOfComputedCustomField(item.uid, "both");
													as computedCus
												) {
													<ng-container
														style="
															width: 100%;
															display: flex;
															justify-content: center;
															align-items: center;
															margin: 16px;
														"
													>
														@if (item.inputFieldType === typeCustomFields.MULTI_SELECT) {
															<mat-form-field
																class="mat-form-field"
																[style.width]="isMobile ? '100%' : '50%'"
																lines="none"
															>
																<mat-label class="label-color">{{
																	item.label[currentLanguage]
																}}</mat-label>
																<mat-select
																	multiple
																	#myMultiSelect
																	[compareWith]="compareWithFn"
																	(selectionChange)="
																		onCustomFieldOptionChange(
																			$any(computedCus),
																			$event
																		)
																	"
																	placeholder="{{
																		'edit-profil.placeholders.select_text-info'
																			| translate
																	}}"
																	formControlName="{{
																		item.uid + '_' + currentLanguage
																	}}"
																	shape="round"
																>
																	@for (
																		option of $any(item.value).baseSettings.options;
																		track trackOptionsByFn($index, option)
																	) {
																		<mat-option
																			[disabled]="
																				item.inputFieldType ===
																					typeCustomFields.MULTI_SELECT &&
																				$any(item.value)?.baseSettings
																					.enableChoiceLimit &&
																				isOptionDisabled(
																					section.uid,
																					item.uid,
																					option,
																					$any(item.value)?.baseSettings
																						.choiceLimit
																				)
																			"
																			[value]="option"
																		>
																			{{ option[currentLanguage] }}
																		</mat-option>
																	}
																</mat-select>
																@if (myMultiSelect.value) {
																	<ion-note style="font-style: italic; opacity: 0.7">
																		{{
																			"texts.x_selected_items"
																				| translate
																					: {
																							count: myMultiSelect.value
																								.length
																					  }
																		}}
																	</ion-note>
																}
															</mat-form-field>
														}
													</ng-container>
												}
												<!-- Date Field type -->
												@if (item.inputFieldType === typeCustomFields.DATE) {
													<mat-form-field
														class="mat-form-field-simple-text"
														labelFloat="always"
														[style.width]="!isMobile ? '50%' : '100%'"
													>
														<mat-label class="label-color"
															>{{ item.label[currentLanguage] }}
														</mat-label>
														<input
															matInput
															readonly="readonly"
															style="
																width: 90% !important;
																margin-left: 0;
																margin-right: auto;
															"
															[matDatepicker]="picker"
															[formControlName]="item.uid"
														/>
														<mat-datepicker-toggle matSuffix [for]="picker">
														</mat-datepicker-toggle>
														<mat-datepicker #picker></mat-datepicker>
													</mat-form-field>
												}
												<!-- "Phone number" field type -->
												@if (item.inputFieldType === typeCustomFields.PHONE) {
													<div class="custom-field-container tel-input-container">
														<ion-label class="item-has-focus label-color">
															{{ item.label[currentLanguage] }}
															@if (item.required) {
																<span class="required"> * </span>
															}
														</ion-label>
														<ngx-intl-tel-input
															style="
																width: 90% !important;
																margin-left: 0;
																margin-right: auto;
															"
															class="input"
															[cssClass]="'tel-input'"
															[preferredCountries]="preferedCountries"
															[enableAutoCountrySelect]="false"
															[enablePlaceholder]="true"
															[customPlaceholder]="
																STranslate.instant(
																	'placeholders.phone_number_placeholder'
																)
															"
															[enableSearch]="true"
															[searchCountryFlag]="true"
															[searchCountryField]="[
																SearchCountryField.Iso2,
																SearchCountryField.Name
															]"
															[disabled]="
																!isUniqueEditControlHasValue(
																	section,
																	item,
																	currentLanguage
																)
															"
															[selectFirstCountry]="false"
															[selectedCountryISO]="
																itemsValues[item.uid] &&
																itemsValues[item.uid]?.field &&
																itemsValues[item.uid]?.field?.phoneNumber &&
																itemsValues[item.uid]?.field?.phoneNumber?.countryCode
																	? itemsValues[
																			item.uid
																		]?.field?.phoneNumber?.countryCode?.toLowerCase()
																	: CountryISO[currentLanguage.slice(2)]
															"
															[maxLength]="15"
															[phoneValidation]="true"
															[inputId]="item.uid"
															[enablePlaceholder]="true"
															name="phone"
															formControlName="{{ item.uid }}"
														></ngx-intl-tel-input>
														<form-field-icons
															style="
																position: absolute;
																top: 25px;
																right: 15px;
																z-index: 999;
															"
															[fieldId]="item.uid"
															[form]="form.get(section.uid)"
															[language]="currentLanguage"
															[fieldType]="
																!SForms.isMultiLanguageField(item)
																	? 'singleLanguage'
																	: 'multiLanguage'
															"
															[disabledIcon]="
																isUniqueEditControlHasValue(
																	section,
																	item,
																	currentLanguage
																)
															"
														>
														</form-field-icons>
													</div>
												}
												<!-- multi text view -->
												@if (
													!item.isBaseField &&
													item.inputFieldType === typeCustomFields.MULTI_TEXT
												) {
													<div class="center-item">
														<!-- On Enter Error MSG -->
														@if (
															multipleTextInputError[
																getMultiTextInputErrorIndex(item.uid)
															]?.msg !== ""
														) {
															<div class="form-bloc-error">
																<p class="error-label-text">
																	{{
																		multipleTextInputError[
																			getMultiTextInputErrorIndex(item.uid)
																		]?.msg
																	}}
																</p>
															</div>
														}
														<!-- Ion chip item -->
														@if (
															multipleTextValues[getMultiTextInputIndex(item.uid)]
																?.multiLanguageTextArray &&
															multipleTextValues[getMultiTextInputIndex(item.uid)]
																?.multiLanguageTextArray[currentLanguage].length > 0
														) {
															<div
																class="filter-group padding-10 margin-b-0 multiple-text-input"
																width="50%"
															>
																@for (
																	value of multipleTextValues[
																		getMultiTextInputIndex(item.uid)
																	]?.multiLanguageTextArray[currentLanguage];
																	track value
																) {
																	<span>
																		<ion-chip>
																			<!-- <ion-icon name="pin" color="primary"></ion-icon> -->
																			<ion-label class="multiple-text-value">{{
																				value
																			}}</ion-label>
																			@if (
																				(item.uniqueEdit &&
																					this.multipleTextValues[
																						this.getMultiTextInputIndex(
																							item.uid
																						)
																					].multiLanguageTextArray[
																						currentLanguage
																					].length === 0) ||
																				!item.uniqueEdit
																			) {
																				<ion-icon
																					name="close-circle"
																					(click)="
																						removeQueryItem(
																							item.uid,
																							value,
																							99,
																							currentLanguage
																						)
																					"
																				></ion-icon>
																			}
																		</ion-chip>
																	</span>
																}
																<br />
															</div>
														}
													</div>
												}
												<!-- Image field type -->
												@if (
													!item.isBaseField && item.inputFieldType === typeCustomFields.IMAGE
												) {
													<div [style.width]="isMobile ? '100%' : '50%'">
														<input
															[formControlName]="item.uid"
															type="file"
															[attr.id]="section.uid + '_' + item.uid"
															style="
																width: 90% !important;
																margin-left: 0;
																margin-right: auto;
															"
															name="image-input"
															class="image-input"
															accept="image/*"
															(change)="
																loadCustomFieldFileImage(
																	$event,
																	$any(item.value),
																	section.uid
																)
															"
														/>
														<label class="image-input-label">
															<div class="image-input-bloc">
																<label class="custom-field-name">
																	{{ item.label[currentLanguage] }}
																	@if (item.required) {
																		<span class="required"> * </span>
																	}
																</label>
																@if (
																	($any(item.value).fieldDatas &&
																		$any(item.value).fieldDatas.field?.image?.url &&
																		$any(item.value).fieldDatas.field?.image
																			?.url !== "") ||
																	isValidUrl
																) {
																	<a
																		target="_blank"
																		(click)="
																			openDocumentOrImage(
																				$any(item.value).fieldDatas?.field
																					?.image
																			)
																		"
																		class="input-image-link"
																	>
																		<span>
																			<input
																				style="cursor: pointer"
																				[formControlName]="item.uid + '_URL'"
																				[placeholder]="
																					($any(item.value).fieldDatas &&
																						$any(item.value).fieldDatas
																							.field?.image?.url &&
																						$any(item.value).fieldDatas
																							.field.image.url !== '') ||
																					isValidUrl
																						? getCorrespondingItemFileOrImage(
																								item.uid
																							)?.name
																						: STranslate.instant(
																								'placeholders.link-or-import-image'
																							)
																				"
																				type="text"
																				class="input-title"
																				slot="end"
																				(input)="
																					getImageByUrl(
																						$event,
																						$any(item.value),
																						section.uid
																					)
																				"
																				[disabled]="
																					isValidUrl ||
																					($any(item.value).fieldDatas.field
																						.image.url &&
																						$any(item.value).fieldDatas
																							.field.image.url !== '')
																				"
																			/>
																		</span>
																	</a>
																}
																@if (
																	!$any(item.value).fieldDatas?.field?.image?.url ||
																	$any(item.value).fieldDatas.field?.image?.url === ""
																) {
																	<input
																		class="custom-input-placeholder"
																		[formControlName]="item.uid + '_URL'"
																		[placeholder]="
																			STranslate.instant(
																				'placeholders.link-or-import-image'
																			)
																		"
																		type="url"
																		class="input-title"
																		slot="end"
																		(input)="
																			getImageByUrl(
																				$event,
																				$any(item.value),
																				section.uid
																			)
																		"
																		[disabled]="
																			isValidUrl ||
																			($any(item.value).fieldDatas?.field?.image
																				?.url &&
																				$any(item.value).fieldDatas?.field
																					?.image?.url !== '')
																		"
																	/>
																}
															</div>
															@if (
																($any(item.value).fieldDatas &&
																	$any(item.value).fieldDatas.field?.image?.url &&
																	$any(item.value)?.fieldDatas?.field?.image?.url !==
																		"") ||
																isValidUrl
															) {
																<label
																	class="file-clear-icon"
																	(click)="
																		deleteCustomFieldFileOrImage(
																			$any(item.value),
																			section.uid
																		)
																	"
																>
																	<img
																		src="assets/images/trash.png"
																		class="check-icon"
																		slot="end"
																		color="danger"
																	/>
																</label>
															}
															<label
																[attr.for]="section.uid + '_' + item.uid"
																class="icon-bloc"
															>
																<img
																	class="input-icon"
																	lazy="true"
																	slot="end"
																	src="/assets/images/upload.png"
																/>
															</label>
														</label>
													</div>
												}
												<!-- "File" field type -->
												@if (item.inputFieldType === typeCustomFields.FILE) {
													<div [style.width]="isMobile ? '100%' : '50%'">
														<input
															class="custom-input-placeholder"
															[formControlName]="item.uid"
															type="file"
															[attr.id]="section.uid + '_' + item.uid"
															style="
																width: 90% !important;
																margin-left: 0;
																margin-right: auto;
															"
															name="file-input"
															class="file-input"
															accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.mp3,audio/*,image/*"
															(change)="
																loadCustomFieldFileImage(
																	$event,
																	$any(item.value),
																	section.uid
																)
															"
														/>
														<label class="file-input-label">
															<div class="bloc-label">
																<label class="custom-field-name">
																	{{ item.label[currentLanguage] }}
																	@if (item.required) {
																		<span class="required"> * </span>
																	}
																</label>
																<label
																	class="input-title"
																	style="color: rgb(109, 109, 109)"
																	slot="end"
																>
																	@if (
																		$any(item.value).fieldDatas &&
																		$any(item.value).fieldDatas?.field?.file &&
																		$any(item.value).fieldDatas?.field.file
																			?.name !== ""
																	) {
																		<span
																			class="file-name-clickable"
																			(click)="
																				openDocumentOrImage(
																					$any(item.value).fieldDatas?.field
																						?.file
																						? $any(item.value).fieldDatas
																								.field?.file
																						: null
																				)
																			"
																		>
																			{{
																				$any(item.value).fieldDatas.field?.file
																					?.name
																			}}</span
																		>
																	}
																	@if (
																		($any(item.value).fieldDatas &&
																			$any(item.value).fieldDatas?.field?.file
																				?.name === "") ||
																		$any(item.value).fieldDatas?.field?.file
																			?.name === null ||
																		$any(item.value).fieldDatas?.field?.file
																			?.name === undefined
																	) {
																		<span class="file-name-placeholder">
																			{{
																				"edit-profil.placeholders.file_text-info"
																					| translate
																			}}
																		</span>
																	}
																</label>
															</div>
															@if (
																!checkFilePresenceInInput(item.uid) &&
																!$any(item.value)?.fieldDatas?.field?.file?.url
															) {
																<label
																	[attr.for]="section.uid + '_' + item.uid"
																	class="icon-bloc"
																>
																	<img
																		class="input-icon"
																		lazy="true"
																		slot="end"
																		src="/assets/images/file-upload.png"
																	/>
																</label>
															}
															@if (
																($any(item.value).fieldDatas &&
																	$any(item.value).fieldDatas?.field?.file?.url &&
																	$any(item.value).fieldDatas.field.file.url !==
																		"") ||
																checkFilePresenceInInput(item.uid)
															) {
																<label
																	class="file-clear-icon"
																	(click)="
																		deleteCustomFieldFileOrImage(
																			$any(item.value),
																			section.uid
																		)
																	"
																>
																	<img
																		src="assets/images/trash.png"
																		class="check-icon"
																		slot="end"
																		color="danger"
																	/>
																</label>
															}
														</label>
													</div>

													<!-- Error file size limit -->
													@if (invalidSizeImg[$any(item.value).baseSettings.uid]?.state) {
														<span
															style="
																text-align: center;
																font-size: 0.7rem;
																font-style: italic;
																color: rgb(176, 48, 48);
															"
														>
															{{
																"register.validator-error-msg.file.file_size"
																	| translate
																		: {
																				limit:
																					MAX_FILE_SIZE / 1000000
																					| number: "1.0-0"
																		  }
															}}
														</span>
													}
												}
												<!-- "CHECKBOX" field type -->
												@if (item.inputFieldType === typeCustomFields.CHECKBOX) {
													<div
														style="
															margin: 15px 0 5px 0;
															display: flex;
															justify-content: center;
															align-items: center;
														"
														[style.width]="isMobile ? '100%' : '50%'"
													>
														<ion-checkbox
															style="min-width: 25px"
															[formControlName]="item.uid"
															[style.--background-checked]="
																event.styling.contentTextColor
															"
															[style.--border-color-checked]="
																event.styling.contentTextColor
															"
															mode="ios"
														>
														</ion-checkbox>
														@if (!item.isBaseField) {
															<ion-label
																for="{{ item.uid }}"
																class="p-l-10"
																style="padding: 0 10px"
																[style.color]="event.styling.contentTextColor"
															>
																{{ item.label[currentLanguage] }}&nbsp;
																@if (item.required) {
																	<span class="required"> * </span>
																}
															</ion-label>
														}
														@if (item.isBaseField) {
															<ion-label
																class="p-l-10"
																[style.color]="event.styling.contentTextColor"
															>
																{{ "login.accept_term" | translate }}&nbsp;
																<a
																	class="conditions-class"
																	ion-button
																	clear
																	icon-only
																	(click)="openModalTermsOrPrivacy('terms')"
																	>{{ "login.terms_of_use" | translate }}</a
																>
																{{ "labels.and" | translate }}&nbsp;
																<a
																	class="conditions-class"
																	ion-button
																	clear
																	icon-only
																	(click)="openModalTermsOrPrivacy('privacy')"
																	>{{ "login.privacy_txt" | translate }}</a
																>
																@if (item.required) {
																	<span class="required"> * </span>
																}
															</ion-label>
														}
													</div>
												}
												<!-- Email auto generating -->
												@if (
													item.inputFieldType === typeCustomFields.EMAIL &&
													item.isBaseField &&
													registerForm.formSettings.enableAnonymousEmails &&
													item.enableAutoGenerateEmails &&
													!eventUser
												) {
													<ion-note
														style="
															text-align: center;
															margin: 6px;
															cursor: pointer;
															display: flex;
															justify-content: center;
														"
														(click)="generateRandomEmail()"
													>
														<!-- {{ "buttons.generate-email-address" | translate }} -->
														<a class="text">{{
															"buttons.generate-random-email-address" | translate
														}}</a>
														<img
															style="width: 25px; height: 25px; margin-left: 5px"
															src="assets/images/magic-wand.png"
															alt="{{ 'buttons.generate-email' | translate }}"
														/>
													</ion-note>
												}
												<!-- Errors -->
												<ion-note style="text-align: center; width: 50%" slot="error">
													<form-field-errors
														[field]="item"
														[form]="form.get(section.uid)"
														[language]="currentLanguage"
														[fieldType]="
															!SForms.isMultiLanguageField(item)
																? 'singleLanguage'
																: 'multiLanguage'
														"
													>
													</form-field-errors>
												</ion-note>
												<!-- Item description -->
												@if (item.showDescription) {
													<ion-label class="field-description">
														<p
															[innerHTML]="item.description[currentLanguage] | safeHtml"
														></p>
													</ion-label>
												}
											</div>
										</div>
									}
									<!-- Module type fields -->
									@if (
										item.type === formItemType.MODULE &&
										(!eventUser || !eventUser?.hasFilledRegisterForm)
									) {
										@if (modulesByUid()[$any(item.value)?.uid]; as registerModule) {
											@if (
												schedulesOfSpecificModule()[$any(item.value)?.uid];
												as registerSessionItems
											) {
												@if (
													item.type === formItemType.MODULE &&
													$any(item.value)?.uid &&
													registerModule
												) {
													<div
														style="
															width: 100%;
															display: flex;
															flex-direction: column;
															align-items: center;
															justify-content: center;
														"
													>
														@if (item.showDescription) {
															<ion-label class="field-description">
																<p
																	[innerHTML]="
																		item.description[currentLanguage] | safeHtml
																	"
																></p>
																@if (item.required) {
																	<span
																		class="required tw-text-xs tw-opacity-55 !tw-font-normal tw-italic"
																	>
																		({{ "labels.mandatory" | translate }})
																	</span>
																}
															</ion-label>
														}
														<cdk-virtual-scroll-viewport
															[class.error-register-schedule]="
																registeredDatasForFieldErrors?.[registerModule.uid]
															"
															[itemSize]="70"
															minBufferPx="280"
															maxBufferPx="560"
															[style.width]="isMobile ? '100%' : '50%'"
															[style.height]="
																registerSessionItems && registerSessionItems.length <= 4
																	? registerSessionItems.length * 70 + 'px'
																	: '280px'
															"
														>
															<div
																*cdkVirtualFor="
																	let session of registerSessionItems;
																	cdkVirtualForOf: cdkVirtuaTrackByFn
																"
																style="
																	width: 100%;
																	height: 70px;
																	display: flex;
																	flex-direction: row;
																	align-items: center;
																	justify-content: space-between;
																	padding: 0 25px;
																"
																[style.opacity]="
																	myCheckbox &&
																	!myCheckbox.checked &&
																	schedulesRegisterCheckboxState()?.[item.uid]?.[
																		session.uid
																	]?.['disabled']
																		? 0.3
																		: 1
																"
															>
																<div
																	style="
																		width: calc(100% - 70px);
																		height: 100%;
																		display: flex;
																		flex-direction: column;
																		align-items: flex-start;
																		justify-content: center;
																	"
																>
																	<div
																		style="
																			width: 100%;
																			height: fit-content;
																			display: flex;
																			flex-direction: row;
																			align-items: center;
																			justify-content: flex-start;
																		"
																	>
																		<ion-icon
																			name="calendar-outline"
																			slot="start"
																			style="margin-right: 10px"
																		></ion-icon>
																		<ion-label
																			style="
																				white-space: nowrap;
																				overflow: hidden;
																				text-overflow: ellipsis;
																				width: 100%;
																			"
																			>{{
																				session.name[currentLanguage]
																			}}</ion-label
																		>
																	</div>
																	<span
																		style="
																			font-size: small;
																			font-weight: bold;
																			margin-left: 25px;
																			font-style: italic;
																		"
																		>{{
																			SUtility.getPartOfDate(
																				event,
																				null,
																				session.startDate,
																				"short"
																			)
																		}}
																		{{
																			registerModule?.options?.canHideSchedules &&
																			!session.hideSchedule
																				? " - " +
																					SUtility.getPartOfDate(
																						event,
																						null,
																						session.startDate,
																						"time"
																					)
																				: ""
																		}}
																		{{
																			session.endDate &&
																			registerModule?.options?.canHideSchedules &&
																			!session.hideSchedule
																				? SUtility.getPartOfDate(
																						event,
																						null,
																						session.endDate,
																						"time"
																					)
																				: ""
																		}}
																	</span>
																	@if (
																		registerModule.type === typeModule.SCHEDULE &&
																		session.attendeesLimit >= 0
																	) {
																		<span
																			style="
																				font-size: small;
																				font-weight: bold;
																				margin-left: 25px;
																				font-style: italic;
																			"
																		>
																			({{ session.registeredUsers.length }}/{{
																				session.attendeesLimit
																			}})
																		</span>
																	}
																</div>
																<ion-checkbox
																	#myCheckbox
																	slot="end"
																	(click)="onManualClick = true"
																	[checked]="
																		schedulesRegisterCheckboxState()?.[item.uid]?.[
																			session.uid
																		]?.['checked']
																	"
																	(ionChange)="
																		registerOrUnregisterToItem(
																			item,
																			session,
																			registerModule.type,
																			$event
																		);
																		setCurrentRegisterScheduleItemState(
																			item,
																			session,
																			registerModule.type,
																			$event
																		)
																	"
																	[disabled]="
																		myCheckbox &&
																		!myCheckbox.checked &&
																		schedulesRegisterCheckboxState()?.[item.uid]?.[
																			session.uid
																		]?.['disabled']
																	"
																></ion-checkbox>
															</div>
														</cdk-virtual-scroll-viewport>
													</div>
												}
											}
										}
									}
								</div>
							}
							<div class="reset-password">
								@if (shouldShowResetPasswordText(section, $any(mode))) {
									<p class="to-login-message" style="text-align: center; cursor: pointer">
										<a class="forgot-password link" (click)="recoveryPassword()">{{
											"login.forgot_pass" | translate
										}}</a>
									</p>
								}
							</div>
							<div class="error-msg">
								<!-- Error messages -->
								@if (messages?.errorMsg !== "") {
									<ion-row>
										<ion-col
											size-sm="12"
											size-md="6"
											size-lg="6"
											push-md="3"
											push-lg="3"
											style="padding-left: 5px !important; padding-right: 5px !important"
										>
											<p class="form-msg-text-error">{{ messages?.errorMsg }}</p>
										</ion-col>
									</ion-row>
								}
								<!-- Warning messages -->
								@if (messages?.warningMsg !== "") {
									<ion-row>
										<ion-col
											size-sm="12"
											size-md="6"
											size-lg="6"
											push-md="3"
											push-lg="3"
											style="padding-left: 5px !important; padding-right: 5px !important"
										>
											<p class="form-msg-text-warning">{{ messages?.warningMsg }}</p>
										</ion-col>
									</ion-row>
								}
								<!-- Success messages -->
								@if (messages?.successMsg !== "") {
									<ion-row>
										<ion-col
											size-sm="12"
											size-md="6"
											size-lg="6"
											push-md="3"
											push-lg="3"
											style="padding-left: 5px !important; padding-right: 5px !important"
										>
											<p class="form-msg-text-success">{{ messages?.successMsg }}</p>
										</ion-col>
									</ion-row>
								}
							</div>
							<!-- Actions buttons -->
							<div [ngClass]="actualStep === 0 ? 'step-action-center' : 'step-action-arround'">
								<!-- Previous step button -->
								@if (actualStep > 0) {
									<ion-button
										(click)="onPreviousStep()"
										shape="round"
										class="btn-default access-btn"
										[style.color]="event.styling.btnTextColor"
										[style.--background]="event.styling.btnBgColor"
										[attr.disabled]="loader"
									>
										@if (!buttonLoader) {
											<span>{{ "register.previous_step" | translate }}</span>
										}
										@if (buttonLoader) {
											<ion-spinner name="crescent" color="light"></ion-spinner>
										}
									</ion-button>
								}
								<!-- Next step button -->
								@if (
									(nextSectionId && nextSectionId !== "submitSection") ||
									(section.defaultNextSectionId &&
										section.defaultNextSectionId !== "submitSection") ||
									(mode === "register-modal" && actualStep < sections.length - 1)
								) {
									<ion-button
										[hidden]="mode !== 'register-modal' && nextSectionId === 'submitSection'"
										(click)="onNextStep(section)"
										shape="round"
										class="btn-default access-btn"
										[attr.disabled]="
											!form.get(section.uid).disabled &&
											(loader ||
												!form.get(section.uid).valid ||
												!isProfilPictureValid ||
												lockNextButton ||
												!isScheduleItemFormRequiredAndHasValue(section) ||
												errorsInFiles())
										"
										[style.color]="event.styling.btnTextColor"
										[style.--background]="event.styling.btnBgColor"
									>
										@if (!loader) {
											<span>{{ "register.next_step" | translate }}</span>
										}
										@if (loader) {
											<ion-spinner name="crescent" color="light"></ion-spinner>
										}
									</ion-button>
								}
								<!-- Validate button for 'register' & 'checkin' modes -->
								@if (
									actualStep === sections.length - 1 ||
									(nextSectionId === "submitSection" &&
										mode !== "register-modal" &&
										mode !== "accompanying-form-modal" &&
										mode !== "checkin") ||
									(mode === "fill-form-modal" &&
										(actualStep === sections.length - 1 ||
											nextSectionId === "submitSection" ||
											section.defaultNextSectionId === "submitSection")) ||
									((mode === "accompanying-form-modal" || mode === "checkin") &&
										(actualStep === sections.length - 1 ||
											nextSectionId === "submitSection" ||
											section.defaultNextSectionId === "submitSection"))
								) {
									<ion-button
										(click)="onValidate()"
										shape="round"
										class="btn-default access-btn"
										[style.--background]="registerForm.formContent.textColor"
										[attr.disabled]="canNotSubmitForm()"
									>
										@if (!loader) {
											<span>{{ "buttons.validate" | translate }}</span>
										}
										@if (loader) {
											<ion-spinner name="crescent" color="light"></ion-spinner>
										}
									</ion-button>
								}
								<!-- User already has an account -->
								@if (mode === "register-modal" && event.settings.allowSwitchForm && actualStep === 0) {
									<p class="to-login-message" [style.color]="event.styling.contentTextColor">
										{{ "register.user_already_has_an_account" | translate }}
										<a class="link" (click)="openLogin()">{{
											"login.access_your_account" | translate
										}}</a>
									</p>
								}
							</div>
							<div class="reset-password">
								@if (shouldShowResetPasswordText(section, $any(mode))) {
									<p class="to-login-message" style="text-align: center; cursor: pointer">
										<a class="forgot-password link" (click)="recoveryPassword()">{{
											"login.forgot_pass" | translate
										}}</a>
									</p>
								}
							</div>
						</mat-step>
					}
				</form>
			}
		</mat-stepper>
	</div>
</div>
