<ng-container class="tag-container">
	@for (computedCustomField of customFields; track computedCustomField.baseSettings.uid) {
		<p
			[hidden]="!computedCustomField.moduleSettings.canBeTag"
			class="tags-info"
			[style.font-size]="fontSize > 0 ? fontSize + 'px' : '16px'"
			[style.height]="!isMobile && customFields.length > 2 ? '20px' : 'auto'"
			[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
		>
			@if (
				((!isPrivateMode &&
					SCustomFields.isUserOrModuleFieldsVisibilityHidden(
						module,
						modulesCustomsFields,
						eventUserProfile,
						computedCustomField.baseSettings.uid,
						"customFields"
					)) ||
					isPrivateMode) &&
				computedCustomField.moduleSettings.canBeTag
			) {
				<!-- Multi-language text field -->
				@if (
					((!isPrivateMode &&
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							"customFields"
						)) ||
						isPrivateMode) &&
					(computedCustomField.baseSettings.type === typeCustomFields.TEXT ||
						computedCustomField.baseSettings.type === typeCustomFields.SELECT) &&
					computedCustomField.fieldDatas.field.multiLanguageText?.[currentLanguage] !== ""
				) {
					<span>
						{{ computedCustomField.fieldDatas.field.multiLanguageText?.[currentLanguage] }}
					</span>
				}
				<!-- Numeric field -->
				@if (
					((!isPrivateMode &&
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							"customFields"
						)) ||
						isPrivateMode) &&
					computedCustomField.baseSettings.type === typeCustomFields.NUMERIC &&
					computedCustomField.fieldDatas.field.numeric !== 0
				) {
					<span>
						{{ computedCustomField.fieldDatas.field.numeric }}
					</span>
				}
				<!-- URL field -->
				@if (
					((!isPrivateMode &&
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							"customFields"
						)) ||
						isPrivateMode) &&
					computedCustomField.baseSettings.type === typeCustomFields.URL &&
					computedCustomField.fieldDatas.field.text !== ""
				) {
					<a
						[style.color]="event.styling.btnBgColor"
						target="_blank"
						href="{{ computedCustomField.fieldDatas.field.text }}"
					>
						{{ computedCustomField.fieldDatas.field.text }}
					</a>
				}
				@if (
					((!isPrivateMode &&
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							"customFields"
						)) ||
						isPrivateMode) &&
					computedCustomField.baseSettings.type === typeCustomFields.EMAIL &&
					computedCustomField.fieldDatas.field.text !== "" &&
					computedCustomField.moduleSettings.canBeTag
				) {
					<a
						[style.color]="event.styling.btnBgColor"
						href="mailto:{{ computedCustomField.fieldDatas.field.text }}"
					>
						{{ computedCustomField.fieldDatas.field.text }}
					</a>
				}
				<!-- Phone field -->
				@if (
					((!isPrivateMode &&
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							"customFields"
						)) ||
						isPrivateMode) &&
					computedCustomField.fieldDatas.field.phoneNumber?.internationalNumber !== "" &&
					computedCustomField.baseSettings.type === typeCustomFields.PHONE
				) {
					<span>
						{{ computedCustomField.fieldDatas.field.phoneNumber?.internationalNumber }}
					</span>
				}
				<!-- visibility chip -->
				@if (
					SCustomFields.checkValueCustomField(
						computedCustomField.baseSettings.type,
						computedCustomField.fieldDatas,
						currentLanguage
					) &&
					module.options.enableUserFieldsHideAbility &&
					isPrivateMode
				) {
					<span class="visibility-state">
						@if (
							!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
								module,
								modulesCustomsFields,
								eventUserProfile,
								computedCustomField.baseSettings.uid,
								"customFields"
							)
						) {
							<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
						}
						@if (
							SCustomFields.isUserOrModuleFieldsVisibilityHidden(
								module,
								modulesCustomsFields,
								eventUserProfile,
								computedCustomField.baseSettings.uid,
								"customFields"
							)
						) {
							<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
						}
					</span>
				}
			}
		</p>
	}
</ng-container>
