import { Injectable } from "@angular/core";
import { take } from "rxjs/operators";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { GetAllGroups } from "../actions/generics-modules-data.actions";
import { checkSameEvent } from "../selectors/generics-modules-data.selectors";
import { IBaseDocument } from "../interfaces";
import { FirestoreService } from "./firestore.service";
import { where } from "@angular/fire/firestore";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";

@Injectable({
	providedIn: "root"
})
export class GroupsService {
	groupsSub: Subscription;

	constructor(
		private SFirestore: FirestoreService,
		private store: Store
	) {}

	unsubscribeAll() {
		if (this.groupsSub) {
			this.groupsSub.unsubscribe();
		}
	}

	/**
	 * Get all groups for event
	 * @param eventId
	 * @returns
	 */
	getGroupsForEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "groups", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.groupsSub && !this.groupsSub.closed) {
					return;
				} else if (!sameEvent && this.groupsSub && !this.groupsSub.closed) {
					this.groupsSub.unsubscribe();
				}

				this.groupsSub = this.SFirestore.collectionGroupValueChangesDocuments("groups", [
					where("eventId", "==", eventId)
				]).subscribe({
					next: (groups: IBaseDocument[]) => {
						this.store.dispatch(GetAllGroups({ payload: groups, eventId: eventId }));

						this.store
							.select(getInitSpecificEventDatasPart("initGroups"))
							.pipe(take(1))
							.subscribe((init) => {
								if (!init) {
									this.store.dispatch(
										InitSpecificEventDatasPart({ part: "initGroups", payload: true })
									);
								}
							});
					},
					error: () => {
						//
					}
				});
			});
	}
}
