import { Component, OnDestroy } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { combineLatest, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeGallery } from "src/app/shared/enums/type-gallery";
import { IDocument, IEvent, IEventUser, IFolder, IModule, IUser } from "src/app/shared/interfaces";
import { getCurrentEventUser, getCurrentUser } from "src/app/shared/selectors/auth.selectors";
import { getFoldersOfModule } from "src/app/shared/selectors/documents.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-gallery-folders-list",
	templateUrl: "./gallery-folders-list.component.html",
	styleUrls: ["./gallery-folders-list.component.scss"],
	standalone: false
})
export class GalleryFoldersListComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	foldersSubscription: Subscription;
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	loader: boolean = true;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	folders: IFolder[] = [];
	documents: IDocument[] = [];
	user: IUser;
	eventUser: IEventUser;
	currentLanguage: string = environment.platform.defaultLanguage;
	typeGallery = TypeGallery;

	constructor(
		private SAnalytics: AnalyticsService,
		private STranslate: TranslateService,
		private navCtrl: NavController,
		private store: Store
	) {}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;

						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_GALLERY_MODULE
						);
						this.getEvent();
						this.getModule();
						this.getUser();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.concat([this.foldersSubscription]).forEach((sub) => sub?.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.concat([this.foldersSubscription]).forEach((sub) => sub?.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;

				if (this.module) {
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
					this.getFolders();
				}
			})
		);
	}

	/**
	 * Getting all folders of module
	 */
	getFolders() {
		if (this.foldersSubscription && !this.foldersSubscription.closed) {
			this.foldersSubscription.unsubscribe();
		}
		this.foldersSubscription = this.store
			.select(
				getFoldersOfModule({
					moduleId: this.moduleId,
					order: this.module.typeOrder,
					language: this.currentLanguage
				})
			)
			.subscribe((folders) => {
				if (!_.isEqual(this.folders, folders)) {
					this.folders = folders;
				}
				this.loader = false;
			});
	}

	/**
	 * Getting user datas
	 */
	getUser() {
		this.subscriptions.push(
			combineLatest([this.store.select(getCurrentUser), this.store.select(getCurrentEventUser)]).subscribe(
				(results) => {
					this.user = results[0];
					this.eventUser = results[1];
				}
			)
		);
	}

	/**
	 * Navigate to folder
	 * @param folder
	 */
	navigateTo(folder: IFolder) {
		this.navCtrl.navigateForward(`/event/${this.eventId}/gallery/${this.moduleId}/folder/${folder.uid}`);
	}
}
