import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavParams, PopoverController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { IEventUser } from "src/app/shared/interfaces";
import { IChat } from "src/app/shared/interfaces/chats.interfaces";
import { getSpecificChat } from "src/app/shared/selectors/chats.selectors";
import { EventUsersService } from "src/app/shared/services";

@Component({
	selector: "app-chat-settings-popup",
	templateUrl: "./chat-settings-popup.component.html",
	styleUrls: ["./chat-settings-popup.component.scss"],
	standalone: false
})
export class ChatSettingsPopupComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	eventId: string;
	moduleId: string;
	chatId: string;
	chat: IChat;
	eventUser: IEventUser;

	url: string;

	constructor(
		private popoverCtrl: PopoverController,
		private store: Store,
		private navParams: NavParams,
		private SEventUsers: EventUsersService
	) {
		const params = this.navParams.get("params");

		this.eventId = params.eventId;
		this.moduleId = params.moduleId;
		this.chatId = params.chatId;
		this.eventUser = params.eventUser;
		this.url = `event/${this.eventId}/chats/${this.moduleId}/${this.chatId}`;
	}

	ngOnInit() {
		this.subscriptions.push(
			this.store.select(getSpecificChat(this.chatId)).subscribe((chat) => {
				this.chat = chat;
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	checkAllowNotifsUserChat() {
		return (this.eventUser &&
			this.chat &&
			this.eventUser.options &&
			this.eventUser.options.chatsNotifs &&
			this.eventUser.options.chatsNotifs[this.chat.uid]) ||
			(this.eventUser && !this.eventUser.options.chatsNotifs) ||
			(this.eventUser &&
				this.eventUser.options.chatsNotifs &&
				this.eventUser.options.chatsNotifs[this.chat.uid] === undefined)
			? false
			: true;
	}

	async changeAllowNotifForUser(evt: any) {
		if (!this.eventUser.options.chatsNotifs) {
			this.eventUser.options.chatsNotifs = {};
		}
		this.eventUser.options.chatsNotifs[this.chat.uid] = !evt.detail.checked;
		await this.SEventUsers.updatePartOfEventUser(this.eventId, this.eventUser.moduleId, this.eventUser.uid, {
			options: {
				chatsNotifs: this.eventUser.options.chatsNotifs
			}
		});
	}

	onDismiss() {
		this.popoverCtrl.dismiss();
	}
}
