import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { Observable, of, Subscription } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { GetAllColorsCharts } from "../actions/interactivity.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IQuiz, ISurvey } from "../interfaces";
import { IColorChart } from "../interfaces/color-charts.interfaces";
import {
	checkSameEvent,
	getAccessiblesQuizsByType,
	getAccessiblesSurveysByType,
	getVisibleAskQuestionsOfModule
} from "../selectors/interactivity.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class InteractivityService {
	colorsChartsSub: Subscription;

	constructor(
		private SFirestore: FirestoreService,
		private store: Store
	) {}

	unsubscribeAll() {
		if (this.colorsChartsSub) {
			this.colorsChartsSub.unsubscribe();
		}
	}

	/**
	 * Getting all color charts of interactivity
	 * @param eventId
	 * @returns
	 */
	getColorCharts(eventId: string) {
		if (eventId) {
			this.store
				.select(checkSameEvent({ key: "colorsCharts", uid: eventId }))
				.pipe(take(1))
				.subscribe((sameEvent) => {
					if (sameEvent && this.colorsChartsSub && !this.colorsChartsSub.closed) {
						return;
					} else if (!sameEvent && this.colorsChartsSub && !this.colorsChartsSub.closed) {
						this.colorsChartsSub.unsubscribe();
					}
					this.colorsChartsSub = this.SFirestore.collectionGroupValueChangesDocuments("color-charts", [
						where("eventId", "==", eventId)
					]).subscribe((colorsCharts: IColorChart[]) => {
						this.store.dispatch(GetAllColorsCharts({ payload: colorsCharts, eventId: eventId }));

						this.store
							.select(getInitSpecificEventDatasPart("initColorsCharts"))
							.pipe(take(1))
							.subscribe((init) => {
								if (!init) {
									this.store.dispatch(
										InitSpecificEventDatasPart({ part: "initColorsCharts", payload: true })
									);
								}
							});
					});
				});
		}
	}

	redirectInteractivity(redirectUrl: string) {
		const splitedRoute = redirectUrl.split("/");
		const selector: Observable<any[]> = redirectUrl.includes("asks-questions")
			? this.store.select(
					getVisibleAskQuestionsOfModule(
						redirectUrl.split("/")[redirectUrl.split("/").length - (splitedRoute.length > 5 ? 2 : 1)]
					)
				)
			: redirectUrl.includes("quizs")
				? this.store.select(getAccessiblesQuizsByType(0, null))
				: this.store.select(getAccessiblesSurveysByType(0, null));

		return selector.pipe(
			switchMap((datas) => {
				let url: string = "";
				if (splitedRoute.length === 5 && datas.length === 1) {
					// Case of redirect url on module directly and datas length at 1, redirect directly on data
					url = `${redirectUrl}/${datas[0].uid}`;
				} else if (splitedRoute.length === 5 && datas.length > 1) {
					// Case of redirect url on module but more than one datas in
					url = redirectUrl;
				} else if (splitedRoute.length === 6 && datas.find((data) => data.uid === splitedRoute[5])) {
					// Case of redirect url on specific interactivity and data exist and visible keep the redirect
					url = redirectUrl;
				} else {
					// Case of no redirection if no data
					url = "";
				}
				return of(url);
			})
		);
	}

	checkAccessible(data: IQuiz | ISurvey) {
		return (
			!data.eventUserResults ||
			data.eventUserResults.length === 0 ||
			(data.eventUserResults &&
				data.eventUserResults.length !==
					(data.questions as any[]).filter((question) => question.type !== "plainText").length)
		);
	}
}
