import {
	AfterViewInit,
	Component,
	ElementRef,
	inject,
	input,
	output,
	signal,
	viewChild,
	WritableSignal
} from "@angular/core";
import { IonicModule, Platform } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import SignaturePad from "signature_pad";
import { IEvent } from "../../interfaces";
import { StorageService } from "../../services";

@Component({
	selector: "app-signature-pad",
	templateUrl: "./signature-pad.component.html",
	styleUrls: ["./signature-pad.component.scss"],
	imports: [IonicModule, TranslateModule],
	standalone: true
})
export class SignaturePadComponent implements AfterViewInit {
	SStorage = inject(StorageService);

	event = input.required<IEvent>();
	width = input<number>(300);
	height = input<number>(200);
	signatureExisting = input<{ url: string; path: string }>(null);
	signatureDocument: WritableSignal<{ url: string; path: string }> = signal(null);
	signaturePad: WritableSignal<SignaturePad> = signal(null);
	canvasEl = viewChild<ElementRef>("signatureCanvas");
	signatureImg: WritableSignal<string> = signal("");
	signatureImgOutput = output<string>();
	noChangeMadeOutput = output<boolean>();

	isMobile: WritableSignal<boolean> = signal(false);

	constructor(private platform: Platform) {
		this.isMobile.set(this.platform.is("mobile"));
	}

	ngAfterViewInit() {
		this.signatureDocument.set(this.signatureExisting());
		this.signaturePad.set(new SignaturePad(this.canvasEl().nativeElement));
	}

	savePad() {
		if (this.signaturePad().isEmpty()) {
			// If signature already exist and no change been made just close
			if (this.signatureDocument() && this.signatureDocument().url) {
				this.noChangeMadeOutput.emit(true);
				return;
			}
		}
		this.signatureImg.set(this.signaturePad().isEmpty() ? "empty" : this.signaturePad().toDataURL());
		this.signatureImgOutput.emit(this.signatureImg());
	}

	async clearPad() {
		if (this.signatureDocument() && this.signatureDocument().path && this.signatureDocument().url) {
			await this.SStorage.deleteDocumentOnStorage(this.signatureDocument().path);
			this.signatureDocument.set(null);
		}
		this.signaturePad().clear();
		this.signatureImg.set("");
		this.signatureImgOutput.emit("");
	}
}
