{
	"alerts": {
		"add_to_my_calendar": "añadir a mi calendario",
		"all_right": "OK",
		"already_scanned": "Qrcode ya validado",
		"already_checked_checkin": "Ya está registrado en este registro",
		"answer_saved_successfully": "Su contribución ha sido registrada",
		"app_open_doc_not_found_txt": "No tienes instalada ninguna aplicación que soporte este tipo de formato, quieres descargar una?",
		"asking_saved_successfully": "Su contribución ha sido registrada con éxito",
		"waitee_not_found": "Participante no encontrado. Quieres volver a escanear?",
		"cannot_scan_web": "No se puede escanear un código QR desde la versión web de la aplicación",
		"change_status": "Cambiar estado",
		"close": "Cerca",
		"change_status_confirm_msg": "Estás seguro de que quieres cambiar el estado de",
		"confirm_exit_event": "Realmente quieres abandonar el evento",
		"confirmed_presence": "¡se ha registrado! Quieres volver a escanear?",
		"date_already_selected": "Ya ha seleccionado una fecha, la nueva fecha no se guardará",
		"delete_account_title": "Elimina tu cuenta",
		"delete_contact": "Eliminar contacto",
		"delte_contact_confirm": "¿Estás seguro de que quieres eliminar este contacto?",
		"delete_account_message": "¿Estás seguro de que quieres eliminar tu cuenta?Esto eliminará todos sus datos y, por lo tanto, sus registros para todos los eventos.",
		"error": "Error",
		"error_scanning": "Error de lectura",
		"event_shortcode_blank": "El código no es válido, por favor inténtelo de nuevo",
		"exit_event": "Salir de la aplicación",
		"failure_scanning": "Imposible decodificar Qrcode",
		"go_to_container_confirm": "Realmente quieres volver a la lista de eventos?",
		"invalid_qrcode": "Este participante no se registra en este punto de cruce",
		"list_events": "Lista de eventos",
		"login-page": "Página de conexión",
		"go-to-login-confirm": "¿Realmente quieres volver a la página de conexión?",
		"login_client_title_error": "Error de conexión",
		"login_client_error": "Está intentando conectarse con una cuenta de cliente, necesita crear una cuenta de participante para poder conectarse.",
		"no_camera": "No se puede acceder a la cámara",
		"not_change_answer": "No puedes editar tus respuestas",
		"not_found_title": "Archivo no encontrado",
		"not_open_market_msg": "Es imposible abrir la tienda, quieres abrir el documento en el navegador?",
		"not_possible_change_status": "Imposible realizar el registro de",
		"not_success": "La operación no tuvo éxito",
		"please_tryagain": ". Por favor, inténtelo más tarde",
		"post_moderated": "Tu mensaje ha sido enviado para su validación",
		"not_confirm_your_presence": "Imposible registrarse. Por favor, inténtelo más tarde",
		"clear-filter": "Borrar filtro",
		"status_changed_successfully": "¡se ha cambiado!",
		"success": "Operación exitosa",
		"thankyour_asking": "¡Gracias!",
		"thankyou_answer": "¡Gracias!",
		"the_presence": "El registro de",
		"the_status_changed": "El estado de",
		"type_event_shortcode": "Introduzca el código del evento",
		"user_chat_not_available": "Este usuario aún no se ha conectado, es imposible chatear con él",
		"your_presence_confirmed": "Su registro se ha completado",
		"blocked-event-info": "El evento está bloqueado, las características y el acceso son limitados",
		"contact_has_been_add_to_your_fav": "El contacto ha sido añadido a tus favoritos",
		"contact_has_not_been_add_because_form_empty": "El contacto no ha sido añadido a tus favoritos porque el formulario está vacío",
		"contact_already_in_your_fav": "El contacto ya está en tus favoritos"
	},
	"appointments": {
		"show-more-timeslots": "Mostrar más tragamonedas",
		"no-rdv-accepted": "Pronto no tienes citas planificadas...",
		"no-rdv-pending": "Actualmente no tiene citas esperando la confirmación...",
		"no-rdv-ended": "No hay cita completada en este momento...",
		"no-rdv-cancelled": "No hay cita cancelada por el momento...",
		"appointment-with": "Cita con ",
		"save-on-personal-schedule": "Ahorre su cita en su agenda personal",
		"appointment-infos": "Información de reunión",
		"aditionnal-infos": "Informaciones complementarias",
		"select-day": "Seleccione un día ...",
		"select-disponibilities": "Seleccione su disponibilidad:",
		"appointment": "Cita",
		"someone-want-to-make-appointment": "{{user}} Deseo hacer una cita..",
		"take-appointment": "Pedir hora",
		"confirm-appointment": "Confirmar la cita",
		"informations": "Información",
		"fill-informations": "Informar la información",
		"notation_title": "Qué te ha parecido esta reunión?",
		"commentary_title": "Tiene algún comentario que hacer?",
		"commentary": "Comentarios",
		"input_subject": "Especifica el asunto...",
		"input_message": "Introduzca un mensaje...",
		"appointment_accepted": "Cita aceptada",
		"appointment_pending": "Solicitud pendiente...",
		"appointment_cancelled": "Cita cancelada",
		"appointment_cancelled_automatically": "Cita cancelada",
		"appointment_rejected": "cita rechazada",
		"appointment_rejected_automatically": "Cita rechazada",
		"cancel_appointment": "Cancelar cita",
		"join_visio": "Únete por vídeo",
		"display_empty_time_slots": "Mostrar ranuras vacías",
		"make_all_disabled": "Hacer que todo no esté disponible",
		"make_all_enabled": "Haz que todo esté disponible",
		"available_time_slot": "Ranura disponible",
		"unavailable_time_slot": "Ranura no disponible",
		"request_appointment": "Solicitar una cita",
		"choose_time_slot": "Elige la franja horaria de la cita",
		"appointment_request": "Solicitud de cita",
		"applicant": "Solicitante",
		"invited": "Invitado",
		"on_date": "Para el",
		"from": "De",
		"to": "a",
		"subject": "Objeto",
		"status": "Estado",
		"see_details": "Ver detalles",
		"accept": "Aceptar la cita",
		"reject": "Rechazar la cita",
		"cancel": "Cancelar la cita",
		"choose_new_time_slot": "Elige una nueva franja horaria",
		"make_appointment": "Pide una cita",
		"select-location": "Seleccione el lugar de su cita...",
		"alerts": {
			"send-appointment-slot-disabled-recipient-title": "Deshabilitar el nicho",
			"send-appointment-slot-disabled-recipient-message": "El nicho ha sido deshabilitado por el destinatario",
			"send-appointment-slots-taken-title": "Nicho ya tomado",
			"send-appointment-slots-taken-message": "El nicho para el que solicitó la cita ya no está disponible",
			"send-appointment-limit-accepted-applicant-title": "Límite alcanzado",
			"send-appointment-limit-accepted-applicant-message": "Imposible hacer la cita, se alcanza su límite de una serie de reuniones aceptadas",
			"send-appointment-limit-accepted-recipient-title": "Límite alcanzado",
			"send-appointment-limit-accepted-recipient-message": "Imposible hacer la cita, se alcanza el límite de reunión aceptado del destinatario",
			"appointment-accepted-limit": "Imposible aceptar la cita, se alcanza el límite de cita aceptado.",
			"send-appointment-title": "Solicitud de cita enviada",
			"send-appointment-message": "Su solicitud ha sido transmitida.Se le enviará una notificación cuando haya sido examinado y aprobado.",
			"send-appointment-exist-title": "Cita no disponible",
			"send-appointment-exist-message": "El nicho para el que hizo la cita ya no está disponible.",
			"cancel_appointment": {
				"title": "Cancelar esta cita",
				"message": "Realmente quiere cancelar esta cita?"
			}
		},
		"buttons": {
			"send-message": "Enviar un mensaje",
			"see-details": "Ver los detalles",
			"accept": "Aceptar",
			"refuse": "Rechazar",
			"cancel": "anular"
		},
		"notification": {
			"reminder-title": "Recordatorio de su cita",
			"reminder-content": "Su cita con {{user}} empieza en {{time}} minutos",
			"accepted": "La cita \"{{subject}}\" ha sido aceptada",
			"pending": "Solicitud de cita {{date}}",
			"rejected": "La cita \"{{subject}}\" ha sido rechazada",
			"cancelled": "La cita \"{{subject}}\" ha sido cancelada",
			"rejected_automatically": "La cita \"{{subject}}\" ha sido rechazada automáticamente. El usuario ya no está disponible en este momento",
			"cancelled_automatically": "La cita \"{{subject}}\" ha sido cancelada automáticamente. El usuario ya no está disponible en este momento"
		},
		"app_status": {
			"coming": "aVenir",
			"waiting": "A la espera",
			"ended": "Terminado",
			"accepted": "Aceptado",
			"cancelled": "Cancelado",
			"cancelled-by": "Cancelado por {{ user }}",
			"cancelled_automatically": "Cancelado automáticamente",
			"rejected": "Disponible en",
			"rejected_automatically": "Rechazado automáticamente",
			"pending": "A la espera de respuesta"
		},
		"select_date": "Selecciona una fecha",
		"date_time_appointment": "La cita es:",
		"make_appointment_available": "Poner la cita a disposición",
		"make_appointment_unavailable": "Hacer que la cita no esté disponible"
	},
	"app_settings": {
		"old_version_description": "Su aplicación tiene una actualización más reciente, descárguela de las tiendas",
		"old_version_title": "Versión antigua"
	},
	"asks-questions": {
		"ask-questions-plc": "Escribe tu texto aquí...",
		"error-send-question": "Imposible enviar su pregunta",
		"error-voting": "Se ha producido un error durante el like/unlike",
		"ask-question-anonymously": "Haz la pregunta de forma anónima",
		"your-question-awaiting-moderator-approbation": "Su pregunta está pendiente de aprobación por parte del moderador"
	},
	"auth": {
		"need_login_register_for_participating": "Para participar debe conectarse o registrarse."
	},
	"buttons": {
		"access": "Conectar",
		"authenticate": "Para autenticar",
		"access_to": "Acceso",
		"access_by_email": "Acceso con correo electrónico",
		"access_by_shortcode": "Acceso con código",
		"add": "Añadir",
		"add-contact": "Añadir contacto",
		"scan-a-profil": "Escanear un perfil",
		"answer": "Validar",
		"apply": "Aplicar",
		"back": "Retour",
		"back_to_list_event": "Volver a la lista",
		"back-to-login": "Volver a la conexión",
		"btn_verify": "Acceso",
		"cancel": "Cancelar",
		"close": "Cerca",
		"comment": "Comentario",
		"confirm": "Confirmar",
		"create": "Crear",
		"delete": "Eliminar",
		"download": "Descarga",
		"delete_account": "Elimina tu cuenta",
		"download_vcf": "Descargar vCard",
		"edit-profil": "Editar perfil",
		"enter": "Validar",
		"exit": "Desconexión",
		"from_photos": "Librería fotográfica",
		"take_photo": "Tomar una fotografía",
		"go": "Enter",
		"generate-email": "Generar un correo electrónico",
		"generate-random-email-address": "Generar una dirección de correo electrónico",
		"log_out": "Cierre de sesión",
		"next": "Siguiente",
		"new": "Nuevo",
		"no": "No",
		"nonees": "Ninguno",
		"open_gallery": "Mis fotos",
		"ok": "Ok",
		"post": "Publicar",
		"prev": "Anterior",
		"public_events_list": "Lista de eventos públicos",
		"register": "Registro",
		"select_picture": "Selecciona una imagen",
		"click-to-select-document": "Haz clic para seleccionar un documento",
		"click-on-document-to-replace": "Haz clic en el documento para reemplazarlo",
		"share": "Compartir",
		"show_more": "Ver más",
		"show_less": "Ver menos",
		"social_sharing": "Redes sociales",
		"take_picture": "Haz una foto",
		"update": "Modificadora",
		"validate": "Validar",
		"yes": "Sí"
	},
	"chat": {
		"chat": "Chat",
		"chats": "Gatos",
		"chat_history": "Conversaciones",
		"chat_empty_content_title": "Chat entre participantes",
		"chat_empty_content_text": "Para iniciar un debate, vaya al perfil de un participante y haga clic en 'Iniciar un debate'.",
		"create_chat": "Crear un grupo de discusión",
		"display_group_info": "Mostrar información",
		"group_chat_empty_content_title": "Discusión entre los participantes",
		"group_chat_empty_content_text": "Déjate llevar por un grupo de discusión. Sé el primero en participar y anima a otros invitados a unirse",
		"groups": "Grupos",
		"group_info": "Información del grupo",
		"new_chat": "Nueva conversación",
		"no_messages": "No tienes mensajes",
		"no_official": "Grupos no oficiales",
		"official": "Grupos oficiales",
		"notifications": "Notificaciones",
		"received_message": "Ha recibido un nuevo mensaje",
		"silent_mode": "Modo silencioso",
		"toggle": "Activar/desactivar",
		"type_msg": "Escriba su mensaje...",
		"you": "Usted: "
	},
	"checkin": {
		"only-one-check-authorized": "Solo se permite un cheque",
		"already_checked": "Ya está registrado",
		"away": "Ausente",
		"default_title": "Check-in",
		"empty_content_title": "Comprueba los ingresos",
		"empty_content_text": "Comprueba las entradas",
		"last_checkins": "Últimos registros",
		"qrcode_focus": "Flash el QRCode",
		"showed": "presente",
		"total": "Total",
		"user_registered": "¡Usuario registrado!",
		"user_unregistered": "¡Usuario no registrado!"
	},
	"documents": {
		"default_title": "Documentos",
		"empty_content_title": "Consulta los documentos del evento",
		"empty_content_text": "¡Vea y descargue los documentos del evento!"
	},
	"edit-profil": {
		"name": "Nombre",
		"email": "Correo electrónico",
		"biography": "Biografía",
		"website": "Sitio web",
		"facebook": "Facebook",
		"linkedin": "LinkedIn",
		"twitter": "X (Twitter)",
		"instagram": "Instagram",
		"submit": "Validar",
		"placeholders": {
			"name_text-info": "Tu nombre",
			"email_text-info": "Su dirección de correo electrónico",
			"password_create_text-info": "Cree una contraseña",
			"password_confirm_text-info": "Confirma tu contraseña",
			"password_text-info: ": "Tu contraseña",
			"bio_text-info": "Cómo te describirías?",
			"website_text-info": "Sitio web",
			"facebook_text-info": "Perfil de Facebook",
			"linkedin_text-info": "Perfil de LinkedIn",
			"twitter_text-info": "Perfil de Twitter",
			"instagram_text-info": "perfil de instagram",
			"company_text-info": "Su empresa",
			"title_text-info": "Qué puesto ocupa?",
			"url_text-info": "Introduzca un enlace válido",
			"simple_text-info": "Introduzca un valor de texto",
			"numeric_text-info": "Introduzca un valor numérico",
			"html_text-info": "Introducir texto o contenido html",
			"select_text-info": "Selecciona un valor",
			"multi-select_text-info": "Selecciona uno o más valores",
			"multiple_text-info": "Introduzca el texto y pulse \"Enter\" para validar",
			"date_text-info": "Elige una fecha",
			"phonetel_text-info": "Introduzca un número de teléfono",
			"image_text-info": "Subir una imagen",
			"file_text-info": "Descargar un archivo",
			"example": {
				"email1": "email@example.com",
				"email2": "Ex : jeandupont@mail.com"
			}
		},
		"errors": {
			"characters": "caracteres",
			"blank": {
				"name": "¡El nombre es obligatorio!",
				"simple_text": "¡Campo vacío! Por favor, introduzca un valor",
				"email": "¡El correo electrónico es obligatorio!",
				"select": "¡Por favor, seleccione una opción!",
				"multi_select": "¡Por favor, seleccione una o más opciones!",
				"required": "Required field !"
			},
			"invalid": {
				"bio": "¡El número de caracteres debe estar entre 15 y 5000!",
				"url": "¡Enlace/URL inválido!",
				"facebook": "¡El enlace de facebook no es válido!",
				"linkedin": "¡El enlace de Linkedin no es válido!",
				"twitter": "¡El enlace de X (Twitter) no es válido!",
				"instagram": "¡El enlace de Instagram no es válido!",
				"name": "¡El nombre debe contener al menos 3 caracteres!",
				"email": "¡Email inválido!",
				"date": "¡Fecha inválida!",
				"generic": "¡El formato no es válido!",
				"multiple_text-too-long": "Se ha superado el número máximo de caracteres",
				"multiple_text-content-exist": "El contenido ya está presente en la lista",
				"min_length": "Por favor, introduzca al menos",
				"phone_number": "El teléfono no es válido",
				"big_number": "Número demasiado grande",
				"file_size": "Archivo demasiado grande, límite : "
			}
		}
	},
	"events": {
		"switch_timezone_user": "Cambiar a la zona horaria local",
		"switch_timezone_event": "Cambiar a la zona horaria del evento",
		"add_event_to_calendar": "Añadir al calendario"
	},
	"events_list": {
		"events": "Eventos",
		"my_events": "Mis eventos",
		"publics_events": "Eventos públicos",
		"no-events": "¡No estás registrado en ningún evento de este contenedor!"
	},
	"event_users": {
		"default_title": "Participantes",
		"empty_content_title": "Consulta la lista de participantes",
		"empty_content_text": "Ver los perfiles de los participantes en el trombinoscopio. Chatea o videochatea directamente con un participante desde su perfil",
		"empty_content_text_card_exchange": "¡Intercambia tarjetas de visita con los participantes del evento!",
		"company": "Entreprise",
		"post": "Poste",
		"delete_all": "borrar todo",
		"not_specified": "No especificado"
	},
	"card-exchange": {
		"ask_to_get_scans_by_email_title": "Recibir escaneos por correo electrónico",
		"ask_to_get_scans_copy_by_email": "¿Le gustaría recibir una copia de sus escaneos por correo electrónico?"
	},
	"feedbacks": {
		"cannot_send_feedback": "Imposible enviar las respuestas de la encuesta",
		"default_title": "Encuesta",
		"default_title_feedback": "Encuesta",
		"answered": "Ya contestado",
		"not_answered": "No contestado",
		"answer_plc": "...",
		"force_complete_title_alert": "Aviso",
		"force_complete_message_alert": "Debe completar todo el formulario",
		"feedback_send": "Sus respuestas a la encuesta han sido enviadas"
	},
	"feed_news": {
		"default_title": "Noticias",
		"description_plc": "¡Habla!",
		"comments_plc": "Escribe un comentario...",
		"likes": "Likes",
		"comment": "Comentario",
		"comments": "Comentarios",
		"thumb-up": "Like",
		"heart": "Love",
		"clap": "Bravo",
		"idea": "Instructive",
		"thinking": "Interesting",
		"time": "Hace {{time}} {{timeLabel}}",
		"empty_content_title": "Publicar contenido en el feed del evento",
		"empty_content_text": "¡Comparte a través de las publicaciones, los \"me gusta\" y los comentarios publicados en el feed de noticias del evento!"
	},
	"filter": {
		"not_specified": "No especificado",
		"delete_all": "borrar todo",
		"text-information": "Usa filtros para refinar tu búsqueda"
	},
	"gallery": {
		"default_title": "Galería",
		"empty_content_title": "Ver fotos del evento",
		"empty_content_text": "¡Vea y descargue las fotos del evento!"
	},
	"gamification": {
		"treasure_hunt_description": "Encuentra los QRcodes repartidos por el evento"
	},
	"interactivity": {
		"default_title": "Interactividad",
		"default_empty_content_title": "¡No hay resultados!",
		"default_empty_content_text": "¡Vuelve más tarde!",
		"externals_interactivity_description": "¡Interactúa con los demás!",
		"note-taking-description": "Para crear una nota, pulse el botón + situado en la parte inferior de la pantalla.",
		"survey_description": "¡Responde a los cuestionarios!",
		"quiz_description": "¡Contesta el cuestionario!",
		"training_description": "Realización de la formación",
		"ask_question_description": "¡Pregunta a los ponentes!",
		"feedback_empty_content_title": "Haz las encuestas",
		"feedback_empty_content_text": "¡Su opinión es esencial! Acceda y responda a las encuestas de satisfacción directamente en la plataforma",
		"quiz_empty_content_title": "Haz la prueba",
		"quiz_empty_content_text": "¡Pon a prueba tus conocimientos con un test!",
		"questions_empty_content_title": "Haz tus preguntas",
		"questions_empty_content_text": "¡Siéntase libre de hacer todas sus preguntas a los ponentes! Los demás participantes podrán enlazar con ellos para destacarlos",
		"survey_empty_content_title": "Realiza la encuesta",
		"survey_empty_content_text": "¡Su opinión es esencial! Acceda y responda a las encuestas de satisfacción directamente en la plataforma",
		"min_points_msg_part_1": "Deberías alcanzar al menos ",
		"min_points_msg_part_2": "¡puntos para activar esta encuesta!"
	},
	"labels": {
		"accompanying": "Accompañado",
		"address": "Dirección",
		"and": "y",
		"code": "Código",
		"comment": "Comentario",
		"content": "Contenido",
		"choose-date": "Elija una fecha",
		"description": "Descripción",
		"contact-editing": "Edición de un contacto",
		"contact-creation": "Creación de un contacto",
		"email": "Correo electrónico",
		"end": "Aleta",
		"empty_notes": "No tienes notas",
		"filters": "Filtros",
		"groups": "Grupos",
		"group": "Grupo",
		"hidden": "Oculto",
		"history": "Historia",
		"visible": "Visible",
		"hours": "hora(s)",
		"identifier": "Identificador",
		"information": "Información",
		"internet_site": "Sitio web",
		"legal_mentions": "Legal",
		"list": "Lista",
		"loading": "Cargando...",
		"load-the-editor": "Cargar el editor",
		"locations": "Localizaciones",
		"members": "Miembros",
		"my_notes": "Mis notas",
		"minutes": "minuto(s)",
		"name": "Nombre",
		"no": "No",
		"on-maintenance": "La plataforma está actualmente en mantenimiento, vuelva más tarde",
		"plan": "Plan",
		"privacy": "Política de privacidad",
		"reset": "Reiniciar",
		"scan": "Escanear",
		"mandatory": "Obligatorio",
		"show_on_public_profile": "Mostrar en el perfil público",
		"scan_date": "fecha de escaneo",
		"search": "Buscar",
		"seconds": "segundo(s)",
		"sessions": "Sesiones",
		"signature": "Firma",
		"start": "Inicio",
		"support": "Correo de soporte",
		"tags": "Etiquetas",
		"terms_of_use": "Términos de uso",
		"title": "Título",
		"tracks": "Pistas",
		"yes": "Sí"
	},
	"placeholders": {
		"enter-phone-number": "Introduzca un número de teléfono válido",
		"link-or-import-image": "Insertar un enlace o importar una imagen",
		"import-file": "Importar archivo",
		"address_plc": "99 Av. des Champs-Élysées, 75008 Paris France",
		"phone_number_placeholder": "07 12 34 56 78"
	},
	"languages": {
		"choose-language": "Elige lengua",
		"arabic": "árabe",
		"english": "Inglés",
		"french": "Français",
		"portuguese": "portugués",
		"spanish": "español",
		"german": "alemán"
	},
	"login": {
		"accept_term": "Acepto el",
		"confirm_new_pass": "Confirma tu nueva contraseña",
		"email": "Correo electrónico",
		"password": "Contraseña",
		"forgot_pass": "He olvidado mi contraseña",
		"first_access_txt_01": "¡Primer acceso!",
		"first_access_txt_02": "Se ha enviado un código de seis dígitos a su dirección de correo electrónico, introdúzcalo a continuación para confirmar su identidad:",
		"go_to_login": "Volver a la página de conexión",
		"new_pass": "Nueva contraseña",
		"not_registered": "Este correo electrónico no está registrado para el evento, por favor regístrese",
		"incorrect_pass": "¡Contraseña inválida!",
		"invalid_pass": "¡Contraseña inválida! Su contraseña debe tener al menos 6 caracteres",
		"min_length_password": "¡Contraseña inválida! Su contraseña debe incluir {{charNbr}} caracteres mínimos",
		"pass_error": "No hemos podido crear tu contraseña, ¡inténtalo más tarde!",
		"recovery_pass_title": "Restablecer contraseña",
		"recovery_pass_txt": "Confirme su dirección de correo electrónico para recibir un correo de restablecimiento de contraseña:",
		"your_email_plc": "Introduzca su correo electrónico",
		"recovery_btn": "Enviar",
		"recovery_success": "Le hemos enviado un correo electrónico para restablecer su contraseña:",
		"recovery_text": "Acabamos de enviarle un correo electrónico permitiéndole restablecer su contraseña. Recuerda revisar tu correo no deseado (spam) porque podría estar ahí. Todo lo que tienes que hacer es hacer clic en el enlace para crear uno nuevo.",
		"recovery_fail": "No hemos podido restablecer tu contraseña. Por favor, inténtelo de nuevo",
		"access_your_account": "Conectar",
		"term_check_required": "Debes aceptar las condiciones de uso y la política de privacidad",
		"terms_of_use": "Condiciones de uso",
		"privacy_txt": "la política de privacidad",
		"invalid_email": "Correo electrónico no válido",
		"invalid_name": "Nombre inválido",
		"blank_email": "Debe introducir un correo electrónico",
		"email_not_found": "El correo electrónico no está registrado",
		"btn_no_have_acc": "No tengo una cuenta",
		"verify_code": "El correo electrónico que contiene el código puede estar en su carpeta de spam",
		"btn_make_pass": "Crear una contraseña",
		"great_title": "¡Bienvenido!",
		"make_pass_txt": "Por favor, cree una contraseña individual (mínimo 6 caracteres)",
		"make_pass_strong_txt": "Cree una contraseña individual (mínimo 12 caracteres mayúsculas, pequeñas, figura, símbolos)",
		"error_send_email_code": "No hemos podido enviarle el correo electrónico que contiene el código de 6 dígitos. Por favor, inténtelo más tarde.",
		"invalid_code": "El código de 6 dígitos no es válido. Por favor, inténtalo de nuevo.",
		"password_not_match": "Las contraseñas no son idénticas",
		"email_already_use": "No hemos podido crear una nueva cuenta con tu correo electrónico porque ya está registrado",
		"resend_code_number": "Si no ha recibido el código de 6 dígitos: compruebe su correo no deseado",
		"remaining_time": "tiempo restante",
		"many_pass_errors": "Tu cuenta ha sido bloqueada temporalmente debido a numerosos intentos de acceso. Por favor, espere unos minutos antes de volver a intentarlo",
		"input_name_plc": "Martine Rault",
		"input_email_plc": "martine@mail.com",
		"geral_error_register": "Se ha producido un error durante el registro, inténtelo de nuevo",
		"resend_code": "Reenviar el código de seis dígitos",
		"resend_email_reset_link": "Haz clic aquí si no recibiste el correo electrónico para restablecer la",
		"account_already_exist_with_email": "Ya existe una cuenta con este correo electrónico. Como se trata de un evento público, simplemente inicie sesión con su contraseña para registrarse.",
		"account_already_exist_with_email_private_event": "Ya existe una cuenta con este correo electrónico. Por favor, inicie sesión utilizando el formulario de inicio de sesión.",
		"account_already_exist_with_email_public_event": "Ya existe una cuenta con este correo electrónico. Como se trata de un evento público, introducirá su contraseña al final del formulario para registrarse.",
		"event_user_already_exist_in_event": "Ya estás registrado para este evento. Simplemente inicie sesión ingresando su contraseña para acceder a ella.",
		"not_have_access_to_event": "El usuario no tiene acceso al evento solicitado",
		"min_6_characters": "mínimo 6 caracteres",
		"account_disabled_many_request": "El acceso a esta cuenta se ha suspendido temporalmente debido a un elevado número de intentos fallidos. Vuelve a intentarlo más tarde",
		"attempt_to_login_with_short_code_private_event_msg": "Este evento es privado, no puede iniciar sesión desde un código de acceso. Inicie sesión directamente con su dirección de correo electrónico",
		"attempt_to_login_with_short_code_private_event_title": "Acceso al evento"
	},
	"note-taking": {
		"success-create": "Nota crea",
		"success-delete": "Nota eliminada",
		"success-edit": "Nota modificada",
		"share-title": "Deberá",
		"title-too-long": "El título no debe ser más de 70 caracteres",
		"content-too-long": "El contenido no debe hacer más de 700 caracteres"
	},
	"notifications": {
		"appointment_chat_title_received": "Cita recibida",
		"appointment_chat_title_updated": "Cita modificada",
		"appointment_chat_title": "Cita",
		"appointment_chat_title_cancelled": "Cita cancelada"
	},
	"profile": {
		"start_chat": "Iniciar un chat",
		"start_visio": "Iniciar un vídeo"
	},
	"quizs": {
		"warning-validation-definitive": "Atención !La validación es final.",
		"score-is": "tu puntaje es...",
		"correct-answered-out-of-1": "Usted respondió a ",
		"correct-answered-out-of-2": " preguntas sobre ",
		"retry": "Reanudar",
		"retry-later": "Empiece de nuevo más tarde",
		"validate": "Validar",
		"cannot_send_quiz": "Imposible enviar las respuestas del cuestionario",
		"default_title": "Quiz",
		"default_title_quiz": "Quiz",
		"answered": "Ya contestado",
		"not_answered": "No contestado",
		"answer_plc": "...",
		"force_complete_title_alert": "Aviso",
		"force_complete_message_alert": "Debe completar todo el formulario",
		"result_send": "Su respuesta ha sido guardada"
	},
	"rankings": {
		"empty_content_title": "Comprueba la clasificación",
		"empty_content_text": "Comprueba la clasificación",
		"global_ranking": "Clasificación general",
		"my_ranking": "Mi clasificación"
	},
	"register": {
		"register": "Inscribirse",
		"email_exist": "Un usuario ya está asociado a esta dirección de correo electrónico",
		"register_to_event": "Registrarse en el evento",
		"register_a_user_to_the_event": "Registrar un usuario en el evento",
		"user_already_has_an_account": "Ya tiene una cuenta ?",
		"user_doesnt_has_an_account": "Aún no se ha registrado ?",
		"next_step": "Próximo paso",
		"previous_step": "Paso anterior",
		"registrations_are_closed_please_contact_your_event_manager": "Los registrantes de este evento están cerrados. Por favor contacte",
		"register_an_accompanying_user_title": "Registrar un acompañante para el evento",
		"register_an_accompanying_user_question": "¿Desea registrar un acompañante para el evento?",
		"accompanying_users_limit_reached": "No puede registrar un acompañante para el evento ya que ha alcanzado el límite de inscripción establecido por el administrador",
		"register-schedule-limit-reached": "Se ha alcanzado la cuota de inscripción para la sesión {{sessionName}}, por favor elija otra o contacte al organizador",
		"validator-error-msg": {
			"multi-text": {
				"required": "Campo obligatorio y debe contener al menos un valor validado",
				"multi_text-control_value_must_validated": "El valor del campo debe ser validado",
				"multi_text-control_value_must_be_unique": "El valor del campo debe ser único",
				"multi_text_must_contain_value": "Se debe ingresar y validar al menos un valor (presionar Enter para validar)"
			},
			"strong-password-pattern": "La palabra de contraseña no es robusta, debe tener al menos 12 caracteres incluidos al menos un personaje capital, una figura y un personaje especial (!@#$%€-_()[];:.<>+~#=*)",
			"date-minimum": "La fecha debe ser posterior al {{minDate}}",
			"date-maximum": "La fecha debe ser anterior al {{maxDate}}",
			"required": "Required field !",
			"checkbox-required": "Debe seleccionar al menos una opción",
			"file": {
				"file_size": "Archivo demasiado grande, límite : {{ limit }} MB"
			}
		},

		"accompanying_remaining_places": "Quedan {{ places }} plazas para acompañantes"
	},
	"reports": {
		"alert_title": "Informe",
		"alert_send": "Informe enviado"
	},
	"schedule": {
		"default_title": "Agenda",
		"filter_hour": "Por día",
		"filter_track": "Por pista",
		"empty_content_title": "Encuentra el programa de tu evento",
		"empty_content_text": "¡Consulte rápidamente el programa digital de su evento!",
		"download_schedule": "Descargar el horario",
		"start-soon": "Empezar en",
		"end-soon": "Terminar con",
		"prev-sessions": "Sesiones anteriores"
	},
	"schedule_detail": {
		"cannot_register_to_session": "Imposible registrarse en la sesión",
		"default_title": "Detalle",
		"no_date": "None",
		"number_attendees": "Participantes ya inscritos",
		"number_available_vacancies": "Número de vacantes restantes",
		"register_to_session": "Registro en la sesión",
		"unregister_to_session": "Desregistro de la sesión",
		"registered_to_session": "Estás registrado en la sesión",
		"unregistered_to_session": "Ya no está registrado en la sesión",
		"error_reg_unreg_to_session": "No se ha podido registrar/desregistrar de la sesión",
		"unavailable": "ninguno",
		"interactivity": {
			"details": "Detalles",
			"ask-questions": "Preguntas",
			"ask-question": "Pregunta",
			"externals_interactivity": "Interactividad externa",
			"survey": "Encuesta",
			"quiz": "Quiz",
			"training": "Entrenamiento",
			"group-discussion": "Grupos de discusión",
			"feedback-session": "encuesta de la sesión"
		},
		"prev-session": "Sesión anterior",
		"next-session": "Próxima sesión",
		"session_limit_attendees": "¡La sesión ha alcanzado su límite de participantes!",
		"registered_users_list": "Participantes registrados"
	},
	"snackbar": {
		"authentication_required": "Debe autenticarse para acceder a este evento",
		"cannot_create_account": "No se ha podido crear la cuenta",
		"cannot_login": "Imposible iniciar sesión",
		"cannot_perform_action": "No se puede realizar la acción",
		"error_occured": "Se ha producido un error",
		"error_uploading_photo": "Error al cargar la foto",
		"error_occured_on_verif_email": "Se ha producido un error al verificar tu dirección de correo electrónico",
		"event_shortcode_blank": "El código no es válido, por favor inténtelo de nuevo",
		"form_cannot_be_edited_after_submission": "El formulario no se puede editar después de enviarlo",
		"no_form_attached": "No se ha encontrado el formulario",
		"form_unavailable": "El formulario ya no está disponible",
		"file_size_greater_than_limit": "El tamaño del archivo es mayor que el límite permitido ({{ limit }})",
		"no_module_found": "No se ha encontrado el módulo",
		"no_homepage": "Este evento no tiene configurada una página de inicio",
		"no_network": "Sin conexión a Internet",
		"no_shortcode": "No shortcode",
		"no_user": "No hay cuenta de usuario",
		"not_authorized_event": "No estás autorizado a acceder a este evento",
		"register-form-already-connected-guard": "Ya estás registrado en este evento",
		"update_successfull": "Actualización exitosa",
		"picture_updated_successfull": "Foto actualizada con éxito",
		"failed_to_upload_media": "Tus archivos no se pudieron cargar",
		"no_password": "Por favor, llene el campo de contraseña",
		"wrong_password": "Contraseña incorrecta",
		"signature-saved": "Guardar firma guardada",
		"success_delete_account": "Su cuenta se está eliminando, esto será efectivo en unos minutos.",
		"limit_groups_exced": "No puedes crear más grupos",
		"limit_avaible_place_exed": "No puedes crear más grupos \"{{field}}\"",
		"confirm_reset_password_error": "Se produjo un error durante la confirmación.",
		"invalid_reset_password_code": "Código de acción no válido o caducado. Intente restablecer la contraseña nuevamente.",
		"confirm_reset_password_success": "La restablecimiento de contraseña se realizó con éxito. Ahora puedes iniciar sesión con tu nueva contraseña."
	},
	"surveys": {
		"cannot_send_survey": "Imposible enviar las respuestas de la encuesta",
		"default_title": "Encuesta",
		"default_title_survey": "Encuesta",
		"respondido": "Ya se ha contestado",
		"answered": "Ya contestado",
		"not_answered": "No contestado",
		"answer_plc": "...",
		"force_complete_title_alert": "Aviso",
		"force_complete_message_alert": "Debe completar todo el formulario",
		"survey_send": "Sus respuestas a la encuesta han sido enviadas",
		"min_points_alert_msg": "Debe obtener al menos <strong> {{ points }} </strong> puntos para habilitar esta encuesta"
	},
	"texts": {
		"see-filter-results": "Ver los resultados",
		"current-date": "Fecha",
		"personalschedule": "Agenda personal",
		"speakers": "Orateurs",
		"addschedule": "Inscripción en la sesión",
		"removeschedule": "Se da de baja de la sesión",
		"event": "Evento",
		"events": "Eventos",
		"survey": "Encuesta de satisfacción",
		"quiz": "Quiz",
		"training": "Cuestionario de formación",
		"ics_link_google": "Sincronizar con Google Calendar",
		"ics_download": "Sincroniza con tu calendario de Apple, Outlook y otras herramientas",
		"ics_schedule_download": "By clicking on the icon, you agree to synchronize the entire schedule to your smartphone's calendar.",
		"subscription": "formulario de inscripción",
		"profile": "Perfil",
		"nation": "país",
		"state": "Estado/Región",
		"city": "Ville",
		"save": "Guardar",
		"site": "sitio de Internet",
		"pt_BR": "Portugués",
		"en_US": "Inglés",
		"es_ES": "Español",
		"en_FR": "Francés",
		"de_DE": "alemán",
		"sunday": "Dimanche",
		"monday": "Lunes",
		"tuesday": "martes",
		"wednesday": "Mercredi",
		"thursday": "Jeudi",
		"friday": "Vendredi",
		"saturday": "Samedi",
		"january": "enero",
		"february": "febrero",
		"march": "Marte",
		"april": "Avril",
		"may": "Mai",
		"june": "junio",
		"july": "julio",
		"august": "agosto",
		"september": "Septiembre",
		"october": "Octubre",
		"november": "Noviembre",
		"december": "diciembre",
		"of": "de",
		"maps": "Planes",
		"btn_send": "Enviar",
		"thankyou": "¡Gracias!",
		"loading": "Cargando la aplicación...",
		"and": "y",
		"terms_of_use": "Condiciones de uso",
		"privacy": "Política de privacidad",
		"cookie": "Política de cookies",
		"legal_mentions": "Legal",
		"terms_legal": "Legal",
		"license": "Invent App - Todos los derechos reservados ©️ 2021",
		"search_plc": "Buscar...",
		"description": "Descripción",
		"select": "Seleccionar",
		"select_image": "Selecciona una imagen",
		"select_option": "Selecciona una opción",
		"image": "Imagen",
		"filter": "Filtro",
		"all": "Todos",
		"about": "Sobre",
		"start_chat": "Chat",
		"picture": "Imagen",
		"name": "Apellido Nombre",
		"points": "Puntos",
		"menu": "Menú",
		"need_login_to_use": "¡Debes iniciar sesión o crear una cuenta para acceder a este contenido!",
		"timezone": "Zona horaria",
		"support": "Ayuda",
		"a_z": "Alfabético A-Z",
		"z_a": "Z-A alfabética",
		"locations": "Ubicaciones",
		"social_medias": "Redes sociales",
		"position": "Pos",
		"network_offline": "¡No hay conexión a Internet!",
		"network_online": "¡Se ha restablecido la conexión a Internet!",
		"network_offline_detail": "No hay conexión a Internet. Algunas funciones pueden no estar disponibles",
		"network_online_detail": "Se restablece la conexión. Todas las funcionalidades están disponibles de nuevo",
		"code": "Código",
		"read_qrcode": "Leer QRCode",
		"join-visio": "Únete al visio",
		"visio-launched": "Videoconferencia lanzada",
		"visio-leaved": "Llamada de vídeo a la izquierda",
		"start_visio": "Call",
		"waiting_for_content": "Esperando contenido...",
		"not_belonging_to_any_group": "No perteneces a ningun grupo",
		"no_result_title": "¡No hay resultados!",
		"no_result_text": "¡Vuelve más tarde!",
		"reactions": "Reactions",
		"zoom-visio-reload": "Tu sesión de Zoom ha terminado, la página se recargará en {{timer}}s",
		"unsopported_navigator_msg1": "Si utilizas una versión del navegador que no es compatible con nuestra plataforma, cambia a Google Chrome para disfrutar de toda la funcionalidad. Puede descargarlo haciendo clic",
		"here": "ici",
		"previous-step": "Paso anterior",
		"x_selected_items": "{{count}} elemento seleccionado"
	},
	"toasts": {
		"copied-to-clipboard": "¡Dado copiado!",
		"download_image": "Descarga finalizada.",
		"errors": {
			"create-basic-error": "Se ha producido un error durante la creación",
			"error-open-document": "Imposible abrir el documento",
			"cannot_send_message": "Imposible enviar el mensaje",
			"download_error": "No se puede descargar",
			"share_error": "No se puede compartir el archivo",
			"you_must_enable_permission": "Debe habilitar los permisos de acceso a la cámara y al micrófono para utilizar esta funcionalidad",
			"cannot-copy-to-clipboard": "No se puede copiar datos"
		}
	},
	"tooltips": {
		"click-for-open-document": "Haga clic para abrir el documento",
		"click-for-view-profile": "Haga clic para ver el perfil del usuario",
		"click-for-access-to-session": "Haga clic para acceder a la sesión",
		"click-to-view-image": "Clique para ver a imagem",
		"click-to-replace-document": "Haga clic para reemplazar el documento",
		"click-to-download-document": "Haga clic para descargar el documento"
	},
	"treasure_hunts": {
		"already_scan": "Ya has escaneado este código QR",
		"btn_qrcode": "FLASH A QR CODE",
		"error_scan": "El código QR no es válido. Quieres volver a escanear?",
		"gaming_default_txt": "Encuentra los códigos QR repartidos por el evento para ganar puntos",
		"go_gaming": "Vamos a jugar",
		"scan_read_error": "Error de lectura del código QR, por favor, inténtelo de nuevo",
		"success_scan": "Gracias por escanear este código QR",
		"remaining_qrcodes": "REMAINING QR CODES:",
		"title": "Gamificación",
		"total_qrcodes": "NÚMERO TOTAL DE QRCODES:"
	},
	"visios": {
		"creating-visio": "Creando el visio...",
		"opening-visio": "Acceso a visio...",
		"visio-created": "Visio crea",
		"visio-created-error": "El visio no pudo ser creado",
		"visio-not-available": "La funcionalidad de visio no está disponible",
		"visio-open-error": "No se puede acceder al video"
	},
	"widgets": {
		"external_link_title": "Embargo externo"
	},
	"word_cloud": {
		"add_word": "Añadir una palabra",
		"error-send-word": "Imposible enviar la palabra",
		"success-send-word": "Palabra enviada"
	},
	"countries": {
		"AF": "Afganistán",
		"AL": "Albania",
		"DZ": "Argelia",
		"AS": "Samoa Americana",
		"AD": "Andorra",
		"AO": "Angola",
		"AI": "Anguila",
		"AQ": "Antártida",
		"AG": "Antigua y Barbuda",
		"AR": "Argentina",
		"AM": "Armenia",
		"AW": "Aruba",
		"AU": "Australia",
		"AT": "Austria",
		"AZ": "Azerbaiyán",
		"BS": "Bahamas",
		"BH": "Baréin",
		"BD": "Bangladés",
		"BB": "Barbados",
		"BY": "Bielorrusia",
		"BE": "Bélgica",
		"BZ": "Belice",
		"BJ": "Benín",
		"BM": "Bermudas",
		"BT": "Bután",
		"BO": "Bolivia",
		"BA": "Bosnia y Herzegovina",
		"BW": "Botsuana",
		"BV": "Isla Bouvet",
		"BR": "Brasil",
		"IO": "Territorio Británico del Océano Índico",
		"BN": "Brunéi",
		"BG": "Bulgaria",
		"BF": "Burkina Faso",
		"BI": "Burundi",
		"KH": "Camboya",
		"CM": "Camerún",
		"CA": "Canadá",
		"CV": "Cabo Verde",
		"KY": "Islas Caimán",
		"CF": "República Centroafricana",
		"TD": "Chad",
		"CL": "Chile",
		"CN": "China",
		"CX": "Isla de Navidad",
		"CC": "Islas Cocos",
		"CO": "Colombia",
		"KM": "Comoras",
		"CG": "Congo",
		"CD": "Congo (República Democrática del)",
		"CK": "Islas Cook",
		"CR": "Costa Rica",
		"HR": "Croacia",
		"CU": "Cuba",
		"CY": "Chipre",
		"CZ": "Chequia",
		"DK": "Dinamarca",
		"DJ": "Yibuti",
		"DM": "Dominica",
		"DO": "República Dominicana",
		"EC": "Ecuador",
		"EG": "Egipto",
		"SV": "El Salvador",
		"GQ": "Guinea Ecuatorial",
		"ER": "Eritrea",
		"EE": "Estonia",
		"ET": "Etiopía",
		"FK": "Islas Malvinas",
		"FO": "Islas Feroe",
		"FJ": "Fiyi",
		"FI": "Finlandia",
		"FR": "Francia",
		"GF": "Guayana Francesa",
		"PF": "Polinesia Francesa",
		"TF": "Territorios Australes Franceses",
		"GA": "Gabón",
		"GM": "Gambia",
		"GE": "Georgia",
		"DE": "Alemania",
		"GH": "Ghana",
		"GI": "Gibraltar",
		"GR": "Grecia",
		"GL": "Groenlandia",
		"GD": "Granada",
		"GP": "Guadalupe",
		"GU": "Guam",
		"GT": "Guatemala",
		"GN": "Guinea",
		"GW": "Guinea-Bisáu",
		"GY": "Guyana",
		"HT": "Haití",
		"HM": "Islas Heard y McDonald",
		"VA": "Ciudad del Vaticano",
		"HN": "Honduras",
		"HK": "Hong Kong",
		"HU": "Hungría",
		"IS": "Islandia",
		"IN": "India",
		"ID": "Indonesia",
		"IR": "Irán",
		"IQ": "Irak",
		"IE": "Irlanda",
		"IL": "Israel",
		"IT": "Italia",
		"JM": "Jamaica",
		"JP": "Japón",
		"JO": "Jordania",
		"KZ": "Kazajistán",
		"KE": "Kenia",
		"KI": "Kiribati",
		"KP": "Corea del Norte",
		"KR": "Corea del Sur",
		"KW": "Kuwait",
		"KG": "Kirguistán",
		"LA": "Laos",
		"LV": "Letonia",
		"LB": "Líbano",
		"LS": "Lesoto",
		"LR": "Liberia",
		"LY": "Libia",
		"LI": "Liechtenstein",
		"LT": "Lituania",
		"LU": "Luxemburgo",
		"MO": "Macao",
		"MK": "Macedonia del Norte",
		"MG": "Madagascar",
		"MW": "Malaui",
		"MY": "Malasia",
		"MV": "Maldivas",
		"ML": "Malí",
		"MT": "Malta",
		"MH": "Islas Marshall",
		"MQ": "Martinica",
		"MR": "Mauritania",
		"MU": "Mauricio",
		"YT": "Mayotte",
		"MX": "México",
		"FM": "Micronesia",
		"MD": "Moldavia",
		"MC": "Mónaco",
		"MN": "Mongolia",
		"MS": "Montserrat",
		"MA": "Marruecos",
		"MZ": "Mozambique",
		"MM": "Myanmar",
		"NA": "Namibia",
		"NR": "Nauru",
		"NP": "Nepal",
		"NL": "Países Bajos",
		"NC": "Nueva Caledonia",
		"NZ": "Nueva Zelanda",
		"NI": "Nicaragua",
		"NE": "Níger",
		"NG": "Nigeria",
		"NU": "Niue",
		"NF": "Isla Norfolk",
		"MP": "Islas Marianas del Norte",
		"NO": "Noruega",
		"OM": "Omán",
		"PK": "Pakistán",
		"PW": "Palaos",
		"PS": "Palestina",
		"PA": "Panamá",
		"PG": "Papúa Nueva Guinea",
		"PY": "Paraguay",
		"PE": "Perú",
		"PH": "Filipinas",
		"PN": "Pitcairn",
		"PL": "Polonia",
		"PT": "Portugal",
		"PR": "Puerto Rico",
		"QA": "Catar",
		"RE": "Reunión",
		"RO": "Rumanía",
		"RU": "Rusia",
		"RW": "Ruanda",
		"SH": "Santa Elena",
		"KN": "San Cristóbal y Nieves",
		"LC": "Santa Lucía",
		"PM": "San Pedro y Miquelón",
		"VC": "San Vicente y las Granadinas",
		"WS": "Samoa",
		"SM": "San Marino",
		"ST": "Santo Tomé y Príncipe",
		"SA": "Arabia Saudita",
		"SN": "Senegal",
		"CS": "Serbia y Montenegro",
		"SC": "Seychelles",
		"SL": "Sierra Leona",
		"SG": "Singapur",
		"SK": "Eslovaquia",
		"SI": "Eslovenia",
		"SB": "Islas Salomón",
		"SO": "Somalia",
		"ZA": "Sudáfrica",
		"GS": "Georgia del Sur e Islas Sandwich del Sur",
		"ES": "España",
		"LK": "Sri Lanka",
		"SD": "Sudán",
		"SR": "Surinam",
		"SJ": "Svalbard y Jan Mayen",
		"SZ": "Suazilandia",
		"SE": "Suecia",
		"CH": "Suiza",
		"SY": "Siria",
		"TW": "Taiwán",
		"TJ": "Tayikistán",
		"TZ": "Tanzania",
		"TH": "Tailandia",
		"TL": "Timor-Leste",
		"TG": "Togo",
		"TK": "Tokelau",
		"TO": "Tonga",
		"TT": "Trinidad y Tobago",
		"TN": "Túnez",
		"TR": "Turquía",
		"TM": "Turkmenistán",
		"TC": "Islas Turcas y Caicos",
		"TV": "Tuvalu",
		"UG": "Uganda",
		"UA": "Ucrania",
		"AE": "Emiratos Árabes Unidos",
		"GB": "Reino Unido",
		"US": "Estados Unidos",
		"UM": "Islas menores alejadas de EE. UU.",
		"UY": "Uruguay",
		"UZ": "Uzbekistán",
		"VU": "Vanuatu",
		"VE": "Venezuela",
		"VN": "Vietnam",
		"VG": "Islas Vírgenes Británicas",
		"VI": "Islas Vírgenes de EE. UU.",
		"WF": "Wallis y Futuna",
		"EH": "Sáhara Occidental",
		"YE": "Yemen",
		"ZM": "Zambia",
		"ZW": "Zimbabue",
		"GG": "Guernsey",
		"IM": "Isla de Man",
		"JE": "Jersey",
		"ME": "Montenegro",
		"BL": "San Bartolomé",
		"MF": "San Martín (parte francesa)",
		"RS": "Serbia",
		"SS": "Sudán del Sur",
		"AX": "Islas Åland",
		"CI": "Costa de Marfil"
	}
}
