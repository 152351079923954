<div *ngIf="event && module && eventUser && treasureHunt && !scanningWeb" class="background" style="overflow-y: auto">
	<div #headerDiv>
		<p class="title" [style.color]="event.styling.titleColor">
			{{ treasureHunt.title ? treasureHunt.title[currentLanguage] : ("treasure_hunts.go_gaming" | translate) }}
		</p>

		<p class="description" [style.color]="event.styling.contentTextColor">
			{{
				treasureHunt.description
					? treasureHunt.description[currentLanguage]
					: ("treasure_hunts.gaming_default_txt" | translate)
			}}
		</p>
	</div>

	<div #counterDiv class="counter-container">
		<div class="text-num" [style.color]="event.styling.titleColor">
			<h2>{{ treasureHuntQrcodes?.length }}</h2>
			<span>{{ "treasure_hunts.total_qrcodes" | translate }}</span>
		</div>
		<div class="divider"></div>
		<div class="text-num">
			<h2>{{ treasureHuntQrcodes?.length - treasureHuntQrcodesResults?.length }}</h2>
			<span>{{ "treasure_hunts.remaining_qrcodes" | translate }}</span>
		</div>
	</div>

	@if (btnPos === "up") {
		<ng-container *ngTemplateOutlet="btnScan"></ng-container>
	}

	<!-- History -->
	<div class="history">
		<h5 class="history-title">{{ "labels.history" | translate }}</h5>
		<ng-container *ngIf="!loader">
			<div #historyDiv class="history-content">
				<ion-item *ngFor="let qrCodeResult of treasureHuntQrcodesResults">
					<label class="ion-text-wrap">
						<span style="font-weight: 600">{{
							getTreasureHuntData(qrCodeResult)
								? getTreasureHuntData(qrCodeResult)?.name[currentLanguage]
								: ""
						}}</span>
						-
						<span style="font-weight: 400; font-size: 0.8em; font-style: italic"
							>{{ getDate(qrCodeResult.creationDate, "full") }},
							{{ getDate(qrCodeResult.creationDate, "time") }}
						</span>
					</label>
				</ion-item>
			</div>
		</ng-container>
	</div>

	@if (btnPos === "down") {
		<ng-container *ngTemplateOutlet="btnScan"></ng-container>
	}
</div>

<ng-template #btnScan>
	<div #btnDiv class="btn-box">
		<button
			class="btn-qrcode"
			[style.background]="event.styling.btnBgColor"
			[style.color]="event.styling.btnTextColor"
			(click)="scanQr()"
			[disabled]="!eventUser || scanning"
		>
			<ion-icon src="assets/icon/Flash.svg"></ion-icon>
			<span>
				{{
					treasureHunt.buttonText
						? treasureHunt.buttonText[currentLanguage]
						: ("treasure_hunts.btn_qrcode" | translate)
				}}
			</span>
		</button>
	</div>
</ng-template>

<div *ngIf="scanningWeb && eventUser" style="position: relative">
	<ion-icon
		(click)="scanning = false; scanningWeb = false; enableScanWeb = false"
		style="position: absolute; top: 20px; right: 20px; font-size: 30px; cursor: pointer; z-index: 99999999"
		color="danger"
		name="close"
	>
	</ion-icon>
	<zxing-scanner
		[enable]="enableScanWeb"
		(camerasFound)="camerasFoundHandler()"
		(camerasNotFound)="camerasNotFoundHandler()"
		(scanSuccess)="scanSuccessHandler($event)"
		(scanError)="scanErrorHandler()"
	>
	</zxing-scanner>
</div>

<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
