/* eslint-disable max-len */
// staging environment
export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyATjXlKOJApi2mseEyxz9hzj-S1kiSisw0",
		authDomain: "b3-app-staging.firebaseapp.com",
		databaseURL: "https://b3-app-staging-default-rtdb.europe-west1.firebasedatabase.app",
		projectId: "b3-app-staging",
		storageBucket: "b3-app-staging.appspot.com",
		messagingSenderId: "397523982983",
		appId: "1:397523982983:web:c4b827723e6b993b67b622",
		measurementId: "G-V30WKGYZB6"
	},
	platform: {
		appName: "B3app staging",
		apiBaseUrl: "https://europe-west3-b3-app-staging.cloudfunctions.net/",
		apiV2BaseUrl: "-v6i3bbug2a-ey.a.run.app",
		oneSignalAppId: "84178a12-6a22-48c5-af5a-279921e04b21",
		oneSignalApiId:
			"os_v2_app_qqlyuetkejemll22e6msdyclef4j3nntiv5ehonjonqwzqwbxpknumlc5bky7xh4ucbstolm5j6fwr6lvatvy5vohnaymn3d2vihfaq",
		defaultLanguage: "FrFR", // FrFR EnUS DeDE PtBR EsES ArAR
		defaultTimezone: "Europe/Paris", // Europe/Paris America/Sao_Paulo
		region: "europe-west3",
		dateFormat: "EEEE dd-MM-yyyy • HH:mm",
		locale: "fr-FR", // fr-FR en-US de-DE pt-BR es-ES
		loginLogoClass: "invent-logo", // options is 'banner-logo' or 'invent-logo'
		containerId: "defaultContainer", // defaultContainer or id of container
		persistence: true,
		version: "3.7.0",
		sparkupDefaultUrl: "https://demo.sparkup.live/connect/INVEN",
		tinyMce: {
			init: {
				base_url: "/tinymce", // Root for resources
				suffix: ".min",
				height: 450,
				menubar: false,
				plugins: "code paste lists media image",
				toolbar: [
					"code | paste pastetext | undo redo | bold italic underline strikethrough | numlist bullist | image media",
					"formatselect fontselect fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify"
				],
				valid_elements: "*[*]"
			},
			initMobile: {
				mobile: {
					base_url: "/tinymce", // Root for resources
					suffix: ".min",
					menubar: true,
					theme: "mobile",
					plugins: "autosave lists autolink",
					toolbar: "undo bold italic styleselect"
				}
			},
			initNothing: {
				base_url: "/tinymce", // Root for resources
				suffix: ".min",
				// height: 450,
				min_height: 150,
				max_height: 450,
				menubar: false,
				plugins: ["autoresize", "lists", "quickbars"],
				toolbar: [],
				quickbars_selection_toolbar: "bold italic underline | blocks | bullist numlist | blockquote",
				valid_elements: "*[*]"
			}
		},
		recaptchaSettings: {
			easy: { siteKey: "6LfGcrwhAAAAAAyou-GLN2pJf4iDMlQcdIZSMXgM" },
			medium: { siteKey: "6LeSTb0hAAAAAOGJLEj0rfis3EFllY5JfMvWfovB" },
			hard: { siteKey: "6LecfLwhAAAAACdkS99NEn22ypR9u_XY9jsKh8yS" }
		},
		recaptchaV3Settings: { siteKey: "6LfBU60hAAAAAOEC3W6FAIYJKyv1TkawqhjIslpu" },
		googleMapApiKey: "AIzaSyAOhR7K9nZ0INeOIR_SL8bE5xzP2sV84do",
		optimisationEvent: true,
		encryptKey: "sTVH798NkDsPikbfHL7JRsgEeY336kGE",
		saveFileVersion: 1,
		appointments: {
			"confirmed-rdv-icon":
				"https://firebasestorage.googleapis.com/v0/b/invent-app-prd.appspot.com/o/rdv-confirmed-icon.png?alt=media&token=477c1773-7685-4cf3-b16f-b835a47f0ff5",
			"cancelled-rdv-icon":
				"https://firebasestorage.googleapis.com/v0/b/invent-app-prd.appspot.com/o/rdv-cancelled-icon.png?alt=media&token=868258c3-ad43-41af-91ee-c168bbaf8b27"
		}
	}
};
