import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "cf-more-less",
	templateUrl: "./cf-more-less.component.html",
	styleUrls: ["./cf-more-less.component.scss"],
	standalone: false
})
export class MoreLessComponent implements OnInit {
	@Input() text: string;
	@Input() textLength: number;

	showMore: boolean = false;

	ngOnInit() {
		if (!this.textLength) this.textLength = 28;

		if (!this.text) this.text = "";
	}

	/**
	 * changeShowState
	 */
	changeShowState() {
		this.showMore = !this.showMore;
	}
}
