import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IEventUser, IModule } from "../interfaces";
import { ICustomPagesState } from "../interfaces/custom-pages.interfaces";
import { getCurrentEventUser } from "./auth.selectors";
import { getSpecificModule } from "./modules.selectors";

export const getCustomPagesState = createFeatureSelector<ICustomPagesState>("customPages");

export const checkSameEvent = (uid: string) =>
	createSelector(getCustomPagesState, (state: ICustomPagesState) => {
		return state.eventId === uid ? true : false;
	});

export const getCustomPages = createSelector(getCustomPagesState, (state: ICustomPagesState) =>
	_.cloneDeep(state.customPages)
);

export const getBasicCustomPagesOfModule = (moduleId: string) =>
	createSelector(getCustomPagesState, getCurrentEventUser, (state: ICustomPagesState, eventUser: IEventUser) => {
		const customPages = _.cloneDeep(state.customPages);
		return customPages.filter(
			(customPage) =>
				customPage.moduleId === moduleId &&
				(customPage.visionType === 0 ||
					(customPage.visionType === 1 &&
						eventUser &&
						eventUser.groups.some((grp) => customPage.groups.includes(grp))))
		);
	});

export const getCustomPagesOfModule = (data: { moduleId: string; language: string }) =>
	createSelector(
		getCustomPagesState,
		getCurrentEventUser,
		getSpecificModule(data.moduleId),
		(state: ICustomPagesState, eventUser: IEventUser, module: IModule) => {
			const customPages = _.cloneDeep(state.customPages);
			return customPages
				.filter((customPage) => {
					if (
						customPage.moduleId === data.moduleId &&
						(customPage.visionType === 0 ||
							(customPage.visionType === 1 &&
								eventUser &&
								eventUser.groups.some((grp) => customPage.groups.includes(grp))))
					) {
						return true;
					}
				})
				.sort((a, b) => {
					if (module.typeOrder === "asc") {
						return a.name[data.language] > b.name[data.language]
							? 1
							: a.name[data.language] < b.name[data.language]
								? -1
								: 0;
					} else if (module.typeOrder === "desc") {
						return a.name[data.language] < b.name[data.language]
							? 1
							: a.name[data.language] > b.name[data.language]
								? -1
								: 0;
					} else if (module.typeOrder === "recent") {
						return a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0;
					} else if (module.typeOrder === "oldest") {
						return a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0;
					} else if (module.typeOrder === "custom") {
						return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
					}
				});
		}
	);

export const getSpecificCustomPage = (uid: string) =>
	createSelector(getCustomPagesState, (state: ICustomPagesState) =>
		_.cloneDeep(state.customPages).find((customPage) => customPage.uid === uid)
	);
