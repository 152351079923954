import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { GetHeaderState, GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { ICustomPage, IEvent, IEventUser, IModule, IUser } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCustomPagesOfModule } from "src/app/shared/selectors/custom-pages.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService } from "src/app/shared/services";
import * as _ from "lodash-es";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-custom-pages",
	templateUrl: "./custom-pages.component.html",
	styleUrls: ["./custom-pages.component.scss"],
	standalone: false
})
export class CustomPagesComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	destroySuscription: Subject<any> = new Subject();
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	loader: boolean = true;

	event: IEvent;
	eventId: string;
	moduleId: string;
	module: IModule;
	user: IUser;
	eventUser: IEventUser;
	customPages: ICustomPage[] = [];
	customPageId: string;
	customPageSelected: ICustomPage;

	isMobile: boolean = false;
	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(
		private SAnalytics: AnalyticsService,
		private platform: Platform,
		private store: Store,
		private navCtrl: NavController,
		private STranslate: TranslateService
	) {}

	ngOnInit(): void {
		this.currentLanguage = this.STranslate.currentLang;
		this.STranslate.onLangChange.pipe(takeUntil(this.destroySuscription)).subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;
	}

	ionViewWillEnter() {
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.customPageSelected = null;
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.customPageId = params.customPageId;

						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_CUSTOM_PAGES_MODULE
						);

						this.getEvent();
						this.getEventUser();
						this.getModule();
						this.getCustomPages();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngOnDestroy() {
		this.destroySuscription.complete();
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Get event
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				this.eventUser = eventUser;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;
				if (this.module && this.customPageId === "all") {
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				}
			})
		);
	}

	/**
	 * Getting custom page list
	 */
	getCustomPages() {
		this.subscriptions.push(
			this.store
				.select(getCustomPagesOfModule({ moduleId: this.moduleId, language: this.event.language }))
				.subscribe((customPages) => {
					if (!_.isEqual(this.customPages, customPages)) {
						this.customPages = customPages;
					}

					if (this.customPageId && this.customPageId !== "all" && this.customPages.length > 0) {
						this.customPageSelected = this.customPages.find(
							(customPage) => customPage.uid === this.customPageId
						);
						this.store.dispatch(
							GetHeaderState({
								payload: {
									item: this.customPageSelected,
									module: this.module,
									title: this.customPageSelected.name,
									type: TypeHeader.CUSTOM_PAGE
								}
							})
						);
					}
					this.loader = false;
				})
		);
	}

	/**
	 * Open custom page
	 * @param customPage
	 */
	openCustomPage(customPage: ICustomPage) {
		this.navCtrl.navigateForward(`event/${this.event.uid}/custom-pages/${this.moduleId}/${customPage.uid}`);
	}
}
