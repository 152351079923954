<ion-content id="main">
	<div
		[ngClass]="isMobile ? 'background background-no-scrollbar' : 'background'"
		style="background-color: &quot;black&quot;; background-size: contain"
		[ngStyle]="{
			'background-image': isMobile
				? 'url(' + event.adsContent.mobileImageUrl + ')'
				: 'url(' + event.adsContent.desktopImageUrl + ')'
		}"
	>
		<div [ngClass]="device === 'ios' ? 'ios-close-btn' : 'close-btn'" class="p-l-10 p-t-20">
			<ion-button *ngIf="canCloseModal" fill="clear" color="dark" (click)="close()">
				<ion-icon color="danger" src="assets/icon/Close.svg"></ion-icon>
			</ion-button>

			<ion-chip *ngIf="!canCloseModal">
				<ion-icon name="timer" color="primary"></ion-icon>
				<ion-label>{{ timerCount }} &nbsp; {{ "labels.seconds" | translate }}</ion-label>
			</ion-chip>
		</div>

		<div class="align-loader" *ngIf="loader">
			<div class="loader-default"></div>
		</div>

		<!-- Add content -->
		<div class="ad-content"></div>
	</div>
</ion-content>
