import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { IEvent } from "src/app/shared/interfaces";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";

@Component({
	selector: "app-terms-n-privacy",
	templateUrl: "./terms-n-privacy.component.html",
	styleUrls: ["./terms-n-privacy.component.scss"],
	standalone: false
})
export class TermsNPrivacyComponent implements OnInit, OnDestroy, AfterViewInit {
	subscriptions: Subscription[] = [];

	eventId: string;
	event: IEvent;

	showAll: boolean = true;
	showTerms: boolean = false;
	showPrivacy: boolean = false;
	showCookiePolicy: boolean = false;

	type: string;

	currentLanguage: string;

	constructor(
		private navParams: NavParams,
		private modalCtrl: ModalController,
		private store: Store,
		private STranslate: TranslateService
	) {
		this.eventId = this.navParams.get("eventId");
		if (this.navParams.get("type")) {
			this.type = this.navParams.get("type");
			if (this.type === "termsOfUse" && this.event.termsOfUse.showLegal) {
				this.showAll = false;
				this.showPrivacy = false;
				this.showCookiePolicy = false;
				this.showTerms = true;
			} else if (this.type === "privacy" && this.event.privacy.showLegal) {
				this.showAll = false;
				this.showTerms = false;
				this.showCookiePolicy = false;
				this.showPrivacy = true;
			} else if (this.type === "cookiePolicy" && this.event.cookiePolicy.showLegal) {
				this.showAll = false;
				this.showTerms = false;
				this.showPrivacy = false;
				this.showCookiePolicy = true;
			} else {
				this.showAll = false;
				this.showTerms = false;
				this.showPrivacy = false;
				this.showCookiePolicy = false;
			}
		}
	}

	ngOnInit() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	ngAfterViewInit() {
		if (navigator.appVersion.indexOf("Win") != -1) {
			const elements = document.querySelectorAll(".windows_scrollbar");
			elements.forEach((e) => {
				e.setAttribute("class", "custom_scrollbar");
			});
		}
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	close() {
		this.modalCtrl.dismiss();
		this.type = null;
		this.eventId = null;
	}

	showRightBlock(type: string) {
		this.showAll = false;
		this.showTerms = false;
		this.showCookiePolicy = false;
		this.showPrivacy = false;
		switch (type) {
			case "terms-of-use":
				this.showTerms = true;
				break;
			case "privacy":
				this.showPrivacy = true;
				break;
			case "cookiePolicy":
				this.showCookiePolicy = true;
				break;
		}
	}

	goBack() {
		this.showAll = true;
		this.showTerms = false;
		this.showPrivacy = false;
		this.showCookiePolicy = false;
	}
}
