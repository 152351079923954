import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { IContainer, IEvent } from "src/app/shared/interfaces";

@Component({
	selector: "app-terms-of-use",
	templateUrl: "./terms-of-use.component.html",
	styleUrls: ["./terms-of-use.component.scss"],
	standalone: false
})
export class TermsOfUseComponent implements OnInit {
	terms: string = null;
	event: IEvent;
	containerSub: Subscription;
	container: IContainer;

	constructor(
		private modalCtrl: ModalController,
		private navParams: NavParams,
		private STranslate: TranslateService
	) {}

	ngOnInit() {
		if (!this.navParams.get("terms")) {
			if (!this.terms) {
				this.terms = this.event.termsOfUse.content[this.STranslate.currentLang]
					? this.event.termsOfUse.content[this.STranslate.currentLang]
					: this.event.termsOfUse.content;
			}
		} else {
			(!this.terms || this.terms === "") && (this.terms = this.navParams.get("terms"));
		}
	}

	close() {
		this.modalCtrl.dismiss();
	}
}
