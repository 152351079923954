<div
	*ngIf="event && module && eventUser"
	class="background"
	[class.full-w]="componentMode"
	[style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'"
>
	<!-- <ion-searchbar *ngIf="!componentMode" searchIcon="assets/icon/Search.svg" mode="md"
        placeholder='{{"texts.search_plc" | translate}}' (ionClear)="q = ''" (keyup)="searchBar($event)">
    </ion-searchbar> -->
	<br />

	<ion-segment *ngIf="!componentMode" [style.--background]="'transparent'" [(ngModel)]="type" mode="md" value="chats">
		<ion-segment-button
			[style.--border-color]="event.styling.btnBgColor"
			[style.--border-radius]="'30px'"
			[style.--background]="'transparent'"
			[style.--background-checked]="event.styling.btnBgColor"
			[style.--background-focused]="event.styling.btnBgColor"
			[style.--color]="event.styling.btnBgColor"
			[style.--color-checked]="event.styling.btnTextColor"
			value="chats"
		>
			<ion-label>{{
				module.options.segmentChatName
					? module.options.segmentChatName[currentLanguage]
					: ("chat.chats" | translate)
			}}</ion-label>
		</ion-segment-button>
		<ion-segment-button
			*ngIf="checkDiscussionGroup()"
			[style.--border-color]="event.styling.btnBgColor"
			[style.--border-radius]="'30px'"
			[style.--background]="'transparent'"
			[style.--background-checked]="event.styling.btnBgColor"
			[style.--background-focused]="event.styling.btnBgColor"
			[style.--color]="event.styling.btnBgColor"
			[style.--color-checked]="event.styling.btnTextColor"
			value="groups"
		>
			<ion-label>{{
				module.options.segmentChatGroupName
					? module.options.segmentChatGroupName[currentLanguage]
					: ("chat.groups" | translate)
			}}</ion-label>
		</ion-segment-button>
	</ion-segment>

	<ng-container *ngIf="getChatsByType().length > 0; else noContentChat">
		<ng-container *ngIf="getChatsByType().length > 0">
			<div
				class="cursor-pointer"
				*ngFor="let chat of getChatsByType() | filterChats: q; let i = index; trackBy: trackByChat"
				(click)="goToChat(chat)"
			>
				<!-- <h2 *ngIf="type === 'groups'" [style.margin-bottom]="'0px'"
                    [style.color]="event.styling.contentTextColor">
                    <ion-icon class="icon-40" [style.margin-right]="'10px'" *ngIf="chat.options.official"
                        color="warning" name="star"></ion-icon>
                    {{ chat.options.name }}
                </h2> -->
				<ion-item *ngIf="chat" class="item-chat" lines="none">
					<ion-avatar
						[class.chat-avatar]="chat.type === 0"
						[class.group-avatar-image]="chat.type === 1 && chat.options.groupImage"
						[class.group-avatar]="chat.type === 1 && !chat.options.groupImage"
						slot="start"
					>
						<img
							[src]="getChatImage(chat)"
							*ngIf="
								(chat.type === 0 && chat.interlocutor && chat.interlocutor.photoUrl) || chat.type === 1
							"
						/>
						<div *ngIf="(!chat.interlocutor || !chat.interlocutor.photoUrl) && chat.type === 0">
							<img class="cus-picture" src="assets/icon/User2.svg" />
						</div>
					</ion-avatar>
					<ion-label [ngClass]="getNotificationsForChat(chat) ? 'msg-not-read' : 'msg-read'">
						<h2>
							{{
								chat.type === 1
									? chat.options?.name
									: chat.interlocutor
										? chat.interlocutor?.name
										: chat.lastMessageUser && chat.lastMesageUser?.name
											? chat.lastMesageUser?.name
											: ""
							}}
							<ion-icon
								*ngIf="chat.type === 1 && chat.options.official"
								class="icon-40"
								[style.margin-right]="'10px'"
								color="warning"
								name="star"
							>
							</ion-icon>
						</h2>
						<p *ngIf="chat.lastMessageUser" class="last-msg">
							<span *ngIf="eventUser && chat.lastMessageUser.uid === eventUser.uid"
								>{{ "chat.you" | translate }}&nbsp;</span
							>
							{{
								getLastMessageOfChat(chat) && getLastMessageOfChat(chat).type === 0
									? getLastMessageOfChat(chat).content
									: ("texts.picture" | translate)
							}}
						</p>
						<p *ngIf="!chat.lastMessageUser" class="msg-read">{{ "chat.no_messages" | translate }}</p>
						<p *ngIf="chat.lastMessageUser" class="time">
							{{
								getLastMessageOfChat(chat)
									? getDate(getLastMessageOfChat(chat) ? getLastMessageOfChat(chat).sendAt : "")
									: ""
							}}
						</p>
					</ion-label>
					<ion-icon
						class="notifs-icon"
						[style.color]="event.styling.btnBgColor"
						*ngIf="eventUser && chat.allowNotifs && !checkAllowNotifsUserChat(chat)"
						name="notifications-off"
						slot="end"
					>
					</ion-icon>
					<ion-badge *ngIf="getNotificationsForChat(chat)" class="notif" slot="end" color="success">
						1
					</ion-badge>
				</ion-item>
			</div>
		</ng-container>
	</ng-container>
	<ng-template #noContentChat>
		<div class="container-no-content-chat">
			<span class="wait-content" [style.color]="event.styling.titleColor">{{
				"texts.waiting_for_content" | translate
			}}</span>
			<ion-icon
				src="assets/images/emptycontent/CHAT.svg"
				[style.color]="
					event.styling.menuColor.includes('linear')
						? event.styling.menuColor.split(',')[1]
						: event.styling.menuColor
				"
			>
			</ion-icon>
			<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
				<span class="title"> {{ "chat.chat_empty_content_title" | translate }}</span>
				<span>{{ "chat.chat_empty_content_text" | translate }}</span>
			</div>
		</div>
	</ng-template>
</div>
<ng-template #loading>
	<ion-item-divider>
		<ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
	</ion-item-divider>
	<ion-item lines="none" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
		<ion-avatar slot="start">
			<img src="../../../assets/images/grey.jpg" />
		</ion-avatar>
		<ion-label>
			<h3>
				<ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
			</h3>
		</ion-label>
	</ion-item>
</ng-template>

<app-login-register-buttons *ngIf="event && module && !eventUser" [event]="event"></app-login-register-buttons>
