<ion-content class="page">
	<div
		*ngIf="!isMobile && event && folder.folderAllowModificationGridDisplayType"
		style="position: absolute; top: 0; right: 0; z-index: 99"
	>
		<ion-icon
			*ngIf="!isMobile && folder.folderGridDisplayType === 'column'"
			class="icons-40 m-a-10"
			(click)="folder.folderGridDisplayType = 'row'; changeVideoDimensions('row')"
			[style.color]="event.styling.menuColor"
			name="grid"
		>
		</ion-icon>
		<ion-icon
			*ngIf="!isMobile && folder.folderGridDisplayType === 'row'"
			class="icons-40 m-a-10"
			(click)="folder.folderGridDisplayType = 'column'; changeVideoDimensions('column')"
			[style.color]="event.styling.menuColor"
			name="albums"
		>
		</ion-icon>
	</div>

	<div *ngIf="event && module && folder && images.length === 0 && videos.length === 0" class="background">
		<div class="container-no-content">
			<span class="wait-content" [style.color]="event.styling.titleColor">{{
				"texts.waiting_for_content" | translate
			}}</span>
			<ion-icon
				src="assets/images/emptycontent/GALERIE.svg"
				[style.color]="
					event.styling.menuColor.includes('linear')
						? event.styling.menuColor.split(',')[1]
						: event.styling.menuColor
				"
			>
			</ion-icon>
			<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
				<span class="title"> {{ "gallery.empty_content_title" | translate }}</span>
				<span>{{ "gallery.empty_content_text" | translate }}</span>
			</div>
		</div>
	</div>

	<div
		*ngIf="event && module && folder && (images.length > 0 || videos.length > 0)"
		class="background"
		style="overflow: auto"
		#parentDiv
	>
		<!-- <div class="container m-t-10 m-b-10 no-paddings"
            [ngStyle]="{'grid-template-rows': getRows(images.length, parentDiv), 'grid-template-columns': getColumns(parentDiv) }">
            <div *ngFor="let image of images; let i = index" class="m-r-15 m-l-15"
                [style.background-image]="'url('+ image.url + ')'" [style.background-size]="backgroundSize()"
                [style.height]="getImageHeight(parentDiv)" (click)="openImage(image)"></div>
        </div> -->

		<ion-grid>
			<ion-row>
				<!-- Images -->
				<ion-col
					*ngFor="let image of images; let i = index"
					[attr.size]="folder.folderGridDisplayType === 'column' ? 6 : 12"
				>
					<div>
						<img [src]="image.url" (click)="openImage(image)" />
					</div>
				</ion-col>

				<!-- Videos -->
				<ion-col
					*ngFor="let video of videos; let i = index"
					[attr.size]="!isMobile && folder.folderGridDisplayType === 'column' ? 6 : 12"
				>
					<!-- Youtube videos -->
					<div *ngIf="video.source === typeVideoSource.YOUTUBE" class="video-bloc">
						<h4
							[style.width]="
								folder.folderGridDisplayType === 'column' || isMobile ? 'inherit' : 'fit-content'
							"
							class="video-title"
						>
							{{ video.name }}
						</h4>
						<iframe
							[attr.id]="video.uid"
							[width]="getVideoWidth()"
							[height]="getVideoHeight()"
							[src]="video.url | safeHtml: 'resourceUrl'"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>

					<!-- Vimeo videos -->
					<div *ngIf="video.source === typeVideoSource.VIMEO" class="video-bloc">
						<h4
							[style.width]="
								folder.folderGridDisplayType === 'column' || isMobile ? 'inherit' : 'fit-content'
							"
							class="video-title"
						>
							{{ video.name }}
						</h4>
						<iframe
							[attr.id]="video.uid"
							[width]="getVideoWidth()"
							[height]="getVideoHeight()"
							[src]="video.url | safeHtml: 'resourceUrl'"
							frameborder="0"
							allow="autoplay; fullscreen"
							allowfullscreen
						></iframe>
					</div>

					<!-- Dailymotion videos -->
					<div *ngIf="video.source === typeVideoSource.DAILYMOTION" class="video-bloc">
						<h4
							[style.width]="
								folder.folderGridDisplayType === 'column' || isMobile ? 'inherit' : 'fit-content'
							"
							class="video-title"
						>
							{{ video.name }}
						</h4>
						<iframe
							[attr.id]="video.uid"
							[width]="getVideoWidth()"
							[height]="getVideoHeight()"
							[src]="video.url | safeHtml: 'resourceUrl'"
							frameborder="0"
							allow="autoplay; fullscreen"
							allowfullscreen
						></iframe>
					</div>

					<!-- Facebook videos -->
					<div *ngIf="video.source === typeVideoSource.FACEBOOK" class="video-bloc">
						<h4
							[style.width]="
								folder.folderGridDisplayType === 'column' || isMobile ? 'inherit' : 'fit-content'
							"
							class="video-title"
						>
							{{ video.name }}
						</h4>
						<iframe
							[attr.id]="video.uid"
							[width]="getVideoWidth()"
							[height]="getVideoHeight()"
							[src]="video.url | safeHtml: 'resourceUrl'"
							frameborder="0"
							allow="autoplay; fullscreen"
							allowfullscreen
						></iframe>
					</div>

					<!-- Other video -->
					<div *ngIf="video.source === typeVideoSource.OTHER" class="video-bloc">
						<h4
							[style.width]="
								folder.folderGridDisplayType === 'column' || isMobile ? 'inherit' : 'fit-content'
							"
							class="video-title"
						>
							{{ video.name }}
						</h4>
						<!-- <video class="video-player-global" #videoPlayer [width]="getVideoWidth()"
                            [height]="getVideoHeight()" controls autoplay>
                            <source [src]="video.url" type="video/mp4">
                            Your browser does not support the video tag.
                        </video> -->
						<iframe
							[attr.id]="video.uid"
							[class]="isMobile ? 'video-player-global-mobile' : 'video-player-global'"
							[width]="getVideoWidth()"
							[height]="getVideoHeight()"
							[src]="video.url | safeHtml: 'resourceUrl'"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</ion-content>
