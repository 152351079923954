<div class="main-container">
	@if (!loader) {
		<form [formGroup]="formCustomFields">
			@for (computedCustomField of computedCustomFields; track computedCustomField; let i = $index) {
				<ion-row>
					<ion-col
						[hidden]="computedCustomField && !computedCustomField.moduleSettings.editionScope"
						class="custom-ion-col"
						col-12
					>
						<!-- "Text" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.TEXT) {
							<div class="custom-field-container">
								<ion-item
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									mode="md"
									lines="none"
									no-lines
									class="input-item"
								>
									<ion-label mode="md" position="floating" class="label-color">
										{{ computedCustomField.baseSettings.name[currentLanguage] }}
										@if (computedCustomField.moduleSettings.required) {
											<span class="required"> * </span>
										}
									</ion-label>
									<ion-input
										autocapitalize="sentences"
										mode="md"
										class="custom-input input-color"
										type="text"
										[ngStyle]="{
											color: formCustomFields.get(
												computedCustomField.baseSettings.uid + '_' + currentLanguage
											)?.errors
												? '#900C3F'
												: '#4B4D4D'
										}"
										placeholder="{{ 'edit-profil.placeholders.simple_text-info' | translate }}"
										formControlName="{{ computedCustomField.baseSettings.uid }}_{{
											currentLanguage
										}}"
										[disabled]="
											computedCustomField.moduleSettings.uniqueEdit &&
											computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !==
												'' &&
											computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !==
												null &&
											computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !==
												undefined
										"
									>
									</ion-input>
									<cf-form-visual-icon
										[mode]="
											isMultiLanguageField(computedCustomField)
												? 'multiLanguage'
												: 'singleLanguage'
										"
										class="input-icon"
										[computedCustomField]="computedCustomField"
										[form]="formCustomFields"
										[language]="currentLanguage"
									></cf-form-visual-icon>
								</ion-item>

								<cf-form-visual-error
									[mode]="
										isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'
									"
									[computedCustomField]="computedCustomField"
									[form]="formCustomFields"
									[language]="currentLanguage"
								></cf-form-visual-error>
							</div>
						}
						<!-- "Select - Unique" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.SELECT) {
							<div class="custom-field-container">
								<ion-item
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									mode="md"
									lines="none"
									class="input-item"
								>
									<ion-label mode="md" position="floating" class="label-color">
										{{ computedCustomField.baseSettings.name[currentLanguage] }}
										@if (computedCustomField.moduleSettings.required) {
											<span class="required"> * </span>
										}
									</ion-label>
									<ion-select
										mode="md"
										class="custom-input input-color"
										type="text"
										[ngStyle]="{
											color:
												formCustomFields.get(
													computedCustomField.baseSettings.uid + '_' + currentLanguage
												) &&
												formCustomFields.get(
													computedCustomField.baseSettings.uid + '_' + currentLanguage
												).errors
													? '#900C3F'
													: '#4B4D4D'
										}"
										placeholder="{{ 'edit-profil.placeholders.select_text-info' | translate }}"
										formControlName="{{ computedCustomField.baseSettings.uid }}_{{
											currentLanguage
										}}"
										[compareWith]="compareFn"
										[disabled]="
											computedCustomField.moduleSettings.uniqueEdit &&
											computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !==
												'' &&
											computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !==
												null &&
											computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !==
												undefined
										"
									>
										@for (
											option of computedCustomField.baseSettings.options;
											track option;
											let optIndex = $index
										) {
											<ion-select-option [value]="option">
												<span>{{ option[currentLanguage] }}</span>
											</ion-select-option>
										}
									</ion-select>
									<cf-form-visual-icon
										[mode]="
											isMultiLanguageField(computedCustomField)
												? 'multiLanguage'
												: 'singleLanguage'
										"
										class="input-icon"
										[computedCustomField]="computedCustomField"
										[form]="formCustomFields"
										[language]="currentLanguage"
									></cf-form-visual-icon>
								</ion-item>

								<cf-form-visual-error
									[mode]="
										isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'
									"
									[computedCustomField]="computedCustomField"
									[form]="formCustomFields"
									[language]="currentLanguage"
								></cf-form-visual-error>
							</div>
						}
						<!-- "Select - Multiple" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.MULTI_SELECT) {
							<div class="custom-field-container">
								<ion-item
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									mode="md"
									lines="none"
									class="input-item"
								>
									<ion-label mode="md" position="floating" class="label-color">
										{{ computedCustomField.baseSettings.name[currentLanguage] }}
										@if (computedCustomField.moduleSettings.required) {
											<span class="required"> * </span>
										}
									</ion-label>
									<ion-select
										multiple
										mode="md"
										class="custom-input input-color"
										type="text"
										[ngStyle]="{
											color:
												formCustomFields.get(
													computedCustomField.baseSettings.uid + '_' + currentLanguage
												) &&
												formCustomFields.get(
													computedCustomField.baseSettings.uid + '_' + currentLanguage
												)?.errors
													? '#900C3F'
													: '#4B4D4D'
										}"
										placeholder="{{
											'edit-profil.placeholders.multi-select_text-info' | translate
										}}"
										formControlName="{{ computedCustomField.baseSettings.uid }}_{{
											currentLanguage
										}}"
										[compareWith]="compareFn"
										[disabled]="
											computedCustomField.moduleSettings.uniqueEdit &&
											computedCustomField.fieldDatas.field.multiLanguageSelectArray &&
											computedCustomField.fieldDatas.field.multiLanguageSelectArray.length > 0
										"
									>
										@for (option of computedCustomField.baseSettings.options; track option) {
											<ion-select-option [value]="option">
												{{ option[currentLanguage] }}
											</ion-select-option>
										}
									</ion-select>
									<cf-form-visual-icon
										[mode]="
											isMultiLanguageField(computedCustomField)
												? 'multiLanguage'
												: 'singleLanguage'
										"
										class="input-icon"
										[computedCustomField]="computedCustomField"
										[form]="formCustomFields"
										[language]="currentLanguage"
									></cf-form-visual-icon>
								</ion-item>

								<cf-form-visual-error
									[mode]="
										isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'
									"
									[computedCustomField]="computedCustomField"
									[form]="formCustomFields"
									[language]="currentLanguage"
								></cf-form-visual-error>
							</div>
						}
						<!-- "HTML" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.HMTL) {
							<div class="custom-field-container" style="display: flex; justify-content: center">
								<ion-item class="tw-w-full" lines="none">
									<ion-label mode="md" position="floating" class="label-color">
										{{ computedCustomField.baseSettings.name[currentLanguage] }}
										@if (computedCustomField.moduleSettings.required) {
											<span class="required"> * </span>
										}
									</ion-label>
									<editor
										manageFieldVisibility
										[datas]="{
											event: event,
											module: module,
											fieldId: computedCustomField.baseSettings.uid,
											computedCustomFields: computedCustomFields
										}"
										[(eventUser)]="eventUserProfile"
										[init]="getTinyInit()"
										formControlName="{{ computedCustomField.baseSettings.uid }}_{{
											currentLanguage
										}}"
										[disabled]="
											computedCustomField.moduleSettings.uniqueEdit &&
											computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !==
												'' &&
											computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !==
												null &&
											computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !==
												undefined
										"
										style="width: 100%; margin: 23px 0; white-space: pre-line"
									>
									</editor>
								</ion-item>
							</div>
						}
						<!-- "Multiple text" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.MULTI_TEXT) {
							<div class="custom-field-container">
								<ion-item
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									mode="md"
									lines="none"
									class="input-item"
								>
									<ion-label mode="md" position="floating" class="label-color">
										{{ computedCustomField.baseSettings.name[currentLanguage] }}
										@if (computedCustomField.moduleSettings.required) {
											<span class="required"> * </span>
										}
									</ion-label>
									<ion-input
										mode="md"
										class="custom-input input-color"
										type="text"
										[ngStyle]="{
											color:
												formCustomFields &&
												formCustomFields?.get(
													computedCustomField.baseSettings.uid + '_' + currentLanguage
												)?.errors
													? '#900C3F'
													: '#4B4D4D'
										}"
										formControlName="{{ computedCustomField.baseSettings.uid }}_{{
											currentLanguage
										}}"
										[disabled]="
											computedCustomField.moduleSettings.uniqueEdit &&
											computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage]
												.length > 0
										"
										(keypress)="
											onEnter(
												$event,
												getMultiTextInputIndex(computedCustomField.baseSettings.uid),
												computedCustomField.baseSettings.name[currentLanguage],
												computedCustomField.baseSettings.uid
											)
										"
										(focusout)="reinitializeInputStatus($event)"
										id="{{ computedCustomField.baseSettings.name[currentLanguage] }}"
										placeholder="{{ 'edit-profil.placeholders.multiple_text-info' | translate }}"
									>
									</ion-input>
									<!-- Errors icon -->
									@if (
										formCustomFields?.get(
											computedCustomField.baseSettings.uid + "_" + currentLanguage
										)?.errors &&
										(formCustomFields?.get(
											computedCustomField.baseSettings.uid + "_" + currentLanguage
										)?.touched ||
											formCustomFields?.get(
												computedCustomField.baseSettings.uid + "_" + currentLanguage
											)?.dirty)
									) {
										<ion-icon
											src="assets/icon/Close.svg"
											class="check-icon"
											slot="end"
											color="danger"
										>
										</ion-icon>
									}
								</ion-item>

								<!-- On Enter Error MSG -->
								@if (
									multipleTextInputError[
										getMultiTextInputErrorIndex(computedCustomField.baseSettings.uid)
									]?.msg !== ""
								) {
									<div class="form-bloc-error">
										<p class="error-label-text">
											{{
												multipleTextInputError[
													getMultiTextInputErrorIndex(computedCustomField.baseSettings.uid)
												]?.msg
											}}
										</p>
									</div>
								}
								<!-- Validators errors -->
								@if (
									formCustomFields.get(computedCustomField.baseSettings.uid + "_" + currentLanguage)
										?.errors &&
									(formCustomFields.get(computedCustomField.baseSettings.uid + "_" + currentLanguage)
										?.touched ||
										formCustomFields.get(
											computedCustomField.baseSettings.uid + "_" + currentLanguage
										)?.dirty)
								) {
									<div class="form-bloc-error">
										<p class="error-label-text">
											{{ "edit-profil.errors.invalid.multiple_text-too-long" | translate }}
										</p>
									</div>
								}
								<!-- Ion chip item -->
								@if (
									multipleTextValues[getMultiTextInputIndex(computedCustomField.baseSettings.uid)]
										?.multiLanguageTextArray &&
									multipleTextValues[getMultiTextInputIndex(computedCustomField.baseSettings.uid)]
										?.multiLanguageTextArray[currentLanguage].length > 0
								) {
									<div class="filter-group padding-10 margin-b-0 multiple-text-input">
										@for (
											value of multipleTextValues[
												getMultiTextInputIndex(computedCustomField.baseSettings.uid)
											]?.multiLanguageTextArray[currentLanguage];
											track value
										) {
											<span>
												<ion-chip>
													<!-- <ion-icon name="pin" color="primary"></ion-icon> -->
													<ion-label class="multiple-text-value">{{ value }}</ion-label>
													@if (!computedCustomField.moduleSettings.uniqueEdit) {
														<ion-icon
															name="close-circle"
															(click)="
																removeQueryItem(
																	computedCustomField.baseSettings.uid,
																	value,
																	99,
																	currentLanguage
																)
															"
														></ion-icon>
													}
												</ion-chip>
											</span>
										}
										<br />
									</div>
								}
							</div>
						}
						<!-- "Numeric" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.NUMERIC) {
							<div class="custom-field-container">
								<ion-item
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									mode="md"
									lines="none"
									class="input-item"
								>
									<ion-label mode="md" position="floating" class="label-color">
										{{ computedCustomField.baseSettings.name[currentLanguage] }}
										@if (computedCustomField.moduleSettings.required) {
											<span class="required"> * </span>
										}
									</ion-label>
									<ion-input
										mode="md"
										class="custom-input input-color"
										type="number"
										placeholder="{{ 'edit-profil.placeholders.numeric_text-info' | translate }}"
										formControlName="{{ computedCustomField.baseSettings.uid }}"
										[ngStyle]="{
											color: formCustomFields.get(computedCustomField.baseSettings.uid).errors
												? '#900C3F'
												: '#4B4D4D'
										}"
										[disabled]="
											computedCustomField.moduleSettings.uniqueEdit &&
											computedCustomField.fieldDatas.field.numeric !== 0 &&
											computedCustomField.fieldDatas.field.numeric !== null &&
											computedCustomField.fieldDatas.field.numeric !== undefined
										"
									>
									</ion-input>
									<cf-form-visual-icon
										[mode]="
											isMultiLanguageField(computedCustomField)
												? 'multiLanguage'
												: 'singleLanguage'
										"
										class="input-icon"
										[computedCustomField]="computedCustomField"
										[form]="formCustomFields"
										[language]="currentLanguage"
									></cf-form-visual-icon>
								</ion-item>

								<cf-form-visual-error
									[mode]="
										isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'
									"
									[computedCustomField]="computedCustomField"
									[form]="formCustomFields"
									[language]="currentLanguage"
								></cf-form-visual-error>
							</div>
						}
						<!-- "URL" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.URL) {
							<div class="custom-field-container">
								<ion-item
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									mode="md"
									lines="none"
									class="input-item"
								>
									<ion-label mode="md" position="floating" class="label-color">
										{{ computedCustomField.baseSettings.name[currentLanguage] }}
										@if (computedCustomField.moduleSettings.required) {
											<span class="required"> * </span>
										}
									</ion-label>
									<ion-input
										mode="md"
										class="custom-input input-color"
										type="text"
										[ngStyle]="{
											color: formCustomFields.get(computedCustomField.baseSettings.uid).errors
												? '#900C3F'
												: '#4B4D4D'
										}"
										placeholder="{{ 'edit-profil.placeholders.url_text-info' | translate }}"
										formControlName="{{ computedCustomField.baseSettings.uid }}"
										[disabled]="
											computedCustomField.moduleSettings.uniqueEdit &&
											computedCustomField.fieldDatas.field.text !== '' &&
											computedCustomField.fieldDatas.field.text !== null &&
											computedCustomField.fieldDatas.field.text !== undefined
										"
									>
									</ion-input>
									<cf-form-visual-icon
										[mode]="
											isMultiLanguageField(computedCustomField)
												? 'multiLanguage'
												: 'singleLanguage'
										"
										class="input-icon"
										[computedCustomField]="computedCustomField"
										[form]="formCustomFields"
										[language]="currentLanguage"
									></cf-form-visual-icon>
								</ion-item>

								<cf-form-visual-error
									[mode]="
										isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'
									"
									[computedCustomField]="computedCustomField"
									[form]="formCustomFields"
									[language]="currentLanguage"
								></cf-form-visual-error>
							</div>
						}
						<!-- "Date" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.DATE) {
							<div style="position: relative">
								<mat-form-field
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									appearance="fill"
									style="width: 100%"
									class="material-date"
								>
									<mat-label>{{ computedCustomField.baseSettings.name[currentLanguage] }}</mat-label>
									<input
										matInput
										readonly="readonly"
										[matDatepicker]="picker"
										formControlName="{{ computedCustomField.baseSettings.uid }}"
									/>
									<mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
									<mat-datepicker #picker></mat-datepicker>
								</mat-form-field>

								<cf-form-visual-error
									[mode]="
										isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'
									"
									[computedCustomField]="computedCustomField"
									[form]="formCustomFields"
									[language]="currentLanguage"
								></cf-form-visual-error>
							</div>
						}
						<!-- "Image" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.IMAGE) {
							<div
								manageFieldVisibility
								[datas]="{
									event: event,
									module: module,
									fieldId: computedCustomField.baseSettings.uid,
									computedCustomFields: computedCustomFields
								}"
								[(eventUser)]="eventUserProfile"
								class="custom-field-container input-media-directive"
							>
								<input
									[formControlName]="computedCustomField.baseSettings.uid + '_base'"
									type="file"
									[attr.id]="computedCustomField.baseSettings.uid + '_base'"
									name="image-input"
									class="image-input"
									accept="image/*"
									(change)="loadCustomFieldFileImage($event, computedCustomField)"
								/>
								<label
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									class="image-input-label input-media-directive"
								>
									<div class="image-input-bloc">
										<label class="custom-field-name">
											{{ computedCustomField.baseSettings.name[currentLanguage] }}
											@if (computedCustomField.moduleSettings.required) {
												<span class="required"> * </span>
											}
										</label>
										@if (
											(computedCustomField.fieldDatas &&
												computedCustomField.fieldDatas.field.image.url &&
												computedCustomField.fieldDatas.field.image.url !== "") ||
											isValidUrl
										) {
											<a
												target="_blank"
												(click)="
													openDocumentOrImage(computedCustomField.fieldDatas.field.image)
												"
												class="input-image-link"
											>
												<span>
													<input
														style="cursor: pointer"
														[formControlName]="
															computedCustomField.baseSettings.uid + '_URL'
														"
														placeholder="{{
															'placeholders.link-or-import-image' | translate
														}}"
														type="text"
														class="input-title image-input"
														slot="end"
														(input)="getImageByUrl($event, computedCustomField)"
														[attr.disabled]="
															isValidUrl || computedCustomField.fieldDatas.field.image.url
														"
													/>
												</span>
												@if (
													computedCustomField.fieldDatas &&
													computedCustomField.fieldDatas.field &&
													computedCustomField.fieldDatas.field.image.name
												) {
													<span
														style="
															font-size: 0.9rem;
															position: relative;
															top: 5px;
															left: 0px;
														"
													>
														{{ computedCustomField.fieldDatas?.field.image.name }}
													</span>
												}
											</a>
										}
										@if (
											!computedCustomField.fieldDatas?.field.image.url ||
											computedCustomField.fieldDatas.field.image.url === ""
										) {
											<input
												[formControlName]="computedCustomField.baseSettings.uid + '_URL'"
												placeholder="{{ 'placeholders.link-or-import-image' | translate }}"
												type="url"
												class="input-title"
												slot="end"
												(input)="getImageByUrl($event, computedCustomField)"
											/>
										}
									</div>
									@if (
										(computedCustomField.fieldDatas &&
											computedCustomField.fieldDatas.field.image.url &&
											computedCustomField.fieldDatas.field.image.url !== "") ||
										isValidUrl
									) {
										<label
											class="file-clear-icon"
											(click)="deleteCustomFieldFileOrImage(computedCustomField)"
										>
											<img
												src="assets/images/trash.png"
												class="check-icon"
												slot="end"
												color="danger"
											/>
										</label>
									}
									<label
										[attr.for]="computedCustomField.baseSettings.uid + '_base'"
										class="icon-bloc"
									>
										<img
											class="input-icon"
											lazy="true"
											slot="end"
											src="/assets/images/upload.png"
										/>
									</label>
								</label>

								<!-- Pattern error -->
								@if (invalidSizeFile) {
									<div class="form-bloc-error">
										<p class="error-label-text">
											{{ "edit-profil.errors.invalid.file_size" | translate }}
											{{ MAX_FILE_SIZE / 1024000 }}MO
										</p>
									</div>
								}
							</div>
						}
						<!-- "File" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.FILE) {
							<div
								manageFieldVisibility
								[datas]="{
									event: event,
									module: module,
									fieldId: computedCustomField.baseSettings.uid,
									computedCustomFields: computedCustomFields
								}"
								[(eventUser)]="eventUserProfile"
								class="custom-field-container input-media-directive"
							>
								<input
									formControlName="{{ computedCustomField.baseSettings.uid }}"
									type="file"
									[attr.id]="computedCustomField.baseSettings.uid"
									name="file-input"
									class="file-input"
									accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.mp3,.mp4,image/*"
									(change)="loadCustomFieldFileImage($event, computedCustomField)"
								/>
								<label class="file-input-label">
									<div class="bloc-label">
										<label class="custom-field-name">
											{{ computedCustomField.baseSettings.name[currentLanguage] }}
											@if (computedCustomField.moduleSettings.required) {
												<span class="required"> * </span>
											}
										</label>
										<label class="input-title" style="color: rgb(109, 109, 109)" slot="end">
											@if (
												computedCustomField.fieldDatas &&
												computedCustomField.fieldDatas?.field.file &&
												computedCustomField.fieldDatas?.field.file?.name !== ""
											) {
												<span
													class="file-name-clickable"
													(click)="
														openDocumentOrImage(
															computedCustomField.fieldDatas
																? computedCustomField.fieldDatas.field.file
																: null
														)
													"
												>
													{{ computedCustomField.fieldDatas.field.file.name }}</span
												>
											}
											@if (
												(computedCustomField.fieldDatas &&
													computedCustomField.fieldDatas?.field.file?.name === "") ||
												computedCustomField.fieldDatas?.field.file?.name === null ||
												computedCustomField.fieldDatas?.field.file?.name === undefined
											) {
												<span>
													{{ "placeholders.import-file" | translate }}
												</span>
											}
										</label>
									</div>
									@if (
										computedCustomField.fieldDatas &&
										computedCustomField.fieldDatas.field.file.url &&
										computedCustomField.fieldDatas.field.file.url !== ""
									) {
										<label
											class="file-clear-icon"
											(click)="deleteCustomFieldFileOrImage(computedCustomField)"
										>
											<img
												src="assets/images/trash.png"
												class="check-icon"
												slot="end"
												color="danger"
											/>
										</label>
									}
									<label [attr.for]="computedCustomField.baseSettings.uid" class="icon-bloc">
										<img
											class="input-icon"
											lazy="true"
											slot="end"
											src="/assets/images/file-upload.png"
										/>
									</label>
								</label>
							</div>
						}
						<!-- "Email" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.EMAIL) {
							<div class="custom-field-container">
								<ion-item
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									mode="md"
									lines="none"
									class="input-item"
								>
									<ion-label mode="md" position="floating" class="label-color">
										{{ computedCustomField.baseSettings.name[currentLanguage] }}
										@if (computedCustomField.moduleSettings.required) {
											<span class="required"> * </span>
										}
									</ion-label>
									<ion-input
										mode="md"
										class="custom-input input-color"
										type="text"
										[ngStyle]="{
											color: formCustomFields.get(computedCustomField.baseSettings.uid).errors
												? '#900C3F'
												: '#4B4D4D'
										}"
										placeholder="{{ 'edit-profil.placeholders.example.email1' | translate }}"
										formControlName="{{ computedCustomField.baseSettings.uid }}"
										[disabled]="
											computedCustomField.moduleSettings.uniqueEdit &&
											computedCustomField.fieldDatas.field.text !== '' &&
											computedCustomField.fieldDatas.field.text !== null &&
											computedCustomField.fieldDatas.field.text !== undefined
										"
									>
									</ion-input>
									<cf-form-visual-icon
										[mode]="
											isMultiLanguageField(computedCustomField)
												? 'multiLanguage'
												: 'singleLanguage'
										"
										class="input-icon"
										[computedCustomField]="computedCustomField"
										[form]="formCustomFields"
										[language]="currentLanguage"
									></cf-form-visual-icon>
								</ion-item>

								<cf-form-visual-error
									[mode]="
										isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'
									"
									[computedCustomField]="computedCustomField"
									[form]="formCustomFields"
									[language]="currentLanguage"
								></cf-form-visual-error>
							</div>
						}
						<!-- "Phone number" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.PHONE) {
							<div
								manageFieldVisibility
								[datas]="{
									event: event,
									module: module,
									fieldId: computedCustomField.baseSettings.uid,
									computedCustomFields: computedCustomFields
								}"
								[(eventUser)]="eventUserProfile"
								class="custom-field-container tel-input-container tel-input-container-directive"
							>
								<ion-label class="item-has-focus label-color" style="z-index: 9">
									{{ computedCustomField.baseSettings.name[currentLanguage] }}
									@if (computedCustomField.moduleSettings.required) {
										<span class="required"> * </span>
									}
								</ion-label>
								<ngx-intl-tel-input
									style="width: 100% !important"
									class="input"
									[cssClass]="'tel-input'"
									[preferredCountries]="preferedCountries"
									[enableAutoCountrySelect]="false"
									[enablePlaceholder]="true"
									[customPlaceholder]="STranslate.instant('placeholders.phone_number_placeholder')"
									[searchCountryFlag]="true"
									[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
									[selectFirstCountry]="false"
									[selectedCountryISO]="
										computedCustomField.fieldDatas?.field &&
										computedCustomField.fieldDatas?.field?.phoneNumber &&
										computedCustomField.fieldDatas?.field?.phoneNumber?.countryCode
											? computedCustomField.fieldDatas.field?.phoneNumber?.countryCode
											: CountryISO[currentLanguage.slice(2)]
									"
									[maxLength]="15"
									[phoneValidation]="true"
									[inputId]="computedCustomField.baseSettings.uid"
									name="phone"
									formControlName="{{ computedCustomField.baseSettings.uid }}"
								></ngx-intl-tel-input>
								<cf-form-visual-icon
									[mode]="
										isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'
									"
									class="input-icon"
									[computedCustomField]="computedCustomField"
									[form]="formCustomFields"
									[language]="currentLanguage"
								></cf-form-visual-icon>
								<!-- Validation -->

								<!-- <cf-form-visual-error
                  [mode]="isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'"
                  [computedCustomField]="computedCustomField"
                  [form]="formCustomFields"
                  [language]="currentLanguage"
                ></cf-form-visual-error> -->
							</div>
						}
						<!-- "ADDRESS" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.ADDRESS) {
							<div class="custom-field-container">
								<ion-item
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									mode="md"
									lines="none"
									class="input-item"
								>
									<!-- <ion-label position="floating" class="label-color">
                  {{ computedCustomField.baseSettings.name[currentLanguage] }}
                  <span class="required" *ngIf="computedCustomField.moduleSettings.required"> * </span>
                </ion-label> -->
									<mat-form-field style="padding-left: 0 !important" class="input-form-fw w-98">
										<mat-label class="label-color"
											>{{ computedCustomField.baseSettings.name[event.language] }}
										</mat-label>
										<input
											class="border-solid input-color"
											type="text"
											matInput
											placeholder="{{ 'placeholders.address_plc' | translate }}"
											[formControlName]="computedCustomField.baseSettings.uid"
											[matAutocomplete]="auto"
										/>
										<mat-autocomplete
											style="position: relative; top: -10px; left: 5px"
											#auto="matAutocomplete"
											[displayWith]="displayFn"
										>
											@for (
												prediction of getPlacePredictionsById(
													computedCustomField.baseSettings.uid
												);
												track prediction
											) {
												<mat-option
													(optionSelections)="
														displayFn($event);
														getCustomFieldAddressState(
															computedCustomField.baseSettings.uid
														).loading = false
													"
													(click)="
														displayFn($event);
														getCustomFieldAddressState(
															computedCustomField.baseSettings.uid
														).loading = false
													"
													[value]="prediction.description"
												>
													{{ prediction.description }}
												</mat-option>
											}
										</mat-autocomplete>
									</mat-form-field>
									<cf-form-visual-icon
										[mode]="
											isMultiLanguageField(computedCustomField)
												? 'multiLanguage'
												: 'singleLanguage'
										"
										[hidden]="
											getCustomFieldAddressState(computedCustomField.baseSettings.uid)?.loading
										"
										class="input-icon"
										[computedCustomField]="computedCustomField"
										[form]="formCustomFields"
										[language]="currentLanguage"
									></cf-form-visual-icon>
									<span
										class="address-loader-spinner"
										[class.button-spinner]="
											getCustomFieldAddressState(computedCustomField.baseSettings.uid)?.loading
										"
									></span>
								</ion-item>
							</div>
						}
						<!-- "CHECKBOX" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.CHECKBOX) {
							<div>
								<mat-checkbox
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									[formControlName]="computedCustomField.baseSettings.uid"
								>
									{{ computedCustomField.baseSettings.name[currentLanguage] }}
								</mat-checkbox>
							</div>
						}
						<!-- "Country" field type -->
						@if (computedCustomField.baseSettings.type === typeCustomFields.COUNTRY) {
							<div>
								<ion-item
									manageFieldVisibility
									[datas]="{
										event: event,
										module: module,
										fieldId: computedCustomField.baseSettings.uid,
										computedCustomFields: computedCustomFields
									}"
									[(eventUser)]="eventUserProfile"
									mode="md"
									lines="none"
									no-lines
									class="input-item"
								>
									<ion-label mode="md" position="floating" class="label-color">
										{{ computedCustomField.baseSettings.name[currentLanguage] }}
										@if (computedCustomField.moduleSettings.required) {
											<span class="required"> * </span>
										}
									</ion-label>
									<ion-input
										(click)="showCoutryModal(computedCustomField)"
										autocapitalize="sentences"
										mode="md"
										class="custom-input input-color"
										type="text"
										[ngStyle]="{
											color: formCustomFields.get(computedCustomField.baseSettings.uid)?.errors
												? '#900C3F'
												: '#4B4D4D'
										}"
										placeholder="{{ 'edit-profil.placeholders.simple_text-info' | translate }}"
										formControlName="{{ computedCustomField.baseSettings.uid }}"
									>
									</ion-input>
									<!-- <cf-form-visual-icon [mode]="isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'" class="input-icon"
                [computedCustomField]="computedCustomField" [form]="formCustomFields"
              [language]="currentLanguage"></cf-form-visual-icon> -->
								</ion-item>

								<!-- <cf-form-visual-error [mode]="isMultiLanguageField(computedCustomField) ? 'multiLanguage' : 'singleLanguage'"
            [computedCustomField]="computedCustomField" [form]="formCustomFields"
          [language]="currentLanguage"></cf-form-visual-error> -->
							</div>
						}
					</ion-col>
				</ion-row>
			}
		</form>
	}
	@if (loader) {
		<div class="loader-container">
			<div class="loader-main" [style.width]="'60px'" [style.height]="'60px'"></div>
		</div>
	}
</div>
