<div
	*ngIf="event && module"
	class="module-container hide-scrollbar scroll-y-only"
	[style.height]="'100% !important'"
	#container
	(window:resize)="onResize()"
	[style.padding]="isMobile ? '0' : '6px'"
>
	<!-- [style.margin-bottom]="isMobile ? '15px' : '0'" -->
	<div
		class="widgets-container"
		#widgetContainer
		id="container{{ module.uid }}"
		[style.min-height]="calcMinHeight(widgetContainer)"
	>
		<ng-container *ngIf="!isMobile; else mobileBlock">
			<ng-container *ngIf="loaderDesktop; else desktopList">
				<!-- <ion-spinner name="bubbles" class="loader-widgets"></ion-spinner> -->
				<div class="loader-default loader-widgets" [style.width]="'60px'" [style.height]="'60px'"></div>
			</ng-container>
			<div
				class="grid"
				[class.grid-debug]="DEBUG_MODE"
				id="grid-desktop"
				[ngStyle]="getColumns(widgetContainer, 'desktop')"
			>
				<ng-container *ngFor="let line of desktopGrid">
					<div
						*ngFor="let c of line"
						[id]="'desktop-' + c.id"
						[style.backgroundColor]="DEBUG_MODE && c.taken ? 'rgba(0,0,0,0.35)' : 'transparent'"
					>
						<span>{{ c.id }}</span>
					</div>
				</ng-container>
			</div>
			<ng-template #desktopList>
				<ng-container *ngIf="desktopWidgets && desktopWidgets.length > 0; else emptyContentDesktop">
					<div
						*ngFor="let widget of desktopWidgets"
						class="widget {{ widget.dimension }} {{
							widget.route && widget.route !== '-1' ? 'clicked-widget' : ''
						}}"
						[id]="widget.uid"
						[style.z-index]="widget.depth"
						[ngStyle]="getWidgetDim(widgetContainer, widget)"
						[style.border-color]="'#080080 !important'"
						[style.left.px]="widget.config.left"
						[style.top.px]="widget.config.top"
						(click)="openWidget(widget)"
					>
						<iframe
							*ngIf="widget && widget.type === 2 && widget.iframeSrc"
							[src]="widget.iframeSrc | safeHtml: 'resourceUrl'"
							class="cus-iframe"
						></iframe>
						<ng-container
							*ngIf="
								eventUser &&
									module &&
									module.options &&
									module.options.activateNotifs &&
									checkNotifsWidget(widget) | async as notifs
							"
						>
							<ion-badge class="widget-badge" *ngIf="notifs > 0" color="warning">
								{{ notifs }}
							</ion-badge>
						</ng-container>
					</div>
				</ng-container>

				<ng-template #emptyContentDesktop>
					<div class="container" *ngIf="module && !module.options.backgroundDesktopImage">
						<span class="wait-content" [style.color]="event.styling.titleColor">{{
							"texts.waiting_for_content" | translate
						}}</span>
						<ion-icon
							src="assets/images/emptycontent/AUCUNRESULTAT.svg"
							[style.color]="
								event.styling.menuColor.includes('linear')
									? event.styling.menuColor.split(',')[1]
									: event.styling.menuColor
							"
						>
						</ion-icon>
						<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
							<span class="title"> {{ "interactivity.default_empty_content_title" | translate }}</span>
							<span>{{ "interactivity.default_empty_content_text" | translate }}</span>
						</div>
					</div>
				</ng-template>
			</ng-template>
		</ng-container>
		<ng-template #mobileBlock>
			<ng-container *ngIf="loaderMobile; else mobileList">
				<!-- <ion-spinner name="bubbles" class="loader-widgets"></ion-spinner> -->
				<div class="loader-default loader-widgets" [style.width]="'60px'" [style.height]="'60px'"></div>
			</ng-container>
			<div
				class="grid"
				[class.grid-debug]="DEBUG_MODE"
				id="grid-mobile"
				[ngStyle]="getColumns(widgetContainer, 'mobile')"
			>
				<ng-container *ngFor="let line of mobileGrid">
					<div *ngFor="let c of line" [id]="'mobile-' + c.id">
						<span>{{ c.id }}</span>
					</div>
				</ng-container>
			</div>
			<ng-template #mobileList>
				<ng-container *ngIf="mobileWidgets && mobileWidgets.length > 0; else emptyContentMobile">
					<div
						*ngFor="let widget of mobileWidgets"
						class="widget {{ widget.dimension }} {{
							widget.route && widget.route !== '-1' ? 'clicked-widget' : ''
						}}"
						[style.z-index]="widget.depth"
						[id]="widget.uid"
						[ngStyle]="getWidgetDim(widgetContainer, widget)"
						[style.border-color]="'#080080 !important'"
						[style.left.px]="widget.config.left - 6"
						[style.top.px]="widget.config.top - 6"
						(click)="openWidget(widget)"
					>
						<iframe
							*ngIf="widget && widget.type === 2 && widget.iframeSrc"
							[src]="widget.iframeSrc | safeHtml: 'resourceUrl'"
							class="cus-iframe"
						></iframe>
						<ng-container
							*ngIf="
								eventUser &&
									module &&
									module.options &&
									module.options.activateNotifs &&
									checkNotifsWidget(widget) | async as notifs
							"
						>
							<ion-badge class="widget-badge" *ngIf="notifs > 0" color="warning">
								{{ notifs }}
							</ion-badge>
						</ng-container>
					</div>
				</ng-container>

				<ng-template #emptyContentMobile>
					<div class="container" *ngIf="module && !module.options.backgroundMobileImage">
						<span class="wait-content" [style.color]="event.styling.titleColor">{{
							"texts.waiting_for_content" | translate
						}}</span>
						<ion-icon
							src="assets/images/emptycontent/AUCUNRESULTAT.svg"
							[style.color]="
								event.styling.menuColor.includes('linear')
									? event.styling.menuColor.split(',')[1]
									: event.styling.menuColor
							"
						>
						</ion-icon>
						<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
							<span class="title"> {{ "interactivity.default_empty_content_title" | translate }}</span>
							<span>{{ "interactivity.default_empty_content_text" | translate }}</span>
						</div>
					</div>
				</ng-template>
			</ng-template>
		</ng-template>
	</div>
	<div
		style="z-index: -1; top: 0; left: 0; width: 100%; height: 100%"
		[ngStyle]="getModuleBackground()"
		[style.min-height]="calcMinHeight(widgetContainer)"
	></div>
</div>
