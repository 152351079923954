/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";

@Component({
	selector: "form-field-icons",
	templateUrl: "./form-field-icons.component.html",
	styleUrls: ["./form-field-icons.component.scss"],
	standalone: false
})
export class FormFieldIconsComponent {
	@Input() fieldId: string;
	@Input() form: UntypedFormGroup | AbstractControl;
	@Input() language: string;
	@Input() fieldType: "singleLanguage" | "multiLanguage";
	@Input() disabledIcon: boolean = false;
}
