import { Component, OnDestroy } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AlertController, ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { NgxImageCompressService } from "ngx-image-compress";
import { Subscription, firstValueFrom } from "rxjs";
import { auditTime, take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeLikeFeedNews } from "src/app/shared/enums/feed-new-like-type";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeVideoSource } from "src/app/shared/enums/type-gallery";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IEvent, IEventUser, IModule, IPost, IUser } from "src/app/shared/interfaces";
import { ILike } from "src/app/shared/interfaces/feed-news.interfaces";
import { IReport } from "src/app/shared/interfaces/reports.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentEventUser, getCurrentUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getPostsOfModule } from "src/app/shared/selectors/feed-news.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	EventUsersService,
	FeedNewsService,
	FirestoreService,
	NotificationsService,
	ReportsService,
	StorageService,
	UtilityService
} from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import * as _ from "lodash-es";

@Component({
	selector: "app-feed-news",
	templateUrl: "./feed-news.component.html",
	styleUrls: ["./feed-news.component.scss"],
	standalone: false
})
export class FeedNewsComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	loader: boolean = true;
	loaderMyImg: boolean = false;
	loaderPost: boolean = false;

	isMobile: boolean = false;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	user: IUser;
	eventUser: IEventUser;
	likes: ILike[] = [];

	menuBadge: number = 0;

	createPostForm: UntypedFormGroup;

	posts: IPost[] = [];

	myImg: string;

	showComments: boolean = false;
	postId: string = "";
	showCreatePost: boolean = false;
	likeClicked: boolean = false;
	numberOfPostsShowed: number = 10; // Min 10
	postsPerPage: number = 10; // Min 10
	postsFiltered: IPost[] = [];

	// Video
	typeVideoSource = TypeVideoSource;
	videosWidth: string | number;
	videosHeight: string | number;

	constructor(
		private SAnalytics: AnalyticsService,
		private navCtrl: NavController,
		public platform: Platform,
		private modalCtrl: ModalController,
		private store: Store,
		private SEventUsers: EventUsersService,
		private SFeedNews: FeedNewsService,
		private SReports: ReportsService,
		private STranslate: TranslateService,
		private imageCompress: NgxImageCompressService,
		private SFirestore: FirestoreService,
		private SNotifs: NotificationsService,
		private SStorage: StorageService,
		private SUtility: UtilityService,
		private fb: UntypedFormBuilder,
		private alertController: AlertController
	) {
		this.createPostForm = this.fb.group({
			description: ["", [Validators.required, Validators.minLength(1)]]
		});
		if (this.platform.is("ios") || this.platform.is("android")) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
			this.showCreatePost = true;
		}

		if (!this.isMobile) {
			this.videosWidth = "100%";
			this.videosHeight = 640;
		} else {
			this.videosWidth = 380;
			this.videosHeight = 240;
		}
	}

	ionViewWillEnter() {
		this.store
			.select(getCurrentEventUser)
			.pipe(auditTime(200), take(1))
			.subscribe(async (eventUser) => {
				if (eventUser) {
					this.eventUser.updatedSettings.accessModule = this.eventUser.updatedSettings.accessModule
						? this.eventUser.updatedSettings.accessModule
						: {};

					const lastDateAccess = DateTime.now().toISO();
					this.eventUser.updatedSettings.accessModule[this.moduleId] = lastDateAccess;
					await this.SEventUsers.updateEventUserUpdatedSettings(
						this.eventUser.eventId,
						this.eventUser.moduleId,
						this.eventUser.uid,
						{
							accessModule: this.eventUser.updatedSettings.accessModule
						}
					);
				}
			});
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.loader = true;
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.getEvent();
						this.getModule();
						this.getUser();

						// Analytics
						this.SAnalytics.moduleAccess(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_FEED_MODULE);
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
		this.removeIframes();
		this.resetDatas();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
		this.posts = [];
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Get module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;

				if (this.module) {
					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: null,
								module: this.module,
								title: this.module.name,
								type: TypeModule.NEWS_FEED
							}
						})
					);
					// this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
					this.getPosts();
				}
			})
		);
	}

	/**
	 * Loading posts
	 */
	getPosts() {
		this.subscriptions.push(
			this.store.select(getPostsOfModule(this.moduleId)).subscribe(async (posts) => {
				this.posts = posts;
				this.postsFiltered = this.posts;
				this.loader = false;

				// get all eventUsers of each posts
				for (const post of this.posts) {
					const baseEventUser = await firstValueFrom(
						this.SEventUsers.getSpecificEventUser(this.eventId, post.user.uid)
					);
					post["baseEventUser"] = baseEventUser;
				}
			})
		);
	}

	/**
	 * Get user data
	 */
	getUser() {
		this.subscriptions.push(
			this.store.select(getCurrentUser).subscribe((user) => {
				this.user = user;
			})
		);

		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				this.eventUser = eventUser;
				if (this.eventUser) {
					this.getLikesOfUser();
				}
			})
		);
	}

	/**
	 * getPaginatedSessions
	 */
	getPagintedPosts() {
		return this.postsFiltered.slice(0, this.numberOfPostsShowed);
	}

	/**
	 * Load more sessions
	 */
	morePosts(evt: any) {
		if (evt) {
			if (this.numberOfPostsShowed < this.postsFiltered.length) {
				setTimeout(() => {
					this.numberOfPostsShowed = this.numberOfPostsShowed + this.postsPerPage;
					evt.target.complete();
				}, 900);
			} else {
				evt.target.disabled = true;
			}
		}
	}

	/**
	 * Get event user likes
	 */
	getLikesOfUser() {
		this.subscriptions.push(
			this.SFeedNews.getEventUserLikes(this.eventId, this.moduleId, this.eventUser.uid).subscribe((likes) => {
				this.likes = likes;
			})
		);
	}

	/**
	 * Check like of post
	 * @param post
	 * @returns
	 */
	verifiesLikedPost(post: IPost) {
		return this.likes.find((like) => like.postId === post.uid && like.userId === this.eventUser.uid) ? true : false;
	}

	/**
	 * Open image
	 * @param url
	 */
	async openImage(url: string) {
		try {
			const urlSplit = url.split("?").length > 0 ? url.split("?")[0] : url;
			const extension = urlSplit.split(".").length > 0 ? urlSplit.split(".")[1] : "jpg";
			const modal = await this.modalCtrl.create({
				component: PathComponents.imageViewerModal,
				componentProps: {
					url: url,
					type: extension,
					name: "b3-app-image-shared.jpg",
					allowDownload: this.module.options.allowImageDownload,
					allowSharing: this.module.options.allowImageSharing
				}
			});
			return await modal.present();
		} catch (error) {
			//("Modal error");
		}
	}

	/**
	 * Open comments
	 * @param postId
	 * @returns
	 */
	viewComments(postId: string) {
		if (!this.eventUser) {
			this.SUtility.alertLoginRegister(this.eventId, this.STranslate.instant("chat.chat"));
			return;
		}

		this.showComments = true;
		this.postId = postId;
	}

	/**
	 * Add or remove like
	 */
	async addOrRemoveLike(post: IPost) {
		try {
			if (this.event.blocked) {
				this.SUtility.presentToast(
					this.STranslate.instant("alerts.blocked-event-info"),
					2500,
					"bottom",
					"danger"
				);
				return;
			}
			if (this.eventUser && this.eventUser.uid && !this.likeClicked) {
				this.likeClicked = true;
				if (!this.verifiesLikedPost(post)) {
					const like: ILike = {
						uid: "",
						creationDate: DateTime.local().toISO(),
						eventId: this.eventId,
						moduleId: this.moduleId,
						postId: post.uid,
						type: TypeLikeFeedNews.CLAP,
						userId: this.eventUser.uid
					};
					post.totalLikes++;
					this.SFeedNews.updatePost(this.eventId, this.moduleId, post.uid, post);
					await this.SFeedNews.createLike(this.eventId, this.moduleId, post.uid, like);
					this.likeClicked = false;

					// Tracking analytic (Send like)
					this.SAnalytics.sendOrRemoveLike(
						this.eventId,
						this.moduleId,
						this.eventUser,
						post.uid,
						TypeTracking.SEND_LIKE_ON_FEED
					);
				} else {
					const like = this.likes.find(
						(like) => (like.postId === post.uid && like.userId) === this.eventUser.uid
					);

					if (like) {
						post.totalLikes--;
						this.SFeedNews.updatePost(this.eventId, this.moduleId, post.uid, post);
						await this.SFeedNews.deleteLike(this.eventId, this.moduleId, post.uid, like);
						this.likeClicked = false;

						// Tracking analytic (Remove like)
						this.SAnalytics.sendOrRemoveLike(
							this.eventId,
							this.moduleId,
							this.eventUser,
							post.uid,
							TypeTracking.REMOVE_LIKE_ON_FEED
						);
					}
				}
			}
		} catch (error) {
			this.likeClicked = false;
		}
	}

	async getImageToSend() {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}
		this.loaderMyImg = true;
		try {
			const image = await this.SStorage.getPicture();
			this.myImg = "data:image/jpeg;base64," + image.base64String;

			const imageCompressed = await this.SUtility.compressImage(this.myImg, 90, 60);

			if (imageCompressed) {
				this.myImg = imageCompressed;
			}
			this.loaderMyImg = false;
		} catch (error) {
			this.loaderMyImg = false;
		}
	}

	/**
	 * Create a post
	 */
	async createPost() {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}
		if (this.user) {
			this.loaderPost = true;

			const data = this.createPostForm.getRawValue();

			const post: IPost = {
				uid: this.SFirestore.createId(`events/${this.eventId}/modules/${this.moduleId}/feed-news`),
				active: this.module.options.needModerate ? false : true,
				creationDate: DateTime.local().toISO(),
				description: data.description.replace(/<[^>]*>/g, ""),
				eventId: this.eventId,
				fixedTop: 1,
				imageUrl: "",
				moduleId: this.moduleId,
				totalLikes: 0,
				totalComments: 0,
				useUserInfos: true,
				user: {
					uid: this.eventUser ? this.eventUser.uid : this.user ? this.user.uid : "",
					creationDate: this.eventUser
						? this.eventUser.creationDate
						: this.user
							? this.user.creationDate
							: "",
					name: this.eventUser ? this.eventUser.name : this.user ? this.user.name : "",
					photoUrl: this.eventUser ? this.eventUser.photoUrl : this.user ? this.user.photoUrl : "",
					type: this.eventUser ? this.eventUser.type : this.user ? this.user.type : 5
				}
			};

			try {
				if (this.myImg) {
					post.imageUrl = await this.SStorage.uploadBase64(
						this.myImg.replace("data:image/jpeg;base64,", ""),
						"image/jpeg",
						`events/${this.eventId}/modules/${this.moduleId}/feed-news/${post.uid}/${
							this.eventUser ? this.eventUser.uid : "anonymous"
						}/${post.uid}.jpg`
					);
				}
				await this.SFeedNews.createPost(this.eventId, this.moduleId, post);

				// Send notification
				// Get event user to send
				if (this.module && this.module.options && this.module.options.activateNotifs) {
					if (this.module.visionType === 0) {
						// Take all event users of event
						this.SNotifs.getOneSignalForAllUsers(
							this.eventId,
							this.moduleId,
							"feedNewsNotifs",
							this.eventUser.uid,
							[
								where("eventId", "==", this.eventId),
								where("options.notifOneSignalConfig.userId", "!=", null)
							]
						)
							.pipe(take(1))
							.subscribe((oneSignalUsersIds) => {
								this.sendNotification(_.uniq(oneSignalUsersIds), post);
							});
					} else {
						// Case vision for groups
						this.SNotifs.getOneSignalForUsersFromGroups(
							this.eventId,
							this.module.groupsAccess,
							this.moduleId,
							"feedNewsNotifs",
							this.eventUser.uid
						)
							.pipe(take(1))
							.subscribe((oneSignalUsersIds) => {
								this.sendNotification(_.uniq(oneSignalUsersIds), post);
							});
					}
				}

				if (this.module.options.needModerate) {
					this.SUtility.presentToast(this.STranslate.instant("alerts.post_moderated"), 3000, "bottom");
				}
				this.createPostForm.patchValue({
					description: ""
				});
				this.myImg = "";
				this.loaderPost = false;
			} catch (error) {
				this.loaderPost = false;
				this.SUtility.presentToast(
					this.STranslate.instant("toasts.errors.create-basic-error"),
					3000,
					"bottom",
					"danger"
				);
			}
		} else {
			this.SUtility.alertLoginRegister(this.eventId, this.STranslate.instant("chat.chat"));
		}
	}

	/**
	 * Send a notification
	 * @param members
	 */
	sendNotification(oneSignalUsersIds: string[], post: IPost) {
		if (oneSignalUsersIds.length > 0) {
			firstValueFrom(
				this.SNotifs.sendNotification(
					this.event,
					this.module,
					post,
					{
						ar: this.event.title,
						en: this.event.title,
						es: this.event.title,
						fr: this.event.title,
						pt: this.event.title,
						de: this.event.title
					},
					{
						ar: `${this.eventUser.name} نشرت للتو على ${this.module.name.ArAR}`,
						en: `${this.eventUser.name} just posted on ${this.module.name.EnUS}`,
						es: `${this.eventUser.name} acaba de publicar en ${this.module.name.EsES}`,
						fr: `${this.eventUser.name} vient de poster sur ${this.module.name.FrFR}`,
						pt: `${this.eventUser.name} acaba de publicar em ${this.module.name.PtBR}`,
						de: `${this.eventUser.name} hat gerade auf ${this.module.name.DeDE} gepostet`
					},
					oneSignalUsersIds,
					{
						redirectUrl: `event/${this.eventId}/feed-news/${this.moduleId}`
					},
					""
				)
			);
		}
	}

	/**
	 * Open confirm delete post alert
	 * @param post
	 */
	async openConfirmDeleteAlert(post: IPost) {
		try {
			const alert = await this.alertController.create({
				header: this.STranslate.instant("buttons.delete") + " ?",
				buttons: [
					{ text: this.STranslate.instant("buttons.no") },
					{
						text: this.STranslate.instant("buttons.yes"),
						handler: () => {
							this.deletePost(post);
						}
					}
				]
			});

			await alert.present();
		} catch (error) {
			// console.error("Modal error");
		}
	}

	/**
	 * Deletes given post.
	 * @param post
	 */
	async deletePost(post: IPost) {
		if (this.user.uid === post.user.uid) {
			try {
				await this.SFeedNews.deletePost(this.eventId, this.moduleId, post.uid);
			} catch (error) {
				this.SUtility.presentToast(
					this.STranslate.instant("toasts.errors.create-basic-error"),
					3000,
					"bottom",
					"danger"
				);
			}
		}
	}

	/**
	 * Convert date to string
	 * @param date
	 * @returns
	 */
	getDate(date: string) {
		const pDate = new Date(date);
		const oneDay = 24 * 60 * 60 * 1000;
		const today = new Date();

		const diffTime = today.getTime() - pDate.getTime();
		const diffDays = diffTime / oneDay;

		if (diffDays >= 1) {
			return this.SUtility.getPartOfDate(this.event, this.eventUser, date, "full");
		} else {
			const diffHou = diffDays * 24;
			if (diffHou >= 1) {
				return this.STranslate.instant("feed_news.time", {
					time: Math.floor(diffHou),
					timeLabel: this.STranslate.instant("labels.hours")
				});
			} else {
				const diffMin = diffHou * 60;
				if (diffMin >= 1) {
					return this.STranslate.instant("feed_news.time", {
						time: Math.floor(diffMin),
						timeLabel: this.STranslate.instant("labels.minutes")
					});
				} else {
					return this.STranslate.instant("feed_news.time", {
						time: Math.floor(diffMin * 60),
						timeLabel: this.STranslate.instant("labels.seconds")
					});
				}
			}
		}
	}

	/**
	 * Redirect to user profile
	 * @param post
	 */
	redirectProfile(post: IPost) {
		if (post && post.moduleId && post.user && post.user.uid) {
			this.SEventUsers.getSpecificEventUser(this.eventId, post.user.uid)
				.pipe(take(1))
				.subscribe((eventUser) => {
					if (eventUser) {
						this.navCtrl.navigateForward(
							`/event/${this.eventId}/profile/${eventUser.moduleId}/${eventUser.uid}`
						);
					}
				});
		}
	}

	/**
	 * Track by uid for flickering
	 * @param index
	 * @param item
	 * @returns
	 */
	trackByFn(index: number, item: IPost): string {
		return item.uid;
	}

	async openReportAlert(post: IPost) {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("reports.alert_title"),
			"",
			[
				{
					text: this.STranslate.instant("buttons.no")
				},
				{
					text: this.STranslate.instant("buttons.yes"),
					cssClass: "red-text",
					handler: (values: any) => {
						this.SEventUsers.getSpecificEventUser(this.eventId, post.user.uid)
							.pipe(take(1))
							.subscribe(async (targetUser) => {
								if (targetUser) {
									const newReport: IReport = {
										uid: "",
										comment: values.comment,
										creationDate: DateTime.local().toISO(),
										eventId: this.eventId,
										moduleId: this.moduleId,
										options: {
											postId: post.uid
										},
										reporterEmail: this.eventUser.email,
										reporterId: this.eventUser.uid,
										reporterIdentifier: this.eventUser.identifier,
										state: "progress",
										targetUserEmail: targetUser.email,
										targetUserId: post.user.uid,
										targetUserIdentifier: targetUser.identifier,
										targetUserModuleId: targetUser.moduleId,
										type: TypeModule.NEWS_FEED
									};
									await this.SReports.createReport(this.eventId, newReport);
									this.SUtility.presentToast(
										this.STranslate.instant("reports.alert_send"),
										2000,
										"bottom",
										"success"
									);
								}
							});
					}
				}
			],
			[
				{
					label: this.STranslate.instant("labels.comment"),
					name: "comment",
					placeholder: this.STranslate.instant("labels.comment"),
					type: "textarea"
				}
			]
		);
		await alert.present();
	}

	/**
	 * removeIframes
	 */
	removeIframes() {
		this.posts.forEach((post) => {
			const frame = window.parent.document.getElementById(post.uid);

			if (frame && frame.parentNode) {
				frame.parentNode.removeChild(frame);
			}
		});
	}

	/**
	 * extractUrlFromIframe
	 */
	extractUrlFromIframe(iframe: string) {
		if (iframe && iframe !== "") {
			const parser = new DOMParser();

			const parsedIframe = parser.parseFromString(iframe, "text/html");
			const iFrame = parsedIframe.getElementsByTagName("iframe");

			// Read URL:
			const src = iFrame[0]?.src;

			return src;
		}
	}

	/**
	 * Get width of mini video player
	 * @returns
	 */
	getVideoWidth(): string | number {
		if (this.isMobile) {
			return this.platform.width() - 20;
		} else {
			return this.videosWidth;
		}
	}

	/**
	 * Get height of mini video player
	 * @returns
	 */
	getVideoHeight() {
		if (this.isMobile) {
			return this.platform.height() * 0.3;
		} else {
			return this.videosHeight;
		}
	}

	/**
	 * getVideoHeightFromIframe
	 */
	getVideoHeightFromIframe(iframe: string): number {
		// get iframe height
		const parser = new DOMParser();
		const parsedIframe = parser.parseFromString(iframe, "text/html");
		const iFrame = parsedIframe.getElementsByTagName("iframe");
		const height = iFrame[0]?.height;

		return Number(height);
	}

	/**
	 * resetDatas
	 */
	resetDatas() {
		this.posts = [];
		this.postsFiltered = [];
		this.numberOfPostsShowed = 10;
	}
}
