import { Component, OnDestroy, ViewChild } from "@angular/core";
import { IonInfiniteScroll } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { combineLatest, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	IEvent,
	IEventUser,
	IGroup,
	IModule,
	IPointRecord,
	IQuiz,
	IRanking,
	ISurvey,
	ITreasureHunt,
	ITreasureHuntQrcode
} from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getTreasureHunts, getTreasureHuntsQrcodes } from "src/app/shared/selectors/gamification.selectors";
import { getGroups } from "src/app/shared/selectors/generics-modules-data.selectors";
import { getQuizs, getSurveys } from "src/app/shared/selectors/interactivity.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { getSpecificRanking } from "src/app/shared/selectors/rankings.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { PointsService } from "src/app/shared/services";

@Component({
	selector: "app-ranking",
	templateUrl: "./ranking.component.html",
	styleUrls: ["./ranking.component.scss"],
	standalone: false
})
export class RankingComponent implements OnDestroy {
	@ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
	subscriptions: Subscription[] = [];
	resultsSub: Subscription;

	loader: boolean = true;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	rankingId: string;
	ranking: IRanking;
	groups: IGroup[] = [];
	quizs: IQuiz[] = [];
	surveys: ISurvey[] = [];
	treasureHunts: ITreasureHunt[] = [];
	qrCodes: ITreasureHuntQrcode[] = [];
	eventUser: IEventUser;

	eventUsers: IEventUser[] = [];
	eventUsersSaved: IEventUser[] = [];

	groupsRanked: Array<IGroup & { points: number }> = [];
	groupsRankedSaved: Array<IGroup & { points: number }> = [];
	points: IPointRecord[] = [];

	searchOpen: boolean = false;

	isMobile: boolean;
	showOrderList = false;
	filterby = "asc";

	limit: number = 30;
	currentSize: number = 0;
	infiniteScrollDisabled: boolean = false;

	constructor(
		private store: Store,
		private SPoints: PointsService
	) {
		this.getSubjectsOfRanking();
	}

	ionViewWillEnter() {
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.rankingId = params.rankingId;
						if (this.resultsSub && !this.resultsSub.closed) {
							this.resultsSub.unsubscribe();
						}
						this.subscriptions.forEach((sub) => sub.unsubscribe());

						this.getNecessaryDatas();
						this.getSubjectsOfRanking();
						this.getRanking();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		if (this.resultsSub && !this.resultsSub.closed) {
			this.resultsSub.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		if (this.resultsSub && !this.resultsSub.closed) {
			this.resultsSub.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Getting necessary datas
	 */
	getNecessaryDatas() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getCurrentEvent),
				this.store.select(getSpecificModule(this.moduleId)),
				this.store.select(getCurrentEventUser)
			]).subscribe((results) => {
				this.event = results[0];
				this.module = results[1];
				this.eventUser = results[2];
				this.eventUser.points = 0;
			})
		);
	}

	/**
	 * Getting subjects of ranking
	 */
	getSubjectsOfRanking() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getGroups),
				this.store.select(getQuizs),
				this.store.select(getSurveys),
				this.store.select(getTreasureHunts),
				this.store.select(getTreasureHuntsQrcodes)
			]).subscribe((results) => {
				this.groups = results[0];
				this.quizs = results[1];
				this.surveys = results[2];
				this.treasureHunts = results[3];
				this.qrCodes = results[4];
			})
		);
	}

	/**
	 * Getting ranking of module
	 */
	getRanking() {
		this.subscriptions.push(
			this.store.select(getSpecificRanking(this.rankingId)).subscribe((ranking) => {
				this.ranking = ranking;
				if (this.ranking) {
					this.store.dispatch(GetHeaderTitle({ payload: this.ranking.name }));
					this.getResultsOfRanking();
				}
				this.loader = false;
			})
		);
	}

	/**
	 * Get results of ranking
	 */
	getResultsOfRanking() {
		if (!this.resultsSub || this.resultsSub.closed) {
			this.resultsSub = this.SPoints.getPointsRecordsForRanking(this.eventId, this.ranking).subscribe(
				(results) => {
					this.points = results.pointsRecords;
					if (this.ranking.rankingByGroups) {
						this.groupsRankedSaved = ([...this.groups] as Array<IGroup & { points: number }>)
							.filter(
								(group) =>
									this.ranking.rankingByGroupsAllGroups ||
									(!this.ranking.rankingByGroupsAllGroups && this.ranking.groups.includes(group.uid))
							)
							.map((group) => {
								const allPoints = [...results.eventUsers]
									.filter((eventUser) => eventUser.groups.includes(group.uid))
									.map((eventUser) => {
										const points =
											// eventUser.points +
											this.points
												.filter((point) => point.userId === eventUser.uid)
												.map((point) => this.getPointsOfPoint(point))
												.reduce((sum, current) => sum + current, 0);
										return points;
									});
								group.points = allPoints.reduce((sum, current) => sum + current, 0);
								return group;
							})
							.sort((a, b) => (a.points < b.points ? 1 : a.points > b.points ? -1 : 0));
						this.groupsRanked = [...this.groupsRankedSaved];
					} else {
						this.eventUsersSaved = [...results.eventUsers]
							.filter((user) => user)
							.map((eventUser) => {
								eventUser.points =
									// eventUser.points +
									this.points
										.filter((point) => point.userId === eventUser.uid)
										.map((point) => this.getPointsOfPoint(point))
										.reduce((sum, current) => sum + current, 0);
								return eventUser;
							})
							.sort((a, b) => (a.points < b.points ? 1 : a.points > b.points ? -1 : 0));
						this.eventUsers = [...this.eventUsersSaved];
					}
					this.loader = false;
				}
			);
		}
	}

	/**
	 * Get points of point
	 * @param point
	 * @returns
	 */
	getPointsOfPoint(point: IPointRecord) {
		if (point.type === TypeModule.QUIZ) {
			const quiz = this.quizs.find((quiz) => quiz.uid === point.options.quizId);
			if (quiz) {
				const question = quiz.questions.find((question) => question.uid === point.options.questionId);
				const weight =
					question.type === "oneSelect"
						? question.answers.find((answer) => answer.uid === point.options.answerId).weight
						: question.answers
								.filter((answer) => point.options.answerIds.includes(answer.uid))
								.reduce((sum, answer) => sum + answer.weight, 0);
				return weight;
			} else {
				return 0;
			}
		} else if (point.type === TypeModule.SURVEY) {
			const survey = this.surveys.find((survey) => survey.uid === point.options.surveyId);
			const question = survey.questions.find((question) => question.uid === point.options.questionId);
			const weight =
				question.type === "oneSelect"
					? question.answers.find((answer) => answer.uid === point.options.answerId).weight
					: question.type === "multipleSelect"
						? question.answers
								.filter((answer) => point.options.answerIds.includes(answer.uid))
								.reduce((sum, answer) => sum + answer.weight, 0)
						: question.weight;
			return weight;
		} else if (point.type === TypeModule.TREASURE_HUNTS) {
			return this.qrCodes.find(
				(qrcode) =>
					qrcode.treasureHuntId === point.options.treasureHuntId && qrcode.uid === point.options.qrCodeId
			)
				? this.qrCodes.find(
						(qrcode) =>
							qrcode.treasureHuntId === point.options.treasureHuntId &&
							qrcode.uid === point.options.qrCodeId
					).points
				: null;
		}
	}

	/**
	 * Get position of user
	 * @returns
	 */
	getEventUserPosition(eventUser: IEventUser) {
		if (eventUser) {
			return this.eventUsersSaved.find((user) => user.uid === eventUser.uid)
				? this.eventUsersSaved.indexOf(this.eventUsersSaved.find((user) => user.uid === eventUser.uid)) + 1
				: null;
		}
	}

	/**
	 * Get position of group
	 * @returns
	 */
	getGroupPosition(group: IGroup) {
		if (group) {
			return this.groupsRankedSaved.find((grp) => grp.uid === group.uid)
				? this.groupsRankedSaved.indexOf(this.groupsRankedSaved.find((grp) => grp.uid === group.uid)) + 1
				: null;
		}
	}

	/**
	 * Get event user points
	 * @param eventUser
	 * @returns
	 */
	getEventUserPoints(eventUser: IEventUser) {
		if (eventUser) {
			return this.eventUsersSaved.find((user) => user.uid === eventUser.uid)
				? this.eventUsersSaved.find((user) => user.uid === eventUser.uid).points
				: 0;
		}
	}

	/**
	 * Get group points
	 * @param group
	 * @returns
	 */
	getGroupPoints(group: IGroup) {
		if (group) {
			return this.groupsRankedSaved.find((grp) => grp.uid === group.uid)
				? this.groupsRankedSaved.find((grp) => grp.uid === group.uid).points
				: 0;
		}
	}

	/**
	 * Get X first places event user
	 * @returns
	 */
	getXFirstPlacesEventUser(evt: any) {
		this.infiniteScrollDisabled = false;
		this.limit += 30;
		this.manageInfiniteScroll(evt);
		return this.eventUsers.slice(0, this.limit);
	}

	/**
	 * Get X first places group
	 * @returns
	 */
	getXFirstPlacesGroup(evt: any) {
		this.infiniteScrollDisabled = false;
		this.limit += 30;
		this.manageInfiniteScroll(evt);
		return this.groupsRanked.slice(0, this.limit);
	}

	/**
	 * Manage infinite scroll
	 * @param evt
	 */
	manageInfiniteScroll(evt: any) {
		if (evt) {
			if (
				(!this.ranking.rankingByGroups && this.limit !== this.eventUsers.length) ||
				(this.ranking.rankingByGroups && this.limit !== this.groupsRanked.length)
			) {
				evt.target.complete();
			} else {
				this.infiniteScrollDisabled = true;
			}
		}
	}

	/**
	 * Search bar
	 * @param ev
	 */
	searchBar(ev) {
		if (ev.target.value.length >= 1) {
			const value = ev.target.value.toLowerCase();
			if (this.ranking.rankingByGroups) {
				this.groupsRanked = [...this.groupsRankedSaved].filter((item) =>
					item.name.toLowerCase().includes(value)
				);
			} else {
				this.eventUsers = [...this.eventUsersSaved].filter((item) => item.name.toLowerCase().includes(value));
			}
		} else {
			this.resetFilter();
		}
	}

	/**
	 * Reset filter
	 */
	resetFilter() {
		this.eventUsers = [...this.eventUsersSaved];
		this.groupsRanked = [...this.groupsRankedSaved];
	}
}
