import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IEventUser } from "../interfaces";
import { IDocumentsState } from "../interfaces/documents.interfaces";
import { getCurrentEventUser } from "./auth.selectors";

export const getDocumentsState = createFeatureSelector<IDocumentsState>("documents");

export const checkSameEvent = (uid: string) =>
	createSelector(getDocumentsState, (state: IDocumentsState) => {
		return state.eventId === uid ? true : false;
	});

/**
 * Documents part
 */
export const getDocuments = createSelector(getDocumentsState, (state: IDocumentsState) => _.cloneDeep(state.documents));

export const getDocumentsOfModule = (moduleId: string) =>
	createSelector(getDocumentsState, (state: IDocumentsState) => {
		const documents = _.cloneDeep(state.documents);
		return documents.filter((document) => document.moduleId === moduleId);
	});

export const getDocumentsOfFolder = (data: { folderId: string; order: string; language: string }) =>
	createSelector(getDocumentsState, (state: IDocumentsState) => {
		const documents = _.cloneDeep(state.documents).filter((document) => document.folderId === data.folderId);
		if (data.order === "asc") {
			return documents.sort((a, b) =>
				a.name[data.language] > b.name[data.language]
					? 1
					: a.name[data.language] < b.name[data.language]
						? -1
						: 0
			);
		} else if (data.order === "desc") {
			return documents.sort((a, b) =>
				a.name[data.language] < b.name[data.language]
					? 1
					: a.name[data.language] > b.name[data.language]
						? -1
						: 0
			);
		} else if (data.order === "oldest") {
			return documents.sort((a, b) =>
				a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0
			);
		} else {
			return documents.sort((a, b) =>
				a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0
			);
		}
	});

export const getSpecificDocument = (uid: string) =>
	createSelector(getDocumentsState, (state: IDocumentsState) =>
		_.cloneDeep(state.documents).find((document) => document.uid === uid)
	);

export const getSpecificDocuments = (uids: string[]) =>
	createSelector(getDocumentsState, (state: IDocumentsState) =>
		_.cloneDeep(state.documents).filter((document) => uids.includes(document.uid))
	);

/**
 * Folders part
 */
export const getFolders = createSelector(getDocumentsState, (state: IDocumentsState) => _.cloneDeep(state.folders));

export const getFoldersOfModule = (data: { moduleId: string; order: string; language: string }) =>
	createSelector(getCurrentEventUser, getDocumentsState, (eventUser: IEventUser, state: IDocumentsState) => {
		const folders = _.cloneDeep(state.folders).filter(
			(folder) =>
				(folder.visionType === 0 && folder.moduleId === data.moduleId) ||
				(folder.visionType === 1 &&
					folder.moduleId === data.moduleId &&
					eventUser &&
					eventUser.groups.some((grpId) => folder.authorizedGroupsVision.includes(grpId)))
		);
		return folders.sort((a, b) => {
			if (data.order === "oldest") {
				return a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0;
			} else if (data.order === "recent") {
				return a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0;
			} else if (data.order === "asc") {
				return a.name[data.language] > b.name[data.language]
					? 1
					: a.name[data.language] < b.name[data.language]
						? -1
						: 0;
			} else if (data.order === "desc") {
				return a.name[data.language] < b.name[data.language]
					? 1
					: a.name[data.language] > b.name[data.language]
						? -1
						: 0;
			} else {
				return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
			}
		});
	});

export const getSpecificFolder = (uid: string) =>
	createSelector(getDocumentsState, (state: IDocumentsState) =>
		_.cloneDeep(state.folders).find((folder) => folder.uid === uid)
	);

/**
 * Images part
 */
export const getImages = createSelector(getDocumentsState, (state: IDocumentsState) => _.cloneDeep(state.images));

export const getImagesOfModule = (moduleId: string) =>
	createSelector(getDocumentsState, (state: IDocumentsState) => {
		const images = _.cloneDeep(state.images);
		return images.filter((image) => image.moduleId === moduleId);
	});

export const getImagesOfFolder = (data: { folderId: string; order: string }) =>
	createSelector(getDocumentsState, (state: IDocumentsState) => {
		const images = _.cloneDeep(state.images).filter((image) => image.folderId === data.folderId);
		if (data.order === "asc") {
			return images.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
		} else if (data.order === "desc") {
			return images.sort((a, b) => (a.name < b.name ? 1 : a.name > b.name ? -1 : 0));
		} else if (data.order === "oldest") {
			return images.sort((a, b) =>
				a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0
			);
		} else {
			return images.sort((a, b) =>
				a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0
			);
		}
	});

export const getSpecificImage = (uid: string) =>
	createSelector(getDocumentsState, (state: IDocumentsState) =>
		_.cloneDeep(state.images).find((image) => image.uid === uid)
	);

/**
 * Video part
 * */

export const getVideos = createSelector(getDocumentsState, (state: IDocumentsState) => _.cloneDeep(state.videos));

export const getVideosOfModule = (moduleId: string) =>
	createSelector(getDocumentsState, (state: IDocumentsState) => {
		const videos = _.cloneDeep(state.videos);
		return videos.filter((video) => video.moduleId === moduleId);
	});

export const getVideosOfFolder = (data: { folderId: string; order: string }) =>
	createSelector(getDocumentsState, (state: IDocumentsState) => {
		const videos = _.cloneDeep(state.videos).filter((video) => video.folderId === data.folderId);
		if (data.order === "asc") {
			return videos.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
		} else if (data.order === "desc") {
			return videos.sort((a, b) => (a.name < b.name ? 1 : a.name > b.name ? -1 : 0));
		} else if (data.order === "oldest") {
			return videos.sort((a, b) =>
				a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0
			);
		} else {
			return videos.sort((a, b) =>
				a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0
			);
		}
	});

export const getSpecificVideo = (uid: string) =>
	createSelector(getDocumentsState, (state: IDocumentsState) =>
		_.cloneDeep(state.videos).find((video) => video.uid === uid)
	);
