@if (text.length <= textLength) {
	<span class="text">
		{{ text }}
	</span>
}
@if (text.length > textLength && !showMore) {
	<span class="text">
		{{ text.substr(0, textLength) }}...
		<button class="show-more-button" (click)="changeShowState()">
			{{ "buttons.show_more" | translate }}
		</button>
	</span>
}
@if (text.length > textLength && showMore) {
	<span class="text">
		{{ text }}
		<button class="show-less-button" (click)="changeShowState()">{{ "buttons.show_less" | translate }}</button>
	</span>
}
