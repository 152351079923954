<div class="video-wrapper integrated" [style.width]="'100%'" [style.height]="getHeight() + 'px'">
	<!-- For vimeo player -->
	<iframe
		#videoPlayer
		id="videoPlayer"
		*ngIf="type === 'vimeo' && src.includes('player')"
		style="display: block !important; margin: auto !important"
		[src]="src | video: start | safeHtml: 'resourceUrl'"
		[width]="getWidth()"
		[height]="getHeight()"
		frameborder="0"
		allow="autoplay; fullscreen"
		allowfullscreen
		[hidden]="moving"
		[style.position]="'absolute'"
	></iframe>
	<div
		*ngIf="type === 'vimeo' && !src.includes('player')"
		[hidden]="moving"
		[style.width]="getWidth()"
		[style.height]="getHeight()"
	>
		<div #videoPlayer id="videoPlayer"></div>
	</div>

	<!-- For youtube player -->
	<div
		#youtubeVideoPlayerContainer
		*ngIf="type === 'youtube'"
		[hidden]="moving"
		[style.width]="'100%'"
		style="display: flex; flex-direction: row; align-items: center; justify-content: center"
		[style.height]="getHeight()"
	>
		<youtube-player
			style="display: block"
			#videoPlayer
			*ngIf="canLoadYtPlayer && youtubeVideoPlayerContainer && youtubeVideoPlayerContainer.clientWidth > 0"
			[disablePlaceholder]="true"
			[disableCookies]="true"
			[videoId]="src"
			[height]="getHeight()"
			[width]="getWidth()"
			(ready)="onReady($event)"
			(stateChange)="stateChange(); stateChangedSubject.next($event)"
			(error)="onError($event)"
		>
		</youtube-player>
	</div>

	<!-- Iframe type video -->
	<iframe
		id="iframe"
		*ngIf="type === 'iframe'"
		[class]="isMobile ? 'video-player-global-mobile' : 'video-player-global'"
		[style.width]="'100%'"
		[height]="getIframeVideoHeight()"
		[src]="src | safeHtml: 'resourceUrl'"
		frameborder="0"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
		allowfullscreen
	></iframe>
	<!-- <video *ngIf="type !== 'youtube' && type !== 'vimeo'" [hidden]="moving" #videoPlayer [width]="getWidth()"
        [height]="getHeight()" controls>
        <source [src]="src" type="video/mp4">
        Your browser does not support the video tag.
    </video> -->

	<!-- Other video -->
	<iframe
		*ngIf="type === 'other'"
		id="other"
		[class]="isMobile ? 'video-player-global-mobile' : 'video-player-global'"
		style="width: 100% !important"
		[height]="getIframeVideoHeight()"
		[src]="src | safeHtml: 'resourceUrl'"
		frameborder="0"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
		allowfullscreen
	></iframe>
</div>
