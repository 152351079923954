import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { select, Store } from "@ngrx/store";
import { skipWhile, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IAskQuestions, IEvent, IEventUser, IModule, ISchedule } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getAccessiblesAsksQuestionsByType } from "src/app/shared/selectors/interactivity.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService, SchedulesService } from "src/app/shared/services";
import * as _ from "lodash-es";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";

@Component({
	selector: "app-asks-questions",
	templateUrl: "./asks-questions.component.html",
	styleUrls: ["./asks-questions.component.scss"],
	standalone: false
})
export class AsksQuestionsComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];

	@Input() componentMode: boolean = false;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	asksQuestions: IAskQuestions[] = [];
	eventUser: IEventUser;
	@Input() sessionId: string;
	session: ISchedule;

	currentLanguage: string = environment.platform.defaultLanguage;

	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		private store: Store,
		private SAnalytics: AnalyticsService,
		private SSchedules: SchedulesService,
		private navCtrl: NavController,
		private STranslate: TranslateService
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
	}

	ngOnInit(): void {
		if (this.componentMode) {
			this.initDatas();
		}
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		if (!this.componentMode) {
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;

							if (!this.componentMode) {
								this.SAnalytics.moduleAccess(
									this.eventId,
									this.moduleId,
									TypeTracking.ACCESS_TO_ASK_QUESTION_MODULE
								);
							}

							this.initDatas();
						});
				});
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		if (this.sessionId) {
			this.getSession();
		}
		this.getAsksQuestions();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.store.dispatch(ResetHeaderState(null));
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
				}
			})
		);
	}

	/**
	 * Get session
	 */
	getSession() {
		this.subscriptions.push(
			this.SSchedules.getSpecificSession(this.eventId, this.moduleId, this.sessionId).subscribe((session) => {
				// this.store.select(getSpecificSession(this.sessionId)).subscribe((session) => {
				if (!_.isEqual(this.session, session)) {
					this.session = session;
				}
			})
		);
	}

	/**
	 * Get accessibles asks questions
	 */
	getAsksQuestions() {
		this.subscriptions.push(
			this.store
				.pipe(
					skipWhile(() => this.componentMode && !this.session),
					select(
						getAccessiblesAsksQuestionsByType(
							this.componentMode ? 1 : 0,
							this.componentMode && this.session ? this.session : null
						)
					)
				)
				.subscribe((asksQuestions) => {
					const asksQuestionsSorted = asksQuestions.sort((a, b) =>
						a.order - b.order !== 0
							? a.order - b.order
							: DateTime.fromISO(b.creationDate).valueOf() - DateTime.fromISO(a.creationDate).valueOf()
					);
					if (!_.isEqual(this.asksQuestions, asksQuestionsSorted)) {
						this.asksQuestions = asksQuestionsSorted;
					}
					if (this.module && !this.componentMode) {
						this.store.dispatch(
							GetHeaderTitle({
								payload: this.asksQuestions.length === 1 ? this.asksQuestions[0].name : this.module.name
							})
						);
					}
				})
		);
	}

	/**
	 * Open ask questions
	 * @param askQuestions
	 */
	openAskQuestions(askQuestions: IAskQuestions) {
		if (this.componentMode) {
			this.changeView.emit({ segmentType: "questions", type: "question", uid: askQuestions.uid });
		} else {
			this.navCtrl.navigateForward(`/event/${this.eventId}/asks-questions/${this.moduleId}/${askQuestions.uid}`);
		}
	}
}
