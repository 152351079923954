import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	WritableSignal,
	input,
	signal
} from "@angular/core";
import { Router } from "@angular/router";
import { NavController, Platform } from "@ionic/angular";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, IEventUser, IModule, IQuiz, IQuizQuestion, ISchedule } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getAccessiblesQuizsByType } from "src/app/shared/selectors/interactivity.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { SchedulesService } from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-quizs",
	templateUrl: "./quizs.component.html",
	styleUrls: ["./quizs.component.scss"],
	standalone: false
})
export class QuizsComponent implements OnDestroy, OnChanges {
	subscriptions: Subscription[] = [];
	accessiblesQuizsSubscription: Subscription;
	analyticsArraySub: { name: string; sub: Subscription }[] = [];
	langSub: Subscription;

	componentMode = input<boolean>(false);
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input() sessionId: string;
	session: WritableSignal<ISchedule> = signal(null);
	eventUser: IEventUser;

	quizs: WritableSignal<IQuiz[]> = signal([]);

	unansweredQuizs: WritableSignal<IQuiz[]> = signal([]);
	answeredQuizs: WritableSignal<IQuiz[]> = signal([]);
	quizsSaved: any;

	quizSelectedId: string;

	needRebuild: boolean = true;
	currentLanguage: string = environment.platform.defaultLanguage;

	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		private SAnalytics: AnalyticsService,
		private SSchedules: SchedulesService,
		private store: Store,
		private navCtrl: NavController,
		private router: Router,
		private STranslate: TranslateService
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		if (this.langSub && !this.langSub.closed) {
			this.langSub.unsubscribe();
		}
		this.langSub = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
		if (!this.componentMode()) {
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.subscriptions.forEach((sub) => sub.unsubscribe());

							// Analytics
							this.SAnalytics.moduleAccess(
								this.eventId,
								this.moduleId,
								TypeTracking.ACCESS_TO_QUIZS_MODULE
							);

							this.initDatas();
						});
				});
		}

		if (this.componentMode()) {
			this.subscriptions.forEach((sub) => sub.unsubscribe());
			this.initDatas();
		}
	}

	ngOnChanges(): void {
		if (this.componentMode()) {
			this.subscriptions.forEach((sub) => sub.unsubscribe());
			this.initDatas();
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		if (this.sessionId) {
			this.getSession();
		} else {
			this.getAccessiblesQuizs();
		}
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.concat([this.langSub]).forEach((sub) => sub?.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub?.unsubscribe());
		if (this.accessiblesQuizsSubscription) {
			this.accessiblesQuizsSubscription.unsubscribe();
		}
	}

	ionViewWillLeave() {
		if (!this.componentMode()) {
			if (!this.router.routerState.snapshot.url.includes("quiz")) {
				this.needRebuild = true;
			}
			this.store.dispatch(ResetHeaderState(null));
		}

		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}

				if (this.module && !this.componentMode()) {
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
				}
			})
		);
	}

	/**
	 * Get session
	 */
	getSession() {
		this.subscriptions.push(
			this.SSchedules.getSpecificSessionOfEvent(this.eventId, this.sessionId).subscribe((session) => {
				if (!_.isEqual(this.session(), session)) {
					this.session.set(session);

					this.getAccessiblesQuizs();
				}
			})
		);
	}

	/**
	 * Get accessibles quizs
	 */
	getAccessiblesQuizs() {
		if (this.accessiblesQuizsSubscription) {
			this.accessiblesQuizsSubscription.unsubscribe();
		}
		this.accessiblesQuizsSubscription = this.store
			.pipe(
				select(
					getAccessiblesQuizsByType(
						this.componentMode() ? 1 : 0,
						this.componentMode() && this.session() ? this.session() : null
					)
				)
			)
			.subscribe((quizs) => {
				const quizsSorted = quizs.sort((a, b) =>
					a.order > b.order
						? 1
						: a.order < b.order
							? -1
							: DateTime.fromISO(b.creationDate).valueOf() - DateTime.fromISO(a.creationDate).valueOf()
				);

				this.quizs.set(quizsSorted);
				this.answeredQuizs.set(
					this.quizs().filter(
						(quiz) =>
							quiz.eventUserResults &&
							quiz.eventUserResults.length ===
								quiz.questions.filter((question) => question.type !== "plainText").length
					)
				);
				this.unansweredQuizs.set(
					this.quizs().filter(
						(quiz) =>
							!quiz.eventUserResults ||
							quiz.eventUserResults.length === 0 ||
							(quiz.eventUserResults &&
								quiz.eventUserResults.length !==
									quiz.questions.filter((question) => question.type !== "plainText").length)
					)
				);

				if (this.unansweredQuizs.length === 1 && this.answeredQuizs.length === 0 && !this.componentMode()) {
					this.openQuiz(this.unansweredQuizs[0]);
				}
			});
	}

	/**
	 * Get answered or not quizs
	 * @param type
	 * @returns
	 */
	getAnsweredOrNotQuizs(type: string) {
		return type === "answered"
			? this.quizs().filter(
					(quiz) => quiz.eventUserResults && quiz.eventUserResults.length === quiz.questions.length
				)
			: this.quizs().filter(
					(quiz) =>
						!quiz.eventUserResults ||
						quiz.eventUserResults.length === 0 ||
						(quiz.eventUserResults && quiz.eventUserResults.length !== quiz.questions.length)
				);
	}

	/**
	 * Navigate to quiz or open it in component mode
	 * @param quiz
	 */
	openQuiz(quiz: IQuiz) {
		if (!this.componentMode()) {
			this.navCtrl.navigateForward(`/event/${this.eventId}/quizs/${quiz.moduleId}/${quiz.uid}`);
		} else {
			// Switch to quiz view on component mode
			this.changeView.emit({ segmentType: "quizs", type: "quiz", uid: quiz.uid });
		}
	}

	/**
	 * Check number of questions active
	 * @param questions
	 * @returns
	 */
	checkNumberOfQuestionsActive(questions: IQuizQuestion[]) {
		return questions.filter((question) => question.visibility && question.type !== "plainText").length;
	}

	/**
	 * Count questions without plainText
	 */
	countQuestions(questions) {
		return questions.filter((question) => question.type !== "plainText").length;
	}
}
