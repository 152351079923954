<div class="tw-w-full tw-min-h-full tw-bg-transparent tw-flex tw-flex-row tw-items-start tw-justify-center">
	<div class="tw-flex tw-flex-col tw-w-full tw-h-full">
		@if (event() && checkin()) {
			<div
				class="tw-flex tw-flex-col tw-items-center tw-justify-start tw-w-full tw-h-fit tw-rounded-[15px]"
				[style.border-top-left-radius]="isMobile() ? '0' : '15px'"
				[style.border-top-right-radius]="isMobile() ? '0' : '15px'"
				[style.border-bottom-left-radius]="'15px'"
				[style.border-bottom-right-radius]="'15px'"
				[style.background]="event().styling.cardBgColor"
			>
				<div
					class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-[90%] tw-h-[65px] tw-mx-auto tw-mt-0 tw-mb-[4px] tw-pt-[11px]"
					#totalContainer
				>
					<div
						class="tw-py-0 tw-px-[10px] tw-rounded-[5px]"
						[style.background-color]="'transparent'"
						[style.color]="'green'"
					>
						<h2 [style.font-family]="'var(--font)'" class="tw-font-bold tw-m-0">{{ totalPresent() }}</h2>
						<span
							[style.font-family]="'var(--font)'"
							class="tw-font-normal tw-text-[14px] tw-opacity-[0.6] tw-m-0"
							>{{ "checkin.showed" | translate }}</span
						>
					</div>
					<div class="tw-w-[1px] tw-bg-[rgba(32, 17, 87, 0.09)] tw-h-[65%] tw-my-auto tw-mx-[27px]"></div>
					<div
						class="tw-py-0 tw-px-[10px] tw-rounded-[5px]"
						[style.background-color]="'transparent'"
						[style.color]="'#d82029'"
					>
						<h2 [style.font-family]="'var(--font)'" class="tw-font-bold tw-m-0">{{ totalAway() }}</h2>
						<span class="tw-font-normal tw-text-[14px] tw-opacity-[0.6] tw-m-0">{{
							"checkin.away" | translate
						}}</span>
					</div>
					<div class="tw-w-[1px] tw-bg-[rgba(32, 17, 87, 0.09)] tw-h-[65%] tw-my-auto tw-mx-[27px]"></div>
					<div
						class="tw-py-0 tw-px-[10px] tw-rounded-[5px]"
						[style.background-color]="'transparent'"
						[style.color]="'black'"
					>
						<h2 [style.font-family]="'var(--font)'" class="tw-font-bold tw-m-0">{{ total() }}</h2>
						<span class="tw-font-normal tw-text-[14px] tw-opacity-[0.6] tw-m-0">{{
							"checkin.total" | translate
						}}</span>
					</div>
				</div>
				<app-tabs
					class="tw-w-full"
					[tabs]="[
						{ text: 'labels.scan', translation: true, active: true, key: 'scan' },
						{ text: 'labels.list', translation: true, active: false, key: 'list' }
					]"
					(tabsOutput)="tabShowed.set($event)"
				></app-tabs>
				@if (tabShowed() === "list") {
					<ion-searchbar
						[style.--background]="'var(--bg-color)'"
						[style.--icon-color]="'rgba(0, 0, 0, 0.6)'"
						[style.font-family]="'var(--font)'"
						class="tw-font-normal tw-w-[90%] tw-m-auto"
						placeholder="{{ 'texts.search_plc' | translate }}"
						cancelButtonText="{{ 'buttons.cancel' | translate }}"
						[debounce]="600"
						(ionInput)="
							$event.detail && $event.detail.value && $event.detail.value.length > 2
								? getSpecificEventUsers($event.detail.value, false)
								: null
						"
						(ionClear)="resetFilter()"
					>
					</ion-searchbar>
				}
			</div>
			@if (tabShowed() === "scan") {
				<zxing-scanner
					[enable]="SCheckins.enableScanning()"
					[delayBetweenScanSuccess]="2000"
					(camerasFound)="SCheckins.camerasFoundHandler()"
					(camerasNotFound)="SCheckins.camerasNotFoundHandler()"
					(scanSuccess)="SCheckins.scanSuccessHandler($event, checkin())"
					(scanError)="SCheckins.scanErrorHandler($event)"
				>
				</zxing-scanner>
			} @else if (tabShowed() === "list") {
				<div #contentContainer [style.height]="'calc(100% - ' + totalContainer.clientHeight + 'px - 70px)'">
					<cdk-virtual-scroll-viewport
						#onVisibleScrollContainer
						id="onVisibleScrollContainer"
						class="tw-w-full tw-h-full"
						[itemSize]="itemSize()"
						[minBufferPx]="height()"
						[maxBufferPx]="height()"
					>
						<div style="height: 100%" [style.padding]="'0 10px 80px 10px'">
							@for (data of datas(); track data.uid) {
								<app-event-user-item
									[event]="event()"
									[mainModule]="module()"
									[checkin]="checkin()"
									[targetModule]="getSpecificEventUsersModule(data.moduleId)"
									[customFields]="getCustomFieldsOfModule(data.moduleId)"
									[data]="data"
									[isChecked]="
										data['checkedsDatas'] &&
										data['checkedsDatas'].length > 0 &&
										data['checkedsDatas'][0].checkinStatus
											? true
											: false
									"
									[checked]="
										data['checkedsDatas'] && data['checkedsDatas'].length > 0
											? data['checkedsDatas'][0]
											: null
									"
									[eventUser]="eventUser()"
									[currentLanguage]="currentLanguage()"
									(changeStatus)="SCheckins.manualChangeStatus(checkin(), $event)"
									(modifyMultiCheck)="
										SCheckins.modifyMultiCheck(checkin(), $event.data.uid, $event.type)
									"
								></app-event-user-item>
							}
						</div>
					</cdk-virtual-scroll-viewport>
				</div>
			}
		}
	</div>
</div>

@if (SCheckins.showCheckModal()) {
	<div
		class="tw-w-screen tw-h-screen tw-bg-black/50 tw-fixed tw-top-0 tw-left-0 tw-z-[9999] tw-flex tw-flex-row tw-items-center tw-justify-center"
	>
		<div
			class="tw-w-fit tw-max-h-[90vh] tw-py-10 tw-px-10 tw-h-fit tw-bg-white tw-rounded-lg tw-border-[2px] tw-border-solid tw-flex tw-flex-col tw-items-center tw-justify-center tw-overflow-y-auto"
			[class.tw-border-green-500]="SCheckins.checkType() === 'checked'"
			[class.tw-border-[#E9990F]]="SCheckins.checkType() === 'unchecked'"
			[class.tw-border-red-500]="SCheckins.checkType() === 'error'"
			[class.tw-border-[0px]]="SCheckins.modalCurrentStep() === 'signature'"
		>
			@if (SCheckins.modalCurrentStep() === "profil") {
				<!-- Icons type based on check type -->
				@if (SCheckins.checkType() === "checked") {
					<span class="material-icons tw-text-green-500 tw-w-[48px] tw-h-[48px] tw-text-[48px]">
						check_circle
					</span>
				} @else if (SCheckins.checkType() === "unchecked") {
					<span class="material-icons-round tw-text-[#E9990F] tw-w-[48px] tw-h-[48px] tw-text-[48px]">
						warning
					</span>
				} @else if (SCheckins.checkType() === "error") {
					<span class="material-icons tw-text-red-500 tw-w-[48px] tw-h-[48px] tw-text-[48px]"> cancel </span>
				}

				<!-- Show checked user infos if exist -->
				@if (SCheckins.eventUserChecked()) {
					<app-event-user-item
						[designType]="'column'"
						[event]="event()"
						[data]="SCheckins.eventUserChecked()"
						[currentLanguage]="currentLanguage()"
						[targetModule]="getSpecificEventUsersModule(SCheckins.eventUserChecked().moduleId)"
						[customFields]="getCustomFieldsOfModule(SCheckins.eventUserChecked().moduleId)"
					></app-event-user-item>
				}

				<!-- If check msg -->
				@if (SCheckins.checkType() === "checked" && SCheckins.validMsgCheck()) {
					<span
						class="tw-text-base tw-text-green-500 tw-font-bold tw-my-4 tw-whitespace-pre-line tw-text-center"
						>{{ SCheckins.validMsgCheck() }}</span
					>
				}

				<!-- If unchecked msg -->
				@if (SCheckins.checkType() === "unchecked" && SCheckins.validMsgCheck()) {
					<span
						class="tw-text-base tw-text-[#E9990F] tw-font-bold tw-my-4 tw-whitespace-pre-line tw-text-center"
						>{{ SCheckins.validMsgCheck() }}</span
					>
				}

				<!-- If error check show error msg -->
				@if (SCheckins.checkType() === "error" && SCheckins.errorCheck()) {
					<span
						class="tw-text-base tw-text-[#ef4444] tw-font-bold tw-my-4 tw-whitespace-pre-line tw-text-center"
						>{{ SCheckins.errorCheck() }}</span
					>
				}
			}

			<!-- Signature step -->
			@if (SCheckins.modalCurrentStep() === "signature") {
				<app-signature-pad
					[event]="event()"
					[signatureExisting]="
						SCheckins.eventUserCheck().signature
							? {
									path:
										'events/' +
										SCheckins.eventUserCheck().eventId +
										'/modules/' +
										SCheckins.eventUserCheck().moduleId +
										'/checkins-signature/' +
										SCheckins.eventUserCheck().checkinId +
										'/' +
										SCheckins.eventUserCheck().userId +
										'/signature.png',
									url: SCheckins.eventUserCheck().signature
								}
							: null
					"
					(signatureImgOutput)="SCheckins.modifySignatureOfCheck(SCheckins.eventUserCheck(), $event)"
					(noChangeMadeOutput)="SCheckins.resetCheck()"
				></app-signature-pad>
			}

			<!-- Buttons on profil step -->
			@if (SCheckins.modalCurrentStep() === "profil") {
				<!-- Buttons next if checked or unchecked type -->
				@if (SCheckins.checkType() === "checked" || SCheckins.checkType() === "unchecked") {
					<ion-button
						class="tw-h-11 btn-outline-default tw-fit tw-mt-4"
						shape="round"
						fill="outline"
						[style.--background]="event().styling.btnBgColor"
						[style.color]="event().styling.btnTextColor"
						(click)="
							checkin().activateSignature
								? SCheckins.modalCurrentStep.set('signature')
								: SCheckins.resetCheck()
						"
					>
						{{ "buttons.next" | translate }}
					</ion-button>
				} @else {
					<!-- Button close in case of error type -->
					<ion-button
						class="tw-h-11 btn-outline-default tw-fit tw-mt-4"
						shape="round"
						fill="outline"
						[style.--background]="event().styling.btnBgColor"
						[style.color]="event().styling.btnTextColor"
						(click)="SCheckins.resetCheck()"
					>
						{{ "buttons.close" | translate }}
					</ion-button>
				}
			}
		</div>
	</div>
}
