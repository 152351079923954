import { Component, OnDestroy } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { Subscription, combineLatest, take } from "rxjs";
import { GetHeaderTitle, GetHeaderType, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule, TypeModuleString } from "src/app/shared/enums/type-module";
import {
	ICustomField,
	IEvent,
	IEventUser,
	IFullCustomField,
	IModule,
	IModuleCustomField,
	IUser
} from "src/app/shared/interfaces";
import { IFavoriteFolder } from "src/app/shared/interfaces/folders.interfaces";
import { getCurrentEventUser, getCurrentUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getFavoritesFolders } from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService, FavoritesService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-favorites",
	templateUrl: "./favorites.component.html",
	styleUrls: ["./favorites.component.scss"],
	standalone: false
})
export class FavoritesComponent implements OnDestroy {
	subscriptions: Subscription[] = [];

	showTitle: boolean = false;
	widthImg: number = 48;
	showNoResultImage: boolean = false;
	typeModuleString = TypeModuleString;
	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	folderId: string;
	folder: IFavoriteFolder;
	user: IUser;
	eventUser: IEventUser;
	currentLanguage: string = environment.platform.defaultLanguage;
	folders: IFavoriteFolder[];
	loader: boolean;
	scanning: boolean;
	isMobile: boolean;
	checkin: any;
	eventUsers: any;
	allEventUsers: any;
	resultsSaved: [ICustomField[], IModuleCustomField[]];
	customFields: IFullCustomField[] = [];

	constructor(
		private SAnalytics: AnalyticsService,
		private store: Store,
		private STranslate: TranslateService,
		private navCtrl: NavController,
		private SFavorite: FavoritesService,
		private snackBar: MatSnackBar
	) {}

	ionViewWillEnter() {
		this.store.dispatch(GetHeaderType({ payload: TypeHeader.FAVORITES }));

		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.folderId = params.folderId;
						// Analytics
						// this.SAnalytics.documentFolderAccess(this.eventId, this.moduleId, this.folderId);

						this.getEvent();
						this.getUser();
						this.getModule();
						this.getFolders();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;

					if (this.module) {
						this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
						this.store.dispatch(GetHeaderType({ payload: TypeHeader.FAVORITES }));
					}
				}
			})
		);
	}

	/**
	 * Getting all folders of module
	 */
	getFolders() {
		this.subscriptions.push(
			this.store.select(getFavoritesFolders).subscribe((folders) => {
				if (folders) {
					this.folders = folders.filter((folder) => !folder.hidden)?.sort((a, b) => a.order - b.order);
				}
			})
		);
	}

	openFolder(folder: IFavoriteFolder) {
		this.navCtrl.navigateForward(
			`event/${this.eventId}/favorites/${this.moduleId}/folder/${
				folder.moduleLinkedType === TypeModule.SCHEDULE ? "schedules" : "event-users"
			}/${folder.uid}`
		);
	}

	/**
	 * Getting user datas
	 */
	getUser() {
		this.subscriptions.push(
			combineLatest([this.store.select(getCurrentUser), this.store.select(getCurrentEventUser)]).subscribe(
				(results) => {
					this.user = results[0];
					this.eventUser = results[1];
				}
			)
		);
	}
}
