import { AfterContentChecked, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Browser } from "@capacitor/browser";
import { Photo } from "@capacitor/camera";
import { ModalController, NavController, Platform, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, combineLatest } from "rxjs";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IEvent, IEventUser, ISheet } from "src/app/shared/interfaces";
import { ICustomField, IFullCustomField, IModuleCustomField } from "src/app/shared/interfaces/custom-fields.interfaces";
import { IFavoriteFolder } from "src/app/shared/interfaces/folders.interfaces";
import { IModule } from "src/app/shared/interfaces/modules.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import {
	getBaseCustomFields,
	getFavoritesFolders,
	getModulesCustomsFieldsOfModule
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByType, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { CardExchangeService, CustomFieldsService, EventUsersService, StorageService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { AppLauncher } from "@capacitor/app-launcher";

@Component({
	selector: "app-profile-general-info",
	templateUrl: "./profile-general-info.component.html",
	styleUrls: ["./profile-general-info.component.scss"],
	standalone: false
})
export class ProfileGeneralInfoComponent implements OnInit, OnDestroy, OnChanges, AfterContentChecked {
	@Input()
	myEventUser: IEventUser;
	@Input()
	eventUserProfile: IEventUser | ISheet;
	@Input()
	event: IEvent;
	@Input()
	module: IModule;
	@Input()
	editMode: boolean;
	@Input()
	isPrivateMode: boolean;
	@Input()
	currentLanguage: string;

	isMobile: boolean = false;
	subscriptions: Subscription[] = [];

	customFields: IFullCustomField[];
	allCustomFields: IFullCustomField[];
	title: string;
	company: string;
	userName: string;

	containerInfosHeight: number;

	locale = environment.platform.locale;

	menuColor: string;

	typeCustomFields = TypeCustomFields;
	typeModule = TypeModule;
	totalCustomFieldsTag = 0;

	// profil picture properties
	pictureLoader: boolean;
	description: string = "";
	photoUrl: string = null;
	file: string = null;
	actualImage: Photo;
	computedCustomFields: IFullCustomField[];
	baseCustomFields: ICustomField[];
	modulesCustomsFields: IModuleCustomField[];
	currentEventUserProfile: IEventUser | ISheet;
	favoriteModule: IModule;
	favoriteState: { [eventUserProfileId: string]: boolean };
	favoriteFolder: IFavoriteFolder;
	cardExchangeMode: boolean = false;
	cardExchangeModule: IModule;

	constructor(
		private modalCtrl: ModalController,
		private platform: Platform,
		private store: Store,
		private navCtrl: NavController,
		private toastController: ToastController,
		private snackbar: MatSnackBar,
		private storage: StorageService,
		private SStorage: StorageService,
		private SEventUsers: EventUsersService,
		private STranslate: TranslateService,
		private router: Router,
		public SCustomFields: CustomFieldsService,
		public SCardExchange: CardExchangeService
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.eventUserProfile &&
			!this.SEventUsers.isEventUsersValuesAreIdenticals(
				changes.eventUserProfile.currentValue,
				this.currentEventUserProfile
			)
		) {
			this.currentEventUserProfile = changes.eventUserProfile.currentValue as IEventUser;

			if (
				!this.module ||
				this.module === null ||
				(changes.module && changes.module.currentValue === null) ||
				this.cardExchangeMode ||
				this.module?.uid !== this.currentEventUserProfile.moduleId
			) {
				this.getEventUserModule();
			} else {
				this.getCustomFields();
			}
			this.computedContainerHeight();
		}
	}

	ngOnInit() {
		if ((this.platform.is("mobile") && window.innerWidth < 768) || window.innerWidth < 768) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}

		this.computedContainerHeight();
		this.currentEventUserProfile = this.eventUserProfile;
		this.getCustomFields();
		this.getFavoriteModuleAndFolders();
		this.getCardExchangeModule();
	}

	ngAfterContentChecked() {
		if (this.router.url.includes("card-exchange")) {
			this.cardExchangeMode = true;
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	/**
	 * getFavoriteModuleAndFolders
	 */
	getFavoriteModuleAndFolders() {
		// Favorite module and its folder
		this.subscriptions.push(
			combineLatest([
				this.store.select(getModulesByType(TypeModule.FAVORITES)),
				this.store.select(getFavoritesFolders)
			]).subscribe(([modules, favoriteFolders]) => {
				if (modules && modules.length > 0) {
					this.favoriteModule = modules[0];
				}

				if (favoriteFolders && favoriteFolders.length > 0) {
					this.favoriteFolder = favoriteFolders.filter(
						(folder) => this.module && folder.moduleLinkedId === this.module.uid && !folder.hidden
					)?.[0];
				}

				this.currentEventUserProfile && this.buildFavoriteState(this.eventUserProfile?.uid);
			})
		);
	}

	/**
	 * countCustomFieldTagged
	 * @description count number of custom field tagged
	 */
	countCustomFieldTagged() {
		return this.customFields.filter((customField) => customField.moduleSettings.canBeTag);
	}

	/**
	 * getEventUserModule
	 */
	getEventUserModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.currentEventUserProfile.moduleId)).subscribe((module) => {
				this.module = module;

				if (this.module) {
					this.getCustomFields();
				}
			})
		);
	}

	/**
	 * getCardExchangeModule
	 */
	getCardExchangeModule() {
		this.store.select(getModulesByType(TypeModule.CARD_EXCHANGE)).subscribe((modules) => {
			if (modules && modules.length > 0) {
				this.cardExchangeModule = modules[0];
			}
		});
	}

	/**
	 * getCustomFields
	 */
	getCustomFields() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(getModulesCustomsFieldsOfModule(this.module.uid))
			]).subscribe((results) => {
				// Custom fields datas
				this.customFields = [];
				this.allCustomFields = [];
				this.baseCustomFields = results[0];
				this.modulesCustomsFields = results[1];

				const moduleTaggableCustomFields = this.modulesCustomsFields.filter((cus) => cus.canBeTag);

				if (moduleTaggableCustomFields.length > 0) {
					this.modulesCustomsFields
						.filter((cus) => cus.canBeTag)
						.forEach((customField) => {
							const baseCustomFieldCorresponding = this.baseCustomFields.find(
								(custField) => custField.uid === customField.uid
							);

							if (baseCustomFieldCorresponding) {
								const data = this.currentEventUserProfile.customFields.find(
									(data) => data.uid === customField.uid
								);
								this.customFields.push({
									baseSettings: baseCustomFieldCorresponding,
									moduleSettings: customField,
									fieldDatas: data
										? data
										: {
												uid: "",
												field: {}
											}
								});
							}
						});
				}

				this.modulesCustomsFields.forEach((customField) => {
					const baseCustomFieldCorresponding = this.baseCustomFields.find(
						(custField) => custField.uid === customField.uid
					);

					if (baseCustomFieldCorresponding) {
						const data = this.currentEventUserProfile.customFields.find(
							(data) => data.uid === customField.uid
						);
						this.allCustomFields.push({
							baseSettings: baseCustomFieldCorresponding,
							moduleSettings: customField,
							fieldDatas: data
								? data
								: {
										uid: "",
										field: {}
									}
						});
					}
				});
			})
		);
	}

	/**
	 * computedContainerHeight
	 * @return void
	 */
	computedContainerHeight(): void {
		if (this.isMobile) {
			this.containerInfosHeight = 0;
			const tags = this.customFields.filter((cus) => {
				if (cus.moduleSettings.canBeTag) {
					if (
						cus.baseSettings.type === TypeCustomFields.TEXT ||
						cus.baseSettings.type === TypeCustomFields.SELECT
					)
						return cus.fieldDatas.field.multiLanguageText?.[this.currentLanguage] !== "";
					else if (
						cus.baseSettings.type === TypeCustomFields.URL ||
						cus.baseSettings.type === TypeCustomFields.EMAIL
					)
						return cus.fieldDatas.field.text !== "";
					else if (cus.baseSettings.type === TypeCustomFields.NUMERIC)
						return cus.fieldDatas.field.numeric !== undefined && cus.fieldDatas.field.numeric !== -1;
				}
			});

			this.currentEventUserProfile;
			this.currentEventUserProfile.name.length > 20 && (this.containerInfosHeight += 45);
			tags.length === 1 && (this.containerInfosHeight += 20);
			tags.length === 2 && (this.containerInfosHeight += 40);
			tags.length === 3 && (this.containerInfosHeight += 60);
			(this.currentEventUserProfile.socialMedias.facebook !== "" ||
				this.currentEventUserProfile.socialMedias.instagram !== "" ||
				this.currentEventUserProfile.socialMedias.twitter !== "" ||
				this.currentEventUserProfile.socialMedias.linkedin !== "" ||
				this.currentEventUserProfile.socialMedias.website !== "") &&
				(this.containerInfosHeight += 25);
		}
	}

	/**
	 * Open image
	 * @param url
	 */
	async openImage(url: string) {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.imageViewerModal,
				componentProps: {
					url: url,
					allowDownload:
						this.module && this.module.options && this.module.options.allowDownloadProfilePicture,
					name: `profile-picture${Math.floor(Math.random() * 99999)}.jpg`
				}
			});

			modal.present();
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * navigateTo
	 * @param path
	 */
	navigateTo(path: string) {
		this.navCtrl.navigateForward(path);
	}

	/**
	 * getPicture
	 */
	async promptCameraModal() {
		try {
			const image = await this.storage.getPictureUri();

			if (this.storage.checkBase64ImgSizeLimit(image.base64String)) {
				return;
			}

			image.base64String = "data:image/jpeg;base64," + image.base64String;
			this.actualImage = image;
		} catch (error) {
			if (!error.toString().includes("cancelled"))
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
		}
	}

	/**
	 * saveProfilPicture
	 * @description save the permanent profil picture to the storage
	 * and update the picture url in the user profile document used on 'profile page'
	 * @Returns Promise<string>
	 */
	async saveProfilPicture() {
		try {
			if (!this.actualImage) return;
			const photoBlob = this.storage.blobFromBase64String(this.actualImage.base64String);

			const path = this.currentEventUserProfile
				? // eslint-disable-next-line max-len
					`/events/${this.event.uid}/modules/${this.currentEventUserProfile.moduleId}/event-users/${this.currentEventUserProfile.uid}/${this.currentEventUserProfile.uid}.${this.actualImage.format}`
				: null;

			if (!path) return "No path!";

			const url = await this.storage.uploadFile(
				photoBlob,
				path,
				photoBlob && photoBlob.type ? photoBlob.type : "image/jpeg"
			);
			if (url) {
				this.SEventUsers.updatePartOfEventUser(this.event.uid, this.module.uid, this.eventUserProfile.uid, {
					photoUrl: url
				});
				this.actualImage = null;
				return url;
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				panelClass: "error-basic",
				duration: 3000
			});
		}
	}

	async deleteProfilePicture() {
		try {
			if (this.currentEventUserProfile.photoUrl) {
				await this.SStorage.deleteDocumentOnStorage(this.currentEventUserProfile.photoUrl);
				await this.SEventUsers.updatePartOfEventUser(
					this.event.uid,
					this.module.uid,
					this.currentEventUserProfile.uid,
					{
						photoUrl: ""
					}
				);
			}
			if (this.actualImage) {
				this.actualImage = null;
			}
			this.snackbar.open(this.STranslate.instant("snackbar.update_successfull"), "", {
				duration: 3000,
				panelClass: "success-snackbar"
			});
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * presentToast
	 * @param msg
	 */
	async presentToast(msg: string) {
		try {
			const toast = await this.toastController.create({
				message: msg
			});
			toast.present();
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open link
	 * @param url
	 */
	openLink(url: string) {
		const pattern = new RegExp("^(https?|ftp)://");
		if (!pattern.test(url)) {
			url = "https://" + url;
		}
		if (this.isMobile) {
			Browser.open({
				url: url
			});
		} else {
			window.open(url, "_system");
		}
	}

	async addToFavorite(eventUserId: string, event) {
		try {
			event.stopPropagation();

			if (this.myEventUser) {
				if (this.myEventUser.favorites) {
					!this.myEventUser.favorites.includes(eventUserId) && this.myEventUser.favorites.push(eventUserId);
				} else {
					this.myEventUser = {
						...this.myEventUser,
						favorites: [eventUserId]
					};
				}
				this.buildFavoriteState(eventUserId);

				await this.SEventUsers.updatePartOfEventUser(
					this.event.uid,
					this.myEventUser.moduleId,
					this.myEventUser.uid,
					{
						favorites: this.myEventUser.favorites
					}
				);
				this.snackbar.open(this.STranslate.instant("snackbar.update_successfull"), null, {
					duration: 3000,
					panelClass: ["snackbar-success"]
				});
			}
		} catch (error) {
			console.error("🚀 ~ addToFavorite ~ error:", error);
			// Snackbar error
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	async removeFromFavorite(eventUserId: string, event) {
		try {
			event.stopPropagation();

			if (this.myEventUser) {
				if (this.myEventUser.favorites) {
					this.myEventUser.favorites.includes(eventUserId) &&
						(this.myEventUser.favorites = this.myEventUser.favorites.filter((fav) => fav !== eventUserId));
				} else {
					this.myEventUser = {
						...this.myEventUser,
						favorites: []
					};
				}
				this.buildFavoriteState(eventUserId);

				await this.SEventUsers.updatePartOfEventUser(
					this.event.uid,
					this.myEventUser.moduleId,
					this.myEventUser.uid,
					{
						favorites: this.myEventUser.favorites
					}
				);
				this.snackbar.open(this.STranslate.instant("snackbar.update_successfull"), null, {
					duration: 3000,
					panelClass: ["snackbar-success"]
				});
			}
		} catch (error) {
			console.error("🚀 ~ removeFromFavorite ~ error:", error);
			// Snackbar error
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	/**
	 * buildFavoriteState
	 * @param sessionId
	 */
	buildFavoriteState(eventUserId: string) {
		this.favoriteState = {
			[eventUserId]: this.myEventUser && this.myEventUser.favorites?.includes(eventUserId) ? true : false
		};
	}

	async openFacebook(webUrl?: string) {
		try {
			if (this.isMobile) {
				const profileId = webUrl.split("/")[webUrl.split("/").length - 1];

				const urlScheme = `fb://profile/${profileId}`;
				const canOpen = await AppLauncher.canOpenUrl({ url: urlScheme });

				if (canOpen.value) {
					await AppLauncher.openUrl({ url: urlScheme });
				} else {
					this.openLink(webUrl);
				}
			} else {
				this.openLink(webUrl);
			}
		} catch (error) {
			console.error("openFacebook ~ error:", error);
		}
	}

	async openLinkedIn(webUrl?: string) {
		try {
			if (this.isMobile) {
				const profileId = webUrl.split("/")[webUrl.split("/").length - 1];

				const urlScheme = `linkedin://in/${profileId}`;
				const canOpen = await AppLauncher.canOpenUrl({ url: urlScheme });

				if (canOpen.value) {
					await AppLauncher.openUrl({ url: urlScheme });
				} else {
					this.openLink(webUrl);
				}
			} else {
				this.openLink(webUrl);
			}
		} catch (error) {
			console.error("openLinkedIn ~ error:", error);
		}
	}

	async openInstagram(webUrl?: string) {
		try {
			if (this.isMobile) {
				const profileId = webUrl.split("/")[webUrl.split("/").length - 1];
				const urlScheme = `instagram://user?username=${profileId}`;
				const canOpen = await AppLauncher.canOpenUrl({ url: urlScheme });

				if (canOpen.value) {
					await AppLauncher.openUrl({ url: urlScheme });
				} else {
					this.openLink(webUrl);
				}
			} else {
				this.openLink(webUrl);
			}
		} catch (error) {
			console.error("openInstagram ~ error:", error);
		}
	}

	async openX(webUrl?: string) {
		try {
			// get profile Id form webUrl
			if (this.isMobile) {
				const profileId = webUrl.split("/")[webUrl.split("/").length - 1];
				const urlScheme = `twitter://user?screen_name=${profileId}`;
				const canOpen = await AppLauncher.canOpenUrl({ url: urlScheme });

				if (canOpen.value) {
					await AppLauncher.openUrl({ url: urlScheme });
				} else {
					this.openLink(webUrl);
				}
			} else {
				this.openLink(webUrl);
			}
		} catch (error) {
			console.error("openX ~ error:", error);
		}
	}
}
