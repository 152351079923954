<div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-start">
	<div [style.font-family]="'var(--font)'" class="tw-text-base tw-font-bold tw-mb-2">
		{{ "labels.signature" | translate }}
	</div>

	<div class="tw-w-fit tw-h-fit tw-rounded tw-border tw-border-black tw-border-solid tw-mb-2">
		@if (signatureDocument() && signatureDocument().url) {
			<img [style.width]="width() + 'px'" [style.height]="height() + 'px'" [src]="signatureDocument().url" />
		}
		<canvas
			[hidden]="signatureDocument() && signatureDocument().url"
			#signatureCanvas
			[width]="width()"
			[height]="height()"
		></canvas>
	</div>

	@if (signaturePad()) {
		<div class="tw-w-full tw-h-fit tw-flex tw-flex-row tw-items-center tw-justify-center">
			<ion-button
				class="tw-h-11 btn-outline-default tw-fit"
				shape="round"
				fill="outline"
				[style.--background]="event().styling.btnBgColor"
				[style.color]="event().styling.btnTextColor"
				(click)="clearPad()"
			>
				{{ "buttons.delete" | translate }}
			</ion-button>
			<ion-button
				class="tw-h-11 btn-outline-default tw-fit tw-ml-1"
				shape="round"
				fill="outline"
				[style.--background]="event().styling.btnBgColor"
				[style.color]="event().styling.btnTextColor"
				(click)="savePad()"
			>
				{{ "texts.save" | translate }}
			</ion-button>
		</div>
	}
</div>
