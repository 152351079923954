<div class="background" style="width: 100%; background: transparent; display: flex; flex-direction: row">
	<!-- Filters -->
	<!-- @if (module && module.options && module.options.showFilter && canShowFilterBloc() && !isMobile && !filtersLoader) {
  <app-filters-for-list style="height: 100dvh" [event]="event" [modules]="[module]" [eventUser]="eventUser"
    [filters]="filters" (filtersStateChanged)="buildFilterQuery()"></app-filters-for-list>
  } -->

	<!-- Filters -->
	@if (module && module.options && module.options.showFilter && canShowFilterBloc() && !isMobile && !filtersLoader) {
		<app-filters-for-list
			style="height: 100dvh"
			[event]="event"
			[module]="module"
			[modules]="[module]"
			[eventUser]="eventUser"
			[filters]="filters"
			[datasGetType]="datasGetType"
			(filtersStateChanged)="filters = $event; resetPagination(); buildFilterQuery()"
		></app-filters-for-list>
	}

	<div class="event-users-container">
		@if (module) {
			<div
				class="event-users-searchbar"
				[style.padding]="
					module.options.showFilter && canShowFilterBloc() && !isMobile ? '0 10px 0 0' : '0 10px'
				"
			>
				<ion-searchbar
					[(ngModel)]="searchValue"
					mode="md"
					[style.--background]="'rgb(234, 239, 242)'"
					placeholder="{{ 'texts.search_plc' | translate }}"
					(ionClear)="resetFilter()"
					(ngModelChange)="searchValueUpdated.next($event)"
				>
				</ion-searchbar>
			</div>
		}
		@if (!loader && event && module) {
			<ng-container>
				@if (datasFiltered().length > 0) {
					<ng-container>
						<div class="content-container">
							<cdk-virtual-scroll-viewport
								#onVisibleScrollContainer
								id="onVisibleScrollContainer"
								(scrolledIndexChange)="scrolledIndexChanged($event)"
								style="height: 100%; width: 100%"
								[itemSize]="itemSize"
								[minBufferPx]="height"
								[maxBufferPx]="height"
							>
								<div
									id="main-list"
									style="height: 100%"
									[style.padding]="
										module.options.showFilter && canShowFilterBloc() && !isMobile
											? '0 10px 0 10px'
											: '0 10px 80px 10px'
									"
								>
									@if (!module.options.showListInTwoBloc) {
										<!-- Normal list -->
										<ng-container>
											<div *cdkVirtualFor="let data of datasFiltered()">
												<app-event-user-item
													[rootElement]="onVisibleScrollContainer.elementRef.nativeElement"
													[event]="event"
													[mainModule]="module"
													[targetModule]="module"
													[cardExchangeModule]="module"
													[cardExchangeForm]="cardExchangeForm"
													[customFields]="flattenCustomFields"
													[groupedLetters]="groupedLetters"
													[data]="data"
													[eventUser]="eventUser"
													[currentLanguage]="currentLanguage"
													[networkStatus]="networkStatus"
													(click)="
														navigateTo(
															!eventUserStates?.[data.uid]
																? '/event/' +
																		eventId +
																		'/profile/' +
																		data.moduleId +
																		'/' +
																		data.uid
																: '/event/' +
																		eventId +
																		'/card-exchange/' +
																		moduleId +
																		'/profile/' +
																		data.uid,
															data
														)
													"
												></app-event-user-item>
											</div>
										</ng-container>
									}
									@if (module.options.showListInTwoBloc) {
										<!-- Two blocs list -->
										<ion-list #listOfTwo id="listOfTwo" style="background: transparent">
											<div class="f-row-c-c" *cdkVirtualFor="let data of datasFilteredInTwo()">
												<app-event-user-item
													[type]="'card'"
													[rootElement]="onVisibleScrollContainer.elementRef.nativeElement"
													[event]="event"
													[mainModule]="module"
													[targetModule]="module"
													[customFields]="flattenCustomFields"
													[groupedLetters]="groupedLetters"
													[data]="data"
													[eventUser]="eventUser"
													[currentLanguage]="currentLanguage"
													[networkStatus]="networkStatus"
													(click)="
														navigateTo(
															!eventUserStates?.[data.uid]
																? '/event/' +
																		eventId +
																		'/profile/' +
																		data.moduleId +
																		'/' +
																		data.uid
																: '/event/' +
																		eventId +
																		'/card-exchange/' +
																		moduleId +
																		'/profile/' +
																		data.uid,
															data
														)
													"
												></app-event-user-item>
											</div>
										</ion-list>
									}
								</div>
							</cdk-virtual-scroll-viewport>
							@if (showNoResultImage) {
								<div class="container-no-result">
									<ion-icon
										src="assets/images/emptycontent/AUCUNRESULTAT.svg"
										[style.color]="
											event.styling.menuColor.includes('linear')
												? event.styling.menuColor.split(',')[1]
												: event.styling.menuColor
										"
									>
									</ion-icon>
									<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
										<span class="title"> {{ "texts.no_result_title" | translate }}</span>
										<span>{{ "texts.no_result_text" | translate }}</span>
									</div>
								</div>
							}
						</div>
					</ng-container>
				} @else {
					<ng-container>
						<div class="container">
							<span class="wait-content" [style.color]="event.styling.titleColor">{{
								(dontBelongAnyGroups
									? "texts.not_belonging_to_any_group"
									: searchValue
										? "texts.no_result_title"
										: "texts.waiting_for_content"
								) | translate
							}}</span>
							<ion-icon
								[src]="
									!searchValue
										? 'assets/images/emptycontent/PARTICIPANTS.svg'
										: 'assets/images/emptycontent/AUCUNRESULTAT.svg'
								"
								[style.color]="
									event.styling.menuColor.includes('linear')
										? event.styling.menuColor.split(',')[1]
										: event.styling.menuColor
								"
							>
							</ion-icon>
							@if (!searchValue) {
								<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
									<span class="title"> {{ "event_users.empty_content_title" | translate }}</span>
									<span>{{ "event_users.empty_content_text" | translate }}</span>
								</div>
							}
						</div>
					</ng-container>
				}
			</ng-container>
		}
		@if (loader) {
			<!-- await loading attendees list -->
			<div class="loader-block">
				<ion-item-divider>
					<ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
				</ion-item-divider>
				@for (item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; track item) {
					<ion-item lines="none">
						<ion-avatar slot="start">
							<img src="../../../assets/images/grey.jpg" />
						</ion-avatar>
						<ion-label>
							<h3>
								<ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
							</h3>
							<h3>
								<ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
							</h3>
							<h3>
								<ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
							</h3>
						</ion-label>
					</ion-item>
				}
			</div>
		}
	</div>
</div>

@if (event && isMobile && module?.options.showFilter && canShowFilterBloc()) {
	<!-- Filter button -->
	<div class="filter-button-container">
		<ion-button
			id="mobile-filters-modal"
			expand="block"
			shape="round"
			class="filter-button"
			[style.--background]="event.styling.btnBgColor"
			[style.color]="event.styling.btnTextColor"
			style="font-weight: 600"
		>
			<ion-icon slot="start" name="filter-outline"></ion-icon>
			{{ "texts.filter" | translate }}
		</ion-button>
		@if (getTotalFiltersChecked() > 0) {
			<div class="filter-button-badge">
				{{ getTotalFiltersChecked() }}
			</div>
		}
	</div>
}

<!-- Mobile filters modal -->
@if (event && isMobile && module?.options.showFilter && canShowFilterBloc()) {
	<ion-modal trigger="mobile-filters-modal">
		<ng-template>
			<app-filters-for-list
				style="height: 100%"
				[event]="event"
				[modules]="[module]"
				[eventUser]="eventUser"
				[filters]="filters"
				[type]="'mobile'"
				[datasGetType]="datasGetType"
				(filtersStateChanged)="resetPagination(); buildFilterQuery()"
			></app-filters-for-list>
		</ng-template>
	</ion-modal>
}
