import { Injectable } from "@angular/core";
import { combineLatest, firstValueFrom, Observable, of, Subscription, switchMap } from "rxjs";
import { Store } from "@ngrx/store";
import { IContainer } from "../interfaces";
import { GetContainers } from "../actions/containers.actions";
import { debounceTime, take } from "rxjs/operators";
import { InitContainers } from "../actions/utility.actions";
import * as _ from "lodash-es";
import { App } from "@capacitor/app";
import { IAppSettings } from "../interfaces/app-settings.interfaces";
import { UtilityService } from "./utility.service";
import { TranslateService } from "@ngx-translate/core";
import { Platform } from "@ionic/angular";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class ContainersService {
	containerSub: Subscription;
	publicEventsSub: Subscription;

	containerId: string;
	previousResults: [IContainer, IContainer];

	init: boolean = false;

	constructor(
		private SFirestore: FirestoreService,
		private store: Store,
		private SUtility: UtilityService,
		private STranslate: TranslateService,
		private platform: Platform
	) {}

	/**
	 * Get containers subscriptions
	 * @returns
	 */
	getContainers(containerId: string) {
		if (this.containerId === containerId && this.containerSub && !this.containerSub.closed) {
			return;
		} else if (this.containerSub && !this.containerSub.closed) {
			this.containerSub.unsubscribe();
		}

		this.containerSub = combineLatest([
			this.SFirestore.valueChangesDocument(`containers/${containerId}`),
			this.SFirestore.valueChangesDocument(`containers/defaultContainer`)
		])
			.pipe(debounceTime(200))
			.subscribe({
				next: (results: [IContainer, IContainer]) => {
					this.containerId = containerId;
					if (!_.isEqual(this.previousResults, results)) {
						const container = results[0];

						/**
						 * Show alert if app version older
						 */
						if (
							this.platform.is("mobile") &&
							window.innerWidth < 768 &&
							container &&
							container.showAlertOldAppVersion &&
							!this.init
						) {
							Promise.all([
								firstValueFrom(this.SFirestore.getDocumentObs(`app-settings/settings`)),
								App.getInfo()
							]).then((results) => {
								const appSettings = results[0].data() as IAppSettings;
								const appInfo = results[1];
								if (
									appSettings &&
									appInfo &&
									appSettings.version &&
									appInfo.version &&
									appSettings.build &&
									appInfo.build &&
									appSettings.version !== appInfo.version &&
									appSettings.build > parseInt(appInfo.build)
								) {
									this.SUtility.presentAlert(
										this.STranslate.instant("app_settings.old_version_title"),
										container.alertOldAppVersionText
											? container.alertOldAppVersionText
											: this.STranslate.instant("app_settings.old_version_description"),
										[
											{
												text: this.STranslate.instant("buttons.ok"),
												role: "cancel"
											}
										]
									).then((alert) => {
										alert.present();
									});
									this.init = true;
								}
							});
						}

						this.store.dispatch(
							GetContainers({ payload: { currentContainer: container, defaultContainer: results[1] } })
						);
						this.store.dispatch(InitContainers({ payload: true }));
					}
					this.previousResults = results;
				}
			});
	}

	/**
	 * getSpecificContainer
	 * @param containerId
	 * @returns
	 */
	getSpecificContainer(containerId: string): Observable<IContainer> {
		return this.SFirestore.getDocumentObs(`containers/${containerId}`).pipe(
			switchMap((container) => {
				if (container.data()) return of(container.data() as IContainer);
				else return of(null);
			}),
			take(1)
		);
	}
}
