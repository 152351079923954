import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import {
	Auth,
	EmailAuthProvider,
	authState,
	confirmPasswordReset,
	reauthenticateWithCredential,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
	verifyPasswordResetCode
} from "@angular/fire/auth";
import { Title } from "@angular/platform-browser";
import { Device } from "@capacitor/device";
import { Network } from "@capacitor/network";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { Subscription, combineLatest, firstValueFrom, from, of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { debounceTime, map, skipWhile, switchMap, take } from "rxjs/operators";
import { IUser } from "src/app/shared/interfaces/user.interfaces";
import { PathApi } from "src/app/shared/paths/path-api";
import { environment } from "src/environments/environment";
import { EventUsersService, FirestoreService, PresenceService } from ".";
import { GetCurrentUser, LogInUser, LogOutUser } from "../actions/auth.actions";
import { InitAuth, InitClaims, InitEvents, InitUser } from "../actions/utility.actions";
import { TypeLogin } from "../enums/type-login";
import { IContainer, IEvent } from "../interfaces";
import { IContainersState } from "../interfaces/containers.interfaces";
import { getContainersStateAndUser } from "../selectors/auth.selectors";
import { getInitClaims, getInitContainers, getInitUser } from "../selectors/utility.selectors";
import { EventsService } from "./events.service";
import { StateManagerService } from "./state-manager.service";
import { TypeCaptchaLevel, TypeCaptchaMethod } from "../enums/type-register";

@Injectable({
	providedIn: "root"
})
export class AuthService {
	headers: HttpHeaders;
	deviceId: string = "";
	userId: string;

	userSubscription: Subscription;
	authSubscription: Subscription;

	containerAndUserSubscription: Subscription;
	currentContainer: IContainer;

	eventsUserSubscription: Subscription;

	previousContainerAndUserState: {
		containersState: IContainersState;
		user: IUser;
	};
	previousUser: IUser;
	SPresenceService: any;

	constructor(
		private auth: Auth,
		private http: HttpClient,
		private navCtrl: NavController,
		private SFirestore: FirestoreService,
		private store: Store,
		private SEvents: EventsService,
		private SEventUsers: EventUsersService,
		private SStateManager: StateManagerService,
		private title: Title,
		private injector: Injector
	) {
		this.headers = new HttpHeaders();
		this.headers.append("content-type", "application/json");
		Device.getId()
			.then((device) => {
				this.deviceId = device.identifier;
				this.subscribeAuthState();
			})
			.catch(() => {
				this.subscribeAuthState();
			});
	}

	/**
	 * Auth subscription for basic actions log in/out, getting user data...
	 */
	subscribeAuthState() {
		/**
		 * Auth subscription for basic actions log in/out, getting user data...
		 */
		this.authSubscription = authState(this.auth).subscribe({
			next: (authState) => {
				if (authState) {
					// Logged in user
					Network.getStatus().then((status) => {
						if (status && status.connected) {
							this.claimsUser(authState.uid).subscribe(() => {
								//
							});
						} else {
							this.store.dispatch(InitClaims({ payload: true }));
						}
					});
					this.getUser(authState.uid);
					this.store.dispatch(LogInUser({ payload: true }));
				} else {
					this.title.setTitle(environment.platform.appName);

					// Not logged in
					if (this.userSubscription && !this.userSubscription.closed) {
						this.userSubscription.unsubscribe();
					}
					this.store.dispatch(LogOutUser({ payload: false }));
					this.store.dispatch(InitUser({ payload: true }));
				}
				this.store.dispatch(InitAuth({ payload: true }));
			}
		});
	}

	/**
	 * Initialize auth
	 */
	initAuth() {
		if (this.containerAndUserSubscription && !this.containerAndUserSubscription.closed) {
			this.containerAndUserSubscription.unsubscribe();
		}

		combineLatest([
			this.store.select(getInitContainers).pipe(skipWhile((init) => init === false)),
			this.store.select(getInitUser).pipe(skipWhile((init) => init === false))
		])
			.pipe(take(1))
			.subscribe(() => {
				this.containerAndUserSubscription = this.store
					.select(getContainersStateAndUser)
					.pipe(debounceTime(200))
					.subscribe((results) => {
						if (
							results &&
							results.user &&
							results.user.connectedPlatformToken &&
							results.user.connectedPlatformToken !== this.deviceId &&
							results.containersState.currentContainer.loginSettings.onlyOneAccountConnected
						) {
							this.logoutUser();
							return;
						}
						if (!_.isEqual(this.previousContainerAndUserState, results)) {
							const containersState = results.containersState;
							this.currentContainer =
								!environment.platform.containerId ||
								environment.platform.containerId === "defaultContainer"
									? containersState.defaultContainer
									: containersState.currentContainer;
							// const user = results.user;
							// this.SEvents.initEvents(
							// 	!environment.platform.containerId ||
							// 		environment.platform.containerId === "defaultContainer"
							// 		? "default"
							// 		: "client",
							// 	!environment.platform.containerId ||
							// 		environment.platform.containerId === "defaultContainer"
							// 		? containersState.defaultContainer
							// 		: containersState.currentContainer,
							// 	user
							// );
							this.store.dispatch(InitEvents({ payload: true }));
						}
						this.previousContainerAndUserState = results;
					});
			});
	}

	/**
	 * Getting user
	 */
	getUser(userId: string) {
		if (this.userSubscription && !this.userSubscription.closed) {
			this.userSubscription.unsubscribe();
		}
		this.store
			.select(getInitClaims)
			.pipe(
				skipWhile((init) => init === false),
				take(1)
			)
			.subscribe(() => {
				this.userId = userId;
				this.userSubscription = this.SFirestore.snapshotChangesDocument(`users/${userId}`)
					.pipe(
						debounceTime(200),
						map((snapshot) => snapshot.data() as IUser)
					)
					.subscribe((user: IUser) => {
						if (user && !_.isEqual(this.previousUser, user)) {
							// Update first access on event-users document
							// firstValueFrom(this.SEventUsers.updateFirstAccessOnEventUsers(user.uid));
							// firstValueFrom(this.SEventUsers.updateLastAccessOnEventUsers(user.uid));
							this.store.dispatch(GetCurrentUser({ payload: user }));
							this.store.dispatch(InitUser({ payload: true }));
							this.previousUser = user;
						} else if (!user) {
							this.logoutUser();
						}
					});
			});
	}

	/**
	 * Update user data
	 * @param user
	 * @returns
	 */
	updateUser(user: IUser) {
		return this.SFirestore.updateDocument(`users/${user.uid}`, _.omit(user, ["uid", "type", "creationDate"]));
	}

	/**
	 * Takes email and password and attempts to log user in firebase
	 * @param {string} email
	 * @param {string} password
	 */
	loginUser(email: string, password: string) {
		return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
			switchMap((credential) => {
				return of(credential);
			})
		);
	}

	/**
	 * Attempts to log the user out from firebase
	 * @memberof AuthService
	 */
	async logoutUser() {
		this.SPresenceService = this.injector.get<PresenceService>(PresenceService);

		try {
			this.SPresenceService.setPresence("offline");
			this.SEvents.unsubscribeAll();
			this.SEventUsers.unsubscribeAll();
			this.SStateManager.unsubscribeAll();
			this.SStateManager.resetStateAll();
			this.previousUser = null;

			await signOut(this.auth);

			if (
				this.currentContainer &&
				(this.currentContainer.loginSettings.type === TypeLogin.WITHOUT_EMAIL_CONFIRM ||
					this.currentContainer.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM)
			) {
				this.navCtrl.navigateRoot(["/login"]);
				// window.location.reload();
			} else if (
				this.currentContainer &&
				this.currentContainer.loginSettings.type === TypeLogin.PUBLIC_EVENT_HOME
			) {
				window.location.reload();
			}
		} catch (error) {
			// console.error("AuthService ~ logoutUser ~ error", error);
		}
	}

	/**
	 * Get user by email
	 * @param email
	 * @returns
	 */
	// getUserByEmail(email: string) {
	// 	return this.SFirestore.getDocumentsObs(`users`, [where("email", "==", email)]).pipe(
	// 		switchMap((users) => {
	// 			if (users.size > 0) {
	// 				return of(users.docs[0].data());
	// 			} else {
	// 				return of(null);
	// 			}
	// 		})
	// 	);
	// }

	/**
	 * Get user by email
	 * @param email
	 * @returns
	 */
	getUserByEmail(email: string) {
		return this.http
			.post(PathApi.authGetUserByEmail, { email: email, returnType: "partial" }, { headers: this.headers })
			.pipe(
				switchMap((results: any) => {
					if (results.code === 201 || results.code === 200) {
						return of(results.result);
					} else {
						return of(null);
					}
				})
			);
	}

	checkAuthAccountExist(email: string, uid: string, type: string) {
		return this.http.post(
			PathApi.checkAuthAccountExist,
			{ email: email, uid: uid, type: type },
			{ headers: this.headers }
		);
	}

	/**
	 *  Returns the user's custom authentication data
	 * @params userId
	 */
	claimsUser(userId: string) {
		return this.http.post(PathApi.authUpdateClaimsUser, { userId: userId }, { headers: this.headers }).pipe(
			switchMap(async () => {
				/**
				 * Here = less charging time but more consomation
				 */
				this.store.dispatch(InitClaims({ payload: true }));
				return await this.auth.currentUser.getIdToken(true);
			}),
			switchMap((token) => {
				return this.http.post(PathApi.authGetClaimsUser, { idToken: token }, { headers: this.headers });
			})
		);
	}

	/**
	 * Create user with auth
	 * @param user
	 * @param password
	 */
	createUserWithAuth(user: IUser, password: string) {
		const headers = new HttpHeaders();
		headers.append("Accept", "application/json");
		headers.append("Content-Type", "application/json");
		return firstValueFrom(
			this.http.post(
				PathApi.authCreateUser,
				{ password: password, user: user },
				{ headers: headers, observe: "body" }
			)
		);
	}

	/**
	 * Create user without auth
	 * @param user
	 */
	createUserWithoutAuth(user: IUser) {
		const id = !user.uid ? this.SFirestore.createId(`users`) : user.uid;
		user.uid = id;

		const headers = new HttpHeaders();
		headers.append("Accept", "application/json");
		headers.append("Content-Type", "application/json");
		return firstValueFrom(
			this.http
				.post(
					PathApi.authCreateUserWithoutAuth,
					{
						user: user
					},
					{
						headers: headers,
						observe: "body"
					}
				)
				.pipe(switchMap((data: any) => of(data.message)))
		);
	}

	/**
	 * Create only auth account
	 * @param user
	 * @param password
	 */
	createOnlyAuthAccount(user: IUser, password: string) {
		const headers = new HttpHeaders();
		headers.append("Accept", "application/json");
		headers.append("Content-Type", "application/json");
		return this.http.post(
			PathApi.authCreateOnlyAuthAccount,
			{
				password: password,
				user: user
			},
			{
				headers: headers,
				observe: "body"
			}
		);
	}

	/**
	 * Recover password
	 * @param email
	 * @returns
	 */
	recoveryPassword(email: string, containerId?: string, event?: IEvent) {
		return this.http.post(
			PathApi.sendToUserPasswordResetLink,
			{
				email: email,
				containerId: containerId || null,
				event: event || null
			},
			{
				headers: this.headers,
				observe: "body"
			}
		);
	}

	/**
	 * recoveryPasswordFromFirebase
	 * @param email
	 * @returns
	 */
	recoveryPasswordFromFirebase(email: string) {
		return sendPasswordResetEmail(this.auth, email);
	}

	/**
	 * Create and send code number
	 * @param userId
	 * @returns
	 */
	createAndSendCodeNumber(userId: string): Observable<{ code: number; message: string; result: any; user: IUser }> {
		return this.http
			.post(
				PathApi.authCreateUser,
				{
					userId: userId
				},
				{
					headers: this.headers,
					observe: "body"
				}
			)
			.pipe(
				switchMap((result) => {
					return of(result as { code: number; message: string; result: any; user: IUser });
				})
			);
	}

	/**
	 * sendCodeNumber
	 * @param userId
	 * @returns
	 */
	sendCodeNumber(
		userId: string,
		deviceLanguage?: string
	): Observable<{ code: number; message: string; result: any; user: IUser }> {
		return this.http
			.post(
				PathApi.authSendCodeNumber,
				{
					userId: userId,
					deviceLanguage: deviceLanguage
				},
				{
					headers: this.headers,
					observe: "body"
				}
			)
			.pipe(
				switchMap((result) => {
					return of(result as { code: number; message: string; result: any; user: IUser });
				})
			);
	}

	/**
	 * Verify code number for first access with email confirm
	 * @param codeNumber
	 * @param userId
	 * @returns
	 */
	verifyCodeNumber(codeNumber: string, userId: string) {
		return this.SFirestore.getDocumentObs(`users/${userId}`).pipe(
			switchMap((userDoc) => {
				const user = userDoc.data();
				if (user.codeNumber && user.codeNumber === codeNumber) {
					return of(true);
				} else {
					return of(false);
				}
			})
		);
	}

	/**
	 * verifyCaptcha
	 * @param event
	 * @param token
	 * @returns
	 */
	verifyCaptcha(event: IEvent, token: string) {
		return this.http.post(
			PathApi.authVerifyCaptcha,
			{
				token: token,
				captchaMethod: TypeCaptchaMethod.IMAGE,
				captchaLevel: TypeCaptchaLevel.HARD
			},
			{
				headers: this.headers,
				observe: "body"
			}
		);
	}

	/**
	 * getClientIP
	 * @returns
	 */
	getClientIP() {
		return this.http.get("https://api.ipify.org/?format=json");
	}

	/**
	 * Get user data
	 * @param userId
	 * @returns
	 */
	getUserData(userId: string) {
		return this.SFirestore.valueChangesDocument(`users/${userId}`);
	}

	/**
	 * Delete user and cascading on event user and auth account
	 * @param password
	 * @returns
	 */
	deleteUser(userId: string, userEmail: string, password: string) {
		return of(this.auth.currentUser).pipe(
			switchMap((user) => {
				const creds = EmailAuthProvider.credential(userEmail, password);
				return from(reauthenticateWithCredential(user, creds));
			}),
			switchMap(() => {
				return this.http.get(PathApi.authDeleteUser, {
					headers: this.headers,
					params: {
						userId: userId
					},
					observe: "body"
				});
			})
		);
	}

	// Verify the password reset code is valid.
	verifyPasswordResetCode(oobCode: string) {
		return verifyPasswordResetCode(this.auth, oobCode);
	}

	// Confirm the new password reset.
	updateUserPassword(oobCode: string, newPassword: string) {
		return confirmPasswordReset(this.auth, oobCode, newPassword);
	}
}
