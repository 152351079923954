import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { where } from "firebase/firestore";
import { firstValueFrom } from "rxjs";
import { IEvent } from "src/app/shared/interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { FirestoreService } from "src/app/shared/services";

@Component({
	selector: "app-login-register-buttons",
	templateUrl: "./login-register-buttons.component.html",
	styleUrls: ["./login-register-buttons.component.scss"],
	standalone: false
})
export class LoginRegisterButtonsComponent {
	@Input() event: IEvent;

	constructor(
		private modalCtrl: ModalController,
		private snackbar: MatSnackBar,
		private STranslate: TranslateService,
		private SFirestore: FirestoreService,
		@Inject(DOCUMENT) private _document: Document
	) {}

	/**
	 * Open login modal
	 * @returns
	 */
	async openLogin() {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.loginModal,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openRegister) {
				this.openRegister();
			} else if (data && data.openFillProfil) {
				// this.openFillProfil();
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open register modal
	 * @returns
	 */
	async openRegister() {
		try {
			const totalEventUsers = await firstValueFrom(
				this.SFirestore.getCountOfQueryObs("event-users", "group", [where("eventId", "==", this.event.uid)])
			);

			if (totalEventUsers >= this.event?.limitations?.usersLimit) {
				this.snackbar.open(this.STranslate.instant("register.event_users_limit_reached"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}
			const modal = await this.modalCtrl.create({
				id: "register-modal",
				component: PathComponents.registerForm,
				backdropDismiss: false,
				componentProps: {
					eventId: this.event.uid,
					moduleId: this.event.settings.defaultAttendeesModule,
					mode: "register-modal",
					manualOpen: true
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();

			if (data && data.openLogin) {
				this.openLogin();
			}

			<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0] &&
				((<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0]).style.visibility =
					"hidden");
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}
}
