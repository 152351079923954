/// <reference types="@types/googlemaps" />
import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { Subscription, take } from "rxjs";
import { GetHeaderTitle, Loading, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, IModule, IUser } from "src/app/shared/interfaces";
import { getCurrentUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService, UtilityService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-info",
	templateUrl: "./info.component.html",
	styleUrls: ["./info.component.scss"],
	standalone: false
})
export class InfoComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	event: IEvent;
	eventId: string;
	moduleId: string;
	module: IModule;
	user: IUser;
	currentLanguage: string = environment.platform.defaultLanguage;

	address: string = "";

	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		private SAnalytics: AnalyticsService,
		private STranslate: TranslateService,
		private httpClient: HttpClient,
		private store: Store,
		public SUtility: UtilityService
	) {}

	ionViewWillEnter() {
		this.store.dispatch(Loading({ payload: true }));
		this.isMobile =
			this.platform.is("ios") || this.platform.is("android") || this.platform.is("mobileweb") ? true : false;
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;

						this.getEvent();
						this.getModule();
						this.getUser();
						// Analytics
						this.SAnalytics.moduleAccess(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_INFO_MODULE);
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (module) {
					this.module = module;
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
					if (
						this.module.options.eventsFieldsOptions.location &&
						this.module.options.eventsFields.location.address
					) {
						this.address = this.module.options.eventsFields.location.address;
					}
					this.store.dispatch(Loading({ payload: false }));
				}
			})
		);
	}

	/**
	 * Getting user
	 */
	getUser() {
		this.subscriptions.push(
			this.store.select(getCurrentUser).subscribe((user) => {
				this.user = user;
			})
		);
	}

	/**
	 * Get date with specific format
	 * @param date
	 * @param format
	 */
	getDate(date: string, format: string) {
		return date && format
			? DateTime.fromISO(date)
					.setLocale(
						this.user && this.user.language
							? this.user.language.substring(0, 2).toLowerCase() +
									"-" +
									this.user.language.substring(2).toUpperCase()
							: this.event.language.substring(0, 2).toLowerCase() +
									"-" +
									this.event.language.substring(2).toUpperCase()
					)
					.toFormat(format)
			: "";
	}

	/**
	 * Open website
	 * @param url
	 */
	openLink(url: string) {
		window.open(url, "_system");
	}

	/**
	 * Open google plan
	 * @param address
	 */
	openGooglePlan(address: string) {
		const addressFormated = address.replaceAll(", ", ",").replaceAll(" ", "+");
		window.open("https://www.google.com/maps/place/" + addressFormated, "_blank");
	}

	/**
	 * Get google plan
	 * @param address
	 * @returns
	 */
	getGooglePlan(address: string, width: number) {
		const addressFormated = address.replaceAll(", ", ",").replaceAll(" ", "+");
		return (
			"https://maps.googleapis.com/maps/api/staticmap?size=" +
			Math.ceil(width) +
			"x" +
			Math.ceil(width / 2) +
			"&maptype=roadmap" +
			"&markers=color:red|" +
			addressFormated +
			"&key=AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg"
		);
	}
}
