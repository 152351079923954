<!-- Mobile filters modal -->
@if (
	event &&
	module &&
	isMobile &&
	!filtersLoader &&
	((module.options.showFilter && canShowFilterBloc()) ||
		(event &&
			event.settings &&
			event.settings.allowTimezoneUserSwitch &&
			event.timezoneType === "local" &&
			eventUser))
) {
	<ion-modal
		[trigger]="filtersUuid"
		[style.--height]="'calc(100% - 57px)'"
		mode="ios"
		[handle]="false"
		[initialBreakpoint]="1"
		[breakpoints]="[0, 1]"
	>
		<ng-template>
			<app-filters-for-list
				style="height: 100dvh"
				[event]="event"
				[module]="module"
				[modules]="[module]"
				[eventUser]="eventUser"
				[filters]="filters"
				[type]="'mobile'"
				(filtersStateChanged)="resetScrollOfList(); applyFilters()"
			></app-filters-for-list>
		</ng-template>
	</ion-modal>
}
@if (event && isMobile && module?.options.showFilter && canShowFilterBloc()) {
	<!-- Filter button -->
	<div class="filter-button-container">
		<ion-button
			[id]="filtersUuid"
			expand="block"
			shape="round"
			class="filter-button"
			[style.--background]="event.styling.btnBgColor"
			[style.color]="event.styling.btnTextColor"
			style="font-weight: 600"
		>
			<ion-icon slot="start" name="filter-outline"></ion-icon>
			{{ "texts.filter" | translate }}
		</ion-button>
		@if (getTotalFiltersChecked() > 0) {
			<div class="filter-button-badge">
				{{ getTotalFiltersChecked() }}
			</div>
		}
	</div>
}

<!-- Fabs buttons -->
@if (
	event &&
	module &&
	(module.options.allowSessionsDownload ||
		(event.settings && event.settings.allowTimezoneUserSwitch && event.timezoneType === "local" && eventUser))
) {
	<ion-fab
		class="fabs-buttons"
		vertical="bottom"
		horizontal="end"
		slot="fixed"
		[ngClass]="isMobile && module?.options.showFilter && canShowFilterBloc() ? 'p-r-b-85' : ''"
	>
		<ion-fab-button class="basic-fab-button">
			<ion-icon name="add"></ion-icon>
		</ion-fab-button>
		<ion-fab-list side="top">
			<!-- Only displays on mobile -->
			@if (
				event &&
				event.settings &&
				event.settings.allowTimezoneUserSwitch &&
				event.timezoneType === "local" &&
				eventUser
			) {
				<ion-fab-button class="basic-fab-button" (click)="openMobileTimezoneSwitchModal()">
					<ion-icon name="time"></ion-icon>
				</ion-fab-button>
			}
			@if (module && module.options.allowSessionsDownload) {
				<ion-fab-button
					[class.fab-button-spinner]="scheduleDownloadButtonLoader"
					[disabled]="scheduleDownloadButtonLoader"
					class="basic-fab-button"
					(click)="openDownloadScheduleModal($event)"
				>
					<ion-icon src="assets/images/calendar_colloqium.svg"></ion-icon>
				</ion-fab-button>
			}
		</ion-fab-list>
	</ion-fab>
}

<ion-content class="page" scrollY="false">
	@if (firstTimeInit && !scheduleLoader()) {
		<div
			style="
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				position: absolute;
				z-index: 999999;
				top: 0;
				right: 0;
			"
		>
			<div class="loader-default" [style.width]="'60px'" [style.height]="'60px'"></div>
		</div>
	}
	@if (event && module) {
		<div class="f-col-fs-fs full-h">
			<!-- Search bar -->
			<div #searchBarContent style="width: 100%; height: 58px">
				@if (showSearchBar) {
					<ion-searchbar
						class="schedule-searchbar"
						[style.--background]="'rgb(234, 239, 242)'"
						searchIcon="assets/icon/Search.svg"
						mode="md"
						placeholder="{{ 'texts.search_plc' | translate }}"
						[(ngModel)]="searchValue"
						(ngModelChange)="searchValueUpdated.next($event)"
						(ionClear)="resetFilter()"
					>
					</ion-searchbar>
				}
			</div>

			<div
				style="
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					justify-content: flex-start;
				"
				[style.height]="showSearchBar ? 'calc(100% - ' + searchBarContent.clientHeight + 'px)' : '100%'"
			>
				<!-- Filters -->
				@if (
					((module.options.showFilter && canShowFilterBloc()) ||
						(event &&
							event.settings &&
							event.settings.allowTimezoneUserSwitch &&
							event.timezoneType === "local" &&
							eventUser)) &&
					!isMobile &&
					!filtersLoader
				) {
					<app-filters-for-list
						style="height: 100%"
						[event]="event"
						[module]="module"
						[modules]="[module]"
						[eventUser]="eventUser"
						[filters]="filters"
						(filtersStateChanged)="resetScrollOfList(); applyFilters()"
					></app-filters-for-list>
				}

				<div
					#sessionsBloc
					id="sessionsBloc"
					style="
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: flex-start;
					"
					[style.height]="'100%'"
				>
					@if (module.options.showHeader) {
						<app-calendar
							style="width: 100%"
							[style.padding]="
								(module.options.showFilter && canShowFilterBloc()) ||
								(event &&
									event.settings &&
									event.settings.allowTimezoneUserSwitch &&
									event.timezoneType === 'local' &&
									eventUser) ||
								isMobile
									? '0'
									: '0 15%'
							"
							[event]="event"
							[module]="module"
							[eventUser]="eventUser"
							[sessions]="allDatas()"
							[datasForBadges]="datasForBadgeCalendar()"
							[currentVisibleDateOnList]="currentVisibleDateOnList"
							(selectedDateChange)="selectedDate = $event; dateChanged = true; applyFilters()"
						></app-calendar>
					}

					<div
						style="position: relative; overflow: hidden; width: 100%; height: 100%"
						[style.background]="event.styling.bgContentColor"
					>
						@if (scheduleLoader()) {
							<div
								style="
									width: 100%;
									height: 100%;
									display: flex;
									flex-direction: row;
									align-items: center;
									justify-content: center;
									position: absolute;
									z-index: 999999;
									top: 0;
									right: 0;
								"
							>
								<div class="loader-default" [style.width]="'60px'" [style.height]="'60px'"></div>
							</div>
						}
						@if (
							(module &&
								module.options &&
								module.options.activateTracksFiltering &&
								datasByTrackDatesFiltered().length > 0) ||
							(!module.options.activateTracksFiltering && datas().length > 0)
						) {
							<div style="height: 100%; width: 100%; overflow: hidden">
								@if (!module.options.activateTracksFiltering) {
									<!-- @if (previousDatasExist() && !selectedDate) {
										<div class="tw-w-full tw-flex tw-align-center tw-justify-center tw-my-1">
											<ion-button
												class="tw-h-11 btn-outline-default tw-fit"
												shape="round"
												fill="outline"
												[style.--background]="event.styling.btnBgColor"
												[style.color]="event.styling.btnTextColor"
												(click)="loadPreviousSessions()"
											>
												{{ "schedule.prev-sessions" | translate }}
											</ion-button>
										</div>
									} -->
									<div
										[style.height]="'100%'"
										style="width: 100%; overflow: hidden; position: relative; padding: 0"
									>
										<div
											#sessionsList
											id="sessionsList"
											style="height: 100%; width: 100%; overflow-y: auto; position: relative"
											[style.padding-bottom]="
												(module.options.showFilter && canShowFilterBloc()) ||
												(event &&
													event.settings &&
													event.settings.allowTimezoneUserSwitch &&
													event.timezoneType === 'local' &&
													eventUser)
													? '80px'
													: '0'
											"
											[style.padding-left]="
												(module.options.showFilter && canShowFilterBloc()) ||
												(event &&
													event.settings &&
													event.settings.allowTimezoneUserSwitch &&
													event.timezoneType === 'local' &&
													eventUser) ||
												isMobile
													? '10px'
													: '15%'
											"
											[style.padding-right]="
												(module.options.showFilter && canShowFilterBloc()) ||
												(event &&
													event.settings &&
													event.settings.allowTimezoneUserSwitch &&
													event.timezoneType === 'local' &&
													eventUser) ||
												isMobile
													? '10px'
													: 'calc(15% - ' +
														(sessionsBloc.clientWidth - sessionsList.clientWidth) +
														'px)'
											"
										>
											@if (previousDatasExist() && !selectedDate) {
												<div
													class="tw-w-full tw-flex tw-align-center tw-justify-center tw-my-1"
												>
													<ion-button
														class="tw-h-11 btn-outline-default tw-fit"
														shape="round"
														fill="outline"
														[style.--background]="event.styling.btnBgColor"
														[style.color]="event.styling.btnTextColor"
														(click)="loadPreviousSessions()"
													>
														{{ "schedule.prev-sessions" | translate }}
													</ion-button>
												</div>
											}
											<div
												id="virtualFirst"
												style="
													width: 100%;
													height: 100px;
													position: absolute;
													top: 0;
													left: 0;
													z-index: -1;
												"
												observeVisibility
												[debounceTime]="0"
												[threshold]="0"
												[root]="'#sessionsList'"
												[rootMargin]="'2000px 0px 0px 0px'"
												(visible)="onVisibleVirtual('first', $event)"
											></div>

											@for (session of datas(); track session.uid; let i = $index) {
												<app-session-item
													observeVisibility
													[debounceTime]="0"
													[threshold]="1"
													[rootMargin]="'0px 0px 0px 0px'"
													[root]="'#sessionsList'"
													(visible)="onVisible(session, $event)"
													[specificIndex]="i"
													[event]="event"
													[module]="module"
													[eventUser]="eventUser"
													[locations]="locations"
													[computedCustomFields]="computedCustomFields"
													[customFieldsModulesSheets]="customFieldsModulesSheets"
													[sessions]="datas"
													[session]="session"
													[queryParams]="queryParams"
													(navigationSavedEvent)="updateNavigationSaved($event)"
													[favoriteFolder]="favoriteFolder"
													[favoriteModule]="favoriteModule"
													[eventUserFavoritesState]="eventUserFavoritesState"
													[currentLanguage]="currentLanguage"
												></app-session-item>
											}
											<div
												id="virtualLast"
												style="
													width: 100%;
													height: 10px;
													position: relative;
													bottom: 0;
													left: 0;
													z-index: -1;
												"
												observeVisibility
												[debounceTime]="0"
												[threshold]="0"
												[rootMargin]="'0px 0px 2000px 0px'"
												[root]="'#sessionsList'"
												(visible)="onVisibleVirtual('last', $event)"
											></div>
										</div>
									</div>
								}
								@if (module.options.activateTracksFiltering) {
									<div
										#sessionsList
										id="sessionsList"
										class="tracks-view"
										style="overflow-y: scroll; height: 100%"
										[style.padding-bottom]="
											(module.options.showFilter && canShowFilterBloc()) ||
											(event &&
												event.settings &&
												event.settings.allowTimezoneUserSwitch &&
												event.timezoneType === 'local' &&
												eventUser)
												? '70px'
												: '0'
										"
										[style.padding-left]="
											(module.options.showFilter && canShowFilterBloc()) ||
											(event &&
												event.settings &&
												event.settings.allowTimezoneUserSwitch &&
												event.timezoneType === 'local' &&
												eventUser) ||
											isMobile
												? '0'
												: '15%'
										"
										[style.padding-right]="
											(module.options.showFilter && canShowFilterBloc()) ||
											(event &&
												event.settings &&
												event.settings.allowTimezoneUserSwitch &&
												event.timezoneType === 'local' &&
												eventUser) ||
											isMobile
												? '0'
												: 'calc(15% - ' +
													(sessionsBloc.clientWidth - sessionsList.clientWidth) +
													'px)'
										"
									>
										@for (
											dateTrack of datasByTrackDatesFiltered();
											track dateTrack;
											let ind = $index
										) {
											<app-session-item
												[specificIndex]="ind"
												[event]="event"
												[module]="module"
												[eventUser]="eventUser"
												[tracks]="tracks"
												[locations]="locations"
												[customFieldsModulesSheets]="customFieldsModulesSheets"
												[sessions]="datas"
												[dateTrack]="dateTrack"
												[queryParams]="queryParams"
												(navigationSavedEvent)="updateNavigationSaved($event)"
												[favoriteFolder]="favoriteFolder"
												[eventUserFavoritesState]="eventUserFavoritesState"
												[eventUsersFromTags]="eventUsersFromTags"
												[currentLanguage]="currentLanguage"
											></app-session-item>
										}
										<div
											id="virtualLastTrack"
											style="
												width: 100%;
												height: 10px;
												position: relative;
												bottom: 0;
												left: 0;
												z-index: -1;
											"
											observeVisibility
											[debounceTime]="0"
											[threshold]="0"
											[rootMargin]="'0px 0px 500px 0px'"
											[root]="'#sessionsList'"
											(visible)="onVisibleVirtualByTrack('last', $event)"
										></div>
										<br />
									</div>
								}
							</div>
						}
						@if (
							!scheduleLoader() &&
							module &&
							module.options &&
							((module.options.activateTracksFiltering && datasByTrackDatesFiltered().length === 0) ||
								(!module.options.activateTracksFiltering && datas().length === 0))
						) {
							<div>
								@if (previousDatasExist()) {
									<div class="tw-w-full tw-flex tw-align-center tw-justify-center tw-my-1">
										<ion-button
											class="tw-h-11 btn-outline-default tw-fit"
											shape="round"
											fill="outline"
											[style.--background]="event.styling.btnBgColor"
											[style.color]="event.styling.btnTextColor"
											(click)="loadPreviousSessions()"
										>
											{{ "schedule.prev-sessions" | translate }}
										</ion-button>
									</div>
								}
								<div class="container">
									<span class="wait-content" [style.color]="event.styling.titleColor">{{
										(searchValue
											? "texts.no_result_title"
											: previousDatasExist()
												? "texts.no-more-sessions"
												: "texts.waiting_for_content"
										) | translate
									}}</span>
									<ion-icon
										[src]="
											!searchValue
												? 'assets/images/emptycontent/AGENDA.svg'
												: 'assets/images/emptycontent/AUCUNRESULTAT.svg'
										"
										[style.color]="
											event.styling.menuColor.includes('linear')
												? event.styling.menuColor.split(',')[1]
												: event.styling.menuColor
										"
									>
									</ion-icon>
									@if (!searchValue && allDatas().length === 0) {
										<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
											<span class="title"> {{ "schedule.empty_content_title" | translate }}</span>
											<span>{{ "schedule.empty_content_text" | translate }}</span>
										</div>
									}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	}
</ion-content>
