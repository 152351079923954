{
	"alerts": {
		"add_to_my_calendar": "Add to my calendar",
		"all_right": "OK",
		"already_scanned": "The QR Code is already validated",
		"already_checked_checkin": "You are already registered at this checkin",
		"answer_saved_successfully": "Your contribution has been saved successfully",
		"app_open_doc_not_found_txt": "We did not found any installed app that supports reading this format type! Would you like to download one ?",
		"asking_saved_successfully": "Your contribution has been saved successfully",
		"attendee_not_found": "Attendee not found. Would you like to re-scan ?",
		"cannot_scan_web": "It is impossible to scan a Qr code using a web version of the application !",
		"change_status": "Change status",
		"change_status_confirm_msg": "Are you sure you want to change the status of",
		"close": "Close",
		"confirm_exit_event": "Are you sure you want to leave the event?",
		"confirmed_presence": "has been registered! Do you want to scan again?",
		"date_already_selected": "You have already selected a date, the new date will not be saved",
		"delete_account_title": "Delete your account",
		"delete_contact": "Delete contact",
		"delte_contact_confirm": "Are you sure you want to delete this contact?",
		"delete_account_message": "Are you sure you want to delete your account?This will delete all your data and therefore your registrations for all events.",
		"error": "Error",
		"error_scanning": "Scanning error",
		"event_shortcode_blank": "The code is invalid, please try again",
		"exit_event": "Exit the application",
		"failure_scanning": "An error occurred while scanning the Qrcode",
		"go_to_container_confirm": "Are you really sure you wanna return to the events list ?",
		"invalid_qrcode": "This participant is not recorded on this crossing point",
		"list_events": "Events list",
		"login-page": "Connection page",
		"go-to-login-confirm": "Do you really want to go back to the connection page?",
		"login_client_title_error": "Connection error",
		"login_client_error": "You are trying to log in with a customer account, you must create a participant account in order to log in!",
		"no_camera": "Unable to access the camera",
		"not_change_answer": "You cannot edit your answers ! ",
		"not_found_title": "File not found",
		"not_open_market_msg": "The store cannot be opened, do you want to open the document in the browser?",
		"not_possible_change_status": "Check-in is not possible for",
		"not_success": "The operation was not successful",
		"please_tryagain": ". Please try again at a later time",
		"post_moderated": "Your post has been sent for validation",
		"not_confirm_your_presence": "Not marked as Check-in. Please try again at a later time.",
		"clear-filter": "Clear filter",
		"status_changed_successfully": "has been modified!",
		"success": "Successful operation",
		"thankyour_asking": "Thank you!",
		"thankyou_answer": "Thank you!",
		"the_presence": "The Check-in of",
		"the_status_changed": "Status of",
		"type_event_shortcode": "Enter the event code",
		"user_chat_not_available": "This user has not logged in yet, it is impossible to chat with him",
		"your_presence_confirmed": "Your Check-in has been completed",
		"blocked-event-info": "The event is blocked, the features and access are limited",
		"contact_has_been_add_to_your_fav": "Contact has been added to your favorites",
		"contact_has_not_been_add_because_form_empty": "Contact has not been added because the form is empty",
		"contact_already_in_your_fav": "Contact already in your favorites"
	},
	"appointments": {
		"show-more-timeslots": "Show more slots",
		"no-rdv-accepted": "You have no planned appointments soon...",
		"no-rdv-pending": "You currently have no appointments waiting for confirmation...",
		"no-rdv-ended": "There is no appointment completed at the moment...",
		"no-rdv-cancelled": "No appointment canceled for the moment...",
		"appointment-with": "Meeting with ",
		"save-on-personal-schedule": "Save your appointment in your personal agenda",
		"appointment-infos": "Meeting information",
		"aditionnal-infos": "Further information",
		"select-day": "Select one day ...",
		"select-disponibilities": "Select your availability:",
		"appointment": "Appointment",
		"someone-want-to-make-appointment": "{{user}} wishes to make an appointment..",
		"take-appointment": "Make an appointment",
		"confirm-appointment": "Confirm the appointment",
		"informations": "Informations",
		"fill-informations": "Inform the information",
		"notation_title": "what are your thoughts about this appointment ?",
		"commentary_title": "Do you have any comments ?",
		"commentary": "Comments",
		"input_subject": "Specify a subject...",
		"input_message": "Input a message...",
		"appointment_accepted": "Appointment accepted",
		"appointment_pending": "Pending request...",
		"appointment_cancelled": "Appointment cancelled",
		"appointment_cancelled_automatically": "Appointment cancelled automatically",
		"appointment_rejected": "Appointment rejected",
		"appointment_rejected_automatically": "Appointment rejected automatically",
		"cancel_appointment": "Cancel appointment",
		"join_visio": "Join visio",
		"display_empty_time_slots": "Display empty time slots",
		"make_all_disabled": "Disable all",
		"make_all_enabled": "Enable all",
		"available_time_slot": "Available time slot",
		"unavailable_time_slot": "Unvailable time slot",
		"request_appointment": "Request an appointment",
		"choose_time_slot": "Choose time slot",
		"appointment_request": "Appointment request",
		"applicant": "Applicant",
		"invited": "Invited",
		"on_date": "On",
		"from": "From",
		"to": "to",
		"subject": "Subject",
		"status": "Status",
		"see_details": "See details",
		"accept": "Accept appointment",
		"reject": "Reject appointment",
		"cancel": "Cancel appointment",
		"choose_new_time_slot": "Choose new time slot",
		"make_appointment": "Make appointment",
		"select-location": "Select the place of your appointment...",
		"alerts": {
			"send-appointment-slot-disabled-recipient-title": "Disable niche",
			"send-appointment-slot-disabled-recipient-message": "The niche has been disabled by the recipient",
			"send-appointment-slots-taken-title": "Niche already taken",
			"send-appointment-slots-taken-message": "The niche for which you asked for the appointment is no longer available",
			"send-appointment-limit-accepted-applicant-title": "Limit reached",
			"send-appointment-limit-accepted-applicant-message": "Impossible to make the appointment, your limit of a number of accepted meetings is reached",
			"send-appointment-limit-accepted-recipient-title": "Limit reached",
			"send-appointment-limit-accepted-recipient-message": "Impossible to make the appointment, the accepted meeting limit of the recipient is reached",
			"appointment-accepted-limit": "Impossible to accept the appointment, the accepted appointment limit is reached.",
			"send-appointment-title": "Submitted appointment request",
			"send-appointment-message": "Your request has been transmitted.A notification will be sent to you when it has been examined and approved.",
			"send-appointment-exist-title": "Appointment not available",
			"send-appointment-exist-message": "The niche for which you made the appointment is no longer available.",
			"cancel_appointment": {
				"title": "Cancel this appointment",
				"message": "Do you want to cancel this appointment?"
			}
		},
		"buttons": {
			"send-message": "Send a message",
			"see-details": "See the details",
			"accept": "Accept",
			"refuse": "Refuse",
			"cancel": "Cancel"
		},
		"notification": {
			"reminder-title": "Reminder of your appointment",
			"reminder-content": "Your appointment with {{user}} starts in {{time}} minutes",
			"accepted": "The appointment \"{{subject}}\" has been accepted.",
			"pending": "Appointment request {{date}}",
			"rejected": "The appointment \"{{subject}}\" has been rejected.",
			"cancelled": "The appointment \"{{subject}}\" has been cancelled.",
			"rejected_automatically": "The appointment \"{{subject}}\" has been rejected automatically. The user is not available for this time slot anymore.",
			"cancelled_automatically": "The appointment \"{{subject}}\" has been cancelled automatically. The user is not available for this time slot anymore."
		},
		"app_status": {
			"coming": "To come",
			"waiting": "On hold",
			"ended": "Finished",
			"accepted": "Accepted",
			"cancelled": "Cancelled",
			"cancelled-by": "Canceled by {{ user }}",
			"cancelled_automatically": "Cancelled automatically",
			"rejected": "Declined",
			"rejected_automatically": "Declined automatically",
			"pending": "Waiting for an answer"
		},
		"select_date": "Select a date",
		"date_time_appointment": "The appointment is set:",
		"make_appointment_available": "Make time slot available",
		"make_appointment_unavailable": "Make time slot unavailable"
	},
	"app_settings": {
		"old_version_description": "Your application has a more recent update, download it on the stores",
		"old_version_title": "Old version"
	},
	"asks-questions": {
		"ask-questions-plc": "Type your text here...",
		"error-send-question": "Could not send your question !",
		"error-voting": "An error occurred during the like/unlike",
		"ask-question-anonymously": "Ask the question anonymously",
		"your-question-awaiting-moderator-approbation": "Your question is awaiting moderator approval"
	},
	"auth": {
		"need_login_register_for_participating": "You must login or register in order to participate."
	},
	"buttons": {
		"access": "Log in",
		"authenticate": "Sign In",
		"access_to": "Access",
		"access_by_email": "Email Access",
		"access_by_shortcode": "Code Access",
		"add": "Add",
		"add-contact": "Add contact",
		"scan-a-profil": "Scan a profile",
		"answer": "Submit",
		"apply": "Apply",
		"back": "Back",
		"back_to_list_event": "Back to events list",
		"back-to-login": "Back to connection",
		"btn_verify": "Access",
		"cancel": "Cancel",
		"close": "Close",
		"comment": "Comment",
		"confirm": "Confirm",
		"create": "Create",
		"delete": "Delete",
		"download": "Download",
		"delete_account": "Delete your account",
		"download_vcf": "Download card (vCard)",
		"edit-profil": "Edit profile",
		"enter": "Enter",
		"exit": "Exit",
		"from_photos": "Photo library",
		"take_photo": "To take a picture",
		"go": "Go",
		"generate-email": "Generate email",
		"generate-random-email-address": "Generate email address",
		"log_out": "Log out",
		"next": "Next",
		"new": "New",
		"no": "No",
		"nonees": "None",
		"open_gallery": "My pictures",
		"ok": "Ok",
		"post": "Publish",
		"prev": "Previous",
		"public_events_list": "Publics events list",
		"register": "Register",
		"select_picture": "Select a picture",
		"click-to-select-document": "Click to select a document",
		"click-on-document-to-replace": "Click on the document to replace it",
		"share": "Share",
		"show_more": "Show more",
		"show_less": "Show less",
		"social_sharing": "Social Networks",
		"take_picture": "Take a picture",
		"update": "Update",
		"validate": "Submit",
		"yes": "Yes"
	},
	"chat": {
		"chat": "Chat",
		"chats": "Chats",
		"chat_history": "Conversations",
		"chat_empty_content_title": "Chat between participants",
		"chat_empty_content_text": "To start a discussion, go to a participant's profile and click on 'Start a discussion'.",
		"create_chat": "Create a discussion group",
		"display_group_info": "Display group information",
		"group_chat_empty_content_title": "Discuss among participants",
		"group_chat_empty_content_text": "Waiting for a discussion group. Be the first to participate and encourage other guests to join you",
		"groups": "Groupes",
		"group_info": "Group information",
		"new_chat": "New chat",
		"no_messages": "You have not started a conversation yet",
		"no_official": "Unofficial groups",
		"official": "Official groups",
		"notifications": "Notifications",
		"received_message": "You have received a new message",
		"silent_mode": "Silent mode",
		"toggle": "unable/disable",
		"type_msg": "Enter your message...",
		"you": "You: "
	},
	"checkin": {
		"only-one-check-authorized": "Only one check is allowed",
		"already_checked": "Already checked",
		"away": "Absent",
		"default_title": "Check-in",
		"empty_content_title": "View check-in",
		"empty_content_text": "View checkins",
		"last_checkins": "Last Check-ins",
		"qrcode_focus": "Scan the QR Code",
		"showed": "Present",
		"total": "Total",
		"user_registered": "User registered!",
		"user_unregistered": "User not registered !"
	},
	"documents": {
		"default_title": "Documents",
		"empty_content_title": "View event documents",
		"empty_content_text": "View and download event documents!"
	},
	"edit-profil": {
		"name": "Name",
		"email": "E-mail",
		"biography": "biography",
		"website": "Website",
		"facebook": "Facebook",
		"linkedin": "LinkedIn",
		"twitter": "X (Twitter)",
		"instagram": "Instagram",
		"submit": "Submit",
		"placeholders": {
			"name_text-info": "Your name",
			"email_text-info": "Your email adress",
			"password_create_text-info": "Create a password",
			"password_confirm_text-info": "Confirm your password",
			"password_text-info": "Your password",
			"bio_text-info": "How do you describe yourself?",
			"website_text-info": "Website",
			"linkedin_text-info": "LinkedIn profile",
			"facebook_text-info": "Facebook profile",
			"twitter_text-info": "Twitter profile",
			"instagram_text-info": "Instagram profile",
			"company_text-info": "Your company",
			"title_text-info": "What position do you hold ?",
			"url_text-info": "Enter a valid link",
			"simple_text-info": "Enter a text value",
			"numeric_text-info": "Enter a numeric value",
			"html_text-info": "Enter text or html content",
			"select_text-info": "Select a value",
			"multi-select_text-info": "Select one or more values",
			"multiple_text-info": "Enter text and press \"Enter\" to validate",
			"date_text-info": "Select a date",
			"phonetel_text-info": "Enter a phone number",
			"image_text-info": "Download an image",
			"file_text-info": "Download a File",
			"example": {
				"email1": "email@example.com",
				"email2": "Ex : johnsmith@mail.com"
			}
		},
		"errors": {
			"characters": "characters",
			"blank": {
				"name": "The name is required!",
				"simple_text": "Empty field! Please enter a value.",
				"email": "The email is required !",
				"select": "Please select one option !",
				"multi_select": "Please select one or more options!",
				"required": "Required field !"
			},
			"invalid": {
				"bio": "The number of characters must be between 15 and 5000!",
				"url": "Invalid link !",
				"facebook": "Facebook link is invalid !",
				"linkedin": "Linkedin link is invalid",
				"twitter": "X (Twitter) link is invalid",
				"instagram": "Instagram link is invalid",
				"name": "The name must contain at least 3 characters!",
				"email": "Invalid email!",
				"date": "Invalid date!",
				"generic": "Invalid format!",
				"multiple_text-too-long": "The maximum characters number has been exceeded",
				"multiple_text-content-exist": "The content is already present in the list",
				"min_length": "Please enter at least",
				"phone_number": "Invalid phone number",
				"big_number": "Number too large",
				"file_size": "File too large, Limit : "
			}
		}
	},
	"events": {
		"switch_timezone_user": "Switch to local time zone",
		"switch_timezone_event": "Switch to event time zone",
		"add_event_to_calendar": "Add to calendar"
	},
	"events_list": {
		"events": "Events",
		"my_events": "My events",
		"publics_events": "Public events",
		"no-events": "You are not registered on any event of this container!"
	},
	"event_users": {
		"default_title": "Attendees",
		"empty_content_title": "View attendees list",
		"empty_content_text": "Consult the trombinoscope with the profiles of the participants. Chat or video chat directly with a participant from their profile!",
		"empty_content_text_card_exchange": "Search for a contact or scan their QR code to save it",
		"company": "Company",
		"post": "Job",
		"delete_all": "Delete all",
		"not_specified": "Not specified"
	},
	"card-exchange": {
		"ask_to_get_scans_by_email_title": "Receive scans by email",
		"ask_to_get_scans_copy_by_email": "Would you like to receive a copy of your scans by email?"
	},
	"feedbacks": {
		"cannot_send_feedback": "Unable to send survey feedbacks",
		"default_title": "Feedback",
		"default_title_feedback": "Feedback",
		"answered": "Answered",
		"not_answered": "Not answered",
		"answer_plc": "...",
		"force_complete_title_alert": "Warning",
		"force_complete_message_alert": "You must completely fill out the form",
		"feedback_send": "Your survey feedbacks have been sent"
	},
	"feed_news": {
		"default_title": "Feed",
		"description_plc": "Tell a little about something ...",
		"comments_plc": "Comment...",
		"likes": "Likes",
		"comment": "Comment",
		"comments": "Comments",
		"thumb-up": "Like",
		"heart": "Love",
		"clap": "Bravo",
		"idea": "Instructive",
		"thinking": "Interesting",
		"time": "{{time}} {{timeLabel}} ago",
		"empty_content_title": "Post content on the event's news feed",
		"empty_content_text": "Discuss with others through posts, likes and comments posted on the event newsfeed!"
	},
	"filter": {
		"not_specified": "Not specified",
		"delete_all": "Delete all",
		"text-information": "Use filters to refine your search"
	},
	"gallery": {
		"default_title": "Gallery",
		"empty_content_title": "View pictures from the event",
		"empty_content_text": "View and download pictures from the event!"
	},
	"gamification": {
		"treasure_hunt_description": "Find the QR Codes scattered throughout the event and earn more points."
	},
	"interactivity": {
		"default_title": "Interactivity",
		"default_empty_content_title": "No result !",
		"default_empty_content_text": "Come back later !",
		"externals_interactivity_description": "Interact with each other!",
		"note-taking-description": "To create a note, click on the + button at the bottom of the screen",
		"survey_description": "Answer the survey!",
		"quiz_description": "Test your knowledge and answer this quiz",
		"training_description": "Conduct the training",
		"ask_question_description": "Ask speakers",
		"feedback_empty_content_title": "Fill surveys",
		"feedback_empty_content_text": "Your opinion is essential! Access and respond to satisfaction surveys directly on the platform.",
		"quiz_empty_content_title": "Take the quiz",
		"quiz_empty_content_text": "Test your knowledge with a quiz!",
		"questions_empty_content_title": "Ask your questions",
		"questions_empty_content_text": "Do not hesitate to ask all your questions to the speakers! The other participants will be able to like them to put them at the top.",
		"survey_empty_content_title": "Answer the surveys!",
		"survey_empty_content_text": "Your opinion is essential! Access and respond to satisfaction surveys directly on the platform.",
		"min_points_msg_part_1": "You should at least achieve ",
		"min_points_msg_part_2": " points to enable this survey !"
	},
	"labels": {
		"accompanying": "Accompanying",
		"address": "Address",
		"and": "and",
		"code": "Code",
		"comment": "Comment",
		"content": "Content",
		"choose-date": "Choose a date",
		"description": "Description",
		"contact-editing": "Editing contact",
		"contact-creation": "Creating a contact",
		"email": "Email",
		"empty_notes": "No notes",
		"filters": "Filters",
		"groups": "Groups",
		"group": "Group",
		"hidden": "Hidden",
		"history": "History",
		"visible": "Visible",
		"hours": "hour(s)",
		"identifier": "Identifier",
		"information": "Information",
		"internet_site": "Website",
		"legal_mentions": "Legal",
		"list": "List",
		"loading": "Loading...",
		"load-the-editor": "Load the editor",
		"locations": "Locations",
		"members": "Members",
		"my_notes": "My notes",
		"minutes": "minute(s)",
		"name": "Name",
		"no": "No",
		"on-maintenance": "The platform is currently undergoing maintenance, please come back later",
		"plan": "Plan",
		"privacy": "Privacy Policy",
		"reset": "Reset",
		"scan": "Scan",
		"mandatory": "Mandatory",
		"show_on_public_profile": "Show on public profile",
		"scan_date": "Scan date",
		"search": "Search",
		"seconds": "s",
		"sessions": "Sessions",
		"signature": "Signature",
		"start": "Beginning",
		"support": "Support Email",
		"tags": "Tags",
		"terms_of_use": "Terms of Use",
		"title": "Title",
		"tracks": "Tracks",
		"yes": "Yes"
	},
	"placeholders": {
		"enter-phone-number": "Enter a valid phone number",
		"link-or-import-image": "Enter a link or upload an image",
		"import-file": "Import a file",
		"address_plc": "99 Av. des Champs-Élysées, 75008 Paris France",
		"phone_number_placeholder": "07 12 34 56 78"
	},
	"languages": {
		"choose-language": "Choose language",
		"arabic": "Arab",
		"english": "English",
		"french": "French",
		"portuguese": "Portuguese",
		"spanish": "Spanish",
		"german": "German"
	},
	"login": {
		"accept_term": "I accept the",
		"confirm_new_pass": "Confirm your new password",
		"email": "Email",
		"password": "Password",
		"forgot_pass": "I forgot my password",
		"first_access_txt_01": "First access!",
		"first_access_txt_02": "A six-digit code has been sent to your email address, enter it below to confirm your identity:",
		"go_to_login": "Go to login",
		"new_pass": "New password",
		"not_registered": "This email is not registered for the event, please register.",
		"incorrect_pass": "Invalid password !",
		"invalid_pass": "Invalid password! Your password must contain at least 6 characters",
		"min_length_Password": "Invalid password! Your password must include {{charNbr}} minimum characters",
		"pass_error": "An error occurred while creating your password, please try again later!",
		"recovery_pass_title": "Reset password",
		"recovery_pass_txt": "Please confirm your email to receive a password reset email:",
		"your_email_plc": "Enter your email",
		"recovery_btn": "Send",
		"recovery_success": "We have sent you an email to reset your password",
		"recovery_text": "We have just sent you an email allowing you to reset your password. Remember to check your junk mail (spam) because it could be there. All you have to do is click on the link to create a new one.",
		"recovery_fail": "We were unable to reset your password. Try Again!",
		"access_your_account": "Log in",
		"term_check_required": "You must agree to the Terms of Service and Privacy Policy",
		"terms_of_use": "Terms of use",
		"privacy_txt": "Privacy Policy",
		"invalid_email": "Invalid email",
		"invalid_name": "Invalid name",
		"blank_email": "Email is required",
		"email_not_found": "Email is not registered",
		"btn_no_have_acc": "I do not have an account",
		"verify_code": "The email containing the code may be in your spam folder.",
		"btn_make_pass": "Create a password",
		"great_title": "Welcome!",
		"make_pass_txt": "Please create an individual password (minimum 6 characters)",
		"make_pass_strong_txt": "Please create an individual password (minimum 12 uppercase characters, tiny, figure, symbols)",
		"error_send_email_code": "We were unable to send you the email containing the 6-digit code. Please try again later!",
		"invalid_code": "The 6-digit code is invalid. Try Again!",
		"password_not_match": "The passwords are not identical.",
		"email_already_use": "Email is already registered in another account",
		"resend_code_number": "If you did not receive the 6-digit code: Check your spam",
		"remaining_time": "Remaining time",
		"many_pass_errors": "Your account has been temporarily blocked due to numerous connection attempts. Please wait a few minutes before trying again.",
		"input_name_plc": "John smith",
		"input_email_plc": "john@mail.com",
		"geral_error_register": "An error occurred during registration, please try again.",
		"resend_code": "Resend the 6-digit code",
		"resend_email_reset_link": "Click here if you dit not receive the password reset email",
		"account_already_exist_with_email": "An account with this email already exists. As this is a public event, simply log in with your password to register.",
		"account_already_exist_with_email_private_event": "An account with this email already exists. Please log in using the login form.",
		"account_already_exist_with_email_public_event": "You have already registered with this email account to another event on this platform. Please insert your password at the end of this registration form in order to finalise your registration.",
		"event_user_already_exist_in_event": "You are already registered for this event. Simply log in by entering your password to access it.",
		"not_have_access_to_event": "The user does not have access to the requested event.",
		"min_6_characters": "minimum 6 characters",
		"account_disabled_many_request": "Access to this account has been temporarily disabl…setting your password or you can try again later.",
		"attempt_to_login_with_short_code_private_event_msg": "This event is private, you cannot log in from an access code. Please login directly using your email address",
		"attempt_to_login_with_short_code_private_event_title": "Access to event"
	},
	"note-taking": {
		"success-create": "Note creates",
		"success-delete": "Deleted note",
		"success-edit": "Modified note",
		"share-title": "Shall",
		"title-too-long": "The title should not be more than 70 characters",
		"content-too-long": "The content should not make more than 700 characters"
	},
	"notifications": {
		"appointment_chat_title_received": "Appointment received",
		"appointment_chat_title_updated": "Modified appointment",
		"appointment_chat_title": "Appointment",
		"appointment_chat_title_cancelled": "Appointment cancelled"
	},
	"profile": {
		"start_chat": "Start a chat",
		"start_visio": "Start a visio"
	},
	"quizs": {
		"warning-validation-definitive": "Attention !Validation is final.",
		"score-is": "Your score is...",
		"correct-answered-out-of-1": "You responded correctly to ",
		"correct-answered-out-of-2": " questions out of ",
		"retry": "Restart",
		"retry-later": "Start again later",
		"validate": "Validate",
		"cannot_send_quiz": "Unable to send quiz answers",
		"default_title": "Quiz",
		"default_title_quiz": "Quiz",
		"answered": "Answered",
		"not_answered": "Not answered",
		"answer_plc": "...",
		"force_complete_title_alert": "Warning",
		"force_complete_message_alert": "You must completely fill out the form",
		"result_send": "Your response is sent"
	},
	"rankings": {
		"empty_content_title": "View ranking",
		"empty_content_text": "view ranking",
		"global_ranking": "Global ranking",
		"my_ranking": "My ranking"
	},
	"register": {
		"register": "Sign up",
		"email_exist": "A user is already associated with this email address",
		"register_to_event": "Register to the event",
		"register_a_user_to_the_event": "Register a user to the event",
		"user_already_has_an_account": "Already have an account ?",
		"user_doesnt_has_an_account": "You are not registered yet ?",
		"next_step": "Next step",
		"previous_step": "Previous step",
		"registrations_are_closed_please_contact_your_event_manager": "Registration is closed. Please contact your event manager",
		"register_an_accompanying_user_title": "Register an accompanying person to the event",
		"register_an_accompanying_user_question": "Would you like to register an accompagnant to the event?",
		"accompanying_users_limit_reached": "You cannot register an accompanying person for the event as you have reached the registration limit set by the administrator",
		"register-schedule-limit-reached": "The registration quota for the session '{{sessionName}}' has been reached, please choose another one or contact the organizer",
		"validator-error-msg": {
			"multi-text": {
				"required": "Field required and must contain at least one validated value",
				"multi_text-control_value_must_validated": "Field value must be validated",
				"multi_text-control_value_must_be_unique": "Field value must be unique",
				"multi_text_must_contain_value": "At least one value must be entered and validated (press Enter to validate)"
			},
			"strong-password-pattern": "The word of Password is not robust, it must have at least 12 characters included at least a capital character, a figure and a special character (!@#$%€-_()[];:.<>+~#=*)",
			"date-minimum": "The date must be greater than {{minDate}}",
			"date-maximum": "The date must be less than {{maxDate}}",
			"required": "Required field !",
			"checkbox-required": "You must check this box",
			"file": {
				"file_size": "File too large, Limit : {{limit}} MB"
			}
		},
		"accompanying_remaining_places": "You can still register {{ places }} accompanying persons"
	},
	"reports": {
		"alert_title": "Report",
		"alert_send": "Report sent"
	},
	"schedule": {
		"default_title": "Schedule",
		"filter_hour": "Per day",
		"filter_track": "Per track",
		"empty_content_title": "Find the program of your event",
		"empty_content_text": "Quickly consult the digital program of your event!",
		"download_schedule": "Download schedule",
		"start-soon": "Start in",
		"end-soon": "End in",
		"prev-sessions": "Previous sessions"
	},
	"schedule_detail": {
		"cannot_register_to_session": "Registration for this session is not possible",
		"default_title": "Details",
		"no_date": "No date",
		"number_attendees": "Attendees already registered",
		"number_available_vacancies": "Remaining places",
		"register_to_session": "Subscribe to the session",
		"unregister_to_session": "Unsubscribe from the session",
		"registered_to_session": "You are registered to the session",
		"unregistered_to_session": "You are no longer registered for the session",
		"error_reg_unreg_to_session": "Could not sub/unsubscribe to the session",
		"unavailable": "Unavailable",
		"interactivity": {
			"details": "Details",
			"ask-questions": "Questions",
			"ask-question": "Question",
			"externals_interactivity": "External interactivity",
			"survey": "Survey",
			"quiz": "Quiz",
			"training": "Training",
			"group-discussion": "Group chat",
			"feedback-session": "Session feedback"
		},
		"prev-session": "Previous session",
		"next-session": "Next session",
		"session_limit_attendees": "The session has reached the limit of participants!",
		"registered_users_list": "Registered participants"
	},
	"snackbar": {
		"authentification_required": "You must login to access this event",
		"cannot_create_account": "Can not create your account",
		"cannot_login": "Can not log in",
		"cannot_perform_action": "Can not perform this action",
		"error_occured": "An error has occurred",
		"error_uploading_photo": "Error uploading photo",
		"error_occured_on_verif_email": "An error has occurred while checking your email address",
		"event_shortcode_blank": "The code is invalid, please try again",
		"form_cannot_be_edited_after_submission": "The form cannot be edited after submission",
		"no_form_attached": "There is no form attached",
		"form_unavailable": "The form is not available",
		"file_size_greater_than_limit": "The file size is greater than the limit ({{limit}} MB)",
		"no_module_found": "No module found",
		"no_homepage": "This event does not have a home page configured",
		"no_network": "No Internet connection",
		"no_shortcode": "No shortcodes",
		"no_user": "No user account",
		"not_authorized_event": "You are not authorized to access this event",
		"register-form-already-connected-guard": "You have already completed the form",
		"update_successfull": "Update successfully",
		"file_updated_successfully": "File uploaded successfully",
		"picture_updated_successfull": "Picture updated successfully",
		"failed_to_upload_media": "Your files failed to upload",
		"no_password": "Please fill the password field",
		"wrong_password": "Bad password",
		"signature-saved": "Signature saved",
		"success_delete_account": "Your account is being deleted, this will be effective in a few minutes.",
		"limit_groups_exced": "You have reached the maximum number of groups",
		"limit_avaible_place_exed": "There is a conclict or you have reached the maximum number of available places for the \"{{field}}\" assertion",
		"confirm_reset_password_error": "An error occurred during confirmation",
		"invalid_reset_password_code": "Invalid or expired action code. Try to reset the password again.",
		"confirm_reset_password_success": "Password reset was successful. You can now close this page and sign in into application with your new password."
	},
	"surveys": {
		"cannot_send_survey": "Unable to send survey responses",
		"default_title": "Survey",
		"default_title_survey": "Survey",
		"answered": "Answered",
		"not_answered": "Not answered",
		"answer_plc": "...",
		"force_complete_title_alert": "Warning",
		"force_complete_message_alert": "You must completely fill out the form",
		"survey_send": "Your survey answers have been sent",
		"min_points_alert_msg": "You should at least achieve <strong> {{ points }} </strong> points to enable this survey !"
	},
	"texts": {
		"see-filter-results": "See the results",
		"current-date": "Today's date",
		"personalschedule": "Personnel Schedule",
		"speakers": "speakers",
		"addschedule": "Session registration",
		"removeschedule": "Remove registration",
		"event": "Event",
		"events": "Events",
		"survey": "Survey",
		"quiz": "Quiz",
		"training": "Training",
		"ics_link_google": "Synchronise with your Google calendar",
		"ics_download": "Synchronize with your Apple calendar, Outlook and other tools",
		"ics_schedule_download": "By clicking on the icon, you agree to synchronize the entire schedule to your smartphone's calendar.",
		"subscription": "Enrollment",
		"profile": "Profile",
		"nation": "Country",
		"state": "State",
		"city": "City",
		"save": "Save",
		"site": "Site",
		"pt_BR": "Portuguese",
		"en_US": "English",
		"es_ES": "Spanish",
		"fr_FR": "French",
		"de_DE": "German",
		"sunday": "Sunday",
		"monday": "Monday",
		"tuesday": "Tuesday",
		"wednesday": "Wednesday",
		"thursday": "Thursday",
		"friday": "Friday",
		"saturday": "Saturday",
		"january": "January",
		"february": "February",
		"march": "March",
		"april": "April",
		"may": "May",
		"june": "June",
		"july": "July",
		"august": "August",
		"september": "September",
		"october": "October",
		"november": "November",
		"december": "December",
		"of": "of",
		"maps": "Maps",
		"btn_send": "Send",
		"thankyou": "Thank you!",
		"loading": "Loading...",
		"and": "and",
		"terms_of_use": "Terms of Use",
		"privacy": "Privacy",
		"cookie": "Cookies",
		"legal_mentions": "Legal",
		"terms_legal": "Legal",
		"license": "B3App Corp - All Rights Reserved",
		"search_plc": "Search...",
		"description": "Description",
		"select": "Select",
		"select_image": "Select an image",
		"select_option": "Select an option",
		"image": "Image",
		"filter": "Filter",
		"all": "All",
		"about": "About",
		"start_chat": "Start chat",
		"picture": "Picture",
		"name": "Name",
		"points": "Points",
		"menu": "Menu",
		"need_login_to_use": "You must log in or create an account to access this content!",
		"timezone": "Timezone",
		"support": "Support",
		"a_z": "Alphabetical A-Z",
		"z_a": "Alphabetical Z-A",
		"locations": "Locations",
		"social_medias": "Social networks",
		"position": "Position",
		"network_offline": "No internet connection!",
		"network_online": "The internet connection has been restored!",
		"network_offline_detail": "No internet connection. Some functionalities may not be available.",
		"network_online_detail": "The internet connection has been restored. The online functionalities of the app will be resumed.",
		"code": "Code",
		"read_qrcode": "Read QRCode",
		"join-visio": "Join the visio",
		"visio-launched": "Visio call launched",
		"visio-leaved": "Video call exited",
		"start_visio": "Video Call",
		"waiting_for_content": "Waiting for content",
		"not_belonging_to_any_group": "You don't belong to any group",
		"no_result_title": "No result !",
		"no_result_text": "Come back later !",
		"reactions": "Reactions",
		"zoom-visio-reload": "Your Zoom sessions is over, this page is going to reload in {{timer}}s.",
		"unsopported_navigator_msg1": "Vous utilisez une version de navigateur qui n'est pas compatible avec notre plateforme, passez à Google Chrome pour profiter de l'intégralité des fonctionnalités. Vous pouvez le télécharger en cliquant",
		"here": "ici",
		"previous-step": "Previous step",
		"x_selected_items": "{{count}} selected item(s)"
	},
	"toasts": {
		"copied-to-clipboard": "Given copied!",
		"download_image": "Downloading done.",
		"errors": {
			"create-basic-error": "An error occurred while creating",
			"error-open-document": "Cannot open the document",
			"cannot_send_message": "Cannot send message",
			"download_error": "Unable to download",
			"share_error": "Unable to share the file",
			"you_must_enable_permission": "You must enable the permission to access the camera",
			"cannot-copy-to-clipboard": "Unable to copy data"
		}
	},
	"tooltips": {
		"click-for-open-document": "Click to open document",
		"click-for-view-profil": "Click to view user profile",
		"click-for-access-to-session": "Click to access the session",
		"click-to-view-image": "Click to view image",
		"click-to-replace-document": "Click to replace the document",
		"click-to-download-document": "Click to download the document"
	},
	"treasure_hunts": {
		"already_scan": "You have already scanned this QR Code",
		"btn_qrcode": "SCAN QR CODE",
		"error_scan": "Invalid QR Code, please try again",
		"gaming_default_txt": "Find the QR Codes scattered throughout the event and earn more points.",
		"go_gaming": "LET'S PLAY",
		"scan_read_error": "QR code scanning error. Please try again",
		"success_scan": "QR Code successfully read!",
		"remaining_qrcodes": "REMAINING QR CODES:",
		"title": "Gamification",
		"total_qrcodes": "TOTAL QR CODES:"
	},
	"visios": {
		"creating-visio": "Visio creating...",
		"opening-visio": "Visio openning...",
		"visio-created": "Visio created",
		"visio-created-error": "The visio could not be created",
		"visio-not-available": "Visio functionality is not available",
		"visio-open-error": "Could not access to visio"
	},
	"widgets": {
		"external_link_title": "External link"
	},
	"word_cloud": {
		"add_word": "Add a word",
		"error-send-word": "Erreur lors de l'envoi du mot",
		"success-send-word": "Word sent successfully"
	},
	"countries": {
		"AF": "Afghanistan",
		"AL": "Albania",
		"DZ": "Algeria",
		"AS": "American Samoa",
		"AD": "Andorra",
		"AO": "Angola",
		"AI": "Anguilla",
		"AQ": "Antarctica",
		"AG": "Antigua and Barbuda",
		"AR": "Argentina",
		"AM": "Armenia",
		"AW": "Aruba",
		"AU": "Australia",
		"AT": "Austria",
		"AZ": "Azerbaijan",
		"BS": "Bahamas",
		"BH": "Bahrain",
		"BD": "Bangladesh",
		"BB": "Barbados",
		"BY": "Belarus",
		"BE": "Belgium",
		"BZ": "Belize",
		"BJ": "Benin",
		"BM": "Bermuda",
		"BT": "Bhutan",
		"BO": "Bolivia",
		"BA": "Bosnia and Herzegovina",
		"BW": "Botswana",
		"BV": "Bouvet Island",
		"BR": "Brazil",
		"IO": "British Indian Ocean Territory",
		"BN": "Brunei Darussalam",
		"BG": "Bulgaria",
		"BF": "Burkina Faso",
		"BI": "Burundi",
		"KH": "Cambodia",
		"CM": "Cameroon",
		"CA": "Canada",
		"CV": "Cape Verde",
		"KY": "Cayman Islands",
		"CF": "Central African Republic",
		"TD": "Chad",
		"CL": "Chile",
		"CN": "China",
		"CX": "Christmas Island",
		"CC": "Cocos (Keeling) Islands",
		"CO": "Colombia",
		"KM": "Comoros",
		"CG": "Congo",
		"CD": "Congo, The Democratic Republic of the",
		"CK": "Cook Islands",
		"CR": "Costa Rica",
		"HR": "Croatia",
		"CU": "Cuba",
		"CY": "Cyprus",
		"CZ": "Czech Republic",
		"DK": "Denmark",
		"DJ": "Djibouti",
		"DM": "Dominica",
		"DO": "Dominican Republic",
		"EC": "Ecuador",
		"EG": "Egypt",
		"SV": "El Salvador",
		"GQ": "Equatorial Guinea",
		"ER": "Eritrea",
		"EE": "Estonia",
		"ET": "Ethiopia",
		"FK": "Falkland Islands (Malvinas)",
		"FO": "Faroe Islands",
		"FJ": "Fiji",
		"FI": "Finland",
		"FR": "France",
		"GF": "French Guiana",
		"PF": "French Polynesia",
		"TF": "French Southern Territories",
		"GA": "Gabon",
		"GM": "Gambia",
		"GE": "Georgia",
		"DE": "Germany",
		"GH": "Ghana",
		"GI": "Gibraltar",
		"GR": "Greece",
		"GL": "Greenland",
		"GD": "Grenada",
		"GP": "Guadeloupe",
		"GU": "Guam",
		"GT": "Guatemala",
		"GN": "Guinea",
		"GW": "Guinea-Bissau",
		"GY": "Guyana",
		"HT": "Haiti",
		"HM": "Heard Island and McDonald Islands",
		"VA": "Holy See (Vatican City State)",
		"HN": "Honduras",
		"HK": "Hong Kong",
		"HU": "Hungary",
		"IS": "Iceland",
		"IN": "India",
		"ID": "Indonesia",
		"IR": "Iran, Islamic Republic of",
		"IQ": "Iraq",
		"IE": "Ireland",
		"IL": "Israel",
		"IT": "Italy",
		"JM": "Jamaica",
		"JP": "Japan",
		"JO": "Jordan",
		"KZ": "Kazakhstan",
		"KE": "Kenya",
		"KI": "Kiribati",
		"KP": "Korea, Democratic People's Republic of",
		"KR": "Korea, Republic of",
		"KW": "Kuwait",
		"KG": "Kyrgyzstan",
		"LA": "Lao People's Democratic Republic",
		"LV": "Latvia",
		"LB": "Lebanon",
		"LS": "Lesotho",
		"LR": "Liberia",
		"LY": "Libyan Arab Jamahiriya",
		"LI": "Liechtenstein",
		"LT": "Lithuania",
		"LU": "Luxembourg",
		"MO": "Macao",
		"MK": "North Macedonia",
		"MG": "Madagascar",
		"MW": "Malawi",
		"MY": "Malaysia",
		"MV": "Maldives",
		"ML": "Mali",
		"MT": "Malta",
		"MH": "Marshall Islands",
		"MQ": "Martinique",
		"MR": "Mauritania",
		"MU": "Mauritius",
		"YT": "Mayotte",
		"MX": "Mexico",
		"FM": "Micronesia, Federated States of",
		"MD": "Moldova, Republic of",
		"MC": "Monaco",
		"MN": "Mongolia",
		"MS": "Montserrat",
		"MA": "Morocco",
		"MZ": "Mozambique",
		"MM": "Myanmar",
		"NA": "Namibia",
		"NR": "Nauru",
		"NP": "Nepal",
		"NL": "Netherlands",
		"AN": "Netherlands Antilles",
		"NC": "New Caledonia",
		"NZ": "New Zealand",
		"NI": "Nicaragua",
		"NE": "Niger",
		"NG": "Nigeria",
		"NU": "Niue",
		"NF": "Norfolk Island",
		"MP": "Northern Mariana Islands",
		"NO": "Norway",
		"OM": "Oman",
		"PK": "Pakistan",
		"PW": "Palau",
		"PS": "Palestine",
		"PA": "Panama",
		"PG": "Papua New Guinea",
		"PY": "Paraguay",
		"PE": "Peru",
		"PH": "Philippines",
		"PN": "Pitcairn",
		"PL": "Poland",
		"PT": "Portugal",
		"PR": "Puerto Rico",
		"QA": "Qatar",
		"RE": "Reunion",
		"RO": "Romania",
		"RU": "Russian Federation",
		"RW": "Rwanda",
		"SH": "Saint Helena",
		"KN": "Saint Kitts and Nevis",
		"LC": "Saint Lucia",
		"PM": "Saint Pierre and Miquelon",
		"VC": "Saint Vincent and The Grenadines",
		"WS": "Samoa",
		"SM": "San Marino",
		"ST": "Sao Tome and Principe",
		"SA": "Saudi Arabia",
		"SN": "Senegal",
		"CS": "Serbia and Montenegro",
		"SC": "Seychelles",
		"SL": "Sierra Leone",
		"SG": "Singapore",
		"SK": "Slovakia",
		"SI": "Slovenia",
		"SB": "Solomon Islands",
		"SO": "Somalia",
		"ZA": "South Africa",
		"GS": "South Georgia and The South Sandwich Islands",
		"ES": "Spain",
		"LK": "Sri Lanka",
		"SD": "Sudan",
		"SR": "Suriname",
		"SJ": "Svalbard and Jan Mayen",
		"SZ": "Swaziland",
		"SE": "Sweden",
		"CH": "Switzerland",
		"SY": "Syrian Arab Republic",
		"TW": "Taiwan, Province of China",
		"TJ": "Tajikistan",
		"TZ": "Tanzania, United Republic of",
		"TH": "Thailand",
		"TL": "Timor-leste",
		"TG": "Togo",
		"TK": "Tokelau",
		"TO": "Tonga",
		"TT": "Trinidad and Tobago",
		"TN": "Tunisia",
		"TR": "Turkey",
		"TM": "Turkmenistan",
		"TC": "Turks and Caicos Islands",
		"TV": "Tuvalu",
		"UG": "Uganda",
		"UA": "Ukraine",
		"AE": "United Arab Emirates",
		"GB": "United Kingdom",
		"US": "United States",
		"UM": "United States Minor Outlying Islands",
		"UY": "Uruguay",
		"UZ": "Uzbekistan",
		"VU": "Vanuatu",
		"VE": "Venezuela",
		"VN": "Viet Nam",
		"VG": "Virgin Islands, British",
		"VI": "Virgin Islands, U.S.",
		"WF": "Wallis and Futuna",
		"EH": "Western Sahara",
		"YE": "Yemen",
		"ZM": "Zambia",
		"ZW": "Zimbabwe",
		"GG": "Guernsey",
		"IM": "Isle of Man",
		"JE": "Jersey",
		"ME": "Montenegro",
		"BL": "Saint Barthélemy",
		"MF": "Saint Martin (French part)",
		"RS": "Serbia",
		"SS": "South Sudan",
		"AX": "Åland Islands",
		"CI": "Ivory Coast"
	}
}
