import { Component, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { firstValueFrom, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, GetScanning, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { ICheckin, IEvent, IEventUser, IModule } from "src/app/shared/interfaces";
import { ICheckinChecked } from "src/app/shared/interfaces/checkin.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getAllCheckins, getCheckinsForSelf } from "src/app/shared/selectors/checkins.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService, CheckinsService, EventUsersService, UtilityService } from "src/app/shared/services";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import * as _ from "lodash-es";

@Component({
	selector: "app-self-checkin",
	templateUrl: "./self-checkin.component.html",
	styleUrls: ["./self-checkin.component.scss"],
	standalone: false
})
export class SelfCheckinComponent implements OnDestroy {
	subscriptions: Subscription[] = [];

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	eventUser: IEventUser;

	checkins: ICheckin[] = [];
	checkeds: ICheckinChecked[] = [];
	validCheckeds: ICheckinChecked[] = [];

	scanning: boolean = false;
	scanningWeb: boolean = false;
	enableScanWeb: boolean = false;

	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		private store: Store,
		private SCheckins: CheckinsService,
		private SEventUsers: EventUsersService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private SAnalytics: AnalyticsService
	) {
		(this.platform.is("ios") || this.platform.is("android")) && !this.platform.is("mobileweb") ? true : false;
	}

	ionViewWillEnter() {
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.init = false;
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.subscriptions.forEach((sub) => sub.unsubscribe());

						this.initDatas();
					});
			});
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		this.init = false;
		// Case scanning running
		BarcodeScanner.showBackground();
		BarcodeScanner.stopScan();
		this.store.dispatch(GetScanning({ payload: false }));

		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;
				if (this.module) {
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	init: boolean = false;
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;

					if (this.eventUser && !this.init) {
						this.init = true;
						this.getCheckins();
						this.getCheckeds();
					}
				}
			})
		);
	}

	/**
	 * Get all checkins
	 */
	getCheckins() {
		this.subscriptions.push(
			this.store.select(getCheckinsForSelf).subscribe((checkins) => {
				this.checkins = checkins;
			})
		);
	}

	/**
	 * Get checkeds
	 */
	getCheckeds() {
		this.subscriptions.push(
			this.SCheckins.getCheckedsForEventUser(this.eventId, this.eventUser.uid).subscribe((checkeds) => {
				this.checkeds = checkeds;
				this.validCheckeds = this.checkeds.filter((checked) => checked.checkinStatus);
			})
		);
	}

	/**
	 * Get checkin data
	 * @param checked
	 * @returns
	 */
	getCheckinData(checked: ICheckinChecked) {
		return this.checkins.find((checkin) => checkin.uid === checked.checkinId);
	}

	/**
	 * Get date
	 * @param date
	 * @returns
	 */
	getDate(date: string) {
		return this.SUtility.getPartOfDate(this.event, this.eventUser, date, "full");
	}

	/**
	 * Scan success event for web
	 * @param evt
	 */
	scanSuccessHandler(barcodeData: string) {
		if (this.enableScanWeb) {
			this.enableScanWeb = false;
			const arrayIds = barcodeData.split("+");
			this.confirmCheckin(arrayIds[0], arrayIds[1]);
		}
	}

	/**
	 * Emits an array of video-devices after view was initialized.
	 */
	camerasFoundHandler() {
		this.enableScanWeb = true;
	}

	/**
	 * Not camera found for scanning
	 */
	async camerasNotFoundHandler() {
		this.scanning = false;
		this.scanningWeb = false;
		this.enableScanWeb = false;
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.error_scanning"),
			this.STranslate.instant("alerts.no_camera"),
			[
				{
					text: this.STranslate.instant("buttons.no"),
					role: "cancel"
				}
			]
		);
		alert.present();
	}

	/**
	 * Scan error handler
	 * @param evt
	 */
	async scanErrorHandler() {
		this.scanning = false;
		this.scanningWeb = false;
		this.enableScanWeb = false;
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.error_scanning"),
			this.STranslate.instant("alerts.invalid_qrcode"),
			[
				{
					text: this.STranslate.instant("buttons.yes"),
					handler: () => {
						this.scanQr();
					}
				},
				{
					text: this.STranslate.instant("buttons.no"),
					role: "cancel"
				}
			]
		);
		alert.present();
	}

	async checkPermission() {
		const status = await BarcodeScanner.checkPermission({ force: true });
		if (status.granted) {
			return true;
		} else if (status.denied) {
			BarcodeScanner.openAppSettings();
			return false;
		}
	}

	/**
	 * Scan qr code
	 */
	async scanQr() {
		if (this.eventUser && !this.scanning) {
			this.scanning = true;

			// For web scanning
			if (!this.isMobile) {
				this.scanning = false;
				this.scanningWeb = true;
				// this.enableScanWeb = true;
				return;
			}

			const allowed = await this.checkPermission();
			if (allowed) {
				try {
					BarcodeScanner.hideBackground(); // make background of WebView transparent
					this.store.dispatch(GetScanning({ payload: true }));
					const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
					this.store.dispatch(GetScanning({ payload: false }));
					// if the result has content
					if (result.hasContent) {
						const arrayIds = result.content.split("+");
						this.confirmCheckin(arrayIds[0], arrayIds[1]);
					} else {
						this.scanning = false;
						this.scanningWeb = false;
						this.enableScanWeb = false;
						this.SUtility.callGoBackOnHeader();
					}
				} catch (error) {
					this.store.dispatch(GetScanning({ payload: false }));
					this.scanning = false;
					this.scanningWeb = false;
					this.enableScanWeb = false;
					const alert = await this.SUtility.presentAlert(
						this.STranslate.instant("alerts.error_scanning"),
						this.STranslate.instant("alerts.no_camera"),
						[
							{
								text: this.STranslate.instant("buttons.yes"),
								handler: () => {
									this.scanQr();
								}
							},
							{
								text: this.STranslate.instant("buttons.no"),
								role: "cancel"
							}
						]
					);
					alert.present();
				}
			}
		}
	}

	/**
	 * Confirm checkin
	 */
	async confirmCheckin(moduleId: string, checkinId: string) {
		const checkin = this.checkins.find((checkin) => checkin.uid === checkinId);
		const checked = this.checkeds.find(
			(checked) => checked.moduleId === moduleId && checked.checkinId === checkinId
		);
		// Invalid qrcode
		if (!checkin) {
			this.scanning = false;
			this.scanningWeb = false;
			this.enableScanWeb = false;
			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant("alerts.error_scanning"),
				this.STranslate.instant("alerts.invalid_qrcode"),
				[
					{
						text: this.STranslate.instant("buttons.yes"),
						handler: () => {
							this.scanQr();
						}
					},
					{
						text: this.STranslate.instant("buttons.no"),
						role: "cancel"
					}
				]
			);
			alert.present();
			return;
		}

		if (checked && checked.checkinStatus) {
			this.scanning = false;
			this.scanningWeb = false;
			this.enableScanWeb = false;
			// Already checked
			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant("alerts.error_scanning"),
				this.STranslate.instant("alerts.already_checked_checkin"),
				[
					{
						text: this.STranslate.instant("buttons.ok"),
						role: "cancel"
					}
				]
			);
			alert.present();
		} else if (!checked || (checked && !checked.checkinStatus)) {
			// Don't checked or don't exist
			if (checked) {
				// If checked already exist update it
				checked.checkinStatus = true;

				if (!this.eventUser.checkins) {
					const checkins = await firstValueFrom(this.store.select(getAllCheckins));
					this.eventUser.checkins = {
						checkinsChecked: [checked.checkinId],
						checkinsNotChecked: checkins
							.filter((checkin) => checkin.uid !== checked.checkinId)
							.map((checkin) => checkin.uid)
					};
				} else if (!this.eventUser.checkins.checkinsChecked.includes(checked.checkinId)) {
					this.eventUser.checkins.checkinsChecked.push(checked.checkinId);
					this.eventUser.checkins.checkinsNotChecked = this.eventUser.checkins.checkinsNotChecked.filter(
						(uid) => uid !== checked.checkinId
					);
				}
				checked.userQueryName = this.eventUser.queryName;
				checked.groupsWhenChecked = this.eventUser.groups;
				checked.creationDate = DateTime.local().toISO();
				await this.SCheckins.updateCheckinChecked(this.eventId, checked.moduleId, checked.checkinId, checked);
				await this.SEventUsers.updatePartOfEventUser(
					this.eventId,
					this.eventUser.moduleId,
					this.eventUser.uid,
					{
						checkins: this.eventUser.checkins
					}
				);

				this.scanning = false;
				this.scanningWeb = false;
				this.enableScanWeb = false;
			} else {
				// If not already exist create it
				const newChecked: ICheckinChecked = {
					uid: "",
					checkinId: checkinId,
					checkinStatus: true,
					creationDate: DateTime.local().toISO(),
					eventId: this.eventId,
					groupsWhenChecked: this.eventUser.groups,
					moduleId: moduleId,
					multiCheckRecords: [],
					multiCheckRecordsCount: 0,
					signature: "",
					userQueryName: this.eventUser.queryName,
					userId: this.eventUser.uid,
					validationType: 3
				};
				if (!this.eventUser.checkins) {
					const checkins = await firstValueFrom(this.store.select(getAllCheckins));
					this.eventUser.checkins = {
						checkinsChecked: [newChecked.checkinId],
						checkinsNotChecked: checkins
							.filter((checkin) => checkin.uid !== newChecked.checkinId)
							.map((checkin) => checkin.uid)
					};
				} else if (!this.eventUser.checkins.checkinsChecked.includes(newChecked.checkinId)) {
					this.eventUser.checkins.checkinsChecked.push(newChecked.checkinId);
					this.eventUser.checkins.checkinsNotChecked = this.eventUser.checkins.checkinsNotChecked.filter(
						(uid) => uid !== newChecked.checkinId
					);
				}
				await this.SCheckins.createCheckinChecked(this.eventId, moduleId, checkinId, newChecked);
				await this.SEventUsers.updatePartOfEventUser(
					this.eventId,
					this.eventUser.moduleId,
					this.eventUser.uid,
					{
						checkins: this.eventUser.checkins
					}
				);
			}
			// await this.SAnalytics.checkinProcessed(this.eventId, this.eventUser, {
			// 	checkinId: checked.checkinId,
			// 	checkinStatus: checked.checkinStatus,
			// 	moduleId: this.moduleId,
			// 	type: TypeTracking.SELF_CHECKIN_ACTION_PROCESSED
			// });
			this.scanning = false;
			this.scanningWeb = false;
			this.enableScanWeb = false;
			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant("alerts.all_right"),
				this.STranslate.instant("alerts.your_presence_confirmed"),
				[
					{
						text: this.STranslate.instant("buttons.ok"),
						role: "cancel"
					}
				]
			);
			alert.present();
		}
	}
}
