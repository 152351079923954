import { Component, OnDestroy } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription, take } from "rxjs";
import { GetHeaderTitle, Loading, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { IEvent, IEventUser, IModule } from "src/app/shared/interfaces";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { FirestoreService, UtilityService } from "src/app/shared/services";
import * as _ from "lodash-es";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { INote } from "src/app/shared/interfaces/notes.interfaces";
import { DateTime } from "luxon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";

@Component({
	selector: "app-note-taking-add",
	templateUrl: "./note-taking-add.component.html",
	styleUrls: ["./note-taking-add.component.scss"],
	standalone: false
})
export class NoteTakingAddComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	creating: boolean = false;

	event: IEvent;
	eventId: string;
	moduleId: string;
	module: IModule;
	eventUser: IEventUser;

	isMobile: boolean = false;

	noteForm: FormGroup;

	constructor(
		private fb: FormBuilder,
		private store: Store,
		private platform: Platform,
		private navCtrl: NavController,
		private snackbar: MatSnackBar,
		private STranslate: TranslateService,
		private SFirestore: FirestoreService,
		public SUtility: UtilityService
	) {
		this.noteForm = this.fb.group({
			content: ["", [Validators.required, Validators.maxLength(700)]],
			title: ["", [Validators.minLength(1), Validators.maxLength(70), Validators.required]]
		});
	}

	ionViewWillEnter() {
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;

						this.getEvent();
						this.getModule();
						this.getEventUser();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.store
			.select(getCurrentEvent)
			.pipe(take(1))
			.subscribe((event) => {
				this.event = event;
			});
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.store
			.select(getSpecificModule(this.moduleId))
			.pipe(take(1))
			.subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
					this.store.dispatch(
						GetHeaderTitle({
							payload: {
								ArAR: "إنشاء ملاحظة",
								DeDE: "Maak een notitie",
								EnUS: "Create a note",
								EsES: "Crear una nota",
								FrFR: "Créer une note",
								PtBR: "Crie uma nota"
							}
						})
					);
				}
			});
	}

	getEventUser() {
		this.store
			.select(getCurrentEventUser)
			.pipe(take(1))
			.subscribe((eventUser) => {
				this.eventUser = eventUser;
			});
	}

	async createNote() {
		if (!this.creating) {
			this.creating = true;
			try {
				this.store.dispatch(Loading({ payload: true }));
				const data = this.noteForm.getRawValue();
				const newNote: INote = {
					uid: this.SFirestore.createId(`events/${this.eventId}/modules/${this.moduleId}/notes`),
					content: data.content,
					creationDate: DateTime.local().toISO(),
					eventId: this.eventId,
					moduleId: this.moduleId,
					title: data.title,
					userId: this.eventUser ? this.eventUser.uid : ""
				};
				await this.SFirestore.setDocument(
					`events/${this.eventId}/modules/${this.moduleId}/notes/${newNote.uid}`,
					newNote
				);
				this.creating = false;
				this.store.dispatch(Loading({ payload: false }));
				this.snackbar.open(this.STranslate.instant("note-taking.success-create"), "", {
					duration: 3000,
					panelClass: "success-snackbar"
				});
				this.navCtrl.navigateBack(`event/${this.eventId}/note-taking/${this.moduleId}`);
			} catch (error) {
				this.creating = false;
				this.store.dispatch(Loading({ payload: false }));
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		}
	}
}
