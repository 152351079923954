import { Component, inject, OnDestroy, OnInit, WritableSignal, signal } from "@angular/core";
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { debounceTime, Subscription } from "rxjs";
import { IEvent, IEventUser, IModule } from "src/app/shared/interfaces";
import { IChat } from "src/app/shared/interfaces/chats.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { ChatsService, EventUsersService, FirestoreService, MongodbService } from "src/app/shared/services";
import { getGenericModuleByType } from "src/app/shared/selectors/modules.selectors";
import { TypeModule } from "src/app/shared/enums/type-module";
import { diacriticSensitiveRegex } from "src/app/shared/helpers-functions/filter-search";

@Component({
	selector: "app-create-chat",
	templateUrl: "./create-chat.component.html",
	styleUrls: ["./create-chat.component.scss"],
	standalone: false
})
export class CreateChatComponent implements OnInit, OnDestroy {
	SMongo = inject(MongodbService);

	subscriptions: Subscription[] = [];

	event: IEvent;
	module: IModule;
	currentUser: IEventUser;

	chatForm: FormGroup;
	selectedMembers: WritableSignal<IEventUser[]> = signal([]);
	filteredMembers: WritableSignal<IEventUser[]> = signal([]);

	tmpEventUsers: IEventUser[] = [];

	searchType: "full" | "partial" = "partial";
	searchInit: boolean = false;

	creating: boolean = false;

	constructor(
		private SEventUsers: EventUsersService,
		private SFirestore: FirestoreService,
		private store: Store,
		public modalCtrl: ModalController,
		private SChats: ChatsService,
		private fb: UntypedFormBuilder
	) {
		this.chatForm = this.fb.group({
			name: ["", Validators.compose([Validators.required])],
			queryMembers: [""]
		});

		this.chatForm
			.get("queryMembers")
			.valueChanges.pipe(debounceTime(200))
			.subscribe((queryMembers) => {
				this.filterMembers(queryMembers);
			});
	}

	ngOnInit() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.currentUser, eventUser)) {
					this.currentUser = eventUser;
				}
			})
		);
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;

					// if (!this.searchInit && this.event) {
					// 	this.SFirestore.getCountOfQueryObs("event-users", "group", [
					// 		where("eventId", "==", this.event.uid)
					// 	]).subscribe((total) => {
					// 		if (total > 1000) {
					// 			this.searchType = "partial";
					// 		} else {
					// 			this.searchType = "full";
					// 			const searchFilter = new SearchFilter();
					// 			searchFilter.equalityFields = [{ fieldKey: "eventId", compareData: this.event.uid }];
					// 			this.SEventUsers.getAllEventUsersForEvent(searchFilter, "aggregation").subscribe(
					// 				(results) => {
					// 					this.tmpEventUsers = results.docs.sort((a, b) =>
					// 						a.queryName > b.queryName ? 1 : a.queryName < b.queryName ? -1 : 0
					// 					);
					// 				}
					// 			);
					// 		}
					// 	});
					// 	this.searchInit = true;
					// }
				}
			})
		);

		this.subscriptions.push(
			this.store.select(getGenericModuleByType(TypeModule.CHAT)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Close modal
	 */
	close() {
		this.modalCtrl.dismiss();
	}

	/**
	 * Create chat
	 */
	async createChat() {
		try {
			if (!this.creating) {
				this.creating = true;
				const data = this.chatForm.getRawValue();
				this.selectedMembers.update((members) => [...members, this.currentUser]);
				const newChat: IChat = {
					uid: this.SFirestore.createId(`events/${this.event.uid}/modules/${this.module.uid}/chats`),
					allowNotifs: true,
					creationDate: DateTime.local().toISO(),
					createdFrom: "app",
					disabled: false,
					eventId: this.event.uid,
					lastAccess: "",
					lastMessageId: "",
					lastMessageUser: null,
					lastUpdated: "",
					members: [],
					moduleId: this.module.uid,
					options: {
						allowVisio: false,
						description: "",
						groupCreator: {
							uid: this.currentUser.uid,
							email: this.currentUser.email,
							eventId: this.currentUser.eventId,
							identifier: this.currentUser.identifier,
							moduleId: this.currentUser.moduleId,
							name: this.currentUser.name,
							type: this.currentUser.type
						},
						groupImage: "",
						groupsLink: {
							linked: false,
							groups: []
						},
						specificMembersLink: {
							linked: true,
							specifics: this.selectedMembers().map((member) => member.uid)
						},
						identifier: data.name,
						muted: [],
						name: data.name,
						official: false,
						schedulesLink: {
							linked: false,
							linkType: -1,
							linkedModules: [],
							linkedSpecifics: []
						}
					},
					type: 1,
					visibility: true
				};
				await this.SChats.createChat(this.event.uid, this.module.uid, newChat);
				this.creating = false;
				this.close();
			}
		} catch (error) {
			this.creating = false;
		}
	}

	/**
	 * Filter members
	 */
	filterMembers(queryMembers) {
		if (queryMembers) {
			const queryTerm = queryMembers.trim().toLocaleUpperCase();
			const pipeline: any[] = [
				{
					$match: {
						eventId: { $eq: this.event.uid },
						$or: [
							{
								email: {
									$regex: `.*${diacriticSensitiveRegex(queryTerm)}.*`,
									$options: "i"
								}
							},
							{
								identifier: {
									$regex: `.*${diacriticSensitiveRegex(queryTerm)}.*`,
									$options: "i"
								}
							},
							{
								name: {
									$regex: `.*${diacriticSensitiveRegex(queryTerm)}.*`,
									$options: "i"
								}
							}
						]
					}
				},
				{
					$addFields: {
						queryName: {
							$toLower: "$name"
						}
					}
				},
				{ $sort: { queryName: 1 } },
				{
					$project: {
						name: 1,
						email: 1,
						identifier: 1,
						uid: 1
					}
				},
				{
					$facet: {
						docs: [{ $skip: 0 }, { $limit: 10 }],
						meta: [{ $count: "total" }]
					}
				}
			];

			this.SMongo.queryDatasMongodb(pipeline, "event-users", {}, "aggregation").subscribe((results) => {
				this.filteredMembers.set(results.docs);
			});

			// if (this.searchType === "full") {
			// 	this.filteredMembers = this.tmpEventUsers.filter((eventUser) =>
			// 		eventUser.queryName.includes(queryTerm)
			// 	);
			// } else {
			// 	const searchFilter = new SearchFilter();
			// 	searchFilter.equalityFields = [{ fieldKey: "eventId", compareData: this.event.uid }];
			// 	if (queryTerm) {
			// 		searchFilter.includeOrTextFields = [
			// 			{ fieldKey: "name", compareData: queryTerm },
			// 			{ fieldKey: "identifier", compareData: queryTerm }
			// 		];
			// 	}
			// 	this.SEventUsers.getAllEventUsersForEvent(searchFilter, "aggregation")
			// 		.pipe(take(1))
			// 		.subscribe((results) => {
			// 			this.filteredMembers = results.docs;
			// 		});
			// }
		} else {
			this.filteredMembers.set([]);
		}
	}

	/**
	 * Selected members
	 * @param item
	 */
	selectMember(item: IEventUser) {
		if (this.selectedMembers().length > 0) {
			const index = this.selectedMembers().indexOf(item);
			if (index == -1) {
				this.selectedMembers.update((members) => [...members, item]);
			}
		} else {
			this.selectedMembers.set([item]);
		}
		this.selectedMembers.update((members) =>
			members.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" }))
		);
		this.chatForm.patchValue({
			queryMembers: ""
		});
		this.filteredMembers.set([]);
	}

	/**
	 * Remove member from selection
	 * @param item
	 */
	removeMember(item) {
		this.selectedMembers.update((members) => members.filter((member) => member.uid !== item.uid));
	}
}
