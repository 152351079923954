import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as ArAR from "../../../assets/i18n/ArAR.json";
import * as DeDE from "../../../assets/i18n/DeDE.json";
import * as EnUS from "../../../assets/i18n/EnUS.json";
import * as EsES from "../../../assets/i18n/EsES.json";
import * as FrFR from "../../../assets/i18n/FrFR.json";
import * as PtBR from "../../../assets/i18n/ArAR.json";

@Injectable({
	providedIn: "root"
})
export class LanguagesService {
	constructor(private STranslate: TranslateService) {}

	getLanguageJson(lang: string) {
		return lang === "ArAR"
			? ArAR
			: lang === "DeDE"
				? DeDE
				: lang === "EnUS"
					? EnUS
					: lang === "EsES"
						? EsES
						: lang === "FrFR"
							? FrFR
							: PtBR;
	}

	/**
	 * Return a list of translated languages names
	 * @returns
	 */
	getLanguagesNames() {
		return {
			ArAR: this.STranslate.instant("languages.arabic"),
			DeDE: this.STranslate.instant("languages.german"),
			EnUS: this.STranslate.instant("languages.english"),
			EsES: this.STranslate.instant("languages.spanish"),
			FrFR: this.STranslate.instant("languages.french"),
			PtBR: this.STranslate.instant("languages.portuguese")
		};
	}
}
