/* eslint-disable max-len */
import { Injectable, signal, WritableSignal } from "@angular/core";
import { Firestore, where, writeBatch, doc } from "@angular/fire/firestore";
import { Subscription, map, switchMap, catchError, of, firstValueFrom, lastValueFrom, Subject } from "rxjs";
import {
	ICardExchangeField,
	ICardExchangeFieldResultData,
	ICardExchangeForm
} from "../interfaces/card-exchange.interfaces";
import { FirestoreService } from "./firestore.service";
import * as _ from "lodash-es";
import { EventUsersService } from "./event-users.service";
import { Store } from "@ngrx/store";
import { QueryConstraint } from "@firebase/firestore";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "./utility.service";
import { IEvent, IEventUser, IFullCustomField, ILanguage, IModule, ISheet } from "../interfaces";
import { ModalController, Platform } from "@ionic/angular";
import { DocumentsService } from "./documents.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomFieldsService } from "./custom-fields.service";
import { getModulesByType } from "../selectors/modules.selectors";
import { TypeModule } from "../enums/type-module";
import * as XLSX from "xlsx";
import { DateTime } from "luxon";
import { EmailDestinationTypeUsers } from "../enums/email-destination-type-users";
import { AutomationsService } from "./automations.service";
import { FileService } from "./file.service";
import { LogFuncTrace } from "../utils-func/debug-decorators";
import { CardExchangeFormComponent } from "src/app/content/pages/event/components/modules/components/card-exchange-form/card-exchange-form.component";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AOA = Array<Array<any>>;

@Injectable({
	providedIn: "root"
})
export class CardExchangeService {
	formsSub: Subscription;
	cardExchangeScanQrSubject: Subject<boolean> = new Subject();
	readonly CARD_EXCHANGE_EMPTY_RESULT: ICardExchangeFieldResultData = {
		uid: null,
		creationDate: null,
		date: null,
		dissertative: null,
		document: null,
		evaluation: null,
		multipleSelect: null,
		oneSelect: null,
		type: null,
		userId: null,
		contactId: null,
		formId: null,
		fieldId: null
	};
	userChoosedToGetMail: WritableSignal<boolean> = signal(false);
	enableScanning: WritableSignal<boolean> = signal(false);
	refreshEventUserList: WritableSignal<boolean> = signal(false);
	cameraFound: WritableSignal<boolean> = signal(false);

	constructor(
		private firestore: Firestore,
		private SFirestore: FirestoreService,
		private SEventUsers: EventUsersService,
		private store: Store,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private platform: Platform,
		private SDocuments: DocumentsService,
		private snackbar: MatSnackBar,
		private SCustomFields: CustomFieldsService,
		private SAutomations: AutomationsService,
		private SFile: FileService,
		private modalCtrl: ModalController
	) {}

	/**
	 * getSpecificForm
	 * @param eventId
	 * @param moduleId
	 * @param formId
	 * @returns
	 */
	getSpecificForm(eventId: string, moduleId: string, formId: string) {
		return this.SFirestore.getDocumentObs(
			`events/${eventId}/modules/${moduleId}/card-exchange-forms/${formId}`
		).pipe(map((doc) => doc.data() as ICardExchangeForm));
	}

	/**
	 * getSpecificFormByGroupId
	 * @param eventId
	 * @param moduleId
	 * @param groupIds
	 * @returns
	 */
	getSpecificFormByGroupId(eventId: string, moduleId: string, groupIds: string[]) {
		if (!groupIds || groupIds.length === 0) return of(null);

		return this.SFirestore.getDocumentsObs(`events/${eventId}/modules/${moduleId}/card-exchange-forms`, [
			where("visibility", "==", true),
			where("groupIds", "array-contains-any", groupIds)
		]).pipe(map((snapshot) => (snapshot.docs[0]?.exists ? (snapshot.docs[0].data() as ICardExchangeForm) : null)));
	}

	/**
	 * getUserContacts
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @returns
	 */
	async getUserContacts(contactIds: string[] = [], eventId: string) {
		if (!contactIds || contactIds.length === 0) return [];

		return (await firstValueFrom(this.SEventUsers.getSpecificsEventUsers(eventId, contactIds)))?.filter((user) =>
			contactIds?.includes(user.uid)
		);
	}

	/**
	 * getUserContactsWithPagination
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @param queryConstraint
	 * @returns
	 */
	getUserContactsWithPagination(eventId: string, contactIds: string[] = [], queryConstraint?: QueryConstraint[]) {
		if (!eventId || !contactIds) return of([]);

		return this.SEventUsers.getSpecificsEventUsersPaginated(eventId, contactIds, queryConstraint);
	}

	/**
	 * getSpecificContactFormDatasOfUser
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @param contactId
	 * @returns
	 */
	getSpecificContactFormDatasOfUser(eventId: string, moduleId: string, userId: string, contactId: string) {
		return this.SFirestore.getDocumentsObs(
			`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas/`,
			[where("contactId", "==", contactId)]
		).pipe(
			map((payload) => payload.docs.map((doc) => doc.data() as ICardExchangeFieldResultData)),
			catchError((_) => of([]))
		);
	}

	/**
	 * createOrUpdateCardExchangeResultDatas
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @param datas
	 * @returns
	 */
	async createOrUpdateCardExchangeResultDatas(
		eventId: string,
		moduleId: string,
		userId: string,
		datas: ICardExchangeFieldResultData[]
	) {
		try {
			const batch = this.SFirestore.getBatch();
			for (const data of datas) {
				await this.deleteUnrelatedFormDatas(eventId, moduleId, userId, data.fieldId);
				// Check if there is a data with data.fieldId, if so, update them, else create them
				const existedData = (
					await firstValueFrom(
						this.SFirestore.getDocumentsObs(
							`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas`,
							[where("fieldId", "==", data.fieldId), where("contactId", "==", data.contactId)]
						)
					)
				)?.docs?.[0]?.data() as ICardExchangeFieldResultData;

				if (existedData) {
					const ref = doc(
						this.firestore,
						// eslint-disable-next-line max-len
						`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas/${existedData.uid}`
					);
					batch.update(
						ref,
						_.omit(
							existedData.type === "oneSelect"
								? { oneSelect: data.oneSelect }
								: existedData.type === "date"
									? { date: data.date }
									: existedData.type === "evaluation"
										? { evaluation: data.evaluation }
										: existedData.type === "document"
											? { document: data.document }
											: existedData.type === "multipleSelect"
												? { multipleSelect: data.multipleSelect }
												: { dissertative: data.dissertative },
							["userId", "contactId", "creationDate", "fieldId", "formId", "type"]
						)
					);
				} else {
					const ref = doc(
						this.firestore,
						`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas/${data.uid}`
					);

					batch.set(ref, data);
				}
			}
			return batch.commit();
		} catch (error) {
			console.error("🚀 ~ CardExchangeService ~ error:", error);
		}
	}

	async deleteUnrelatedFormDatas(
		eventId: string,
		moduleId: string,
		userId: string,
		formFieldId: string
	): Promise<void> {
		try {
			const datasToDelete = (
				await firstValueFrom(
					this.SFirestore.getDocumentsObs(
						`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas`,
						[where("fieldId", "==", null), where("contactId", "==", formFieldId)]
					)
				)
			).docs.map((data) => data.data() as ICardExchangeFieldResultData);
			for (const dataToDelete of datasToDelete) {
				await this.SFirestore.deleteDocument(
					`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas/${dataToDelete.uid}`
				);
			}
		} catch (error) {
			console.error("🚀 ~ CardExchangeService ~ error:", error);
		}
	}

	/**
	 * storeContactInEventUserDoc
	 * @param event
	 * @param eventUser
	 * @param contactId
	 * @returns
	 */
	storeContactInEventUserDoc(event: IEvent, eventUser: IEventUser, contactId: string) {
		eventUser?.contactIds ? eventUser?.contactIds.push(contactId) : (eventUser.contactIds = [contactId]);

		const newCardExchangeData: ICardExchangeFieldResultData = {
			...this.CARD_EXCHANGE_EMPTY_RESULT,
			uid: this.SFirestore.createId(
				`events/${event?.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/card-exchange-datas`
			),
			creationDate: DateTime.now().toISO(),
			userId: eventUser.uid,
			contactId: contactId
		};

		const updateOfFormsDatas = this.createOrUpdateCardExchangeResultDatas(
			event.uid,
			eventUser.moduleId,
			eventUser.uid,
			[newCardExchangeData]
		);
		const updateOfUserCollection = this.SEventUsers.updatePartOfEventUser(
			event.uid,
			eventUser.moduleId,
			eventUser.uid,
			{
				contactIds: eventUser.contactIds
			}
		);

		return Promise.all([updateOfFormsDatas, updateOfUserCollection]);
	}

	/**
	 * removeCardExchangeResultDatas
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @param contactId
	 * @returns
	 */
	removeCardExchangeResultDatas(eventId: string, moduleId: string, userId: string, contactId: string) {
		// get all datas realted to the contactId
		return lastValueFrom(
			this.SFirestore.getDocumentsObs(
				`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas`,
				[where("contactId", "==", contactId)]
			).pipe(
				switchMap((snapshot) => {
					const batch = writeBatch(this.firestore);
					snapshot.docs.forEach((doc) => {
						batch.delete(doc.ref);
					});
					return batch.commit();
				})
			)
		);
	}

	/**
	 * updateCardExchangeResultDatas
	 * @param eventId
	 * @param moduleId
	 * @param formId
	 * @param userId
	 * @param results
	 * @returns
	 */
	updateCardExchangeResultDatas(
		eventId: string,
		moduleId: string,
		userId: string,
		datas: ICardExchangeFieldResultData[]
	) {
		const batch = writeBatch(this.firestore);
		datas.forEach((data) => {
			const ref = doc(
				this.firestore,
				`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas/${data.uid}`
			);
			batch.update(ref, _.omit(data, ["formId", "fieldId", "userId", "creationDate", "type"]));
		});
		return batch.commit();
	}

	/**
	 * openCardExchangeForm
	 * @param contactId
	 * @returns
	 */
	@LogFuncTrace
	async openCardExchangeForm(
		form: ICardExchangeForm,
		componentProps: {
			event: IEvent;
			module: IModule;
			eventUserAdmin: IEventUser;
			eventUserContactId: string;
			isMobile: boolean;
		}
	) {
		try {
			const eventUserContact = await lastValueFrom(
				this.SEventUsers.getSpecificEventUser(componentProps.event.uid, componentProps.eventUserContactId)
			);

			if (!eventUserContact) {
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), null, {
					duration: 3000,
					panelClass: ["snackbar-error"]
				});
				return;
			}

			if (form) {
				const modal = await this.modalCtrl.create({
					component: CardExchangeFormComponent,
					componentProps: {
						event: componentProps.event,
						module: componentProps.module,
						myEventUser: componentProps.eventUserAdmin,
						eventUserContact: eventUserContact,
						eventUserContactId: eventUserContact.uid
					},

					cssClass: componentProps.isMobile
						? "card-exhange-form-modal-css-mobile"
						: "card-exchange-form-modal-css"
				});

				await modal.present();

				const { data } = await modal.onWillDismiss();

				if (!data) {
					return;
				}

				if (data && data?.error) {
					this.SUtility.presentToast(
						this.STranslate.instant("snackbar.error_occured"),
						3000,
						"bottom",
						"error"
					);

					return;
				}

				if (data?.success) {
					await this.storeContactInEventUserDoc(
						componentProps.event,
						componentProps.eventUserAdmin,
						eventUserContact.uid
					);
					this.refreshEventUserList.set(true);
				}
			} else {
				await this.storeContactInEventUserDoc(
					componentProps.event,
					componentProps.eventUserAdmin,
					eventUserContact.uid
				);
				this.refreshEventUserList.set(true);
			}

			this.SUtility.presentToast(
				this.STranslate.instant("snackbar.update_successfull"),
				3000,
				"bottom",
				"success"
			);
		} catch (error) {
			console.error("🚀 ~ CardExchangeService ~ error:", error);

			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	@LogFuncTrace
	async scanSuccessHandler(
		datas: {
			form: ICardExchangeForm;
			event: IEvent;
			module: IModule;
			eventUserAdmin: IEventUser;
			eventUserContactId: string;
			isMobile: boolean;
		},
		barcodeData: string
	) {
		if (this.enableScanning()) {
			try {
				this.enableScanning.set(false);
				this.refreshEventUserList.set(false);
				const contactId = barcodeData.split("+")?.[1];
				if (!contactId) throw new Error("No contact id found");
				await this.openCardExchangeForm(datas.form, { ...datas, eventUserContactId: contactId });
			} catch (error) {
				console.error("🚀 ~ CardExchangeService ~ error:", error);
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), null, {
					duration: 3000,
					panelClass: ["snackbar-error"]
				});
			}
		}
	}

	@LogFuncTrace
	async camerasNotFoundHandler() {
		this.enableScanning.set(false);
		this.cameraFound.set(false);
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.error_scanning"),
			this.STranslate.instant("alerts.no_camera"),
			[
				{
					text: this.STranslate.instant("buttons.no"),
					role: "cancel"
				}
			]
		);
		alert.present();
	}

	/**
	 * Emits an array of video-devices after view was initialized.
	 */
	@LogFuncTrace
	camerasFoundHandler() {
		this.cameraFound.set(true);
	}

	@LogFuncTrace
	scanErrorHandler() {
		this.snackbar.open(this.STranslate.instant("alerts.error_scanning"), null, {
			duration: 3000,
			panelClass: ["snackbar-error"]
		});
	}

	/**
	 *downloadVcard
	 * @param event
	 * @param module
	 * @param authenticateEventUser
	 * @param eventUserToDownload
	 * @param customFields
	 * @param formFields
	 * @param domEvent
	 */
	async downloadVcard(
		event: IEvent,
		module: IModule,
		authenticateEventUser: IEventUser,
		eventUserToDownload: IEventUser | ISheet,
		customFields: IFullCustomField[],
		formFields: ICardExchangeField[],
		domEvent: Event
	) {
		const eventUser = _.cloneDeep(eventUserToDownload);
		domEvent?.stopPropagation();
		try {
			const eventUserUpdatedSettings = await firstValueFrom(
				this.SEventUsers.getSpecificEventUserUpdatedSettings(
					eventUser.eventId,
					eventUser.moduleId,
					eventUser.uid
				)
			);

			if (!formFields) {
				const cardExchangeModule = (
					await firstValueFrom(this.store.select(getModulesByType(TypeModule.CARD_EXCHANGE)))
				)?.[0];
				if (!cardExchangeModule) throw new Error("No card exchange module found");
				const form = await firstValueFrom(
					this.getSpecificFormByGroupId(event.uid, cardExchangeModule.uid, authenticateEventUser.groups)
				);
				if (form) {
					formFields = form.fields;
				}
			}

			if (!eventUserUpdatedSettings) throw new Error("No updated settings found for this user");

			if (eventUserUpdatedSettings) eventUser["updatedSettings"] = eventUserUpdatedSettings;

			const { baseInformationDatas, otherFieldsDatas } = await this.getCardExchangeFieldsDatas(
				event,
				module,
				authenticateEventUser,
				eventUserToDownload as IEventUser,
				customFields,
				formFields
			);

			const { eventUserJob, eventUserCompany, eventUserPhoneNumber, eventUserAddress } = baseInformationDatas;

			const eventUserPhotoData =
				eventUser.photoUrl !== ""
					? await firstValueFrom(this.SDocuments.convertFileUrlToBase64(eventUser.photoUrl))
					: null;

			let vCardData = `BEGIN:VCARD
					VERSION:3.0
					N:${
						eventUser.name.split(" ").length > 1
							? eventUser.name.split(" ")[0] + ";" + eventUser.name.split(" ").slice(1).join(" ")
							: eventUser.name
					};;;
					FN:${eventUser.name};
					X-SOCIALPROFILE;type=Facebook:${
						(module.options.enableUserFieldsHideAbility &&
							eventUser["updatedSettings"] &&
							(eventUser["updatedSettings"]?.fieldsVisibility?.["facebook"] === false ||
								(!eventUser["updatedSettings"]?.fieldsVisibility &&
									module.options.requiredFields?.["facebook"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser?.socialMedias.facebook
							: ""
					}
					X-SOCIALPROFILE;type=Linkedin:${
						(module.options.enableUserFieldsHideAbility &&
							eventUser["updatedSettings"] &&
							(eventUser["updatedSettings"]?.fieldsVisibility?.["linkedin"] === false ||
								(!eventUser["updatedSettings"]?.fieldsVisibility &&
									module.options.requiredFields?.["linkedin"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser?.socialMedias.linkedin
							: ""
					}
					X-SOCIALPROFILE;type=Twitter:${
						(module.options.enableUserFieldsHideAbility &&
							eventUser["updatedSettings"] &&
							(eventUser["updatedSettings"]?.fieldsVisibility?.["twitter"] === false ||
								(!eventUser["updatedSettings"]?.fieldsVisibility &&
									module.options.requiredFields?.["twitter"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser?.socialMedias.twitter
							: ""
					}
					X-SOCIALPROFILE;type=Instagram:${
						(module.options.enableUserFieldsHideAbility &&
							eventUser["updatedSettings"] &&
							(eventUser["updatedSettings"]?.fieldsVisibility?.["instagram"] === false ||
								(!eventUser["updatedSettings"]?.fieldsVisibility &&
									module.options.requiredFields?.["instagram"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser?.socialMedias.instagram
							: ""
					}
					X-SOCIALPROFILE;type=Website:${
						(module.options.enableUserFieldsHideAbility &&
							eventUser["updatedSettings"] &&
							(eventUser["updatedSettings"]?.fieldsVisibility?.["website"] === false ||
								(!eventUser["updatedSettings"]?.fieldsVisibility &&
									module.options.requiredFields?.["website"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser?.socialMedias.website
							: ""
					}
					ORG:${
						eventUser["updatedSettings"] && eventUserCompany
							? eventUserCompany[eventUser["updatedSettings"]?.language] &&
								eventUserCompany[eventUser["updatedSettings"]?.language] !== ""
								? eventUserCompany[eventUser["updatedSettings"]?.language]
								: (eventUserCompany?.[event.language] ?? "")
							: (eventUserCompany?.[event.language] ?? "")
					};
					TITLE:${
						eventUser["updatedSettings"] && eventUserJob
							? eventUserJob[eventUser["updatedSettings"]?.language] &&
								eventUserJob[eventUser["updatedSettings"]?.language] !== ""
								? eventUserJob[eventUser["updatedSettings"]?.language]
								: (eventUserJob?.[event.language] ?? "")
							: (eventUserJob?.[event.language] ?? "")
					}
					JOB:${
						eventUser["updatedSettings"] && eventUserJob
							? eventUserJob[eventUser["updatedSettings"]?.language] &&
								eventUserJob[eventUser["updatedSettings"]?.language] !== ""
								? eventUserJob[eventUser["updatedSettings"]?.language]
								: (eventUserJob?.[event.language] ?? "")
							: (eventUserJob?.[event.language] ?? "")
					}
					X-JOB:${
						eventUser["updatedSettings"] && eventUserJob
							? eventUserJob[eventUser["updatedSettings"]?.language] &&
								eventUserJob[eventUser["updatedSettings"]?.language] !== ""
								? eventUserJob[eventUser["updatedSettings"]?.language]
								: (eventUserJob?.[event.language] ?? "")
							: (eventUserJob?.[event.language] ?? "")
					}
					TEL:${eventUserPhoneNumber ? eventUserPhoneNumber : ""}
					EMAIL:${
						(module.options.enableUserFieldsHideAbility &&
							eventUser["updatedSettings"] &&
							(eventUser["updatedSettings"]?.fieldsVisibility?.["email"] === false ||
								(!eventUser["updatedSettings"]?.fieldsVisibility &&
									module.options.requiredFields?.["email"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser["email"]
							: ""
					}
					ADR: ${eventUserAddress ? eventUserAddress : ""}
					NOTE:${
						otherFieldsDatas
							? Object.entries(
									otherFieldsDatas?.reduce(
										(obj, item) => Object.assign(obj, { [item.name]: item.value }),
										{}
									)
								)
									.filter(([_, value]) => value !== "" && value !== null && value !== undefined)
									.map(([key, value]) => `${key}: ${value}`)
									.join(" | ")
							: ""
					}
					${eventUserPhotoData ? `PHOTO;ENCODING=b;TYPE=JPEG:${eventUserPhotoData}` : null}
					END:VCARD`;

			// Remove all tabulations
			vCardData = vCardData.replace(/\t/g, "");

			if (!this.platform.is("mobile")) {
				const element = document.createElement("a");
				element.setAttribute("href", "data:text/vcard;charset=utf-8," + encodeURIComponent(vCardData));
				element.setAttribute("download", `${eventUser.name}.vcf`);
				element.setAttribute("target", "_blank");
				element.style.display = "none";
				element.click();
			} else {
				this.SDocuments.downloadFile(`${eventUser.name}.vcf`, vCardData);
			}
		} catch (error) {
			console.error("🚀 ~ CardExchangeService ~ error:", error);
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 *getCardExchangeFieldsDatas
	 * @param event
	 * @param module
	 * @param authUser
	 * @param eventUser
	 * @param customFields
	 * @param formFields
	 * @returns
	 */
	async getCardExchangeFieldsDatas(
		event: IEvent,
		module: IModule,
		authUser: IEventUser,
		eventUser: IEventUser,
		customFields: IFullCustomField[],
		formFields: ICardExchangeField[]
	): Promise<{
		baseInformationDatas: {
			eventUserJob: ILanguage;
			eventUserCompany: ILanguage;
			eventUserPhoneNumber: string;
			eventUserAddress: string;
		};
		customFieldsDatas: {
			name: string;
			value: string;
			fieldId: string;
		}[];
		otherFieldsDatas: {
			name: string;
			value: string;
			fieldId: string;
		}[];
	}> {
		const { baseInformations, otherInformations } = this.SCustomFields.getCustomFieldsCardDatas(
			eventUser,
			module,
			customFields,
			event.language
		);

		const language = authUser?.updatedSettings?.language ?? event.language;
		const cardExchangeRestulDatas = (await firstValueFrom(
			this.getSpecificContactFormDatasOfUser(event.uid, authUser.moduleId, authUser.uid, eventUser.uid)
		)) as ICardExchangeFieldResultData[];
		const fields = _.uniqBy(
			cardExchangeRestulDatas?.map((data) => ({
				name: "Scan Date",
				contactId: data.contactId,
				value: DateTime.fromISO(data.creationDate)
					.setLocale(language.substring(0, 2).concat("-" + language.substring(2)))
					.toLocaleString(DateTime.DATETIME_SHORT)
			})),
			"contactId"
		)
			.map((dateObj) => ({
				name: dateObj.name,
				fieldId: dateObj.name,
				value: dateObj.value
			}))
			.concat(
				cardExchangeRestulDatas
					?.map((data) => this.getFieldDataValue(data, formFields, language))
					?.filter((data) => data)
					?.sort((a, b) => (a.name >= b.name ? 1 : -1))
			);

		return {
			baseInformationDatas: baseInformations,
			customFieldsDatas: otherInformations,
			otherFieldsDatas: fields
		};
	}

	async downloadScans(
		event: IEvent,
		module: IModule,
		authenticateEventUser: IEventUser,
		eventUsersToDownload: IEventUser[],
		customFields: { [moduleId: string]: IFullCustomField[] },
		formFields: ICardExchangeField[],
		domEvent: Event,
		allEventUsersModules: IModule[]
	) {
		try {
			domEvent.stopImmediatePropagation();
			const mergedCustomFields = Object.values(customFields).flat();

			if (module.options.sendEmailWhenDownloadScans) {
				const alert = await this.SUtility.presentAlert(
					this.STranslate.instant("card-exchange.ask_to_get_scans_by_email_title"),
					this.STranslate.instant("card-exchange.ask_to_get_scans_copy_by_email"),
					[
						{
							text: this.STranslate.instant("buttons.yes"),
							cssClass: "primary",
							handler: async () => {
								this.userChoosedToGetMail.set(true);
								await this.buildExcelFile(
									event,
									module,
									allEventUsersModules,
									authenticateEventUser,
									eventUsersToDownload,
									mergedCustomFields,
									formFields
								);
							}
						},
						{
							text: this.STranslate.instant("buttons.no"),
							handler: async () => {
								this.userChoosedToGetMail.set(false);
								await this.buildExcelFile(
									event,
									module,
									allEventUsersModules,
									authenticateEventUser,
									eventUsersToDownload,
									mergedCustomFields,
									formFields
								);
							}
						}
					]
				);

				await alert.present();
			} else {
				await this.buildExcelFile(
					event,
					module,
					allEventUsersModules,
					authenticateEventUser,
					eventUsersToDownload,
					mergedCustomFields,
					formFields
				);
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	@LogFuncTrace
	async buildExcelFile(
		event: IEvent,
		module: IModule,
		allEventUsersModules: IModule[],
		authenticateEventUser: IEventUser,
		eventUsersToDownload: IEventUser[],
		customFields: IFullCustomField[],
		formFields: ICardExchangeField[]
	): Promise<void> {
		const language = authenticateEventUser?.updatedSettings?.language ?? event.language;

		const sheetFormatedDatas: string[][] = [];
		const baseColumnTitles: string[] = [
			this.STranslate.instant("labels.name"),
			this.STranslate.instant("labels.email")
		];
		const customFieldColumnTitles: { name: string; fieldId: string; value: null }[] = _.uniqBy(
			customFields
				.filter((cus) =>
					this.SCustomFields.canExportCustomFieldBasedOnModuleAndEventUserOptions(
						cus,
						allEventUsersModules.find((mod) => mod.uid === cus.moduleSettings.moduleId),
						null
					)
				)
				.map((cus) => ({
					name: cus.baseSettings.name[authenticateEventUser?.updatedSettings?.language ?? language],
					fieldId: cus.baseSettings.uid,
					value: null
				})),
			"fieldId"
		);

		const otherFieldColumnTitles: { fieldId: string; name: string; value: string }[] = [
			{
				fieldId: "Scan Date",
				name: this.STranslate.instant("labels.scan_date"),
				value: this.STranslate.instant("labels.scan_date")
			}
		]
			.concat(
				formFields
					? formFields.map((field) => ({
							fieldId: field.uid,
							name: field.name[authenticateEventUser?.updatedSettings?.language ?? language] as string,
							value: field.name[authenticateEventUser?.updatedSettings?.language ?? language] as string
						}))
					: null
			)
			.filter((field) => field)
			.sort((a, b) => (a.fieldId > b.fieldId ? 1 : a.fieldId < b.fieldId ? -1 : 0))
			.concat(customFieldColumnTitles);

		const eventUserUpdatedSettingsPromises = [];
		for (const eventUserToDownload of eventUsersToDownload) {
			eventUserUpdatedSettingsPromises.push(
				firstValueFrom(
					this.SEventUsers.getSpecificEventUserUpdatedSettings(
						eventUserToDownload.eventId,
						eventUserToDownload.moduleId,
						eventUserToDownload.uid
					)
				)
			);
		}

		const allEventUsersToDownloadWithTheirSettings = (await Promise.all(eventUserUpdatedSettingsPromises)).map(
			(settings, index) =>
				({
					...eventUsersToDownload[index],
					updatedSettings: settings
				}) as IEventUser
		);

		for (const eventUserToDownload of allEventUsersToDownloadWithTheirSettings) {
			const { customFieldsDatas, otherFieldsDatas } = await this.getCardExchangeFieldsDatas(
				event,
				allEventUsersModules.find((mod) => mod.uid === eventUserToDownload.moduleId),
				authenticateEventUser,
				eventUserToDownload,
				customFields,
				formFields
			);

			sheetFormatedDatas.push(
				[eventUserToDownload.name, eventUserToDownload.email].concat(
					this.equalizeArraysByPadding(
						otherFieldColumnTitles,
						otherFieldsDatas
							.sort((a, b) => (a.fieldId > b.fieldId ? 1 : a.fieldId < b.fieldId ? -1 : 0))
							.concat(customFieldsDatas),
						null,
						"fieldId"
					).map((data) => (data ? data["value"] : ""))
					// customFieldsDatas.map((cusData) => cusData.value)
				)
			);
		}

		const wb = XLSX.utils.book_new();
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([
			baseColumnTitles.concat(otherFieldColumnTitles.map((field) => field.name)),
			...sheetFormatedDatas
		]);
		ws["!cols"] = baseColumnTitles
			.concat(otherFieldColumnTitles.map((field) => field.name))
			.map(() => ({ wpx: 150 }));
		XLSX.utils.book_append_sheet(wb, ws, language);

		if (!this.platform.is("mobile")) {
			XLSX.writeFile(wb, `${authenticateEventUser.name} (scans).xlsx`, { type: "base64" });
		} else {
			await this.SFile.downloadFile(
				XLSX.write(wb, { type: "base64" }),
				`${authenticateEventUser.name} (scans).xlsx`
			);
		}

		if (this.userChoosedToGetMail() && module.options.sendEmailWhenDownloadScans) {
			this.sendExcelViaEmail(event, authenticateEventUser, XLSX.write(wb, { type: "base64" }));
			this.userChoosedToGetMail.set(false);
		}

		if (module.options.sendEmailToStaffWhenDownloadScans) {
			this.sendExcelViaEmail(
				event,
				authenticateEventUser,
				XLSX.write(wb, { type: "base64" }),
				EmailDestinationTypeUsers.STAFF
			);
		}
	}

	/**
	 * getFieldDataValue
	 * @param data
	 * @param fields
	 * @param language
	 * @returns
	 */
	getFieldDataValue(
		data: ICardExchangeFieldResultData,
		fields: ICardExchangeField[],
		language: string
	): { name: string; value: string; fieldId: string } {
		const field = fields?.find((field) => field.uid === data.fieldId);
		if (!field) return null;

		if (data.type === "oneSelect") {
			return {
				name: field.name[language],
				value: field.answers.find((answer) => answer.uid === data.oneSelect.answerId)
					? field.answers.find((answer) => answer.uid === data.oneSelect.answerId)?.answer?.[language]
					: null,
				fieldId: field.uid
			};
		} else if (data.type === "multipleSelect") {
			return {
				name: field.name[language],
				value: field.answers
					.filter((answer) => data.multipleSelect.answersIds.includes(answer.uid))
					.map((answer) => answer.answer[language])
					.join(", "),
				fieldId: field.uid
			};
		} else if (data.type === "dissertative" || data.type === "plainText") {
			return { name: field.name[language], value: data.dissertative, fieldId: field.uid };
		} else if (data.type === "date") {
			return { name: field.name[language], value: data.date, fieldId: field.uid };
		} else if (data.type === "document") {
			return { name: field.name[language], value: data.document.url, fieldId: field.uid };
		} else if (data.type === "evaluation") {
			return { name: field.name[language], value: data.evaluation.toString(), fieldId: field.uid };
		}
	}

	sendExcelViaEmail(event: IEvent, authUser: IEventUser, file: any, dest?: EmailDestinationTypeUsers): void {
		this.SAutomations.sendEmail({
			eventId: authUser.eventId,
			mailContent: this.SAutomations.parseEmailVarDatas(
				dest ? this.SAutomations.EMAIL_EXCEL_TEMPLATE_STAFF : this.SAutomations.EMAIL_EXCEL_TEMPLATE,
				event,
				authUser
			),
			mailSubject: dest ? `${authUser.name}  (copie des scans)` : `${authUser.name} (scans)`,
			mailTo: dest ? null : authUser.email,
			attachement: {
				data: file,
				name: `${authUser.name} (scans).xlsx`,
				contenType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			},
			destinationType: dest ? dest : EmailDestinationTypeUsers.SPECIFIC
		});
	}

	checkIsContact(eventUser: IEventUser, contactId: string) {
		return eventUser.contactIds?.includes(contactId);
	}

	/**
	 * equalizeArraysByPadding
	 * @param biggerArray
	 * @param smallerArray
	 * @param padValue
	 * @param keyToCompareObj
	 * @returns
	 * @description replace null value when there's not a corresponding value in the smaller obj
	 */
	equalizeArraysByPadding<T>(
		biggerArray: Array<T>,
		smallerArray: Array<T>,
		padValue = null,
		keyToCompareObj?: string
	) {
		const resultArray: Array<T> = Array(biggerArray.length).fill(padValue);
		biggerArray.forEach((value, currentIndex) => {
			const index = smallerArray?.findIndex(
				(smallArrayValue) => smallArrayValue?.[keyToCompareObj] === value?.[keyToCompareObj]
			);

			index !== -1 &&
				(resultArray[currentIndex] = smallerArray?.find(
					(smallArrayValue) => smallArrayValue?.[keyToCompareObj] === value?.[keyToCompareObj]
				));
		});

		return resultArray;
	}
}
