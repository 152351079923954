<ion-header>
	<ion-toolbar
		class="ion-color"
		[style.background]="event.styling.menuColor"
		[style.color]="event.styling.menuTextColor"
	>
		<ion-buttons slot="start">
			<ion-button (click)="dismiss()">
				<ion-icon slot="icon-only" src="assets/icon/Close.svg"></ion-icon>
			</ion-button>
		</ion-buttons>
		<!-- <ion-title>
            {{ "widgets.external_link_title" | translate }}
        </ion-title> -->
	</ion-toolbar>
</ion-header>
<ion-content class="hide-scrollbar" style="--background: var(--bg-color-content)">
	<div class="background" style="width: 100% !important; height: 100%; overflow: hidden">
		<iframe
			[src]="url | safeHtml: 'resourceUrl'"
			frameborder="0"
			style="overflow: hidden; height: 100%; width: 100%"
			height="100%"
			width="100%"
		></iframe>
	</div>
</ion-content>
