import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, OnChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom, Subscription, take } from "rxjs";
import { GetHeaderState, GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { IEvent, IEventUser, IExternalInteractivity, IModule } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificExternalInteractivity } from "src/app/shared/selectors/interactivity.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService, UtilityService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
import * as _ from "lodash-es";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { Platform } from "@ionic/angular";

@Component({
	selector: "app-external-interactivity",
	templateUrl: "./external-interactivity.component.html",
	styleUrls: ["./external-interactivity.component.scss"],
	standalone: false
})
export class ExternalInteractivityComponent implements OnInit, OnDestroy, OnChanges {
	subscriptions: Subscription[] = [];

	loader: boolean = true;
	init: boolean = false;

	@Input() componentMode: boolean = false;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input() externalInteractivityId: string;
	externalInteractivity: IExternalInteractivity;
	@Input() sessionId: string;
	eventUser: IEventUser;
	@Input() showBackBtn: boolean;

	isMobile: boolean = false;
	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(
		private platform: Platform,
		private SAnalytics: AnalyticsService,
		private store: Store,
		private SUtility: UtilityService,
		private STranslate: TranslateService
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
	}

	ngOnInit() {
		if (this.componentMode) {
			this.currentLanguage = this.STranslate.currentLang;
			this.subscriptions.push(
				this.STranslate.onLangChange.subscribe((lang) => {
					this.currentLanguage = lang.lang;
				})
			);
			this.initDatas();
		}
	}

	ionViewWillEnter() {
		if (!this.componentMode) {
			this.currentLanguage = this.STranslate.currentLang;
			this.subscriptions.push(
				this.STranslate.onLangChange.subscribe((lang) => {
					this.currentLanguage = lang.lang;
				})
			);
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.externalInteractivityId = params.externalInteractivityId;
							this.subscriptions.forEach((sub) => sub.unsubscribe());

							// Analytics
							this.SAnalytics.externalInteractivityAccess(
								this.eventId,
								this.moduleId,
								this.externalInteractivityId
							);

							this.initDatas();
						});
				});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.externalInteractivityId && !changes.externalInteractivityId.firstChange) {
			this.subscriptions.forEach((sub) => sub?.unsubscribe());
			this.event = null;
			this.module = null;
			this.externalInteractivity = null;
			this.eventUser = null;
			this.initDatas();
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getExternalInteractivity();
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.store.dispatch(ResetHeaderState(null));
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);
	}

	/**
	 * Get external interactivity
	 */
	getExternalInteractivity() {
		this.subscriptions.push(
			this.store
				.select(getSpecificExternalInteractivity(this.externalInteractivityId))
				.subscribe(async (externalInteractivity) => {
					if (!_.isEqual(this.externalInteractivity, externalInteractivity)) {
						this.externalInteractivity = externalInteractivity;
					}

					if (this.externalInteractivity) {
						const selectedUrl = await firstValueFrom(this.store.select(selectUrl));
						if (!this.componentMode) {
							this.store.dispatch(
								GetHeaderState({
									payload: {
										item: this.externalInteractivity,
										title: this.externalInteractivity.name,
										type: TypeHeader.EXTERNAL_INTERACTIVITY
									}
								})
							);
						} else if (!selectedUrl.includes("session")) {
							this.store.dispatch(GetHeaderTitle({ payload: this.externalInteractivity.name }));
						}
					}
				})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
				}
			})
		);
	}

	/**
	 * Go back to externals interactivity list
	 */
	goBackToList() {
		if (this.componentMode) {
			this.changeView.emit({ segmentType: "externalsInteractivity", type: "externalsInteractivity", uid: "" });
		} else {
			this.SUtility.callGoBackOnHeader();
		}
	}
}
