import { Injectable } from "@angular/core";
import { orderBy, where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetAllPosts } from "../actions/feed-news.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IComment, ILike, IPost, IReactionEmoji } from "../interfaces/feed-news.interfaces";
import { checkSameEvent } from "../selectors/feed-news.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";
import * as _ from "lodash-es";

@Injectable({
	providedIn: "root"
})
export class FeedNewsService {
	postsSub: Subscription;

	constructor(
		private SFirestore: FirestoreService,
		private store: Store,
		private STranslate: TranslateService
	) {}

	unsubscribeAll() {
		[this.postsSub].forEach((sub) => {
			if (sub) sub.unsubscribe();
		});
	}

	getReactionEmojis(): IReactionEmoji[] {
		return [
			{
				name: "thumb-up",
				text: this.STranslate.instant("feed_news.thumb-up"),
				selected: false
			},
			{
				name: "heart",
				text: this.STranslate.instant("feed_news.heart"),
				selected: false
			},
			{
				name: "clap",
				text: this.STranslate.instant("feed_news.clap"),
				selected: false
			},
			{
				name: "idea",
				text: this.STranslate.instant("feed_news.idea"),
				selected: false
			},
			{
				name: "thinking",
				text: this.STranslate.instant("feed_news.thinking"),
				selected: false
			}
		];
	}

	/**
	 * Getting posts
	 * @param eventId
	 * @param moduleId
	 * @returns
	 */
	getPosts(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.postsSub && !this.postsSub.closed) {
					return;
				} else if (!sameEvent && this.postsSub && !this.postsSub.closed) {
					this.postsSub.unsubscribe();
				}

				this.postsSub = this.SFirestore.collectionGroupValueChangesDocuments("feed-news", [
					where("eventId", "==", eventId)
				]).subscribe((posts: IPost[]) => {
					this.store.dispatch(GetAllPosts({ payload: posts, eventId: eventId }));
					this.store
						.select(getInitSpecificEventDatasPart("initFeedNews"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initFeedNews", payload: true })
								);
							}
						});
				});
			});
	}

	/**
	 * Create a new post
	 * @param eventId
	 * @param moduleId
	 * @param post
	 * @returns
	 */
	createPost(eventId: string, moduleId: string, post: IPost) {
		const id = !post.uid ? this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/feed-news`) : post.uid;
		post.uid = id;
		return this.SFirestore.setDocument(`events/${eventId}/modules/${moduleId}/feed-news/${post.uid}`, post);
	}

	/**
	 * Update post
	 * @param eventId
	 * @param moduleId
	 * @param post
	 * @returns
	 */
	updatePost(eventId: string, moduleId: string, postId: string, post: IPost) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/feed-news/${postId}`,
			_.omit(post, [
				"eventId",
				"moduleId",
				"uid",
				"description",
				"imageUrl",
				"videoUrl",
				"videoSource",
				"user",
				"name",
				"photoUrl",
				"type",
				"creationDate"
			])
		);
	}

	updatePartOfPost(eventId: string, moduleId: string, postId: string, datas: any) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/feed-news/${postId}`,
			_.omit(datas, [
				"eventId",
				"moduleId",
				"uid",
				"description",
				"videoUrl",
				"videoSource",
				"user",
				"name",
				"photoUrl",
				"type",
				"creationDate"
			])
		);
	}

	/**
	 * Delete post
	 * @param eventId
	 * @param moduleId
	 * @param postUid
	 * @returns
	 */
	deletePost(eventId: string, moduleId: string, postUid: string) {
		return this.SFirestore.deleteDocument(`events/${eventId}/modules/${moduleId}/feed-news/${postUid}`);
	}

	/**
	 * Get event user likes
	 * @param eventId
	 * @param moduleId
	 * @param eventUserId
	 * @returns
	 */
	getEventUserLikes(eventId: string, moduleId: string, eventUserId: string) {
		return this.SFirestore.collectionGroupValueChangesDocuments("feed-news-likes", [
			where("eventId", "==", eventId),
			where("moduleId", "==", moduleId),
			where("userId", "==", eventUserId)
		]);
	}

	/**
	 * Get likes of a post
	 * @param eventId
	 * @param moduleId
	 * @param postId
	 * @returns
	 */
	getLikesOfPost(eventId: string, moduleId: string, postId: string) {
		return this.SFirestore.valueChangesDocuments(
			`events/${eventId}/modules/${moduleId}/feed-news/${postId}/feed-news-likes`,
			[]
		);
	}

	/**
	 * Get likes of a post
	 * @param eventId
	 * @param moduleId
	 * @param postId
	 * @param userId
	 * @returns
	 */
	getLikesOfPostForUser(eventId: string, moduleId: string, postId: string, userId: string) {
		return this.SFirestore.valueChangesDocuments(
			`events/${eventId}/modules/${moduleId}/feed-news/${postId}/feed-news-likes`,
			[where("userId", "==", userId)]
		);
	}

	/**
	 * Create a like on a feed news
	 * @param eventId
	 * @param moduleId
	 * @param postId
	 * @param like
	 * @returns
	 */
	createLike(eventId: string, moduleId: string, postId: string, like: ILike) {
		const id = like.uid
			? like.uid
			: this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/feed-news/${postId}/feed-news-likes`);
		like.uid = id;

		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/feed-news/${postId}/feed-news-likes/${like.uid}`,
			like
		);
	}

	/**
	 * Delete a like on a feed news
	 * @param eventId
	 * @param moduleId
	 * @param postId
	 * @param like
	 * @returns
	 */
	deleteLike(eventId: string, moduleId: string, postId: string, like: ILike) {
		return this.SFirestore.deleteDocument(
			`events/${eventId}/modules/${moduleId}/feed-news/${postId}/feed-news-likes/${like.uid}`
		);
	}

	/**
	 * Update a like on a feed news
	 * @param eventId
	 * @param moduleId
	 * @param postId
	 * @param like
	 * @returns
	 */
	updateLike(eventId: string, moduleId: string, postId: string, like: ILike) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/feed-news/${postId}/feed-news-likes/${like.uid}`,
			like
		);
	}

	/**
	 * Get event user comments
	 * @param eventId
	 * @param moduleId
	 * @returns
	 */
	getEventUserComments(eventId: string, moduleId: string) {
		return this.SFirestore.collectionGroupValueChangesDocuments("feed-news-comments", [
			where("eventId", "==", eventId),
			where("moduleId", "==", moduleId)
		]);
	}

	/**
	 * Get comments of a post
	 * @param eventId
	 * @param moduleId
	 * @param postId
	 * @returns
	 */
	getCommentsOfPost(eventId: string, moduleId: string, postId: string) {
		return this.SFirestore.valueChangesDocuments(
			`events/${eventId}/modules/${moduleId}/feed-news/${postId}/feed-news-comments`,
			[orderBy("creationDate")]
		);
	}

	/**
	 * Create a comment on a feed news
	 * @param eventId
	 * @param moduleId
	 * @param postId
	 * @param comment
	 * @returns
	 */
	createComment(eventId: string, moduleId: string, postId: string, comment: IComment) {
		const id = comment.uid
			? comment.uid
			: this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/feed-news/${postId}/feed-news-comments`);
		comment.uid = id;

		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/feed-news/${postId}/feed-news-comments/${comment.uid}`,
			comment
		);
	}

	/**
	 * Delete a comment on a feed news
	 * @param eventId
	 * @param moduleId
	 * @param postId
	 * @param comment
	 * @returns
	 */
	deleteComment(eventId: string, moduleId: string, postId: string, comment: IComment) {
		return this.SFirestore.deleteDocument(
			`events/${eventId}/modules/${moduleId}/feed-news/${postId}/feed-news-comments/${comment.uid}`
		);
	}
}
