import { Component, Input, OnDestroy } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { IEvent, IEventUser, IFullCustomField, IModule, IModuleCustomField, ISheet } from "src/app/shared/interfaces";
import { CustomFieldsService } from "src/app/shared/services";

@Component({
	selector: "app-custom-fields-tags",
	templateUrl: "./custom-fields-tags.component.html",
	styleUrls: ["./custom-fields-tags.component.scss"],
	standalone: false
})
export class CustomFieldsTagsComponent implements OnDestroy {
	subscriptions: Subscription[] = [];

	@Input() event: IEvent;
	@Input() module: IModule;
	@Input() modulesCustomsFields: IModuleCustomField[] = [];
	@Input() eventUserProfile: IEventUser | ISheet;
	@Input() customFields: IFullCustomField[] = [];
	@Input() fontSize: number = -1;

	@Input() currentLanguage: string;
	@Input() isPrivateMode: boolean = false;

	typeCustomFields = TypeCustomFields;
	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		public SCustomFields: CustomFieldsService
	) {
		if ((this.platform.is("mobile") && window.innerWidth < 768) || window.innerWidth < 768) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}
}
