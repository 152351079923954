<div *ngIf="event && module && !showComments" class="feed-news-container scroll">
	<ion-content role="feed">
		<form [formGroup]="createPostForm" (ngSubmit)="createPost()" *ngIf="module.options.public">
			<ion-card class="custom-card">
				<ion-card-header>
					<ion-textarea
						autocapitalize="sentences"
						formControlName="description"
						class="description-input"
						id="textArea"
						placeholder="{{ 'feed_news.description_plc' | translate }}"
					></ion-textarea>
				</ion-card-header>

				<ion-card-content>
					<div>
						<div *ngIf="myImg">
							<img id="myImg" [src]="myImg" />
						</div>
						<ion-spinner
							name="dots"
							class="ion-color"
							[style.color]="event.styling.menuColor"
							*ngIf="loaderMyImg"
						>
						</ion-spinner>
					</div>
					<div class="bottom-form">
						<a
							(click)="getImageToSend()"
							class="btn-attach-picture"
							[style.color]="event.styling.titleColor"
						>
							<ion-icon class="cursor-pointer" src="assets/icon/Image.svg"></ion-icon>
						</a>

						<div class="separator-vertical"></div>

						<button
							(click)="createPost()"
							shape="round"
							class="btn-publish btn-validate-primary"
							[disabled]="loaderPost || (!createPostForm.valid && !myImg)"
						>
							<ion-icon src="assets/icon/Send.svg"></ion-icon>
							<span>{{ "buttons.post" | translate }}</span>
						</button>
						<ion-spinner
							name="dots"
							class="ion-color"
							[style.color]="event.styling.menuColor"
							*ngIf="loaderPost"
						>
						</ion-spinner>
					</div>
				</ion-card-content>
			</ion-card>
		</form>

		<ion-list
			lines="none"
			*ngIf="postsFiltered.length > 0"
			[style.padding]="platform.is('ios') ? '0 0 50px 0' : '0'"
		>
			<ng-container *ngFor="let post of getPagintedPosts(); trackBy: trackByFn">
				<ion-item *ngIf="post.active" role="article" style="margin: 15px 0px">
					<ion-card class="custom-card">
						<ion-card-header class="header-post-item">
							<ion-item>
								<ion-avatar (click)="redirectProfile(post)" slot="start">
									<img
										[src]="post.user.photoUrl"
										*ngIf="
											(!post.useUserInfos || post.useUserInfos === undefined) &&
											post.user &&
											post.user.photoUrl
										"
									/>
									<img
										*ngIf="post.useUserInfos && post['baseEventUser']"
										[src]="
											post['baseEventUser']?.photoUrl
												? post['baseEventUser']?.photoUrl
												: 'assets/icon/User2.svg'
										"
									/>
								</ion-avatar>

								<ion-label class="ion-text-wrap">
									<h2
										class="name-post"
										[style.color]="event.styling.titleColor"
										(click)="redirectProfile(post)"
									>
										{{
											(!post.useUserInfos || post.useUserInfos === undefined) &&
											post.user &&
											post.user.name
												? post?.user.name
												: post?.["baseEventUser"]?.name
										}}
									</h2>

									<p class="post-date">{{ getDate(post.creationDate) }}</p>
								</ion-label>
								<ion-icon
									*ngIf="user && post.user && post.user.uid === user.uid"
									name="trash-outline"
									(click)="openConfirmDeleteAlert(post)"
									class="cursor-pointer"
								></ion-icon>
								<ion-icon
									slot="end"
									*ngIf="post.fixedTop === 0"
									src="assets/icon/push-pin.svg"
									[style.color]="event.styling.titleColor"
								></ion-icon>
								<ion-icon
									*ngIf="
										eventUser && eventUser.uid !== post.user.uid && module.options.activateReport
									"
									slot="end"
									name="alert"
									color="danger"
									class="cursor-pointer"
									(click)="openReportAlert(post)"
								></ion-icon>
							</ion-item>
						</ion-card-header>
						<ion-card-content class="post-card-content">
							<p
								*ngIf="post.description"
								class="post-description"
								style="white-space: pre-line"
								[style.color]="event.styling.contentTextColor"
								[innerHTML]="post.description | linky | safeHtml"
							></p>

							<ion-img
								*ngIf="post.imageUrl"
								class="post-img"
								[src]="post.imageUrl"
								(click)="openImage(post.imageUrl)"
							>
							</ion-img>

							<!-- Videos -->
							<!-- Youtube videos -->
							<div
								*ngIf="post.videoSource === typeVideoSource.YOUTUBE"
								class="video-bloc"
								[style.height.px]="getVideoHeightFromIframe(post.videoUrl)"
							>
								<iframe
									[attr.id]="post.uid"
									[width]="getVideoWidth()"
									[height]="getVideoHeightFromIframe(post.videoUrl)"
									[src]="extractUrlFromIframe(post.videoUrl) | safeHtml: 'resourceUrl'"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>

							<!-- Vimeo videos -->
							<div
								*ngIf="post.videoSource === typeVideoSource.VIMEO"
								class="video-bloc"
								[style.height.px]="getVideoHeightFromIframe(post.videoUrl)"
							>
								<iframe
									[attr.id]="post.uid"
									[width]="getVideoWidth()"
									[height]="getVideoHeightFromIframe(post.videoUrl)"
									[src]="extractUrlFromIframe(post.videoUrl) | safeHtml: 'resourceUrl'"
									frameborder="0"
									allow="autoplay; fullscreen"
									allowfullscreen
								></iframe>
							</div>

							<!-- Dailymotion videos -->
							<div *ngIf="post.videoSource === typeVideoSource.DAILYMOTION" class="video-bloc">
								<iframe
									[attr.id]="post.uid"
									[width]="getVideoWidth()"
									[height]="getVideoHeight()"
									[src]="extractUrlFromIframe(post.videoUrl) | safeHtml: 'resourceUrl'"
									frameborder="0"
									allow="autoplay; fullscreen"
									allowfullscreen
								></iframe>
							</div>

							<!-- Facebook videos -->
							<div *ngIf="post.videoSource === typeVideoSource.FACEBOOK" class="video-bloc">
								<iframe
									[attr.id]="post.uid"
									[width]="getVideoWidth()"
									[height]="getVideoHeight()"
									[src]="extractUrlFromIframe(post.videoUrl) | safeHtml: 'resourceUrl'"
									frameborder="0"
									allow="autoplay; fullscreen"
									allowfullscreen
								></iframe>
							</div>

							<!-- Other video -->
							<div *ngIf="post.videoSource === typeVideoSource.OTHER" class="video-bloc">
								<iframe
									[attr.id]="post.uid"
									[class]="isMobile ? 'video-player-global-mobile' : 'video-player-global'"
									[width]="getVideoWidth()"
									[height]="getVideoHeight()"
									[src]="post.videoUrl | safeHtml: 'resourceUrl'"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>

							<div class="divider"></div>

							<ion-grid>
								<ion-row class="align-footer-post">
									<ion-col *ngIf="module.options.comments" size="12">
										<app-feed-news-react
											[post]="post"
											[event]="event"
											[eventUser]="eventUser"
											(openComments)="viewComments(post.uid)"
										>
										</app-feed-news-react>
									</ion-col>
								</ion-row>
							</ion-grid>
							<div class="divider"></div>
						</ion-card-content>
					</ion-card>
				</ion-item>
			</ng-container>
		</ion-list>
		<ion-infinite-scroll #ionInfiniteScroll threshold="150px" position="bottom" (ionInfinite)="morePosts($event)">
			<ion-infinite-scroll-content loadingSpinner="dots"> </ion-infinite-scroll-content>
		</ion-infinite-scroll>

		<div *ngIf="postsFiltered.length === 0">
			<div class="container">
				<span class="wait-content" [style.color]="event.styling.titleColor">{{
					"texts.waiting_for_content" | translate
				}}</span>
				<ion-icon
					src="assets/images/emptycontent/FILDACTU.svg"
					[style.color]="
						event.styling.menuColor.includes('linear')
							? event.styling.menuColor.split(',')[1]
							: event.styling.menuColor
					"
				>
				</ion-icon>
				<div class="text-empty-content default-color" [style.color]="event.styling.contentTextColor">
					<span class="title"> {{ "feed_news.empty_content_title" | translate }}</span>
					<span>{{ "feed_news.empty_content_text" | translate }}</span>
				</div>
			</div>
		</div>
	</ion-content>
</div>

<!-- Showing comments of a post -->
<div *ngIf="showComments" class="feed-news-container scroll">
	<ion-content role="feed">
		<app-feed-news-comments
			[eventId]="eventId"
			[moduleId]="moduleId"
			[postId]="postId"
			[userId]="user.uid"
			(closeComments)="showComments = $event"
		></app-feed-news-comments>
	</ion-content>
</div>
<!-- </ion-content> -->
