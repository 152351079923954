/* eslint-disable max-len */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { UntypedFormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Browser } from "@capacitor/browser";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription, firstValueFrom, of } from "rxjs";
import { take } from "rxjs/operators";
import { FormFieldsComponent } from "src/app/content/pages/event/components/modals/register-form/components/form-fields/form-fields.component";
import { environment } from "src/environments/environment";
import { GetAllBaseCustomFields, GetAllModulesCustomFields } from "../actions/generics-modules-data.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { TypeCustomFields, TypeCustomModule } from "../enums/type-custom-fields";
import { FormItemType } from "../enums/type-register-forms";
import { IEvent, IEventUser, ILanguage, IModule, ISheet } from "../interfaces";
import {
	IAddress,
	ICustomField,
	ICustomFieldData,
	IField,
	IFile,
	IFilterOptions,
	IFilteredItemFormat,
	IFullCustomField,
	IImage,
	IModuleCustomField,
	IPhoneNumber
} from "../interfaces/custom-fields.interfaces";
import { IRegisterFormItem, IRegisterFormSection } from "../interfaces/register-form.interfaces";
import { checkSameEvent } from "../selectors/generics-modules-data.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";
import { StorageService } from "./storage.service";
import { UtilityService } from "./utility.service";
import { IFilter, IFilters } from "../interfaces/filters.interfaces";

@Injectable({
	providedIn: "root"
})
export class CustomFieldsService {
	baseCustomsFieldsSub: Subscription;
	modulesCustomsFieldsSub: Subscription;

	constructor(
		private SFirestore: FirestoreService,
		private SStorage: StorageService,
		private STranslate: TranslateService,
		private http: HttpClient,
		private store: Store,
		private storage: StorageService,
		private snackbar: MatSnackBar,
		private SUtility: UtilityService
	) {}

	/**
	 * Get all custom fields of custom fields module manager
	 * @param eventId
	 * @returns
	 */
	getBaseCustomFields(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "baseCustomFields", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.baseCustomsFieldsSub && !this.baseCustomsFieldsSub.closed) {
					return;
				} else if (!sameEvent && this.baseCustomsFieldsSub && !this.baseCustomsFieldsSub.closed) {
					this.baseCustomsFieldsSub.unsubscribe();
				}

				this.baseCustomsFieldsSub = this.SFirestore.collectionGroupValueChangesDocuments("custom-fields", [
					where("eventId", "==", eventId),
					where("moduleType", "==", TypeCustomModule.BASE)
				]).subscribe((customFields: ICustomField[]) => {
					this.store.dispatch(GetAllBaseCustomFields({ payload: customFields, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initBaseCustomFields"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initBaseCustomFields", payload: true })
								);
							}
						});
				});
			});
	}

	/**
	 * Get all custom fields of custom fields modules
	 * @param eventId
	 * @returns
	 */
	getModulesCustomFields(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "modulesCustomFields", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.modulesCustomsFieldsSub && !this.modulesCustomsFieldsSub.closed) {
					return;
				} else if (!sameEvent && this.modulesCustomsFieldsSub && !this.modulesCustomsFieldsSub.closed) {
					this.modulesCustomsFieldsSub.unsubscribe();
				}

				this.modulesCustomsFieldsSub = this.SFirestore.collectionGroupValueChangesDocuments("custom-fields", [
					where("eventId", "==", eventId),
					where("moduleType", "==", TypeCustomModule.MODULE)
				]).subscribe((customFields: IModuleCustomField[]) => {
					this.store.dispatch(GetAllModulesCustomFields({ payload: customFields, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initModulesCustomFields"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initModulesCustomFields", payload: true })
								);
							}
						});
				});
			});
	}

	/**
	 * updateModuleCustomField
	 * @param eventId
	 * @param moduleId
	 * @param customField
	 */
	updateModuleCustomFieldCount(
		eventId: string,
		moduleId: string,
		customField: ICustomField,
		lang: string,
		changeOperations: string[],
		type?: "optionsCount" | "tempOptionsCount" | "optionsCountUids" | "tempOptionsCountUids",
		eventUserUid?: string
	) {
		const headers = new HttpHeaders();
		headers.append("Accept", "application/json");
		headers.append("Content-Type", "application/json");
		return firstValueFrom(
			this.http.post(
				environment.platform.apiV2BaseUrl
					? "https://customFields-updateCustomFieldSelectOptionNumber" + environment.platform.apiV2BaseUrl
					: environment.platform.apiBaseUrl + "customFields-updateCustomFieldSelectOptionNumber",
				{
					eventId: eventId,
					moduleId: moduleId,
					customField: customField,
					lang: lang,
					changeOperations: changeOperations,
					type: type,
					eventUserUid: eventUserUid
				},
				{ headers: headers, observe: "body" }
			)
		);
	}

	unsubscribeAll() {
		[this.baseCustomsFieldsSub, this.modulesCustomsFieldsSub].forEach((sub) => {
			if (sub) sub.unsubscribe();
		});
	}

	/**
	 * Check if a value exist for a specific custom field
	 * @param type
	 * @param customData
	 * @param language
	 * @returns
	 */
	checkValueCustomField(type: number, customData: ICustomFieldData, language: string): boolean {
		if (type === TypeCustomFields.URL || type === TypeCustomFields.EMAIL) {
			return customData.field.text ? true : false;
		} else if (
			type === TypeCustomFields.TEXT ||
			type === TypeCustomFields.SELECT ||
			type === TypeCustomFields.HMTL
		) {
			return customData.field.multiLanguageText &&
				customData.field.multiLanguageText[language] &&
				customData.field.multiLanguageText[language] !== ""
				? true
				: false;
		} else if (type === TypeCustomFields.MULTI_SELECT) {
			return customData.field.multiLanguageSelectArray && customData.field.multiLanguageSelectArray.length > 0
				? true
				: false;
		} else if (type === TypeCustomFields.MULTI_TEXT) {
			return customData.field.multiLanguageTextArray &&
				customData.field.multiLanguageTextArray[language].length > 0
				? true
				: false;
		} else if (type === TypeCustomFields.NUMERIC) {
			return customData.field.numeric ? true : false;
		} else if (type === TypeCustomFields.DATE) {
			return customData.field.date ? true : false;
		} else if (type === TypeCustomFields.IMAGE) {
			return customData.field.image && customData.field.image.url ? true : false;
		} else if (type === TypeCustomFields.FILE) {
			return customData.field.file && customData.field.file.url ? true : false;
		} else if (type === TypeCustomFields.PHONE) {
			return customData.field.phoneNumber && customData.field.phoneNumber.number ? true : false;
		} else if (type === TypeCustomFields.MODULE) {
			return customData.field.module && customData.field.module.items.length > 0 ? true : false;
		} else if (type === TypeCustomFields.ADDRESS) {
			return customData.field.address.fullAddress ? true : false;
		} else if (type === TypeCustomFields.CHECKBOX) {
			return customData.field.checkbox ? true : false;
		} else if (type === TypeCustomFields.COUNTRY) {
			return customData.field.country ? true : false;
		}

		return false;
	}

	/**
	 * Get value for custom field
	 * @param type
	 * @param customData
	 * @param language
	 * @returns
	 */
	getValueForCustomField(
		type: number,
		customData: ICustomFieldData,
		eventLanguage: string,
		eventUserLanguage: string
	) {
		if (!customData) return null;

		if (type === TypeCustomFields.URL || type === TypeCustomFields.EMAIL) {
			return customData.field.text ? customData.field.text : "";
		} else if (type === TypeCustomFields.TEXT || type === TypeCustomFields.HMTL) {
			return customData.field.multiLanguageText &&
				customData.field.multiLanguageText?.[eventUserLanguage] &&
				customData.field.multiLanguageText?.[eventUserLanguage] !== ""
				? customData.field.multiLanguageText[eventUserLanguage]
				: customData.field.multiLanguageText?.[eventLanguage];
		} else if (type === TypeCustomFields.SELECT) {
			return customData.field.multiLanguageText ? customData.field.multiLanguageText : null;
		} else if (type === TypeCustomFields.MULTI_SELECT) {
			return customData.field.multiLanguageSelectArray && customData.field.multiLanguageSelectArray.length > 0
				? customData.field.multiLanguageSelectArray
				: [];
		} else if (type === TypeCustomFields.NUMERIC) {
			return customData.field.numeric ? customData.field.numeric : 0;
		} else if (type === TypeCustomFields.DATE) {
			return customData.field.date ? customData.field.date?.fullDateISO : "";
		} else if (type === TypeCustomFields.IMAGE) {
			return customData.field.image && customData.field.image.url ? customData.field.image.name : "";
		} else if (type === TypeCustomFields.FILE) {
			return customData.field.file && customData.field.file.url ? customData.field.file.url : "";
		} else if (type === TypeCustomFields.PHONE) {
			return customData.field.phoneNumber && customData.field.phoneNumber ? customData.field.phoneNumber : "";
		} else if (type === TypeCustomFields.MULTI_TEXT) {
			return customData.field.multiLanguageTextArray && customData.field.multiLanguageTextArray[eventUserLanguage]
				? customData.field.multiLanguageTextArray[eventUserLanguage]
				: customData.field.multiLanguageTextArray[eventLanguage];
		} else if (type === TypeCustomFields.ADDRESS) {
			return customData.field.address ? customData.field.address.fullAddress : "";
		} else if (type === TypeCustomFields.CHECKBOX) {
			return customData.field.checkbox;
		} else if (type === TypeCustomFields.COUNTRY) {
			return customData.field.country ? customData.field.country : "";
		}
	}

	/* ------ CUSTOM FIELDS UTILITY FUNCTIONS ------ */

	/* ------ END OF "CUSTOM FIELDS UTILITY FUNCTIONS" ------ */

	/**
	 *getImageByUrl
	 * @param formCustomFields
	 * @param customField
	 * @param language
	 * @param isValidURL
	 */
	getImageByUrl(
		formCustomFields: UntypedFormGroup,
		customField: IFullCustomField,
		language: string,
		isValidURL: boolean
	) {
		const imageUrl = formCustomFields.get(customField.baseSettings.name[language] + "_URL").value?.toString();
		const urlRegex = /(https?:\/\/.*\.(?:png|jpg))/gm;
		if (urlRegex.test(imageUrl)) {
			const urlSplit1 = imageUrl.split(".");
			const urlSplit2 = imageUrl.split("/");
			const formatImage = imageUrl.split(".")[urlSplit1.length - 1];
			const imageName = imageUrl.split("/")[urlSplit2.length - 1];
			customField.fieldDatas.field.image.name = imageName;
			customField.fieldDatas.field.image.lastModified = Date.now();
			customField.fieldDatas.field.image.lastModifiedDate = DateTime.fromMillis(Date.now()).toString();
			customField.fieldDatas.field.image.format = formatImage;
			customField.fieldDatas.field.image.size = 0;
			customField.fieldDatas.field.image.url = imageUrl;

			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			isValidURL = true;
		}
	}

	/**
	 * importCustomFieldFileImage
	 * @description Import file or image from user computer and store it on the storage
	 * @param event
	 * @param customField
	 */
	importCustomFieldFileImage(
		event: IEvent,
		module: IModule,
		currentEventUserProfile: IEventUser,
		formCustomFields: UntypedFormGroup,
		event$: any,
		customField: IFullCustomField,
		language: string
	) {
		const item: File = event$.target.files[0];
		if (currentEventUserProfile) {
			// eslint-disable-next-line max-len
			const path = `/events/${event.uid}/modules/${module.uid}/event-users/${currentEventUserProfile.uid}/custom-fields/${customField.baseSettings.name[language]}_item`;

			this.storage.uploadFile(item, path, item && item.type ? item.type : "image/png").then((url) => {
				if (url) {
					if (customField.baseSettings.type === TypeCustomFields.FILE) {
						customField.fieldDatas.field.file.name = item.name;
						customField.fieldDatas.field.file.lastModified = Date.now();
						customField.fieldDatas.field.file.lastModifiedDate = DateTime.fromMillis(Date.now()).toString();
						customField.fieldDatas.field.file.type = item.type;
						customField.fieldDatas.field.file.size = item.size;
						customField.fieldDatas.field.file.url = url;
					} else {
						// IMAGE
						// Update the url text field
						formCustomFields.get(customField.baseSettings.name[language] + "_URL").patchValue(url);

						customField.fieldDatas.field.image.name = item.name;
						customField.fieldDatas.field.image.lastModified = Date.now();
						customField.fieldDatas.field.image.lastModifiedDate = DateTime.fromMillis(
							Date.now()
						).toString();
						customField.fieldDatas.field.image.format = item.type;
						customField.fieldDatas.field.image.size = item.size;
						customField.fieldDatas.field.image.url = url;
					}
				} else {
					this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
				}
			});
		}
	}

	/**
	 * deleteCustomFieldFieldImage
	 * @description delete custom field input file
	 * @param customField
	 */
	deleteCustomFieldFileImage(customField: IFullCustomField) {
		customField.baseSettings.type === TypeCustomFields.FILE
			? (customField.fieldDatas.field.file = {
					lastModified: 0,
					lastModifiedDate: "",
					name: "",
					type: "",
					url: "",
					size: 0
				})
			: (customField.fieldDatas.field.image = {
					lastModified: 0,
					format: "",
					lastModifiedDate: "",
					name: "",
					url: "",
					size: 0,
					isExternalUrl: false
				});
	}

	/**
	 * openDocument
	 * @param document
	 */
	async openDocument(file: IFile) {
		try {
			await Browser.open({
				url: file.url
			});
		} catch (error) {
			this.SUtility.presentToast(
				this.STranslate.instant("errors.error.error-open-document"),
				3000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * getFormatedAddress
	 * @param customField
	 * @param address
	 */
	getFormatedAddress(customField: IFullCustomField, event): IAddress {
		switch (event.types[0]) {
			case "street_address":
				return {
					fullAddress: event.formatted_address,
					number: event.address_components[0] ? event.address_components[0].long_name : "",
					street: event.address_components[1] ? event.address_components[1].long_name : "",
					city: event.address_components[2] ? event.address_components[2].long_name : "",
					state: event.address_components[3] ? event.address_components[3].long_name : "",
					region: event.address_components[4] ? event.address_components[4].long_name : "",
					country: event.address_components[5] ? event.address_components[5].long_name : "",
					postalCode: event.address_components[6] ? event.address_components[6].long_name : "",
					url: event.url
				} as IAddress;
				break;

			case "route":
				return {
					fullAddress: event.formatted_address,
					street: event.address_components[0] ? event.address_components[0].long_name : "",
					city: event.address_components[1] ? event.address_components[1].long_name : "",
					state: event.address_components[2] ? event.address_components[2].long_name : "",
					region: event.address_components[3] ? event.address_components[3].long_name : "",
					country: event.address_components[4] ? event.address_components[4].long_name : "",
					postalCode: event.address_components[5] ? event.address_components[5].long_name : "",
					url: event.url
				} as IAddress;
				break;

			case "town_square":
				return {
					fullAddress: event.formatted_address,
					street: event.address_components[0] ? event.address_components[0].long_name : "",
					city: event.address_components[1] ? event.address_components[1].long_name : "",
					state: event.address_components[2] ? event.address_components[2].long_name : "",
					region: event.address_components[3] ? event.address_components[3].long_name : "",
					country: event.address_components[4] ? event.address_components[4].long_name : "",
					postalCode: event.address_components[5] ? event.address_components[5].long_name : "",
					url: event.url
				} as IAddress;
				break;

			case "locality":
				return {
					fullAddress: event.formatted_address,
					city: event.address_components[0] ? event.address_components[0].long_name : "",
					state: event.address_components[1] ? event.address_components[1].long_name : "",
					region: event.address_components[2] ? event.address_components[2].long_name : "",
					country: event.address_components[3] ? event.address_components[3].long_name : "",
					url: event.url
				} as IAddress;
				break;

			case "country":
				return {
					fullAddress: event.formatted_address,
					country: event.address_components[0] ? event.address_components[0].long_name : "",
					url: event.url
				} as IAddress;
				break;

			default:
				throw new Error("Invalid address");
		}
	}

	/**
	 * getPlacePredictions
	 */
	getPlacePredictions(value: string) {
		const headers = new HttpHeaders();
		headers.append("Accept", "application/json");
		headers.append("Content-Type", "application/json");
		return firstValueFrom(
			this.http.post(
				environment.platform.apiV2BaseUrl
					? "https://customFields-getPlacePredictions" + environment.platform.apiV2BaseUrl
					: environment.platform.apiBaseUrl + "customFields-getPlacePredictions",
				{
					queryText: value
				},
				{ headers: headers, observe: "body" }
			)
		);
	}

	/**
	 * Build searchDatas array for search custom fields
	 * @param customFields
	 * @param languages
	 * @returns
	 */
	getAllDatasOnArrayForSearch(
		customFields: IFullCustomField[],
		groups: string[],
		checkinsChecked: string[],
		languages: string[]
	) {
		let searchDatas: string[] = [];

		for (let i = 0; i < customFields.length; i++) {
			const customField = customFields[i];
			const customDatas = customField.fieldDatas;
			for (let iL = 0; iL < languages.length; iL++) {
				const language = languages[iL];
				const values = this.getValueForCustomField(
					customField.baseSettings.type,
					customDatas,
					language,
					language
				);
				if (customField.baseSettings.type === TypeCustomFields.MULTI_SELECT) {
					values.forEach((value) => {
						searchDatas.push(`${customField.baseSettings.uid}|${value}|${language}`);
					});
				} else {
					searchDatas.push(`${customField.baseSettings.uid}|${values}|${language}`);
				}
			}
		}

		if (groups.length > 0) {
			searchDatas = searchDatas.concat(groups.map((groupId) => `group|${groupId}`));
		}

		if (checkinsChecked.length > 0) {
			searchDatas = searchDatas.concat(checkinsChecked.map((checkinId) => `checkin-checked|${checkinId}`));
		}

		return _.uniq(searchDatas);
	}

	/**
	 * getCustomFieldsCardDatas
	 * @param eventUser
	 * @returns
	 */
	getCustomFieldsCardDatas(
		eventUser: IEventUser | ISheet,
		module: IModule,
		customFields: IFullCustomField[],
		defaultLanguage: string
	) {
		const filteredCustomFields = _.cloneDeep(customFields).filter((cus) =>
			this.canExportCustomFieldBasedOnModuleAndEventUserOptions(cus, module, eventUser)
		);

		return {
			baseInformations: {
				eventUserJob: eventUser.customFields.find((cus) =>
					filteredCustomFields.find(
						(computedCus) =>
							computedCus.baseSettings.uid === cus.uid &&
							((computedCus.baseSettings.type === TypeCustomFields.TEXT &&
								computedCus.baseSettings.name["EnUS"] === "Job") ||
								computedCus.baseSettings?.baseTextType === 0)
					)
				)?.field?.multiLanguageText,
				eventUserCompany: eventUser.customFields.find((cus) =>
					filteredCustomFields.find(
						(computedCus) =>
							computedCus.baseSettings.uid === cus.uid &&
							((computedCus.baseSettings.type === TypeCustomFields.TEXT &&
								computedCus.baseSettings.name["EnUS"] === "Company") ||
								computedCus.baseSettings?.baseTextType === 1)
					)
				)?.field?.multiLanguageText,
				eventUserPhoneNumber:
					eventUser.customFields.find((cus) =>
						filteredCustomFields.find(
							(computedCus) =>
								computedCus.baseSettings.uid === cus.uid &&
								computedCus.baseSettings.type === TypeCustomFields.PHONE
						)
					)?.field?.phoneNumber?.internationalNumber ?? null,
				eventUserAddress:
					eventUser.customFields.find((cus) =>
						filteredCustomFields.find(
							(computedCus) =>
								computedCus.baseSettings.uid === cus.uid &&
								computedCus.baseSettings.type === TypeCustomFields.ADDRESS
						)
					)?.field?.address?.fullAddress ?? null
			},
			otherInformations: eventUser.customFields

				.map((cus) => {
					const correspondingCus = filteredCustomFields.find(
						(eventCus) => eventCus.baseSettings.uid === cus.uid
					);
					if (!correspondingCus) return null;
					else if (
						correspondingCus.baseSettings.type === TypeCustomFields.TEXT ||
						correspondingCus.baseSettings.type === TypeCustomFields.SELECT
					)
						return {
							fieldId: correspondingCus.baseSettings.uid,
							name: correspondingCus.baseSettings.name[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							],
							value: cus.field.multiLanguageText[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							]
						};
					else if (correspondingCus.baseSettings.type === TypeCustomFields.MULTI_SELECT)
						return {
							fieldId: correspondingCus.baseSettings.uid,
							name: correspondingCus.baseSettings.name[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							],
							value: cus.field.multiLanguageSelectArray
								.map((value) => value[eventUser["updatedSettings"]?.language ?? defaultLanguage])
								.join(", ")
						};
					else if (correspondingCus.baseSettings.type === TypeCustomFields.MULTI_TEXT) {
						return {
							fieldId: correspondingCus.baseSettings.uid,
							name: correspondingCus.baseSettings.name[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							],
							value: cus.field.multiLanguageTextArray[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							].join(", ")
						};
					} else if (correspondingCus.baseSettings.type === TypeCustomFields.DATE) {
						return {
							fieldId: correspondingCus.baseSettings.uid,
							name: correspondingCus.baseSettings.name[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							],
							value: cus.field.date.value ?? ""
						};
					} else if (correspondingCus.baseSettings.type === TypeCustomFields.COUNTRY) {
						return {
							fieldId: correspondingCus.baseSettings.uid,
							name: correspondingCus.baseSettings.name[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							],
							value: cus.field.country.name ?? ""
						};
					} else if (correspondingCus.baseSettings.type === TypeCustomFields.NUMERIC) {
						return {
							fieldId: correspondingCus.baseSettings.uid,
							name: correspondingCus.baseSettings.name[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							],
							value: cus.field.numeric ?? ""
						};
					} else if (
						correspondingCus.baseSettings.type === TypeCustomFields.URL ||
						correspondingCus.baseSettings.type === TypeCustomFields.EMAIL
					) {
						return {
							fieldId: correspondingCus.baseSettings.uid,
							name: correspondingCus.baseSettings.name[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							],
							value: cus.field.text ?? ""
						};
					} else if (correspondingCus.baseSettings.type === TypeCustomFields.PHONE) {
						return {
							fieldId: correspondingCus.baseSettings.uid,
							name: correspondingCus.baseSettings.name[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							],
							value: cus.field.phoneNumber?.internationalNumber ?? ""
						};
					} else if (correspondingCus.baseSettings.type === TypeCustomFields.ADDRESS) {
						return {
							fieldId: correspondingCus.baseSettings.uid,
							name: correspondingCus.baseSettings.name[
								eventUser["updatedSettings"]?.language ?? defaultLanguage
							],
							value: cus.field.address?.fullAddress ?? ""
						};
					}
				})
				.filter((cus) => cus !== null && cus !== undefined)
		};
	}

	/**
	 * canExportCustomFieldBasedOnModuleAndEventUserOptions
	 * @param customField
	 * @param module
	 * @param eventUser
	 * @returns
	 */
	canExportCustomFieldBasedOnModuleAndEventUserOptions(
		customField: IFullCustomField,
		module: IModule,
		eventUser: IEventUser | ISheet
	) {
		const excludedCustomFieldTypes = [
			TypeCustomFields.MODULE,
			TypeCustomFields.FILE,
			TypeCustomFields.IMAGE,
			TypeCustomFields.CHECKBOX,
			TypeCustomFields.HMTL
		];

		return eventUser
			? (!excludedCustomFieldTypes.includes(customField.baseSettings.type) &&
					((module.options.enableUserFieldsHideAbility &&
						((customField.moduleSettings.hiding.list && customField.moduleSettings.canBeTag) ||
							!customField.moduleSettings.hiding.list) &&
						eventUser["updatedSettings"] &&
						eventUser["updatedSettings"]?.fieldsVisibility &&
						eventUser["updatedSettings"]?.fieldsVisibility?.[customField?.baseSettings?.uid]) ||
						(eventUser["updatedSettings"] &&
							!eventUser["updatedSettings"]?.fieldsVisibility &&
							customField.moduleSettings.hiding.default === false))) ||
					(!module.options.enableUserFieldsHideAbility &&
						((customField.moduleSettings.hiding.list && customField.moduleSettings.canBeTag) ||
							!customField.moduleSettings.hiding.list))
			: !excludedCustomFieldTypes.includes(customField.baseSettings.type) &&
					((customField.moduleSettings.hiding.list && customField.moduleSettings.canBeTag) ||
						!customField.moduleSettings.hiding.list);
	}

	/**
	 * getCustomFieldType
	 * @param customFieldId
	 * @returns
	 */
	getCustomFieldType(customFields: IFullCustomField[], customFieldId: string): number {
		return customFields.find((cus) => cus.baseSettings.uid === customFieldId)?.baseSettings.type;
	}

	/************************************************************************************************
                                        Filters functions
    ************************************************************************************************/

	notSpecifiedTrad = {
		ArAR: "غير محدد",
		DeDE: "Niet gespecificeerd",
		EnUS: "Unspecified",
		EsES: "No especificado",
		FrFR: "Non spécifié",
		PtBR: "Não especificado"
	};

	getFiltersForModule = (
		module: IModule,
		eventUser: IEventUser,
		computedCustomFields: IFullCustomField[],
		tracks: { name: ILanguage; uid: string }[],
		locations: { name: string; uid: string }[],
		groups: { name: string; uid: string }[],
		currentLanguage: string,
		datas: any[] | null,
		datasGetType?: "mongo" | "firestore"
	) => {
		const filters: IFilters = {
			locations: [],
			tracks: [],
			groups: [],
			customFields: [],
			principalKey: ""
		};
		if (datasGetType === "firestore" || !datasGetType) {
			if (datas) {
				const filteredDatas = datas
					.filter((data) => data.visibility || data.visibility === undefined)
					.filter(
						(data) =>
							module &&
							module.options &&
							(!module.options.viewOnlyGroupsContent ||
								(module.options.viewOnlyGroupsContent &&
									eventUser &&
									data.groups.some((grpId) => eventUser.groups.includes(grpId))))
					);
				const filteredCustomFields = _.uniqBy(computedCustomFields, "baseSettings.uid");
				filters.customFields = this.buildCustomFieldsFilters(
					filteredDatas,
					filteredCustomFields,
					currentLanguage
				);
				filters.tracks =
					tracks.length > 0 ? this.buildTracksFilters(filteredDatas, tracks, currentLanguage) : [];
				filters.locations = locations.length > 0 ? this.buildLocationsFilters(filteredDatas, locations) : [];
				filters.groups = groups.length > 0 ? this.buildGroupsFilters(filteredDatas, groups) : [];
				filters["moduleId"] = module.uid;
				return of(filters);
			} else {
				return of(filters);
			}
		} else {
			if (datas) {
				const filteredDatas = datas
					.filter((data) => data.visibility || data.visibility === undefined)
					.filter(
						(data) =>
							module &&
							module.options &&
							(!module.options.viewOnlyGroupsContent ||
								(module.options.viewOnlyGroupsContent &&
									eventUser &&
									data.groups.some((grpId) => eventUser.groups.includes(grpId))))
					);
				const filteredCustomFields = _.uniqBy(computedCustomFields, "baseSettings.uid");
				filters.customFields = this.buildCustomFieldsFilters(
					filteredDatas,
					filteredCustomFields,
					currentLanguage,
					datasGetType
				);
				filters.tracks =
					tracks.length > 0 ? this.buildTracksFilters(filteredDatas, tracks, currentLanguage) : [];
				filters.locations = locations.length > 0 ? this.buildLocationsFilters(filteredDatas, locations) : [];
				filters.groups = groups.length > 0 ? this.buildGroupsFilters(filteredDatas, groups) : [];
				filters["moduleId"] = module.uid;
				return of(filters);
			} else {
				return of(filters);
			}
		}
	};

	buildCustomFieldsFilters = (
		datas: any[],
		computedCustomFields: IFullCustomField[],
		currentLanguage: string,
		datasGetType?: "mongo" | "firestore"
	) => {
		const customFieldsFilters: IFilteredItemFormat[] = [];
		computedCustomFields.forEach((customField) => {
			if (
				customField.moduleSettings.enableFilter &&
				(customField.baseSettings.type === TypeCustomFields.TEXT ||
					customField.baseSettings.type === TypeCustomFields.SELECT ||
					customField.baseSettings.type === TypeCustomFields.MULTI_SELECT ||
					customField.baseSettings.type === TypeCustomFields.DATE ||
					customField.baseSettings.type === TypeCustomFields.URL ||
					customField.baseSettings.type === TypeCustomFields.EMAIL ||
					customField.baseSettings.type === TypeCustomFields.NUMERIC)
			) {
				const values: IFilterOptions[] = [];
				if (datasGetType === "firestore" || !datasGetType) {
					datas.forEach((data) => {
						const cus = data.customFields.find((cus) => cus && cus.uid === customField.baseSettings.uid);
						if (cus) {
							customField.baseSettings.type === TypeCustomFields.TEXT ||
							customField.baseSettings.type === TypeCustomFields.SELECT
								? values.push({
										isNotSpecified:
											cus.field &&
											cus.field.multiLanguageText &&
											cus.field.multiLanguageText[currentLanguage] &&
											(cus.field.multiLanguageText[currentLanguage] !== null ||
												cus.field.multiLanguageText[currentLanguage] !== "")
												? false
												: true,
										isReachable: true,
										isSelected: false,
										filterId: customField.baseSettings.uid,
										value:
											cus.field &&
											cus.field.multiLanguageText &&
											cus.field.multiLanguageText[currentLanguage] &&
											(cus.field.multiLanguageText[currentLanguage] !== null ||
												cus.field.multiLanguageText[currentLanguage] !== "")
												? cus.field.multiLanguageText[currentLanguage]
												: this.notSpecifiedTrad[currentLanguage],
										totalDatas: 0
									})
								: customField.baseSettings.type === TypeCustomFields.DATE
									? values.push({
											isNotSpecified:
												cus.field.date && cus.field.date.fullDateISO !== "" ? false : true,
											isReachable: true,
											isSelected: false,
											filterId: customField.baseSettings.uid,
											value:
												cus.field.date && cus.field.date.fullDateISO !== ""
													? cus.field.date.fullDateISO?.split("T")[0]
													: this.notSpecifiedTrad[currentLanguage],
											totalDatas: 0
										})
									: customField.baseSettings.type === TypeCustomFields.URL ||
										  customField.baseSettings.type === TypeCustomFields.EMAIL
										? values.push({
												isNotSpecified:
													cus.field && cus.field.text && cus.field.text !== "" ? false : true,
												isReachable: true,
												isSelected: false,
												filterId: customField.baseSettings.uid,
												value:
													cus.field && cus.field.text && cus.field.text !== ""
														? cus.field.text
														: this.notSpecifiedTrad[currentLanguage],
												totalDatas: 0
											})
										: customField.baseSettings.type === TypeCustomFields.NUMERIC
											? values.push({
													isNotSpecified:
														(cus.field.numeric || cus.field.numeric === 0) &&
														cus.field.numeric?.toString() !== "-1"
															? false
															: true,
													isReachable: true,
													isSelected: false,
													filterId: customField.baseSettings.uid,
													value:
														(cus.field.numeric || cus.field.numeric === 0) &&
														cus.field.numeric?.toString() !== "-1"
															? cus.field.numeric?.toString()
															: this.notSpecifiedTrad[currentLanguage],
													totalDatas: 0
												})
											: cus.field.multiLanguageSelectArray
													?.map((option: ILanguage) => option[currentLanguage])
													.forEach((option: string) => {
														values.push({
															isNotSpecified:
																option !== "" &&
																!values.find((value) => value.value === option)
																	? false
																	: true,
															isReachable: true,
															isSelected: false,
															filterId: customField.baseSettings.uid,
															value:
																option !== "" &&
																!values.find((value) => value.value === option)
																	? option
																	: this.notSpecifiedTrad[currentLanguage],
															totalDatas: 0
														});
													});
						}
					});
				} else {
					if (customField) {
						customField.baseSettings.type === TypeCustomFields.TEXT
							? values.push({
									isNotSpecified: false,
									isReachable: true,
									isSelected: false,
									filterId: customField.baseSettings.uid,
									value: "empty",
									totalDatas: 0
								})
							: customField.baseSettings.type === TypeCustomFields.SELECT
								? customField.baseSettings.options
										?.map((option: ILanguage) => option[currentLanguage])
										.forEach((option: string) => {
											values.push({
												isNotSpecified:
													option !== "" && !values.find((value) => value.value === option)
														? false
														: true,
												isReachable: true,
												isSelected: false,
												filterId: customField.baseSettings.uid,
												value:
													option !== "" && !values.find((value) => value.value === option)
														? option
														: this.notSpecifiedTrad[currentLanguage],
												totalDatas: 0
											});
										})
								: customField.baseSettings.type === TypeCustomFields.DATE
									? values.push({
											isNotSpecified: false,
											isReachable: true,
											isSelected: false,
											filterId: customField.baseSettings.uid,
											value: null,
											totalDatas: 0
										})
									: customField.baseSettings.type === TypeCustomFields.URL ||
										  customField.baseSettings.type === TypeCustomFields.EMAIL
										? values.push({
												isNotSpecified: false,
												isReachable: true,
												isSelected: false,
												filterId: customField.baseSettings.uid,
												value: null,
												totalDatas: 0
											})
										: customField.baseSettings.type === TypeCustomFields.NUMERIC
											? values.push({
													isNotSpecified: false,
													isReachable: true,
													isSelected: false,
													filterId: customField.baseSettings.uid,
													value: null,
													totalDatas: 0
												})
											: customField.baseSettings.options
													?.map((option: ILanguage) => option[currentLanguage])
													.forEach((option: string) => {
														values.push({
															isNotSpecified:
																option !== "" &&
																!values.find((value) => value.value === option)
																	? false
																	: true,
															isReachable: true,
															isSelected: false,
															filterId: customField.baseSettings.uid,
															value:
																option !== "" &&
																!values.find((value) => value.value === option)
																	? option
																	: this.notSpecifiedTrad[currentLanguage],
															totalDatas: 0
														});
													});
					}
				}

				customFieldsFilters.push({
					uid: customField.baseSettings.uid,
					name: customField.baseSettings.name[currentLanguage],
					strictSelectionMode: customField.baseSettings.type === TypeCustomFields.MULTI_SELECT ? false : null,
					customFieldType: customField.baseSettings.type,
					search: "",
					values: values
						.filter(
							(item, i, self) =>
								i ===
								self.findIndex(
									(it) =>
										item &&
										item.value &&
										item.value !== "" &&
										it.value &&
										it.value !== "" &&
										it.value.toString().trim()?.toLowerCase() ===
											item.value.toString().trim()?.toLowerCase()
								)
						)
						.sort((a, b) => {
							if (a.value.toString().toLowerCase() == b.value.toString().toLowerCase()) return 0;
							if (
								a.value.toString().toLowerCase() == this.notSpecifiedTrad[currentLanguage].toLowerCase()
							)
								return 1;
							if (
								b.value.toString().toLowerCase() == this.notSpecifiedTrad[currentLanguage].toLowerCase()
							)
								return -1;

							if (a.value.toString().toLowerCase() > b.value.toString().toLowerCase()) return 1;
							if (a.value.toString().toLowerCase() < b.value.toString().toLowerCase()) return -1;
							return 0;
						})
				} as IFilteredItemFormat);
			}
		});

		return customFieldsFilters;
	};

	buildTracksFilters = (datas: any[], tracks: { uid: string; name: ILanguage }[], currentLanguage: string) => {
		let tracksFilters: IFilter[] = [];

		tracksFilters = tracks
			.filter((track) =>
				datas.some((data) => data.tracks && data.tracks.length > 0 && data.tracks.includes(track.uid))
			)
			.sort((a, b) =>
				a.name[currentLanguage] > b.name[currentLanguage]
					? 1
					: a.name[currentLanguage] < b.name[currentLanguage]
						? -1
						: 0
			)
			.map((track) => {
				return {
					uid: track.uid,
					name: track.name,
					checked: false,
					isReachable: true,
					totalDatas: 0
				};
			});

		return tracksFilters;
	};

	buildLocationsFilters = (datas: any[], locations: { uid: string; name: string }[]) => {
		let locationsFilters: IFilter[] = [];

		locationsFilters = locations
			.filter((location) =>
				datas.some(
					(data) => data.locations && data.locations.length > 0 && data.locations.includes(location.uid)
				)
			)
			.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
			.map((location) => {
				return {
					uid: location.uid,
					name: location.name,
					checked: false,
					isReachable: true,
					totalDatas: 0
				};
			});

		return locationsFilters;
	};

	buildGroupsFilters = (datas: any[], groups: { uid: string; name: string }[]) => {
		let groupsFilters: IFilter[] = [];
		groupsFilters = groups
			.filter((group) =>
				datas.some((data) => data.groups && data.groups.length > 0 && data.groups.includes(group.uid))
			)
			.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
			.map((group) => {
				return {
					uid: group.uid,
					name: group.name,
					checked: false,
					isReachable: true,
					totalDatas: 0
				};
			});

		return groupsFilters;
	};
	/**
	 * getCustomFieldsData
	 */
	getRegisterFormDatas(
		event: IEvent,
		customsFieldsData: any,
		sectionId: string,
		items: IRegisterFormItem[],
		registerFormFieldsComponent: FormFieldsComponent,
		selectCusToUpdate: ICustomField[],
		customFieldMediaToUpload: IFullCustomField[],
		currentLanguage: string
	) {
		return items
			.filter((item) => item.type === FormItemType.INPUT && !item.isBaseField && !item.hidden)
			.map((item) => item.value as IFullCustomField)
			.map((custom) => {
				if (customsFieldsData[sectionId][`${custom.baseSettings.uid}_${event.language}`]) {
					if (
						(custom.baseSettings.type === TypeCustomFields.TEXT ||
							custom.baseSettings.type === TypeCustomFields.HMTL) &&
						customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
					) {
						// multi language txt value
						return {
							uid: custom.baseSettings.uid,
							field: {
								multiLanguageText: {
									ArAR: customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										? customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										: "",
									DeDE: customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										? customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										: "",
									EnUS: customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										? customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										: "",
									EsES: customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										? customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										: "",
									FrFR: customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										? customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										: "",
									PtBR: customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										? customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
										: ""
								} as ILanguage
							} as IField
						};
					} else if (
						custom.baseSettings.type === TypeCustomFields.SELECT &&
						customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]
					) {
						// multi language txt value
						selectCusToUpdate.push(custom.baseSettings);

						const option = custom.baseSettings.options.find(
							(opt) =>
								opt[currentLanguage] ===
								customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`]?.[
									currentLanguage
								]
						);

						return {
							uid: custom.baseSettings.uid,
							field: {
								multiLanguageText: option
									? option
									: {
											ArAR: "",
											DeDE: "",
											EnUS: "",
											EsES: "",
											FrFR: "",
											PtBR: ""
										}
							} as IField
						};
					} else if (custom.baseSettings.type === TypeCustomFields.MULTI_SELECT) {
						// multi language txt value
						// all array "multi language" select value
						const choices: ILanguage[] =
							customsFieldsData[sectionId][`${custom.baseSettings.uid}_${currentLanguage}`];

						return {
							uid: custom.baseSettings.uid,
							field: {
								multiLanguageSelectArray: choices
									? choices?.map((ch) => {
											const option = custom.baseSettings.options.find(
												(opt) => opt[currentLanguage] === ch[currentLanguage]
											);
											return option
												? option
												: {
														ArAR: "",
														DeDE: "",
														EnUS: "",
														EsES: "",
														FrFR: "",
														PtBR: ""
													};
										})
									: []
							} as IField
						};
					}
				} else if (custom.baseSettings.type === TypeCustomFields.NUMERIC) {
					// "Number" type value
					return {
						uid: custom.baseSettings.uid,
						field: {
							numeric: customsFieldsData[sectionId][`${custom.baseSettings.uid}`]
						} as IField
					};
				} else if (custom.baseSettings.type === TypeCustomFields.PHONE) {
					// "Phone Number" type value
					// formating data to corrspond with the back-office
					const phone = customsFieldsData[sectionId][`${custom.baseSettings.uid}`] as IPhoneNumber;

					if (!phone)
						return {
							uid: custom.baseSettings.uid,
							field: {
								phoneNumber: {
									countryCode: null,
									dialCode: null,
									e164Number: null,
									internationalNumber: null,
									isoCode: null,
									nationalNumber: null,
									number: null
								} as IPhoneNumber
							} as IField
						};
					phone.number = phone.nationalNumber.split(" ")?.join().replace(/,/g, "");
					phone.internationalNumber = phone.internationalNumber?.split(" ").join().replace(/,/g, "");
					phone.nationalNumber = phone.nationalNumber?.split(" ").join().replace(/,/g, "");
					phone.countryCode = phone.countryCode?.toUpperCase();
					// phone.e164Number = phone.e164Number;
					// phone.dialCode = phone.dialCode;

					return {
						uid: custom.baseSettings.uid,
						field: {
							phoneNumber: customsFieldsData[sectionId][`${custom.baseSettings.uid}`] as IPhoneNumber
						} as IField
					};
				} else if (custom.baseSettings.type === TypeCustomFields.FILE) {
					// all "file" field control
					const data = registerFormFieldsComponent.customFieldMediaItems.find(
						(cus) => cus.uid === custom.baseSettings.uid
					)?.data;

					if (data) {
						custom.fieldDatas = {
							uid: custom.baseSettings.uid,
							field: {
								file: {
									type: data.type,
									lastModified: Date.now(),
									lastModifiedDate: DateTime.fromMillis(Date.now()).toString(),
									name: data.name,
									size: data.size,
									url: null,
									isExternalUrl: false
								} as IFile
							} as IField
						};
					} else {
						if (custom.fieldDatas?.field?.file?.url === null) {
							custom.fieldDatas = {
								uid: custom.baseSettings.uid,
								field: {
									file: {
										lastModified: null,
										lastModifiedDate: null,
										name: null,
										size: null,
										type: null,
										url: null
									} as IFile
								} as IField
							};
							return null;
						}
					}

					customFieldMediaToUpload.push(custom);

					return custom.fieldDatas;
				} else if (custom.baseSettings.type === TypeCustomFields.IMAGE) {
					// all "image" field control
					const data = registerFormFieldsComponent.customFieldMediaItems.find(
						(cus) => cus.uid === custom.baseSettings.uid
					)?.data;

					if (data) {
						custom.fieldDatas = {
							uid: custom.baseSettings.uid,
							field: {
								image: {
									format: data.type,
									lastModified: Date.now(),
									lastModifiedDate: DateTime.fromMillis(Date.now()).toString(),
									name: data.name,
									size: data.size,
									url: null,
									isExternalUrl: false
								} as IImage
							} as IField
						};
					} else {
						if (!custom.fieldDatas?.field?.image?.url) {
							custom.fieldDatas = {
								uid: custom.baseSettings.uid,
								field: {
									image: {
										format: null,
										lastModified: null,
										lastModifiedDate: null,
										name: null,
										size: null,
										url: null,
										isExternalUrl: false
									} as IImage
								} as IField
							};

							return null;
						}
					}

					customFieldMediaToUpload.push(custom);

					return custom.fieldDatas;
				} else if (custom.baseSettings.type === TypeCustomFields.ADDRESS) {
					// all "address" field control
					const string_address = customsFieldsData[sectionId][`${custom.baseSettings.uid}`]
						? customsFieldsData[sectionId][`${custom.baseSettings.uid}`]
						: "";

					return {
						uid: custom.baseSettings.uid,
						field: {
							address: {
								fullAddress: string_address,
								street: "",
								number: "",
								city: "",
								country: "",
								postalCode: "",
								state: "",
								region: "",
								url: ""
							}
						}
					};
				} else if (custom.baseSettings.type === TypeCustomFields.CHECKBOX) {
					// Checkbox field control
					return {
						uid: custom.baseSettings.uid,
						field: {
							checkbox: customsFieldsData[sectionId][`${custom.baseSettings.uid}`]
						}
					};
				} else if (custom.baseSettings.type === TypeCustomFields.COUNTRY) {
					// country field control
					return {
						uid: custom.baseSettings.uid,
						field: {
							country: custom.fieldDatas.field?.country?.name
								? custom.fieldDatas.field.country
								: {
										name: "",
										code: "",
										dialCode: "",
										flag: ""
									}
						}
					};
				} else if (
					(custom.baseSettings.type === TypeCustomFields.URL ||
						custom.baseSettings.type === TypeCustomFields.EMAIL) &&
					customsFieldsData[sectionId][`${custom.baseSettings.uid}`]
				) {
					// all "single language" text value
					return {
						uid: custom.baseSettings.uid,
						field: {
							text: customsFieldsData[sectionId][`${custom.baseSettings.uid}`]
						} as IField
					};
				} else if (custom.baseSettings.type === TypeCustomFields.DATE) {
					// "Date" value
					const date: Date | string = customsFieldsData[sectionId][`${custom.baseSettings.uid}`];
					if (!date) {
						return null;
					}
					typeof date === "object" && date.setHours(12, 0, 0, 0);

					// "Date" value
					const dateISO =
						typeof date === "object" && DateTime.fromJSDate(date).toISO()?.split("T")?.[0] !== null
							? DateTime.fromJSDate(date).toISO()?.split("T")?.[0]
							: (date as string)?.split("T")?.[0];

					return {
						uid: custom.baseSettings.uid,
						field: {
							date: {
								day: dateISO ? parseInt(dateISO?.split("-")?.[2]) : null,
								month: dateISO ? parseInt(dateISO?.split("-")?.[1]) : null,
								year: dateISO ? parseInt(dateISO?.split("-")?.[0]) : null,
								value: dateISO ? this.SUtility.getDateFormat(dateISO, currentLanguage) : null,
								fullDateISO: dateISO ? dateISO : null,
								zone: event.timezone || environment.platform.defaultTimezone
							}
						} as IField
					};
				} else if (custom.baseSettings.type === TypeCustomFields.MULTI_TEXT) {
					// all array "multi language" text value

					return {
						uid: custom.baseSettings.uid,
						field: {
							multiLanguageTextArray:
								registerFormFieldsComponent.multipleTextValues[
									registerFormFieldsComponent.getMultiTextInputIndex(custom.baseSettings.uid)
								]?.multiLanguageTextArray
						} as IField
					};
				}
			})
			.filter((item) => item !== null && item !== undefined);
	}

	getRegisterFormBaseFields(sections: IRegisterFormSection[], formDatas: any, _language: string) {
		const baseItems = sections
			.map((section) => section.items.map((item) => (item = { ...item, sectionId: section.uid } as any)))
			.reduce((prev, curr) => prev.concat(curr), [])
			.map((item: IRegisterFormItem) => (item.isBaseField ? item : null))
			.filter((item: IRegisterFormItem) => item !== null);

		const res = {
			name: formDatas[(baseItems.find((item: IRegisterFormItem) => item.uid === "name") as any)?.sectionId]?.[
				`name`
			],
			email: formDatas[(baseItems.find((item: IRegisterFormItem) => item.uid === "email") as any)?.sectionId]?.[
				`email`
			],
			credentials: {
				password:
					formDatas[
						(baseItems.find((item: IRegisterFormItem) => item.uid === "password") as any)?.sectionId
					]?.[`password`],
				confirmPassword:
					formDatas[
						(baseItems.find((item: IRegisterFormItem) => item.uid === "passwordConfirmation") as any)
							?.sectionId
					]?.[`passwordConfirmation`],
				codeNumber: null,
				termCheck:
					formDatas[
						(baseItems.find((item: IRegisterFormItem) => item.uid === "termCheck") as any)?.sectionId
					]?.[`termCheck`]
			}
		};

		return res;
	}

	/**
	 * isUserOrModuleFieldsVisibilityHidden
	 * @param module
	 * @param modulesCustomsFields
	 * @param eventUserProfile
	 * @param key
	 * @param type
	 */
	isUserOrModuleFieldsVisibilityHidden(
		module: IModule,
		modulesCustomsFields: IModuleCustomField[],
		eventUserProfile: IEventUser | ISheet,
		key: string,
		type: "baseFields" | "customFields" = "customFields"
	) {
		if (type === "baseFields") {
			return module.options.enableUserFieldsHideAbility
				? eventUserProfile["updatedSettings"] &&
					(eventUserProfile["updatedSettings"]?.fieldsVisibility?.[key] === undefined ||
						(eventUserProfile["updatedSettings"]?.fieldsVisibility?.[key] !== undefined &&
							eventUserProfile["updatedSettings"]?.fieldsVisibility?.[key] !==
								!module.options.requiredFields?.[key]?.hiding?.default &&
							!eventUserProfile["editedProfile"]))
					? !module.options.requiredFields?.[key]?.hiding?.default
					: (eventUserProfile &&
							eventUserProfile["updatedSettings"] &&
							eventUserProfile["updatedSettings"]?.fieldsVisibility?.[key]) ||
						false
				: true;
		} else {
			const correspondingCus = modulesCustomsFields?.find((cus) => cus.uid === key);
			if (correspondingCus) {
				return module.options.enableUserFieldsHideAbility
					? eventUserProfile["updatedSettings"] &&
						(eventUserProfile["updatedSettings"]?.fieldsVisibility?.[key] === undefined ||
							(eventUserProfile["updatedSettings"]?.fieldsVisibility?.[key] !== undefined &&
								eventUserProfile["updatedSettings"]?.fieldsVisibility?.[key] !==
									!correspondingCus.hiding?.default &&
								!eventUserProfile["editedProfile"]))
						? !correspondingCus.hiding?.default
						: (eventUserProfile &&
								eventUserProfile["updatedSettings"] &&
								eventUserProfile["updatedSettings"]?.fieldsVisibility?.[key]) ||
							false
					: true;
			}
		}
	}
}
