import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { auditTime, take } from "rxjs/operators";
import { GetAllWordClouds } from "../actions/interactivity.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IWordCloud, IWordCloudWord } from "../interfaces/word-clouds.interfaces";
import { checkSameEvent } from "../selectors/interactivity.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class WordCloudsService {
	wordCloudsSub: Subscription;

	constructor(
		private SFirestore: FirestoreService,
		private store: Store
	) {}

	unsubscribeAll() {
		if (this.wordCloudsSub) {
			this.wordCloudsSub.unsubscribe();
		}
	}

	/**
	 * Get all wordsClouds of event
	 * @param eventId
	 */
	getWordsCloudsOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "wordClouds", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.wordCloudsSub && !this.wordCloudsSub.closed) {
					return;
				} else if (!sameEvent && this.wordCloudsSub && !this.wordCloudsSub.closed) {
					this.wordCloudsSub.unsubscribe();
				}

				this.wordCloudsSub = this.SFirestore.collectionGroupValueChangesDocuments("word-clouds", [
					where("eventId", "==", eventId)
				]).subscribe((wordClouds: IWordCloud[]) => {
					this.store.dispatch(GetAllWordClouds({ payload: wordClouds, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initWordClouds"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initWordClouds", payload: true })
								);
							}
						});
				});
			});
	}

	/**
	 * Get words of word cloud
	 * @param eventId
	 * @param moduleId
	 * @param wordCloudId
	 * @returns
	 */
	getWordsOfWordCloud(eventId: string, moduleId: string, wordCloudId: string) {
		return this.SFirestore.valueChangesDocuments(
			`events/${eventId}/modules/${moduleId}/word-clouds/${wordCloudId}/word-clouds-words`,
			[]
		).pipe(auditTime(1000));
	}

	/**
	 * Create a word for a words cloud
	 * @param eventId
	 * @param moduleId
	 * @param wordsCloudId
	 * @param word
	 * @returns
	 */
	createWordOfWordsCloud(eventId: string, moduleId: string, wordsCloudId: string, word: IWordCloudWord) {
		word.uid = word.uid
			? word.uid
			: this.SFirestore.createId(
					`events/${eventId}/modules/${moduleId}/word-clouds/${wordsCloudId}/word-clouds-words`
				);
		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/word-clouds/${wordsCloudId}/word-clouds-words/${word.uid}`,
			word
		);
	}
}
