import { CommonModule } from "@angular/common";
import { Component, input, output, signal, WritableSignal, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "app-tabs",
	templateUrl: "./tabs.component.html",
	styleUrls: ["./tabs.component.scss"],
	standalone: true,
	imports: [CommonModule, TranslateModule]
})
export class TabsComponent implements OnInit {
	tabs = input.required<{ text: string; translation: boolean; active: boolean; key: string }[]>();

	tabActive: WritableSignal<string> = signal("");

	tabsOutput = output<string>();

	ngOnInit() {
		const init = this.tabs().find((tab) => tab.active);
		this.tabActive.set(init ? init.key : "");
	}

	tabChanged(key: string) {
		this.tabActive.set(key);
		this.tabsOutput.emit(key);
	}
}
