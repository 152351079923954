export class SearchFilter implements ISearchFilter {
	equalityFields: ISearchField[] = []; // Compare data can be an array for a in filter
	inequalityFields: ISearchField[] = [];
	includeTextFields: ISearchField[] = [];
	includeOrTextFields: ISearchField[] = [];
	isIncludedInFields: ISearchField[] = [];
	superiorFields: ISearchField[] = [];
	superiorOrEqualFields: ISearchField[] = [];
	inferiorFields: ISearchField[] = [];
	inferiorOrEqualFields: ISearchField[] = [];
	anyTextFields: ISearchField[] = [];
	arrayContainsAnyFields: ISearchField[] = [];
	arrayContainsAllFields: ISearchField[] = [];
	arrayContainsBlocAndOrFields: ISearchFieldBloc[] = [];
	arrayNotContainsFields: ISearchField[] = [];
	arrayIsEmpty: { fieldKey: string }[] = [];
	elemMatchArrayFields: ISearchField[] = [];
	specificMongodbQuery: any[] = [];
	lookup: {
		where: "beforeMatch" | "afterMatch";
		stage: {
			$lookup: {
				from: string;
				let?: any;
				localField?: string;
				foreignField?: string;
				pipeline?: any[];
				as: string;
			};
		};
	}[] = [];
	addFields: any[] = [];
	page: number = 0;
	itemsPerPage: number = 30;
	sortBy: {
		fieldKey: string;
		type: string | number;
	}[] = [];
}

export interface ISearchFilter {
	equalityFields?: ISearchField[]; // Compare data can be an array for a in filter
	inequalityFields?: ISearchField[];
	includeTextFields?: ISearchField[];
	includeOrTextFields?: ISearchField[];
	isIncludedInFields?: ISearchField[];
	superiorFields?: ISearchField[];
	superiorOrEqualFields?: ISearchField[];
	inferiorFields?: ISearchField[];
	inferiorOrEqualFields?: ISearchField[];
	anyTextFields?: ISearchField[];
	arrayContainsAnyFields?: ISearchField[];
	arrayContainsAllFields?: ISearchField[];
	arrayContainsBlocAndOrFields?: ISearchFieldBloc[];
	arrayNotContainsFields?: ISearchField[];
	arrayIsEmpty?: { fieldKey: string }[];
	elemMatchArrayFields?: ISearchField[];
	specificMongodbQuery?: any[];
	lookup?: {
		where: "beforeMatch" | "afterMatch";
		stage: {
			$lookup: {
				from: string;
				let?: any;
				localField?: string;
				foreignField?: string;
				pipeline?: any[];
				as: string;
			};
		};
	}[];
	addFields?: any[];
	page: number;
	itemsPerPage: number;
	sortBy: {
		fieldKey: string;
		type: string | number;
	}[];
}

export interface ISearchField {
	fieldKey: string;
	compareData: any;
}

export interface ISearchFieldBloc {
	fieldKey: string;
	compareData: any[];
}

export interface ISearchFieldSetting {
	searchActivated: boolean;

	// Basic field
	key: string;
	fieldType: string;
	fixedName: string;
	translationKey: string;
	multiLanguage: boolean;

	// Custom field
	isCustomField: boolean;
	customFieldType: number;
	customFieldUid: string;
}
