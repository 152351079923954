import { Component, OnDestroy } from "@angular/core";
import { Browser } from "@capacitor/browser";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { combineLatest, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IDocument, IEvent, IEventUser, IFolder, IModule, IUser } from "src/app/shared/interfaces";
import { getCurrentEventUser, getCurrentUser } from "src/app/shared/selectors/auth.selectors";
import {
	getDocumentsOfFolder,
	getFoldersOfModule,
	getSpecificFolder
} from "src/app/shared/selectors/documents.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { DocumentsService, UtilityService } from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { environment } from "src/environments/environment";
import { Clipboard } from "@capacitor/clipboard";

@Component({
	selector: "app-documents",
	templateUrl: "./documents.component.html",
	styleUrls: ["./documents.component.scss"],
	standalone: false
})
export class DocumentsComponent implements OnDestroy {
	subscriptions: Subscription[] = [];

	showTitle: boolean = false;
	widthImg: number = 48;
	showNoResultImage: boolean = false;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	folderId: string;
	folder: IFolder;
	documentsSaved: IDocument[] = [];
	documents: IDocument[] = [];
	user: IUser;
	eventUser: IEventUser;
	currentLanguage: string = environment.platform.defaultLanguage;
	folders: IFolder[];
	loader: boolean;

	constructor(
		private SAnalytics: AnalyticsService,
		private store: Store,
		private SUtility: UtilityService,
		private STranslate: TranslateService,
		public SDocuments: DocumentsService
	) {}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
				this.filterDocuments();
			})
		);
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.folderId = params.folderId;
						// Analytics
						this.SAnalytics.documentFolderAccess(this.eventId, this.moduleId, this.folderId);

						this.getEvent();
						this.getUser();
						this.getModule();
						this.getFolder();
						this.getFolders();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);
	}

	/**
	 * Getting folder
	 */
	getFolder() {
		this.subscriptions.push(
			this.store.select(getSpecificFolder(this.folderId)).subscribe((folder) => {
				if (!_.isEqual(this.folder, folder)) {
					if (
						(this.folder && folder && !_.isEqual(this.folder.orderInFolder, folder.orderInFolder)) ||
						(!this.folder && folder)
					) {
						this.folder = folder;
						this.getDocuments();
					}
					this.folder = folder;
				}

				if (this.folder) {
					this.store.dispatch(
						GetHeaderTitle({
							payload: {
								ArAR: this.folder.name.ArAR,
								DeDE: this.folder.name.DeDE,
								EnUS: this.folder.name.EnUS,
								EsES: this.folder.name.EsES,
								FrFR: this.folder.name.FrFR,
								PtBR: this.folder.name.PtBR
							}
						})
					);
				}
			})
		);
	}

	/**
	 * Getting all folders of module
	 */
	getFolders() {
		this.subscriptions.push(
			this.store
				.select(
					getFoldersOfModule({
						moduleId: this.moduleId,
						order: this.module.typeOrder,
						language: this.currentLanguage
					})
				)
				.subscribe((folders) => {
					this.folders = folders;

					if (this.folders && this.folders.length === 1) {
						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_DOCUMENTS_MODULE
						);
					}
				})
		);
	}

	/**
	 * Get documents
	 */
	getDocuments() {
		this.store
			.select(
				getDocumentsOfFolder({
					folderId: this.folderId,
					order: this.folder.orderInFolder,
					language: this.user && this.user.language ? this.user.language : this.event.language
				})
			)
			.pipe(take(1))
			.subscribe((documents) => {
				if (!_.isEqual(this.documentsSaved, documents)) {
					this.documentsSaved = documents;
					this.filterDocuments();
				}
			});
	}

	filterDocuments() {
		this.documents = this.documentsSaved.filter((doc) => !doc.language || doc.language === this.currentLanguage);
	}

	/**
	 * Getting user datas
	 */
	getUser() {
		this.subscriptions.push(
			combineLatest([this.store.select(getCurrentUser), this.store.select(getCurrentEventUser)]).subscribe(
				(results) => {
					this.user = results[0];
					this.eventUser = results[1];
				}
			)
		);
	}

	/**
	 * Open a document
	 * @param document
	 */
	async openDocument(document: IDocument) {
		try {
			await Browser.open({
				url: document.url
			});

			// Tracking Analytic
			this.SAnalytics.documentOpended(this.eventId, this.moduleId, this.eventUser, this.folderId, document.uid);
		} catch (error) {
			this.SUtility.presentToast(
				this.STranslate.instant("errors.error.error-open-document"),
				3000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * Present a confirmation alert
	 */
	async presentAlertConfirm() {
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("global.alerts.thankyou_answer"),
			this.STranslate.instant("global.alerts.answer_saved_successfully"),
			[
				{
					text: this.STranslate.instant("global.buttons.ok"),
					handler: () => {
						//
					}
				}
			],
			[]
		);

		await alert.present();
	}

	/**
	 * Filter documents
	 * @param event
	 */
	filterDocs(ev) {
		this.documents = this.documentsSaved;

		const val = ev.target.value.toLowerCase();
		const extensions = this.getExtensions(val);
		if (val && val.trim() != "") {
			this.documents = [...this.documentsSaved].filter((item: any) => {
				return (
					item.name[this.user && this.user.language ? this.user.language : this.event.language]
						.toLowerCase()
						.indexOf(val.toLowerCase()) > -1 || extensions.includes(item.type)
				);
			});
		}
	}

	/**
	 * Get extension
	 * @param value
	 * @returns
	 */
	getExtensions(value: string) {
		if ("word".includes(value)) return ["doc", "docx", "docm"];
		else if ("excel".includes(value)) return ["xls", "xlt", "xml", "xlsx", "xlsm", "xlsb", "xltx", "xltm"];
		else if ("pdf".includes(value)) return ["pdf", "PDF"];
		else if ("png".includes(value)) return ["png", "PNG"];
		else if ("jpg".includes(value)) return ["jpg", "JPG"];
		else if ("jpeg".includes(value)) return ["jpeg", "JPEG"];
		else if ("powerpoint".includes(value)) return ["ppt", "pptx", "thmx", "ppsx", "pps", "ppsm"];
		else if ("media".includes(value)) return ["mp4", "wmv", "3gp", "avi", "mp3", "wav"];
		else return [];
	}

	/**
	 * Reset filter
	 */
	resetFilter() {
		this.documents = [...this.documentsSaved];
	}

	async copyToClipboard(document: IDocument) {
		try {
			await Clipboard.write({
				string: document.url
			});
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.copied-to-clipboard"),
				3000,
				"bottom",
				"success"
			);
		} catch (error) {
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.cannot-copy-to-clipboard"),
				3000,
				"bottom",
				"danger"
			);
		}
	}
}
