import { Injectable } from "@angular/core";
import {
	Firestore,
	collection,
	collectionGroup,
	getDoc,
	getDocs,
	doc,
	setDoc,
	updateDoc,
	addDoc,
	deleteDoc,
	writeBatch,
	collectionData,
	collectionSnapshots,
	query,
	docData,
	docSnapshots,
	Query,
	QueryConstraint,
	QueryDocumentSnapshot,
	increment,
	collectionChanges,
	runTransaction,
	clearIndexedDbPersistence,
	DocumentData
} from "@angular/fire/firestore";
import {
	DocumentChangeType,
	Transaction,
	DocumentReference,
	WriteBatch,
	QueryCompositeFilterConstraint,
	getCountFromServer
} from "firebase/firestore";
import { from, of, switchMap } from "rxjs";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { IBaseDocument } from "../interfaces/generics.interfaces";

@Injectable({
	providedIn: "root"
})
export class FirestoreService {
	firestoreDocSizeLimit: number = 200000; // Technically it's 1,048,576 bytes but we take less

	constructor(public readonly firestore: Firestore) {}

	clearPersistence() {
		return clearIndexedDbPersistence(this.firestore);
	}

	getTransaction(updateFunction: (transaction: Transaction) => Promise<unknown>) {
		return runTransaction(this.firestore, updateFunction);
	}

	getIncrement(val: number) {
		return increment(val);
	}

	/**
	 * Get doc reference
	 * @param path
	 * @returns
	 */
	docRef(path: string) {
		return doc(this.firestore, path);
	}

	/**
	 * Get collection reference
	 * @param path
	 * @returns
	 */
	collectionRef(path: string) {
		return collection(this.firestore, path);
	}

	/**
	 * Get batch
	 * @returns
	 */
	getBatch() {
		return writeBatch(this.firestore);
	}

	/**
	 * Create id
	 * @param id
	 * @returns
	 */
	createId(path: string) {
		return doc(collection(this.firestore, path)).id;
	}

	/**
	 * Get a document
	 * @param path
	 * @returns
	 */
	getDocument(path: string) {
		return getDoc(doc(this.firestore, path));
	}

	/**
	 * Get a document
	 * @param path
	 * @returns
	 */
	getDocumentObs(path: string) {
		return from(getDoc(doc(this.firestore, path)));
	}

	/**
	 * Value changes document
	 * @param path
	 * @returns
	 */
	valueChangesDocument(path: string) {
		return docData<any>(doc(this.firestore, path));
	}

	/**
	 * Snapshot changes document
	 * @param path
	 * @returns
	 */
	snapshotChangesDocument(path: string) {
		return docSnapshots<any>(doc(this.firestore, path));
	}

	/**
	 * Get documents
	 * @param path
	 * @param queryConstraint
	 * @returns
	 */
	getDocuments(path: string, queryConstraints: QueryConstraint[]) {
		return getDocs(query<any, DocumentData>(collection(this.firestore, path), ...queryConstraints));
	}

	/**
	 * Get documents
	 * @param path
	 * @param queryConstraint
	 * @returns
	 */
	getDocumentsObs(path: string, queryConstraints: QueryConstraint[]) {
		return from(getDocs(query<any, DocumentData>(collection(this.firestore, path), ...queryConstraints)));
	}

	/**
	 * Get documents
	 * @param path
	 * @param queryConstraint
	 * @returns
	 */
	getDocumentsCollectionGroup(collectionId: string, queryConstraints: QueryConstraint[]) {
		return getDocs(query<any, DocumentData>(collectionGroup(this.firestore, collectionId), ...queryConstraints));
	}

	/**
	 * Collection groups documents
	 * @param collectionId
	 * @param queryConstraint
	 * @returns
	 */
	collectionGroupValueChangesDocuments(collectionId: string, queryConstraints: QueryConstraint[]) {
		return collectionData(
			query<any, DocumentData>(collectionGroup(this.firestore, collectionId), ...queryConstraints)
		);
	}

	/**
	 * Value changes documents
	 * @param path
	 * @param queryConstraint
	 * @returns
	 */
	valueChangesDocuments(path: string, queryConstraints: QueryConstraint[] | QueryCompositeFilterConstraint[]) {
		return collectionData(query<any, DocumentData>(collection(this.firestore, path), ...(queryConstraints as any)));
	}

	/**
	 * Snapshot changes documents
	 * @param path
	 * @param queryConstraint
	 * @returns
	 */
	snapshotChangesDocuments(path: string, queryConstraints: QueryConstraint[] | QueryCompositeFilterConstraint[]) {
		return collectionSnapshots(
			query<any, DocumentData>(collection(this.firestore, path), ...(queryConstraints as any))
		);
	}

	/**
	 * Snapshot changes collection group documents
	 * @param path
	 * @param queryConstraint
	 * @returns
	 */
	collectionGroupSnapshotChangesDocuments(path: string, queryConstraints: QueryConstraint[]) {
		return collectionSnapshots(
			query<any, DocumentData>(collectionGroup(this.firestore, path), ...queryConstraints)
		);
	}

	/**
	 * Get count of documents
	 * @param query
	 * @returns
	 */
	getCountOfDocumentsObs(query: Query<any>) {
		return from(getCountFromServer(query));
	}

	/**
	 * Get count of documents with an observable
	 * @param query
	 * @returns
	 */
	getCountOfDocuments(query: Query<any>) {
		return getCountFromServer(query);
	}

	collectionChanges(path: string, queryConstraints: QueryConstraint[], eventsType: DocumentChangeType[]) {
		return collectionChanges(query<any, DocumentData>(collection(this.firestore, path), ...queryConstraints), {
			events: eventsType
		});
	}

	collectionChangesGroup(path: string, queryConstraints: QueryConstraint[], eventsType: DocumentChangeType[]) {
		return collectionChanges(query<any, DocumentData>(collectionGroup(this.firestore, path), ...queryConstraints), {
			events: eventsType
		});
	}

	/**
	 * Snapshot change document with pagination
	 * @param path
	 * @param queryConstraints
	 * @returns
	 */
	snapshotChangesDocumentsPaginated(path: string, queryConstraints: QueryConstraint[]) {
		return collectionSnapshots(
			query<any, DocumentData>(collection(this.firestore, path), ...queryConstraints)
		).pipe(
			switchMap((docs) => {
				return of({
					start: docs[0],
					last: docs[docs.length - 1],
					docsSnapshots: docs,
					docsDatas: docs.map((doc) => doc.data())
				});
			})
		);
	}

	/**
	 * Add a document
	 * @param path
	 * @param data
	 * @returns
	 */
	addDocument(path: string, data: any) {
		const colRef = collection(this.firestore, path);
		return addDoc(colRef, { ...data, updatedDate: DateTime.local().toMillis() });
	}

	/**
	 * Set a document
	 * @param path
	 * @param data
	 * @returns
	 */
	setDocument(path: string, data: any) {
		// data.id = data.id ? data.id : this.createId(path);
		const docRef = doc(this.firestore, `${path}`);
		return setDoc(docRef, { ...data, updatedDate: DateTime.local().toMillis() });
	}

	/**
	 * Set a document with merge
	 * @param path
	 * @param data
	 * @returns
	 */
	setDocumentMerge(path: string, data: any) {
		// data.id = data.id ? data.id : this.createId(path);
		const docRef = doc(this.firestore, `${path}`);
		return setDoc(docRef, { ...data, updatedDate: DateTime.local().toMillis() }, { merge: true });
	}

	/**
	 * Set multiple documents
	 * @param paths
	 * @param datas
	 * @returns
	 */
	setDocuments(paths: string[], datas: any[]) {
		const batch = writeBatch(this.firestore);
		paths.forEach((path, index) => {
			datas[index].id = datas[index].id ? datas[index].id : this.createId(path);
			const docRef = doc(this.firestore, `${path}/${datas[index].id}`);
			batch.set(docRef, { ...datas[index], updatedDate: DateTime.local().toMillis() });
		});

		return batch.commit();
	}

	/**
	 * Update a document
	 * @param path
	 * @param dataUpdated
	 * @returns
	 */
	updateDocument(path: string, dataUpdated: any) {
		return updateDoc(doc(this.firestore, path), { ...dataUpdated, updatedDate: DateTime.local().toMillis() });
	}

	/**
	 * Update multiple documents
	 * @param paths
	 * @param datasUpdated
	 * @returns
	 */
	updateDocuments(paths: string[], datasUpdated: any[]) {
		const batch = writeBatch(this.firestore);
		paths.forEach((path, index) => {
			batch.update(doc(this.firestore, path), {
				...datasUpdated[index],
				updatedDate: DateTime.local().toMillis()
			});
		});

		return batch.commit();
	}

	/**
	 * Delete document
	 * @param path
	 * @returns
	 */
	deleteDocument(path: string) {
		return deleteDoc(doc(this.firestore, path));
	}

	/**
	 * Delete multiple documents
	 * @param paths
	 * @returns
	 */
	deleteDocuments(paths: string[]) {
		const batch = writeBatch(this.firestore);
		paths.forEach((path) => {
			batch.delete(doc(this.firestore, path));
		});

		return batch.commit();
	}

	/**
	 * Delete all docs on a collection
	 * @param path
	 * @returns
	 */
	deleteAllDocsOnCollection(path: string) {
		return from(this.getDocuments(path, [])).pipe(
			switchMap((snapshot) => {
				const promiseAllArray = [];
				const chunkedDocs: QueryDocumentSnapshot<any>[][] = _.chunk(snapshot.docs, 400);
				chunkedDocs.forEach((chunk) => {
					const batch = writeBatch(this.firestore);
					chunk.forEach((document) => {
						batch.delete(doc(this.firestore, document.ref.path));
					});
					promiseAllArray.push(batch.commit());
				});
				return Promise.all(promiseAllArray);
			})
		);
	}

	/**
	 * Managing document on array
	 * @param path
	 * @param docs
	 */
	manageDocuments(path: string, docs: any[], datas: any[], eventId: string, moduleId: string) {
		// Get rough size of an object
		const roughSizeOfAData =
			datas && datas.length > 0
				? datas.sort((a, b) =>
						a && b && a.docSize < b.docSize ? 1 : a && b && a.docSize > b.docSize ? -1 : 0
					)[0].docSize * 1.2
				: 0;

		const batch = writeBatch(this.firestore);
		const chunkedDatas = _.chunk(
			datas,
			Math.floor(this.firestoreDocSizeLimit / roughSizeOfAData) > 100
				? 100
				: Math.floor(this.firestoreDocSizeLimit / roughSizeOfAData)
		);

		// If same length only update docs with new datas
		if (chunkedDatas.length === docs.length) {
			chunkedDatas.forEach((chunk, index) => {
				docs[index].datas = chunk;
				batch.update(doc(this.firestore, `${path}/${docs[index].id}`), {
					...docs[index],
					updatedDate: DateTime.local().toMillis()
				});
			});
		} else if (chunkedDatas.length > docs.length) {
			// If datas superior to docs create new docs and update existing
			chunkedDatas.forEach((chunk, index) => {
				if (docs[index]) {
					docs[index].datas = chunk;
					batch.update(doc(this.firestore, `${path}/${docs[index].id}`), {
						...docs[index],
						updatedDate: DateTime.local().toMillis()
					});
				} else {
					const id = this.createId(path);
					const newDoc: IBaseDocument = {
						id: id,
						creationDate: DateTime.local().toISO(),
						eventId: eventId,
						moduleId: moduleId,
						datas: chunk
					};
					batch.set(doc(this.firestore, `${path}/${id}`), {
						...newDoc,
						updatedDate: DateTime.local().toMillis()
					});
				}
			});
		} else if (chunkedDatas.length < docs.length) {
			// If datas inferior to docs delete docs and update existing
			if (chunkedDatas.length > 0) {
				docs.forEach((document, index) => {
					if (chunkedDatas[index]) {
						document.datas = chunkedDatas[index];
						batch.update(doc(this.firestore, `${path}/${document.id}`), {
							...document,
							updatedDate: DateTime.local().toMillis()
						});
					} else {
						batch.delete(doc(this.firestore, `${path}/${document.id}`));
					}
				});
			} else {
				// Delete all if no datas
				docs.forEach((document) => {
					batch.delete(doc(this.firestore, `${path}/${document.id}`));
				});
			}
		}
		return batch.commit();
	}

	/**
	 * Get documents paginated
	 * @param path
	 * @param queryConstraints
	 * @param typeObs
	 * @param typeCollection
	 * @returns
	 */
	getDocumentsPaginated(
		path: string,
		queryConstraints: QueryConstraint[],
		typeObs: "snapshotchanges" | "get",
		typeCollection: "group" | "default"
	) {
		const snapshotQuery =
			typeObs === "snapshotchanges" && typeCollection === "group"
				? this.collectionGroupSnapshotChangesDocuments(path, queryConstraints).pipe(
						switchMap((docs) => {
							return of({
								start: docs[0],
								last: docs[docs.length - 1],
								docsSnapshots: docs,
								docsDatas: docs.map((doc) => doc.data())
							});
						})
					)
				: typeObs === "snapshotchanges" && typeCollection === "default"
					? this.snapshotChangesDocuments(path, queryConstraints).pipe(
							switchMap((docs) => {
								return of({
									start: docs[0],
									last: docs[docs.length - 1],
									docsSnapshots: docs,
									docsDatas: docs.map((doc) => doc.data())
								});
							})
						)
					: typeObs === "get" && typeCollection === "group"
						? from(this.getDocumentsCollectionGroup(path, queryConstraints)).pipe(
								switchMap((docs) => {
									return of({
										start: docs.docs[0],
										last: docs.docs[docs.docs.length - 1],
										docsSnapshots: docs.docs,
										docsDatas: docs.docs.map((doc) => doc.data())
									});
								})
							)
						: from(this.getDocuments(path, queryConstraints)).pipe(
								switchMap((docs) => {
									return of({
										start: docs.docs[0],
										last: docs.docs[docs.docs.length - 1],
										docsSnapshots: docs.docs,
										docsDatas: docs.docs.map((doc) => doc.data())
									});
								})
							);

		return snapshotQuery;
	}

	/**
	 * Get last document of query
	 * @param path
	 * @param queryConstraints
	 * @param typeCollection
	 * @returns
	 */
	getLastDocumentOfQuery(path: string, queryConstraints: QueryConstraint[], typeCollection: "group" | "default") {
		return typeCollection === "group"
			? from(this.getDocumentsCollectionGroup(path, queryConstraints)).pipe(
					switchMap((docs) => {
						return of(docs.size > 0 ? docs.docs[0] : null);
					})
				)
			: from(this.getDocuments(path, queryConstraints)).pipe(
					switchMap((docs) => {
						return of(docs.size > 0 ? docs.docs[0] : null);
					})
				);
	}

	/**
	 * Get count of documents
	 * @param query
	 * @returns
	 */
	getCountOfQueryObs(path: string, typeCollection: "group" | "default", queryConstraints: QueryConstraint[]) {
		const countQuery =
			typeCollection === "group"
				? query(collectionGroup(this.firestore, path), ...queryConstraints)
				: query(collection(this.firestore, path), ...queryConstraints);
		return from(getCountFromServer(countQuery)).pipe(
			switchMap((resultCount) => {
				return of(resultCount.data().count);
			})
		);
	}

	roughSizeOfObject(object: any) {
		const objectList = [];
		const stack = [object];
		let bytes = 0;

		while (stack.length) {
			const value = stack.pop();

			if (typeof value === "boolean") {
				bytes += 1;
			} else if (typeof value === "string") {
				bytes += value.length * 2;
			} else if (typeof value === "number") {
				bytes += 8;
			} else if (typeof value === "object" && objectList.indexOf(value) === -1) {
				objectList.push(value);

				for (const i in value) {
					stack.push(value[i]);
				}
			}
		}
		return bytes;
	}

	setBatch(batch: WriteBatch, doc: DocumentReference, datas: any) {
		return batch.set(doc, { ...datas, updatedDate: DateTime.local().toMillis() });
	}

	updateBatch(doc: DocumentReference, datas: any) {
		const batch = writeBatch(this.firestore);
		return batch.update(doc, { ...datas, updatedDate: DateTime.local().toMillis() });
	}

	deleteBatch(batch: WriteBatch, doc: DocumentReference) {
		return batch.delete(doc);
	}
}
