import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetAllRankings } from "../actions/rankings.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IRanking } from "../interfaces/ranking.interfaces";
import { checkSameEvent } from "../selectors/rankings.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class RankingsService {
	rankingsSub: Subscription;

	constructor(
		private SFirestore: FirestoreService,
		private store: Store
	) {}

	unsubscribeAll() {
		if (this.rankingsSub) {
			this.rankingsSub.unsubscribe();
		}
	}

	/**
	 * Get all rankings of event
	 * @param eventId
	 */
	getRankingsOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.rankingsSub && !this.rankingsSub.closed) {
					return;
				} else if (!sameEvent && this.rankingsSub && !this.rankingsSub.closed) {
					this.rankingsSub.unsubscribe();
				}

				this.rankingsSub = this.SFirestore.collectionGroupValueChangesDocuments("rankings", [
					where("eventId", "==", eventId)
				]).subscribe((rankings: IRanking[]) => {
					this.store.dispatch(GetAllRankings({ payload: rankings, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initRankings"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initRankings", payload: true })
								);
							}
						});
				});
			});
	}
}
