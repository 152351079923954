<!-- Blank (Required) Error field -->
@if (
	SForm.getInputErrorType($any(form.get(fieldType === "singleLanguage" ? field.uid : field.uid + "_" + language))) ===
	"required"
) {
	<div class="form-bloc-error">
		<p class="error-label-text">
			{{ "edit-profil.errors.blank.simple_text" | translate }}
		</p>
	</div>
}

<!-- Invalids format -->
<!-- (--minimum length error --) -->
@if (
	SForm.getInputErrorType($any(form.get(fieldType === "singleLanguage" ? field.uid : field.uid + "_" + language))) ===
		"minlength" && $any(field.inputFieldType) !== typeCustomFields.PASSWORD
) {
	<div class="form-bloc-error">
		<p class="error-label-text">
			{{ $any(field.value)?.baseSettings?.type }}
			{{ "edit-profil.errors.invalid.min_length" | translate }}&nbsp;({{ SForm.minChar1 }})
			{{ "edit-profil.errors.characters" | translate }}&nbsp;
		</p>
	</div>
}

<!-- (--maximum length error --) -->
@if (
	SForm.getInputErrorType($any(form.get(fieldType === "singleLanguage" ? field.uid : field.uid + "_" + language))) ===
	"maxlength"
) {
	<div class="form-bloc-error">
		<p class="error-label-text">
			{{ "edit-profil.errors.invalid.multiple_text-too-long" | translate }} ({{ SForm.maxChar100 }})
		</p>
	</div>
}

<!-- Patterns -->
@if (
	SForm.getInputErrorType($any(form.get(fieldType === "singleLanguage" ? field.uid : field.uid + "_" + language))) ===
	"email"
) {
	<div class="form-bloc-error">
		<!-- EMAIL -->
		<p class="error-label-text">
			{{ "edit-profil.errors.invalid.email" | translate }}
		</p>
	</div>
}

<!-- others -->
@if (
	SForm.getInputErrorType($any(form.get(fieldType === "singleLanguage" ? field.uid : field.uid + "_" + language))) ===
	"pattern"
) {
	<div class="form-bloc-error">
		<!-- Url -->
		<p class="error-label-text">
			{{ "edit-profil.errors.invalid.generic" | translate }}
		</p>
	</div>
}

<!-- Custom Validators -->
<!-- @if ($any(field.inputFieldType) === typeCustomFields.PASSWORD && SForm.getInputErrorType($any(form.get(field.uid))); as
errorType ) {
<div class="form-bloc-error tw-mx-auto">
  @if ( errorType === 'password') {
  <p class="error-label-text">
    {{ form.get(field.uid)?.errors?.[errorType]?.['msg'] | translate }}
  </p>
  }
</div>
} -->

<!-- Date -->
@if (
	$any(field.value)?.baseSettings?.type === typeCustomFields.DATE &&
		SForm.getInputErrorType($any(form.get(field.uid)));
	as errorType
) {
	<div class="form-bloc-error">
		@if (
			errorType === "multiTextIsRequired" ||
			errorType === "controlShouldNotContainValue" ||
			errorType === "multiTextShouldContainValue"
		) {
			<p class="error-label-text">
				{{ form.get(field.uid)?.errors?.[errorType]?.["msg"] | translate }}
			</p>
		}
	</div>
}

<!-- Min/max dateValidators -->
@if (
	$any(field.value)?.baseSettings?.type === typeCustomFields.DATE &&
		SForm.getInputErrorType($any(form.get(field.uid)));
	as errorType
) {
	<div class="form-bloc-error">
		@if (errorType === "dateMaximum") {
			<p class="error-label-text">
				{{
					form.get(field.uid)?.errors?.[errorType]?.["msg"]
						| translate
							: {
									maxDate: SLuxon.convertIsoDateToFormat(
										$any(field.value)?.baseSettings?.dateRule?.maxDayDateIsEnabled
											? dateTime.now().toISO()
											: $any(field.value)?.baseSettings?.dateRule?.maxDate,
										language
									)
							  }
				}}
			</p>
		}
		@if (errorType === "dateMinimum") {
			<p class="error-label-text">
				{{
					form.get(field.uid)?.errors?.[errorType]?.["msg"]
						| translate
							: {
									minDate: SLuxon.convertIsoDateToFormat(
										$any(field.value)?.baseSettings?.dateRule?.minDayDateIsEnabled
											? dateTime.now().toISO()
											: $any(field.value)?.baseSettings?.dateRule?.minDate,
										language
									)
							  }
				}}
			</p>
		}
	</div>
}

<!-- checkbox -->
@if (
	$any(field.value)?.baseSettings?.type === typeCustomFields.CHECKBOX &&
		SForm.getInputErrorType($any(form.get(field.uid)));
	as errorType
) {
	<div class="form-bloc-error">
		@if (errorType === "checkboxError") {
			<p class="error-label-text">
				{{ form.get(field.uid)?.errors?.[errorType]?.["msg"] | translate }}
			</p>
		}
	</div>
}
